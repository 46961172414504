import React from "react";
import "./Form.scss";

function TextInput(props) {
  return (
    <div className="form-line-input-wrapper">
      {props.label && (
        <label className="text-input-form-label">{props.label}</label>
      )}
      <input
        autoComplete="off"
        className={`form-input-ui`}
        id={props.name}
        name={props.name}
        type="text"
        readOnly={!!props.readOnly}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange}
        value={props.value}
        spellCheck="false"
        disabled={props.disabled}
        style={props.style}
        {...props}
      ></input>
    </div>
  );
}
export { TextInput };

function NumberInput(props) {
  return (
    <div className="xinput-wrapper form-line-input-wrapper">
      {props.label && (
        <label className="text-input-form-label">{props.label}</label>
      )}
      <input
        autoComplete="off"
        className={`form-input-ui`}
        id={props.id}
        name={props.name}
        type={props.type}
        readOnly={!!props.readOnly}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        max={props.max}
        {...props}
      />
    </div>
  );
}

export { NumberInput };

function PaletteTextInput(props) {
  return (
    <div className="form-line-input-wrapper">
      <label className="text-input-form-label">{props.label}</label>
      <input
        autoComplete="off"
        className={`form-input-ui general-settings-form-input-ui`}
        id={props.name}
        name={props.name}
        type="text"
        readOnly={!!props.readOnly}
        onChange={props.onChange}
        value={props.value}
        spellCheck="false"
        max={props.max}
        style={props.style}
        {...props}
      />
    </div>
  );
}

export { PaletteTextInput };

function LayerSettingsInput(props) {
  return (
    <input
      className={`form-input-ui general-settings-form-input-ui`}
      id={props.id}
      readOnly={!!props.readOnly}
      name={props.id}
      disabled={props.disabled}
      autoComplete="off"
      type="text"
      onChange={props.onChange}
      value={props.value}
      spellCheck="false"
      max={props.max}
      style={props.style}
      onFocus={props.onFocus}
      {...props}
    />
  );
}

export { LayerSettingsInput };
