import React from "react";
import Switch from "react-switch";
import BasicRasterizationSettings from "./BasicRasterizationSettings";
import ExpertRasterizationSettings from "./ExpertRasterizationSettings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import * as actions from "../../../redux/actions/rasterAreaActions";
import "./RasterizationSettings.scss";

class RasterizationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inBasicMode: true,
    };
    this.rasterModeChange = this.rasterModeChange.bind(this);
  }

  componentDidMount() {
    if (
      this.props.rasterArea.rasterPreview === null &&
      this.props.rasterArea.parentRasterPreview === null
    ) {
      this.props.setInitialDefaultVisibilites();
    }

    if (this.props.applicationField === 2) {
      this.props.setRasterGridWidth({
        rasterGridWidth: 1,
        crsDef: this.props.crsDef,
      });
    }
  }

  componentWillUnmount() {
    this.props.setVisibilitiesOnExitMode();
  }

  rasterModeChange() {
    this.setState({ inBasicMode: !this.state.inBasicMode });
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="raster-switch-container">
          <div className="raster-switch-lbl">{t("Basic")}</div>
          <Switch
            onChange={this.rasterModeChange}
            checked={!this.state.inBasicMode}
            checkedIcon={false}
            uncheckedIcon={false}
            height={11}
            width={25}
            handleDiameter={6}
            offHandleColor="#ffffff"
            onColor="#a1a1a1"
            offColor="#a1a1a1"
          />
          <div className="raster-switch-lbl">{t("Expert")}</div>
        </div>
        <h3 className="raster-settings-header">{`${t("RasterSettings")}`}</h3>

        {this.state.inBasicMode ? (
          <BasicRasterizationSettings inBasicMode={this.state.inBasicMode} />
        ) : (
          <ExpertRasterizationSettings />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rasterArea: state.rasterArea,
    applicationField: state.projects?.currentCase?.applicationField,
    crsDef: state.map.crsDef,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInitialDefaultVisibilites: () =>
      dispatch(actions.setInitialDefaultVisibilites()),
    setVisibilitiesOnExitMode: () =>
      dispatch(actions.setVisibilitiesOnExitMode()),
    onSingleRasterLoadVisibility: () =>
      dispatch(actions.onSingleRasterLoadVisibility()),
    setRasterGridWidth: (payload) =>
      dispatch(actions.setRasterGridWidth(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(RasterizationSettings)));
