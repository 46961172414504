export const types = {
  RESET_LEGEND: "RESET_LEGEND",
  SET_HIDDEN_OBJECT_TYPES_INDEXES: "SET_HIDDEN_OBJECT_TYPES_INDEXES",
  TOGGLE_TOPOGRAPHY_VISIBILITY: "TOGGLE_TOPOGRAPHY_VISIBILITY",
  TOGGLE_ALL_DRAWN_OBJECTS_VISIBILITY: "TOGGLE_ALL_DRAWN_OBJECTS_VISIBILITY",
  TOGGLE_ALL_DRAWN_TREES_VISIBILITY: "TOGGLE_ALL_DRAWN_TREES_VISIBILITY",
  SHOW_LEGEND: "SHOW_LEGEND",
  HIDE_LEGEND: "HIDE_LEGEND",
  HIDE_TOPOGRAPHY: "HIDE_TOPOGRAPHY",
  GET_MAX_ELEVATION: "GET_MAX_ELEVATION",
  GET_MIN_ELEVATION: "GET_MIN_ELEVATION",
  GET_LEGEND_DATA: "GET_LEGEND_DATA",
  GET_EXTRA_LEGEND_DATA: "GET_EXTRA_LEGEND_DATA",
  SET_EXTRA_TOPOGRAPHY_VISIBILITY: "SET_EXTRA_TOPOGRAPHY_VISIBILITY",
  GET_EXTRA_LEGEND_DATA_PROPS: "GET_EXTRA_LEGEND_DATA_PROPS",

  TOGGLE_WIND_TURBINE_TOOLTIP: "TOGGLE_WIND_TURBINE_TOOLTIP",

  TOGGLE_MAP_POI_TOOLTIP: "TOGGLE_MAP_POI_TOOLTIP",
};
