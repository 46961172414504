import React from "react";
import {
  RotateCcw,
  Grid,
  Airplay,
  Move,
  CheckCircle,
  XCircle,
} from "react-feather";
import { Loader } from "../../../Components/Loader";
import { Select } from "../Form/Select";
import { errorCodes } from "../consts/consts";
import {
  domainType,
  gridSizes,
  windGridSizes,
  staticExpertGridSizes,
} from "./RasterizationSettingsConst";
import { NumericInput } from "../Form/NumericInput";
import * as actions from "../../../redux/actions/rasterAreaActions";
import { putCaseGeo } from "../../../api/caseApi";
import { reproject } from "reproject";
import { wgDef } from "../../../common/utmDef";
import gp from "geojson-precision";
import { selectCaseSimulationNotifications } from "../../../redux/selectors/simulationSelector";
import {
  requestRasterizationPreviewAsync,
  requestStaticDriverAsync,
} from "../../../api/rasterizationApi";
import { setCaseDrawnGeojsonUrl } from "../../../redux/actions/caseActions";
import { uploadBlobToAzure, downloadBlobAsync } from "../../../api/storageApi";
import {
  setDrawnPolygonGeojsonCrs,
  createSingleTreeGeoJson,
  createSingleEmissionDrawnGeoJson,
  createSingleMapPoiDrawnGeoJson,
  createSingleWindTurbineDrawnGeoJson,
} from "../../../redux/actions/mapActions";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import "./RasterizationSettings.scss";

const helpUrl =
  "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/domainmap.html#sec-domainrasterization";

class BasicRasterizationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRasterProcessing: false,
      rasterError: null,
      isStaticDriverProcessing: false,
    };
    this.createRasterPreview = this.createRasterPreview.bind(this);
    this.createStaticDriver = this.createStaticDriver.bind(this);
  }

  async componentDidMount() {
    if (this.props.rasterArea.rasterPreview !== null) {
      this.props.onSingleRasterLoadVisibility();
    } else if (this.props.rasterArea.parentRasterPreview !== null) {
      this.props.onNestedRasterLoadVisibility();
    }

    if (
      (this.props.currentCase &&
        !this.props.rasterArea.rasterPreview &&
        this.props.currentCase?.rasterPreviewJob?.status === 2) ||
      this.props.currentCase?.rasterPreviewJob?.status === 2
    ) {
      if (!this.props.currentCase.rasterPreviewJob.childResultUrl) {
        const rasterPreviewGeoJson = await downloadBlobAsync(
          this.props.currentCase.rasterPreviewJob.resultUrl
        );
        this.props.getRasterPreview({
          rasterPreview: rasterPreviewGeoJson.data,
          crsDef: this.props.crsDef,
        });
      } else {
        const childRasterPreviewGeoJson = await downloadBlobAsync(
          this.props.currentCase.rasterPreviewJob.childResultUrl
        );
        const parentRasterPreviewGeoJson = await downloadBlobAsync(
          this.props.currentCase.rasterPreviewJob.resultUrl
        );
        this.props.getNestedRasterPreview({
          parentRasterPreview: parentRasterPreviewGeoJson.data,
          childRasterPreview: childRasterPreviewGeoJson.data,
          crsDef: this.props.crsDef,
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.currentCase?.rasterPreviewJob &&
      this.props.currentCase?.rasterPreviewJob
    ) {
      if (
        prevProps.currentCase.rasterPreviewJob.status !== 2 &&
        this.props.currentCase.rasterPreviewJob.status === 2
      ) {
        this.setState({ isRasterProcessing: false });

        if (!this.props.currentCase.rasterPreviewJob.childResultUrl) {
          const rasterPreviewGeoJson = await downloadBlobAsync(
            this.props.currentCase.rasterPreviewJob.resultUrl
          );
          this.props.getRasterPreview({
            rasterPreview: rasterPreviewGeoJson.data,
            crsDef: this.props.crsDef,
          });
        } else {
          const childRasterPreviewGeoJson = await downloadBlobAsync(
            this.props.currentCase.rasterPreviewJob.childResultUrl
          );
          const parentRasterPreviewGeoJson = await downloadBlobAsync(
            this.props.currentCase.rasterPreviewJob.resultUrl
          );
          this.props.getNestedRasterPreview({
            parentRasterPreview: parentRasterPreviewGeoJson.data,
            childRasterPreview: childRasterPreviewGeoJson.data,
            crsDef: this.props.crsDef,
          });
        }
      } else if (
        prevProps.currentCase.rasterPreviewJob.status !== 3 &&
        this.props.currentCase.rasterPreviewJob.status === 3
      ) {
        console.log("error");
        this.setState({
          isRasterProcessing: false,
          //rasterError: "RasterProcessError",
        });
      }
    }

    if (
      prevProps.currentCase?.staticDriverJob &&
      this.props.currentCase?.staticDriverJob
    ) {
      if (
        prevProps.currentCase.staticDriverJob.status !== 2 &&
        this.props.currentCase.staticDriverJob.status === 2
      ) {
        this.setState({
          isStaticDriverProcessing: false,
        });
      } else if (
        prevProps.currentCase.staticDriverJob.status !== 3 &&
        this.props.currentCase.staticDriverJob.status === 3
      ) {
        this.setState({
          isStaticDriverProcessing: false,
        });
      }
    }

    if (
      this.props.currentCase.applicationField === 2 &&
      this.props.rasterArea.domainType === "SingleDomain" &&
      prevProps.rasterArea.domainType === "NestedDomain"
    ) {
      this.props.setRasterGridWidth({
        rasterGridWidth: 1,
        crsDef: this.props.crsDef,
      });
    }
  }

  async createRasterPreview() {
    this.setState({
      staticDriverProcessing: true,
      isRasterProcessing: true,
    });
    const caseEntity = this.props.loadedProject.caseDetailsDto.find(
      (c) => c.id === this.props.match.params.caseId
    );

    const geojsonUrl = caseEntity.verifiedGeojsonUrl;
    const drawnGeojsonUrl = `${this.props.loadedProject.id}/${caseEntity.id}/drawnGeojsonUrl.json`;
    const polygonDataUrl = `${this.props.loadedProject.id}/${caseEntity.id}/polygonData.json`;

    await this.props.createSingleTreeGeoJson(this.props.loadedProject.epsg);
    const geoJsonWg = cloneDeep(this.props.geoJsonWg);

    const emissionGeoJsonWg = cloneDeep(this.props.emissionGeoJsonWg);
    const mapPoiGeoJsonWg = cloneDeep(this.props.mapPoiGeoJsonWg);
    const windTurbineGeoJsonWg = cloneDeep(this.props.windTurbineGeoJsonWg);

    if (emissionGeoJsonWg.features.length > 0) {
      geoJsonWg.features.push(...emissionGeoJsonWg.features);
    }
    if (mapPoiGeoJsonWg.features.length > 0) {
      geoJsonWg.features.push(...mapPoiGeoJsonWg.features);
    }
    if (windTurbineGeoJsonWg.features.length > 0) {
      geoJsonWg.features.push(...windTurbineGeoJsonWg.features);
    }

    const geoJson = reproject(geoJsonWg, wgDef, this.props.crsDef);
    const geoJsonUTM = gp.parse(geoJson, 2);

    const drawnPolygonGeojsonWg = this.props.drawnPolygonGeojsonWg;
    drawnPolygonGeojsonWg.crs.properties.name = `urn:ogc:def:crs:EPSG::${this.props.loadedProject.epsg}`;

    const drawnPolygonGeoJsonConv = reproject(
      drawnPolygonGeojsonWg,
      wgDef,
      this.props.crsDef
    );

    const drawnPolygonGeoJsonUtm = gp.parse(drawnPolygonGeoJsonConv, 2);

    await this.props.createSingleEmissionDrawnGeoJson(
      this.props.loadedProject.epsg
    );
    await this.props.createSingleMapPoiDrawnGeoJson(
      this.props.loadedProject.epsg
    );
    await this.props.createSingleWindTurbineDrawnGeoJson(
      this.props.loadedProject.epsg
    );

    if (this.props.emissionDrawnGeoJsonUtm.features.length !== 0) {
      drawnPolygonGeoJsonUtm.features.push(
        ...this.props.emissionDrawnGeoJsonUtm.features
      );
    }

    if (this.props.mapPoiDrawnGeoJsonUtm.features.length !== 0) {
      drawnPolygonGeoJsonUtm.features.push(
        ...this.props.mapPoiDrawnGeoJsonUtm.features
      );
    }
    if (this.props.windTurbineDrawnGeoJsonUtm.features.length !== 0) {
      drawnPolygonGeoJsonUtm.features.push(
        ...this.props.windTurbineDrawnGeoJsonUtm.features
      );
    }

    this.props.treeGeoJsonUtm.features.map((x) => {
      return drawnPolygonGeoJsonUtm.features.push(x);
    });

    if (!!geojsonUrl) {
      await uploadBlobToAzure(geojsonUrl, JSON.stringify(geoJsonUTM));
    }

    await uploadBlobToAzure(
      polygonDataUrl,
      JSON.stringify(this.props.polygonData)
    );

    await uploadBlobToAzure(
      drawnGeojsonUrl,
      JSON.stringify(drawnPolygonGeoJsonUtm)
    );

    const request = {
      geojsonUrl: geojsonUrl,
      polygonDataUrl: polygonDataUrl,
      drawnGeojsonUrl: drawnGeojsonUrl,
    };

    await putCaseGeo(caseEntity.id, request);
    this.props.setCaseDrawnGeojsonUrl({
      drawnGeojsonUrl: drawnGeojsonUrl,
      polygonDataUrl: polygonDataUrl,
    });

    const rasterMethodIndex =
      this.props.rasterArea.rasterMethod === "TouchAll" ? 2 : 1;
    const soilType = this.props.rasterArea.soilType;
    const soilTypeIndex =
      soilType === "Coarse"
        ? 1
        : soilType === "Medium"
        ? 2
        : soilType === "MediumFine"
        ? 3
        : soilType === "Fine"
        ? 4
        : soilType === "VeryFine"
        ? 5
        : soilType === "Organic"
        ? 6
        : soilType === "UserDefined"
        ? 99
        : "";

    const externalFiles = cloneDeep(this.props.files);

    const tiffFiles = externalFiles.filter((x) => {
      return x.type === "image/tiff";
    });

    const bhParentFile =
      this.props.rasterArea.buildingHeights === "fromGeotiff" &&
      this.props.rasterArea.buildingHeightsFile === null
        ? tiffFiles[0]
        : this.props.rasterArea.buildingHeights === "fromGeotiff"
        ? tiffFiles.find((x) => {
            return (
              x.name === this.props.rasterArea.buildingHeightsFile ||
              x.path === this.props.rasterArea.buildingHeightsFile ||
              x.name === this.props.rasterArea.buildingHeightsFile?.name ||
              x.path === this.props.rasterArea.buildingHeightsFile?.path
            );
          })
        : this.props.rasterArea.buildingHeightsFile;

    const thParentFile =
      this.props.rasterArea.treeHeights === "fromGeotiff" &&
      this.props.rasterArea.treeHeightsFile === null
        ? tiffFiles[0]
        : this.props.rasterArea.treeHeights === "fromGeotiff"
        ? tiffFiles.find((x) => {
            return (
              x.name === this.props.rasterArea.treeHeightsFile ||
              x.path === this.props.rasterArea.treeHeightsFile ||
              x.name === this.props.rasterArea.treeHeightsFile?.name ||
              x.path === this.props.rasterArea.treeHeightsFile?.path
            );
          })
        : this.props.rasterArea.treeHeightsFile;

    if (this.props.rasterArea.domainType === "SingleDomain") {
      const resolution = this.props.rasterArea.rasterGridWidth;
      const xLength = resolution * this.props.rasterArea.rasterGridXNumber;
      const yLength = resolution * this.props.rasterArea.rasterGridYNumber;
      const x_min = this.props.rasterArea.rasterCenterLngUtm - xLength / 2;
      const x_max =
        Number(this.props.rasterArea.rasterCenterLngUtm) + Number(xLength) / 2;
      const y_min = this.props.rasterArea.rasterCenterLatUtm - yLength / 2;
      const y_max =
        Number(this.props.rasterArea.rasterCenterLatUtm) + Number(yLength) / 2;

      const request = {
        rasterParameters: {
          x_min: Number(x_min),
          x_max: x_max,
          y_min: Number(y_min),
          y_max: y_max,
          season: this.props.rasterArea.season,
          soil_Type: soilTypeIndex,
          resolution: resolution,
          rasterMethod: rasterMethodIndex,
          rasterizationPriority: this.props.rasterArea.rasterizationPriority,
          fillValue: this.props.rasterArea.fillValue.rasterValue,
          surfaceBelowTree:
            this.props.rasterArea.fillValueBelowTreePatches.rasterValue,
          additionalData: {
            globalParameters: {
              soilParameterization: "GloballyUniform",
              waterTemperatureParameteriation:
                this.props.rasterArea.globalWaterTemperatureSource,
              waterTemperatureGlobal:
                this.props.rasterArea.globalWaterTemperature,
              verticalResolutionMode:
                this.props.rasterArea.verticalResolutionMode,
              verticalResolutionMeter:
                this.props.rasterArea.verticalResolutionMode === "Manual"
                  ? this.props.rasterArea.verticalResolutionMeter
                  : this.props.rasterArea.rasterGridWidth,
              parentOffsetX: this.props.rasterArea.parentOffsetX,
              parentOffsetY: this.props.rasterArea.parentOffsetY,
              normalizeTopography: this.props.rasterArea.normalizeTopography,
              buildingSettings: {
                bhParent: this.props.rasterArea.buildingHeights,
                bhParentFile: bhParentFile,
                bhChild: null,
                bhChildFile: null,
              },
              treeSettings: {
                thParent: this.props.rasterArea.treeHeights,
                thParentFile: thParentFile,
                thChild: null,
                thChildFile: null,
              },
              domainBufferSettings: {
                gridPointsNorth: this.props.rasterArea.gridPointsNorth,
                gridPointsSouth: this.props.rasterArea.gridPointsSouth,
                gridPointsWest: this.props.rasterArea.gridPointsWest,
                gridPointsEast: this.props.rasterArea.gridPointsEast,
                bufferFillType: this.props.rasterArea.bufferFillType,
                bufferFillValue:
                  this.props.rasterArea.bufferFillValue.rasterValue,
                bufferMethod: this.props.rasterArea.bufferMethod,
                bufferTopographyInterpMethod:
                  this.props.rasterArea.bufferTopographyInterpMethod,
              },
            },
          },
        },
        geojsonUrl: geojsonUrl,
        polygonDataUrl: polygonDataUrl,
        drawnGeojsonUrl: drawnGeojsonUrl,
      };

      if (soilType === "UserDefined") {
        request.rasterParameters.additionalData.globalParameters.soilParameters =
          {
            alpha_vg: this.props.rasterArea.soilAlphaCoefficient,
            l_vg: this.props.rasterArea.lSoilCoefficient,
            n_vg: this.props.rasterArea.nSoilCoefficient,
            gamma_w_sat: this.props.rasterArea.soilHydraulicConductivity,
            m_sat: this.props.rasterArea.soilMoistureSaturation,
            m_fc: this.props.rasterArea.soilMoistureCapacity,
            m_wilt: this.props.rasterArea.soilMoistureWilting,
            m_res: this.props.rasterArea.soilMoistureContent,
          };
      }

      console.log(JSON.stringify(request, null, 2));
      await requestRasterizationPreviewAsync(
        this.props.match.params.caseId,
        request
      );
      this.props.setSingleRasterCaseProps(request.rasterParameters);
    } else if (this.props.rasterArea.domainType === "NestedDomain") {
      const parentResolution = this.props.rasterArea.parentGridSize;
      const childResolution = this.props.rasterArea.childGridSize;
      const xParentLength =
        parentResolution * this.props.rasterArea.parentRasterGridXNumber;
      const yParentLength =
        parentResolution * this.props.rasterArea.parentRasterGridYNumber;
      const x_parent_min =
        this.props.rasterArea.rasterCenterLngUtm - xParentLength / 2;
      const x_parent_max =
        Number(this.props.rasterArea.rasterCenterLngUtm) +
        Number(xParentLength) / 2;
      const y_parent_min =
        this.props.rasterArea.rasterCenterLatUtm - yParentLength / 2;
      const y_parent_max =
        Number(this.props.rasterArea.rasterCenterLatUtm) +
        Number(yParentLength) / 2;

      const xChildLength =
        childResolution * this.props.rasterArea.childRasterGridXNumber;
      const yChildLength =
        childResolution * this.props.rasterArea.childRasterGridYNumber;
      const x_child_min =
        this.props.rasterArea.rasterCenterLngUtm - xChildLength / 2;
      const x_child_max =
        Number(this.props.rasterArea.rasterCenterLngUtm) +
        Number(xChildLength) / 2;
      const y_child_min =
        this.props.rasterArea.rasterCenterLatUtm - yChildLength / 2;
      const y_child_max =
        Number(this.props.rasterArea.rasterCenterLatUtm) +
        Number(yChildLength) / 2;

      const bhChildFile =
        this.props.rasterArea.childBuildingHeights === "fromGeotiff" &&
        this.props.rasterArea.childBuildingHeightsFile === null
          ? tiffFiles[0]
          : this.props.rasterArea.childBuildingHeights === "fromGeotiff"
          ? tiffFiles.find((x) => {
              return (
                x.name === this.props.rasterArea.childBuildingHeightsFile ||
                x.name ===
                  this.props.rasterArea.childBuildingHeightsFile.name ||
                x.path === this.props.rasterArea.childBuildingHeightsFile ||
                x.path === this.props.rasterArea.childBuildingHeightsFile?.path
              );
            })
          : this.props.rasterArea.childBuildingHeightsFile;

      const thChildFile =
        this.props.rasterArea.childTreeHeights === "fromGeotiff" &&
        this.props.rasterArea.childTreeHeightsFile === null
          ? tiffFiles[0]
          : this.props.rasterArea.childTreeHeights === "fromGeotiff"
          ? tiffFiles.find((x) => {
              return (
                x.name === this.props.rasterArea.childTreeHeightsFile ||
                x.name === this.props.rasterArea.childTreeHeightsFile.name ||
                x.path === this.props.rasterArea.childTreeHeightsFile ||
                x.path === this.props.rasterArea.childTreeHeightsFile?.path
              );
            })
          : this.props.rasterArea.childTreeHeightsFile;

      const request = {
        rasterParameters: {
          x_min: x_parent_min + Number(this.props.rasterArea.parentOffsetX),
          x_max: x_parent_max + Number(this.props.rasterArea.parentOffsetX),
          y_min: y_parent_min + Number(this.props.rasterArea.parentOffsetY),
          y_max: y_parent_max + Number(this.props.rasterArea.parentOffsetY),
          season: this.props.rasterArea.season,
          soil_Type: soilTypeIndex,
          resolution: parentResolution,
          rasterMethod: rasterMethodIndex,
          rasterizationPriority: this.props.rasterArea.rasterizationPriority,
          fillValue: this.props.rasterArea.fillValue.rasterValue,
          surfaceBelowTree:
            this.props.rasterArea.fillValueBelowTreePatches.rasterValue,
          additionalData: {
            globalParameters: {
              soilParameterization: "GloballyUniform",
              waterTemperatureParameteriation:
                this.props.rasterArea.globalWaterTemperatureSource,
              waterTemperatureGlobal:
                this.props.rasterArea.globalWaterTemperature,
              verticalResolutionMode:
                this.props.rasterArea.verticalResolutionMode,
              verticalResolutionMeter:
                this.props.rasterArea.verticalResolutionMode === "Manual"
                  ? this.props.rasterArea.verticalResolutionMeter
                  : this.props.rasterArea.rasterGridWidth,
              parentOffsetX: this.props.rasterArea.parentOffsetX,
              parentOffsetY: this.props.rasterArea.parentOffsetY,
              normalizeTopography: this.props.rasterArea.normalizeTopography,
              buildingSettings: {
                bhParent: this.props.rasterArea.buildingHeights,
                bhParentFile: bhParentFile,
                bhChild: this.props.rasterArea.childBuildingHeights,
                bhChildFile: bhChildFile,
              },
              treeSettings: {
                thParent: this.props.rasterArea.treeHeights,
                thParentFile: thParentFile,
                thChild: this.props.rasterArea.childTreeHeights,
                thChildFile: thChildFile,
              },
              domainBufferSettings: {
                gridPointsNorth: this.props.rasterArea.gridPointsNorth,
                gridPointsSouth: this.props.rasterArea.gridPointsSouth,
                gridPointsWest: this.props.rasterArea.gridPointsWest,
                gridPointsEast: this.props.rasterArea.gridPointsEast,
                bufferFillType: this.props.rasterArea.bufferFillType,
                bufferFillValue:
                  this.props.rasterArea.bufferFillValue.rasterValue,
                bufferMethod: this.props.rasterArea.bufferMethod,
                bufferTopographyInterpMethod:
                  this.props.rasterArea.bufferTopographyInterpMethod,
              },
            },
          },
        },
        childRasterParameters: {
          x_min: x_child_min,
          x_max: x_child_max,
          y_min: y_child_min,
          y_max: y_child_max,
          season: this.props.rasterArea.season,
          soil_Type: soilTypeIndex,
          resolution: childResolution,
          rasterMethod: rasterMethodIndex,
          rasterizationPriority: this.props.rasterArea.rasterizationPriority,
          fillValue: this.props.rasterArea.fillValue.rasterValue,
          surfaceBelowTree:
            this.props.rasterArea.fillValueBelowTreePatches.rasterValue,
        },
        geojsonUrl: geojsonUrl,
        polygonDataUrl: polygonDataUrl,
        drawnGeojsonUrl: drawnGeojsonUrl,
      };

      if (soilType === "UserDefined") {
        request.rasterParameters.additionalData.globalParameters.soilParameters =
          {
            alpha_vg: this.props.rasterArea.soilAlphaCoefficient,
            l_vg: this.props.rasterArea.lSoilCoefficient,
            n_vg: this.props.rasterArea.nSoilCoefficient,
            gamma_w_sat: this.props.rasterArea.soilHydraulicConductivity,
            m_sat: this.props.rasterArea.soilMoistureSaturation,
            m_fc: this.props.rasterArea.soilMoistureCapacity,
            m_wilt: this.props.rasterArea.soilMoistureWilting,
            m_res: this.props.rasterArea.soilMoistureContent,
          };
      }

      console.log(JSON.stringify(request, null, 2));

      await requestRasterizationPreviewAsync(
        this.props.match.params.caseId,
        request
      );
      this.props.setNestedRasterCaseProps(request);
    }

    this.props.setDrawnPolygonGeojsonCrs(this.props.loadedProject.epsg);
  }

  async createStaticDriver() {
    this.setState({ isStaticDriverProcessing: true });
    const caseEntity = this.props.loadedProject.caseDetailsDto.find(
      (c) => c.id === this.props.match.params.caseId
    );

    const geojsonUrl = caseEntity.verifiedGeojsonUrl;
    const drawnGeojsonUrl = `${this.props.loadedProject.id}/${caseEntity.id}/drawnGeojsonUrl.json`;
    const polygonDataUrl = `${this.props.loadedProject.id}/${caseEntity.id}/polygonData.json`;

    await this.props.createSingleTreeGeoJson(this.props.loadedProject.epsg);
    //const geoJson = reproject(this.props.geoJsonWg, wgDef, this.props.crsDef);
    //const geoJsonUTM = gp.parse(geoJson, 2);
    const drawnPolygonGeoJsonConv = reproject(
      this.props.drawnPolygonGeojsonWg,
      wgDef,
      this.props.crsDef
    );

    const externalFiles = cloneDeep(this.props.files);

    const tiffFiles = externalFiles.filter((x) => {
      return x.type === "image/tiff";
    });

    const drawnPolygonGeoJsonUtm = gp.parse(drawnPolygonGeoJsonConv, 2);
    this.props.treeGeoJsonUtm.features.map((x) => {
      return drawnPolygonGeoJsonUtm.features.push(x);
    });

    // if (!!geojsonUrl) {
    //   await uploadBlobToAzure(geojsonUrl, JSON.stringify(geoJsonUTM));
    // }

    // await uploadBlobToAzure(
    //   polygonDataUrl,
    //   JSON.stringify(this.props.polygonData)
    // );

    // await uploadBlobToAzure(
    //   drawnGeojsonUrl,
    //   JSON.stringify(drawnPolygonGeoJsonUtm)
    // );

    // const request = {
    //   geojsonUrl: geojsonUrl,
    //   polygonDataUrl: polygonDataUrl,
    //   drawnGeojsonUrl: drawnGeojsonUrl,
    // };

    // await putCaseGeo(caseEntity.id, request);
    // this.props.setCaseDrawnGeojsonUrl({
    //   drawnGeojsonUrl: drawnGeojsonUrl,
    //   polygonDataUrl: polygonDataUrl,
    // });

    const rasterMethodIndex =
      this.props.rasterArea.rasterMethod === "TouchAll" ? 2 : 1;
    const soilType = this.props.rasterArea.soilType;
    const soilTypeIndex =
      soilType === "Coarse"
        ? 1
        : soilType === "Medium"
        ? 2
        : soilType === "MediumFine"
        ? 3
        : soilType === "Fine"
        ? 4
        : soilType === "VeryFine"
        ? 5
        : soilType === "Organic"
        ? 6
        : soilType === "UserDefined"
        ? 99
        : "";

    const bhParentFile =
      this.props.rasterArea.buildingHeights === "fromGeotiff" &&
      this.props.rasterArea.buildingHeightsFile === null
        ? tiffFiles[0]
        : this.props.rasterArea.buildingHeights === "fromGeotiff"
        ? tiffFiles.find((x) => {
            return (
              x.name === this.props.rasterArea.buildingHeightsFile ||
              x.path === this.props.rasterArea.buildingHeightsFile ||
              x.name === this.props.rasterArea.buildingHeightsFile?.name ||
              x.path === this.props.rasterArea.buildingHeightsFile?.path
            );
          })
        : this.props.rasterArea.buildingHeightsFile;

    const thParentFile =
      this.props.rasterArea.treeHeights === "fromGeotiff" &&
      this.props.rasterArea.treeHeightsFile === null
        ? tiffFiles[0]
        : this.props.rasterArea.treeHeights === "fromGeotiff"
        ? tiffFiles.find((x) => {
            return (
              x.name === this.props.rasterArea.treeHeightsFile ||
              x.path === this.props.rasterArea.treeHeightsFile ||
              x.name === this.props.rasterArea.treeHeightsFile?.name ||
              x.path === this.props.rasterArea.treeHeightsFile?.path
            );
          })
        : this.props.rasterArea.treeHeightsFile;

    if (this.props.rasterArea.domainType === "SingleDomain") {
      const resolution = this.props.rasterArea.rasterGridWidth;
      const xLength = resolution * this.props.rasterArea.rasterGridXNumber;
      const yLength = resolution * this.props.rasterArea.rasterGridYNumber;
      const x_min = this.props.rasterArea.rasterCenterLngUtm - xLength / 2;
      const x_max =
        Number(this.props.rasterArea.rasterCenterLngUtm) + Number(xLength) / 2;
      const y_min = this.props.rasterArea.rasterCenterLatUtm - yLength / 2;
      const y_max =
        Number(this.props.rasterArea.rasterCenterLatUtm) + Number(yLength) / 2;

      const request = {
        rasterParameters: {
          x_min: Number(x_min),
          x_max: x_max,
          y_min: Number(y_min),
          y_max: y_max,
          season: this.props.rasterArea.season,
          soil_Type: soilTypeIndex,
          resolution: resolution,
          rasterMethod: rasterMethodIndex,
          rasterizationPriority: this.props.rasterArea.rasterizationPriority,
          fillValue: this.props.rasterArea.fillValue.rasterValue,
          surfaceBelowTree:
            this.props.rasterArea.fillValueBelowTreePatches.rasterValue,
          additionalData: {
            globalParameters: {
              soilParameterization: "GloballyUniform",
              waterTemperatureParameteriation:
                this.props.rasterArea.globalWaterTemperatureSource,
              waterTemperatureGlobal:
                this.props.rasterArea.globalWaterTemperature,
              verticalResolutionMode:
                this.props.rasterArea.verticalResolutionMode,
              verticalResolutionMeter:
                this.props.rasterArea.verticalResolutionMode === "Manual"
                  ? this.props.rasterArea.verticalResolutionMeter
                  : this.props.rasterArea.rasterGridWidth,
              parentOffsetX: this.props.rasterArea.parentOffsetX,
              parentOffsetY: this.props.rasterArea.parentOffsetY,
              buildingSettings: {
                bhParent: this.props.rasterArea.buildingHeights,
                bhParentFile: bhParentFile,
                bhChild: null,
                bhChildFile: null,
              },
              treeSettings: {
                thParent: this.props.rasterArea.treeHeights,
                thParentFile: thParentFile,
                thChild: null,
                thChildFile: null,
              },
              domainBufferSettings: {
                gridPointsNorth: this.props.rasterArea.gridPointsNorth,
                gridPointsSouth: this.props.rasterArea.gridPointsSouth,
                gridPointsWest: this.props.rasterArea.gridPointsWest,
                gridPointsEast: this.props.rasterArea.gridPointsEast,
                bufferFillType: this.props.rasterArea.bufferFillType,
                bufferFillValue:
                  this.props.rasterArea.bufferFillValue.rasterValue,
                bufferMethod: this.props.rasterArea.bufferMethod,
                bufferTopographyInterpMethod:
                  this.props.rasterArea.bufferTopographyInterpMethod,
              },
            },
          },
        },
        geojsonUrl: geojsonUrl,
        polygonDataUrl: polygonDataUrl,
        drawnGeojsonUrl: drawnGeojsonUrl,
      };

      if (soilType === "UserDefined") {
        request.rasterParameters.additionalData.globalParameters.soilParameters =
          {
            alpha_vg: this.props.rasterArea.soilAlphaCoefficient,
            l_vg: this.props.rasterArea.lSoilCoefficient,
            n_vg: this.props.rasterArea.nSoilCoefficient,
            gamma_w_sat: this.props.rasterArea.soilHydraulicConductivity,
            m_sat: this.props.rasterArea.soilMoistureSaturation,
            m_fc: this.props.rasterArea.soilMoistureCapacity,
            m_wilt: this.props.rasterArea.soilMoistureWilting,
            m_res: this.props.rasterArea.soilMoistureContent,
          };
      }

      console.log(JSON.stringify(request, null, 2));
      await requestStaticDriverAsync(this.props.match.params.caseId, request);
    } else if (this.props.rasterArea.domainType === "NestedDomain") {
      const parentResolution = this.props.rasterArea.parentGridSize;
      const childResolution = this.props.rasterArea.childGridSize;
      const xParentLength =
        parentResolution * this.props.rasterArea.parentRasterGridXNumber;
      const yParentLength =
        parentResolution * this.props.rasterArea.parentRasterGridYNumber;
      const x_parent_min =
        this.props.rasterArea.rasterCenterLngUtm - xParentLength / 2;
      const x_parent_max =
        Number(this.props.rasterArea.rasterCenterLngUtm) +
        Number(xParentLength) / 2;
      const y_parent_min =
        this.props.rasterArea.rasterCenterLatUtm - yParentLength / 2;
      const y_parent_max =
        Number(this.props.rasterArea.rasterCenterLatUtm) +
        Number(yParentLength) / 2;

      const xChildLength =
        childResolution * this.props.rasterArea.childRasterGridXNumber;
      const yChildLength =
        childResolution * this.props.rasterArea.childRasterGridYNumber;
      const x_child_min =
        this.props.rasterArea.rasterCenterLngUtm - xChildLength / 2;
      const x_child_max =
        Number(this.props.rasterArea.rasterCenterLngUtm) +
        Number(xChildLength) / 2;
      const y_child_min =
        this.props.rasterArea.rasterCenterLatUtm - yChildLength / 2;
      const y_child_max =
        Number(this.props.rasterArea.rasterCenterLatUtm) +
        Number(yChildLength) / 2;

      const bhChildFile =
        this.props.rasterArea.childBuildingHeights === "fromGeotiff" &&
        this.props.rasterArea.childBuildingHeightsFile === null
          ? tiffFiles[0]
          : this.props.rasterArea.childBuildingHeights === "fromGeotiff"
          ? tiffFiles.find((x) => {
              return (
                x.name === this.props.rasterArea.childBuildingHeightsFile ||
                x.name ===
                  this.props.rasterArea.childBuildingHeightsFile.name ||
                x.path === this.props.rasterArea.childBuildingHeightsFile ||
                x.path === this.props.rasterArea.childBuildingHeightsFile?.path
              );
            })
          : this.props.rasterArea.childBuildingHeightsFile;

      const thChildFile =
        this.props.rasterArea.childTreeHeights === "fromGeotiff" &&
        this.props.rasterArea.childTreeHeightsFile === null
          ? tiffFiles[0]
          : this.props.rasterArea.childTreeHeights === "fromGeotiff"
          ? tiffFiles.find((x) => {
              return (
                x.name === this.props.rasterArea.childTreeHeightsFile ||
                x.name === this.props.rasterArea.childTreeHeightsFile.name ||
                x.path === this.props.rasterArea.childTreeHeightsFile ||
                x.path === this.props.rasterArea.childTreeHeightsFile?.path
              );
            })
          : this.props.rasterArea.childTreeHeightsFile;

      const request = {
        rasterParameters: {
          x_min: x_parent_min,
          x_max: x_parent_max,
          y_min: y_parent_min,
          y_max: y_parent_max,
          season: this.props.rasterArea.season,
          soil_Type: soilTypeIndex,
          resolution: parentResolution,
          rasterMethod: rasterMethodIndex,
          rasterizationPriority: this.props.rasterArea.rasterizationPriority,
          fillValue: this.props.rasterArea.fillValue.rasterValue,
          surfaceBelowTree:
            this.props.rasterArea.fillValueBelowTreePatches.rasterValue,

          additionalData: {
            globalParameters: {
              soilParameterization: "GloballyUniform",
              waterTemperatureParameteriation:
                this.props.rasterArea.globalWaterTemperatureSource,
              waterTemperatureGlobal:
                this.props.rasterArea.globalWaterTemperature,
              verticalResolutionMode:
                this.props.rasterArea.verticalResolutionMode,
              verticalResolutionMeter:
                this.props.rasterArea.verticalResolutionMode === "Manual"
                  ? this.props.rasterArea.verticalResolutionMeter
                  : this.props.rasterArea.rasterGridWidth,
              parentOffsetX: this.props.rasterArea.parentOffsetX,
              parentOffsetY: this.props.rasterArea.parentOffsetY,
              buildingSettings: {
                bhParent: this.props.rasterArea.buildingHeights,
                bhParentFile: bhParentFile,
                bhChild: this.props.rasterArea.childBuildingHeights,
                bhChildFile: bhChildFile,
              },
              treeSettings: {
                thParent: this.props.rasterArea.treeHeights,
                thParentFile: thParentFile,
                thChild: this.props.rasterArea.childTreeHeights,
                thChildFile: thChildFile,
              },
              domainBufferSettings: {
                gridPointsNorth: this.props.rasterArea.gridPointsNorth,
                gridPointsSouth: this.props.rasterArea.gridPointsSouth,
                gridPointsWest: this.props.rasterArea.gridPointsWest,
                gridPointsEast: this.props.rasterArea.gridPointsEast,
                bufferFillType: this.props.rasterArea.bufferFillType,
                bufferFillValue:
                  this.props.rasterArea.bufferFillValue.rasterValue,
                bufferMethod: this.props.rasterArea.bufferMethod,
                bufferTopographyInterpMethod:
                  this.props.rasterArea.bufferTopographyInterpMethod,
              },
            },
          },
        },
        childRasterParameters: {
          x_min: x_child_min,
          x_max: x_child_max,
          y_min: y_child_min,
          y_max: y_child_max,
          season: this.props.rasterArea.season,
          soil_Type: soilTypeIndex,
          resolution: childResolution,
          rasterMethod: rasterMethodIndex,
          rasterizationPriority: this.props.rasterArea.rasterizationPriority,
          fillValue: this.props.rasterArea.fillValue.rasterValue,
          surfaceBelowTree:
            this.props.rasterArea.fillValueBelowTreePatches.rasterValue,
          topographyUrl:
            this.props.rasterArea.topographyForChildDomain ===
            "SameAsParentDomain"
              ? null
              : this.props.rasterArea.topographyForChildDomain,
        },
        geojsonUrl: geojsonUrl,
        polygonDataUrl: polygonDataUrl,
        drawnGeojsonUrl: drawnGeojsonUrl,
      };

      if (soilType === "UserDefined") {
        request.rasterParameters.additionalData.globalParameters.soilParameters =
          {
            alpha_vg: this.props.rasterArea.soilAlphaCoefficient,
            l_vg: this.props.rasterArea.lSoilCoefficient,
            n_vg: this.props.rasterArea.nSoilCoefficient,
            gamma_w_sat: this.props.rasterArea.soilHydraulicConductivity,
            m_sat: this.props.rasterArea.soilMoistureSaturation,
            m_fc: this.props.rasterArea.soilMoistureCapacity,
            m_wilt: this.props.rasterArea.soilMoistureWilting,
            m_res: this.props.rasterArea.soilMoistureContent,
          };
      }

      console.log(JSON.stringify(request, null, 2));
      await requestStaticDriverAsync(this.props.match.params.caseId, request);
    }
  }

  render() {
    const { t } = this.props;
    let isStaticDriverProcessing = false;
    if (
      this.state.isStaticDriverProcessing ||
      (this.props.currentCase?.staticDriverJob &&
        (this.props.currentCase.staticDriverJob.status === 0 ||
          this.props.currentCase.staticDriverJob.status === 1 ||
          this.props.currentCase.staticDriverJob.status === "New" ||
          this.props.currentCase.staticDriverJob.status === "Processing"))
    ) {
      isStaticDriverProcessing = true;
    }

    return (
      <>
        {(this.props.simulationJobs?.length ||
          !!this.props.simulationNotifications) && (
          <p className="sim-warning">{t("RasterStaticLocked")}</p>
        )}

        <div className={`raster-settings-btn-wrapper`}>
          <button
            className={`raster-settings-btn ${
              this.props.rasterArea.isRasterAreaDraggable
                ? `raster-settings-btn-active`
                : ""
            }`}
            onClick={this.props.toggleRasterAreaDraggable}
            disabled={
              this.props.simulationJobs?.length ||
              !!this.props.simulationNotifications ||
              isStaticDriverProcessing
            }
          >
            <div className="btn-content-wrapper">
              <Move color={"#494949"} size={"16px"} strokeWidth={"1.8px"} />
              <div className="raster-btn-lbl">{t("MoveCentre")}</div>
            </div>
          </button>

          <button
            className="raster-settings-btn"
            onClick={this.props.resetRasterAreaProperties}
            disabled={
              this.props.simulationJobs?.length ||
              !!this.props.simulationNotifications ||
              isStaticDriverProcessing
            }
          >
            <div className="btn-content-wrapper">
              <RotateCcw
                color={"#494949"}
                size={"16px"}
                strokeWidth={"1.8px"}
              />

              <div className="raster-btn-lbl">{t("DefaultSettings")}</div>
            </div>
          </button>
        </div>

        <NumericInput
          name={"CentreEasting"}
          label={t("CentreEasting")}
          value={this.props.rasterArea.rasterCenterLngUtm}
          maxLength={"9"}
          onChange={(e) =>
            this.props.setRasterCenterLngUtm({
              rasterCenterLngUtm: e.target.value,
              crsDef: this.props.crsDef,
              hasRasterMoved: true,
            })
          }
          disabled={isStaticDriverProcessing}
          unit={"m"}
          help={helpUrl}
        />

        <NumericInput
          name={"CentreNorthing"}
          label={t("CentreNorthing")}
          value={this.props.rasterArea.rasterCenterLatUtm}
          maxLength={"12"}
          onChange={(e) =>
            this.props.setRasterCenterLatUtm({
              rasterCenterLatUtm: e.target.value,
              crsDef: this.props.crsDef,
              hasRasterMoved: true,
            })
          }
          unit={"m"}
          disabled={isStaticDriverProcessing}
          help={helpUrl}
        />
        <hr className="raster-settings-ruler" />
        <Select
          label={t("SelectDomainType")}
          value={this.props.rasterArea.domainType}
          options={domainType.map((x) => {
            return { label: t(`${x}`), value: x };
          })}
          onChange={(e) => this.props.setDomainType(e.target.value)}
          disabled={
            //this.props.currentCase.applicationField === 1 ||
            this.props.currentCase.applicationField === 3 ||
            isStaticDriverProcessing ||
            this.props.currentCase?.rasterPreviewJob?.status === 2 ||
            this.props.currentCase?.rasterPreviewJob?.status === "Completed"
          }
          help={helpUrl}
        />

        {this.props.rasterArea.domainType === "SingleDomain" && (
          <>
            <NumericInput
              name={"Grid Width"}
              label={t("GridWidth")}
              value={
                this.props.currentCase.applicationField === 2
                  ? 1
                  : this.props.rasterArea.rasterGridWidth
              }
              min={"1"}
              max={
                this.props.currentCase.applicationField === 0 ||
                this.props.currentCase.applicationField === 4
                  ? "50"
                  : "50"
              }
              step={"1"}
              unit={"m"}
              maxLength={"2"}
              onChange={(e) =>
                this.props.setRasterGridWidth({
                  rasterGridWidth: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              disabled={
                this.props.currentCase.applicationField === 2 ||
                isStaticDriverProcessing
              }
              onBlur={(e) => {
                if (
                  this.props.rasterArea.rasterGridWidth <
                    Number(e.target.min) ||
                  this.props.rasterArea.rasterGridWidth > Number(e.target.max)
                ) {
                  this.props.setRasterGridWidth({
                    rasterGridWidth: 5,
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              help={helpUrl}
            />
            <NumericInput
              name={"No.ofGridPointsInX-Direction"}
              label={t("NoOfGridPointsInXDirection")}
              value={this.props.rasterArea.rasterGridXNumber}
              maxLength={"3"}
              min={this.props.currentCase.applicationField === 2 ? 30 : 100}
              max={this.props.currentCase.applicationField === 2 ? 600 : 1000}
              step={2}
              unit={"-"}
              onChange={(e) =>
                this.props.setRasterGridXNumber({
                  rasterGridXNumber: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              onBlur={(e) => {
                if (
                  this.props.rasterArea.rasterGridXNumber <
                    Number(e.target.min) ||
                  this.props.rasterArea.rasterGridXNumber > Number(e.target.max)
                ) {
                  this.props.setRasterGridXNumber({
                    rasterGridXNumber: 200,
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"No.ofGridPointsInY-Direction"}
              label={t("NoOfGridPointsInYDirection")}
              value={this.props.rasterArea.rasterGridYNumber}
              maxLength={"3"}
              min={this.props.currentCase.applicationField === 2 ? 30 : 100}
              max={this.props.currentCase.applicationField === 2 ? 600 : 1000}
              step={"2"}
              unit={"-"}
              onChange={(e) =>
                this.props.setRasterGridYNumber({
                  rasterGridYNumber: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              onBlur={(e) => {
                if (
                  this.props.rasterArea.rasterGridYNumber <
                    Number(e.target.min) ||
                  this.props.rasterArea.rasterGridYNumber > Number(e.target.max)
                ) {
                  this.props.setRasterGridYNumber({
                    rasterGridYNumber: 200,
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
          </>
        )}

        {this.props.rasterArea.domainType === "NestedDomain" && (
          <>
            <Select
              label={t("SelectGridSizes")}
              value={this.props.rasterArea.gridSizes}
              options={
                this.props.currentCase.applicationField === 2
                  ? windGridSizes.map((x) => {
                      return { label: t(`${x}`), value: x };
                    })
                  : this.props.currentCase.applicationField === 0 ||
                    this.props.currentCase.applicationField === 4
                  ? staticExpertGridSizes.map((x) => {
                      return { label: t(`${x}`), value: x };
                    })
                  : gridSizes.map((x) => {
                      return { label: t(`${x}`), value: x };
                    })
              }
              onChange={(e) =>
                this.props.setGridSizes({
                  gridSizes: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"Parent: No. of GridPoints in x-Direction"}
              label={t("ParentNumberOfGridPointsInXDirection")}
              value={this.props.rasterArea.parentRasterGridXNumber}
              min={
                this.props.currentCase?.applicationField === 2 ? "30" : "100"
              }
              max={
                this.props.currentCase?.applicationField === 2
                  ? "600"
                  : this.props.currentCase?.applicationField === 0 ||
                    this.props.currentCase?.applicationField === 4
                  ? "1000"
                  : "400"
              }
              step={"2"}
              maxLength={"3"}
              unit={"-"}
              onChange={(e) =>
                this.props.setParentRasterGridXNumber({
                  parentRasterGridXNumber: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              onBlur={(e) => {
                if (
                  this.props.rasterArea.parentRasterGridXNumber <
                    Number(e.target.min) ||
                  this.props.rasterArea.parentRasterGridXNumber >
                    Number(e.target.max)
                ) {
                  this.props.setParentRasterGridXNumber({
                    parentRasterGridXNumber: 200,
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"Parent: No.ofGridPoints in y-Direction"}
              label={t("ParentNumberOfGridPointsInYDirection")}
              value={this.props.rasterArea.parentRasterGridYNumber}
              min={
                this.props.currentCase?.applicationField === 2 ? "30" : "100"
              }
              max={
                this.props.currentCase?.applicationField === 2
                  ? "600"
                  : this.props.currentCase?.applicationField === 0 ||
                    this.props.currentCase?.applicationField === 4
                  ? "1000"
                  : "400"
              }
              step={"2"}
              maxLength={"3"}
              onChange={(e) =>
                this.props.setParentRasterGridYNumber({
                  parentRasterGridYNumber: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              unit={"-"}
              onBlur={(e) => {
                if (
                  this.props.rasterArea.parentRasterGridYNumber <
                    Number(e.target.min) ||
                  this.props.rasterArea.parentRasterGridYNumber >
                    Number(e.target.max)
                ) {
                  this.props.setParentRasterGridYNumber({
                    parentRasterGridYNumber: "200",
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"Child: No. of GridPoints in x-Direction"}
              label={t("ChildNumberOfGridPointsInXDirection")}
              value={this.props.rasterArea.childRasterGridXNumber}
              min={
                this.props.currentCase?.applicationField === 2 ? "30" : "100"
              }
              max={
                this.props.currentCase?.applicationField === 2
                  ? "600"
                  : this.props.currentCase?.applicationField === 0 ||
                    this.props.currentCase?.applicationField === 4
                  ? "1000"
                  : "400"
              }
              step={"2"}
              maxLength={"3"}
              onChange={(e) =>
                this.props.setChildRasterGridXNumber({
                  childRasterGridXNumber: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              unit={"-"}
              onBlur={(e) => {
                if (
                  this.props.rasterArea.childRasterGridXNumber <
                    Number(e.target.min) ||
                  this.props.rasterArea.childRasterGridXNumber >
                    Number(e.target.max)
                ) {
                  this.props.setChildRasterGridXNumber({
                    childRasterGridXNumber: "200",
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"Child: No.ofGridPoints in y-Direction"}
              label={t("ChildNumberOfGridPointsInYDirection")}
              value={this.props.rasterArea.childRasterGridYNumber}
              min={
                this.props.currentCase?.applicationField === 2 ? "30" : "100"
              }
              max={
                this.props.currentCase?.applicationField === 2
                  ? "600"
                  : this.props.currentCase?.applicationField === 0 ||
                    this.props.currentCase?.applicationField === 4
                  ? "1000"
                  : "400"
              }
              step={"2"}
              maxLength={"3"}
              unit={"-"}
              onChange={(e) =>
                this.props.setChildRasterGridYNumber({
                  childRasterGridYNumber: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              onBlur={(e) => {
                if (
                  this.props.rasterArea.childRasterGridYNumber <
                    Number(e.target.min) ||
                  this.props.rasterArea.childRasterGridYNumber >
                    Number(e.target.max)
                ) {
                  this.props.setChildRasterGridYNumber({
                    childRasterGridYNumber: "200",
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"ParentOffsetXDirection"}
              label={t("ParentOffsetXDirection")}
              value={this.props.rasterArea.parentOffsetX}
              min={"-999"}
              max={"999"}
              step={"1"}
              maxLength={"3"}
              onChange={(e) =>
                this.props.setParentOffsetX({
                  parentOffsetX: e.target.value,
                  crsDef: this.props.crsDef,
                })
              }
              unit={"-"}
              onBlur={(e) => {
                if (
                  this.props.rasterArea.parentOffsetX > Number(e.target.max) ||
                  this.props.rasterArea.parentOffsetX < Number(e.target.min) ||
                  this.props.rasterArea.parentOffsetX === ""
                ) {
                  this.props.setParentOffsetX({
                    parentOffsetX: 0,
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
            <NumericInput
              name={"ParentOffsetYDirection"}
              label={t("ParentOffsetYDirection")}
              value={this.props.rasterArea.parentOffsetY}
              min={"-999"}
              max={"999"}
              step={1}
              maxLength={3}
              unit={"-"}
              onChange={(e) => {
                this.props.setParentOffsetY({
                  parentOffsetY: e.target.value,
                  crsDef: this.props.crsDef,
                });
              }}
              onBlur={(e) => {
                if (
                  this.props.rasterArea.parentOffsetY < -999 ||
                  this.props.rasterArea.parentOffsetY > 999 ||
                  this.props.rasterArea.parentOffsetY === ""
                ) {
                  this.props.setParentOffsetY({
                    parentOffsetY: "0",
                    crsDef: this.props.crsDef,
                  });
                }
              }}
              disabled={isStaticDriverProcessing}
              help={helpUrl}
            />
          </>
        )}

        <div
          className="prev-wrapper"
          style={{
            justifyContent:
              this.props.language && this.props.language === "de-DE"
                ? "space-between"
                : "space-around",
          }}
        >
          <button
            className="raster-settings-btn"
            onClick={this.createRasterPreview}
            disabled={
              this.state.isRasterProcessing ||
              this.state.isStaticDriverProcessing ||
              this.props.currentCase.rasterPreviewJob?.status === 0 ||
              this.props.currentCase.rasterPreviewJob?.status === 1 ||
              this.props.currentCase.rasterPreviewJob?.status === "New" ||
              this.props.currentCase.rasterPreviewJob?.status ===
                "Processing" ||
              this.props.currentCase.staticDriverJob?.status === 0 ||
              this.props.currentCase.staticDriverJob?.status === 1 ||
              this.props.currentCase.staticDriverJob?.status === 2 ||
              this.props.currentCase.staticDriverJob?.status === "New" ||
              this.props.currentCase.staticDriverJob?.status === "Processing" ||
              this.props.currentCase.staticDriverJob?.status === "Completed"
            }
            style={{
              padding:
                this.props.language && this.props.language === "de-DE"
                  ? "6px 5px"
                  : "6px 10px",
            }}
          >
            <div className="btn-content-wrapper">
              {this.state.isRasterProcessing ||
              this.props.currentCase.rasterPreviewJob?.status === 0 ||
              this.props.currentCase.rasterPreviewJob?.status === 1 ||
              this.props.currentCase.rasterPreviewJob?.status === "New" ||
              this.props.currentCase.rasterPreviewJob?.status ===
                "Processing" ? (
                <Loader size="xx-small" />
              ) : (
                <Grid color={"#494949"} size={"16px"} strokeWidth={"1.8px"} />
              )}
              <div className="raster-btn-lbl">{t("CreateRasterPreview")}</div>
            </div>
          </button>

          <button
            className="raster-settings-btn"
            onClick={this.createStaticDriver}
            disabled={
              (this.props.currentCase.rasterPreviewJob?.status !== 2 &&
                this.props.currentCase.rasterPreviewJob?.status !==
                  "Completed") ||
              this.state.isRasterProcessing ||
              this.state.isStaticDriverProcessing ||
              this.props.currentCase.staticDriverJob?.status === 0 ||
              this.props.currentCase.staticDriverJob?.status === 1 ||
              this.props.currentCase.staticDriverJob?.status === "New" ||
              this.props.currentCase.staticDriverJob?.status === "Processing" ||
              this.props.currentCase.staticDriverJob?.status === 2 ||
              this.props.currentCase.staticDriverJob?.status === "Completed" ||
              this.props.simulationJobs?.length ||
              !!this.props.simulationNotifications ||
              this.props.rasterArea.hasGeometryChanged
            }
            style={{
              padding:
                this.props.language && this.props.language === "de-DE"
                  ? "6px 5px"
                  : "6px 10px",
            }}
          >
            <div className={`btn-content-wrapper `}>
              {this.state.isStaticDriverProcessing ||
              this.props.currentCase.staticDriverJob?.status === 0 ||
              this.props.currentCase.staticDriverJob?.status === 1 ||
              this.props.currentCase.staticDriverJob?.status === "New" ||
              this.props.currentCase.staticDriverJob?.status ===
                "Processing" ? (
                <Loader size="xx-small" />
              ) : (
                <Airplay color={"#494949"} size={"16px"} strokeWidth={"2px"} />
              )}
              <div className="raster-btn-lbl">{t("CreateStaticDriver")}</div>
            </div>
          </button>
        </div>

        {this.props.rasterArea.rasterPreview !== null &&
          this.props.rasterArea.hasGeometryChanged &&
          this.props.currentCase.rasterPreviewJob?.status !== 0 &&
          this.props.currentCase.rasterPreviewJob?.status !== 1 &&
          this.props.currentCase.rasterPreviewJob?.status !== "New" &&
          this.props.currentCase.rasterPreviewJob?.status !== "Processing" &&
          this.props.currentCase.staticDriverJob?.status !== 2 &&
          this.props.currentCase.staticDriverJob?.status !== "Completed" && (
            <div
              style={{
                display: "flex",
                height: "fit-content",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <div>
                <XCircle
                  color={"#a81d1dcf"}
                  size={"14px"}
                  strokeWidth={"1.8px"}
                />
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#a81d1dcf",
                  paddingLeft: "6px",
                }}
              >
                {t("RasterGeometryChangedWarning")}
              </div>
            </div>
          )}

        {this.props.rasterArea.rasterPreview !== null &&
          this.props.rasterArea.parentRasterPreview === null && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "50%", height: "fit-content" }}>
                <label className="legend-row-container legend-wrapper">
                  <input
                    type="checkbox"
                    className="raster-checkbox"
                    checked={this.props.rasterArea.isRasterPreviewVisible}
                    onClick={() => this.props.toggleRasterPreviewVisibility()}
                    disabled={isStaticDriverProcessing}
                  />

                  <label className="raster-object-name">
                    {t("RasterPreview")}
                  </label>
                </label>

                <label className="legend-row-container legend-wrapper">
                  <input
                    type="checkbox"
                    className="raster-checkbox"
                    checked={this.props.rasterArea.isGeoJsonVisible}
                    onClick={() => this.props.toggleGeoJsonVisibility()}
                    disabled={isStaticDriverProcessing}
                  />

                  <label className="raster-object-name">
                    {t("GeojsonPreview")}
                  </label>
                </label>

                <label className="legend-row-container legend-wrapper">
                  <input
                    type="checkbox"
                    className="raster-checkbox"
                    checked={this.props.rasterArea.isRasterAreaVisible}
                    onClick={() => this.props.toggleRasterAreaVisibility()}
                    disabled={isStaticDriverProcessing}
                  />

                  <label className="raster-object-name">
                    {t("RasterAreaPreview")}
                  </label>
                </label>
                <label className="legend-row-container legend-wrapper">
                  <input
                    type="checkbox"
                    className="raster-checkbox"
                    checked={this.props.rasterArea.isBufferAreaVisible}
                    onClick={() => this.props.toggleBufferAreaVisibility()}
                    disabled={isStaticDriverProcessing}
                  />

                  <label className="raster-object-name">
                    {t("BufferAreaPreview")}
                  </label>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                {(this.props.currentCase.staticDriverJob?.status === 2 ||
                  this.props.currentCase.staticDriverJob?.status ===
                    "Completed") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <CheckCircle size="16px" color="rgb(6, 137, 108)" />
                    <p
                      style={{
                        margin: "0px",
                        marginLeft: "9px",
                        cursor: "default",
                      }}
                      className={`new-layer-name-error new-layer-success `}
                    >
                      {t("StaticDriverHasBeenCreated")}
                    </p>
                  </div>
                )}
                {(this.props.currentCase?.staticDriverJob?.status === 3 ||
                  this.props.currentCase?.staticDriverJob?.status ===
                    "Error") && (
                  <div
                    style={{
                      display: "flex",
                      height: "fit-content",
                      alignItems: "center",
                      marginTop: "12px",
                    }}
                  >
                    <div>
                      <XCircle
                        color={"#a81d1dcf"}
                        size={"14px"}
                        strokeWidth={"1.8px"}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#a81d1dcf",
                        paddingLeft: "6px",
                      }}
                    >
                      {t(
                        errorCodes[this.props.currentCase.staticDriverJob.error]
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        {this.props.rasterArea.parentRasterPreview !== null && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "50%", height: "fit-content" }}>
              <label className="legend-row-container legend-wrapper">
                <input
                  type="checkbox"
                  className="raster-checkbox"
                  checked={this.props.rasterArea.isParentRasterPreviewVisible}
                  onClick={() =>
                    this.props.toggleParentRasterPreviewVisibility()
                  }
                  disabled={isStaticDriverProcessing}
                />
                <label className="raster-object-name">
                  {t("ParentRasterPreview")}
                </label>
              </label>
              <label className="legend-row-container legend-wrapper">
                <input
                  type="checkbox"
                  className="raster-checkbox"
                  checked={this.props.rasterArea.isChildRasterPreviewVisible}
                  onClick={() =>
                    this.props.toggleChildRasterPreviewVisibility()
                  }
                  disabled={isStaticDriverProcessing}
                />
                <label className="raster-object-name">
                  {t("ChildRasterPreview")}
                </label>
              </label>
              <label className="legend-row-container legend-wrapper">
                <input
                  type="checkbox"
                  className="raster-checkbox"
                  checked={this.props.rasterArea.isGeoJsonVisible}
                  onClick={() => this.props.toggleGeoJsonVisibility()}
                  disabled={isStaticDriverProcessing}
                />
                <label className="raster-object-name">
                  {t("GeojsonPreview")}
                </label>
              </label>
              <label className="legend-row-container legend-wrapper">
                <input
                  type="checkbox"
                  className="raster-checkbox"
                  checked={this.props.rasterArea.isParentRasterAreaVisible}
                  onClick={() => this.props.toggleParentRasterAreaVisibility()}
                  disabled={isStaticDriverProcessing}
                />
                <label className="raster-object-name">
                  {t("ParentRasterArea")}
                </label>
              </label>
              <label className="legend-row-container legend-wrapper">
                <input
                  type="checkbox"
                  className="raster-checkbox"
                  checked={this.props.rasterArea.isChildRasterAreaVisible}
                  onClick={() => this.props.toggleChildRasterAreaVisibility()}
                  disabled={isStaticDriverProcessing}
                />
                <label className="raster-object-name">
                  {t("ChildRasterArea")}
                </label>
              </label>
              <label className="legend-row-container legend-wrapper">
                <input
                  type="checkbox"
                  className="raster-checkbox"
                  checked={this.props.rasterArea.isBufferAreaVisible}
                  onClick={() => this.props.toggleBufferAreaVisibility()}
                  disabled={isStaticDriverProcessing}
                />

                <label className="raster-object-name">
                  {t("BufferAreaPreview")}
                </label>
              </label>
            </div>
            {(this.props.currentCase.staticDriverJob?.status === 2 ||
              this.props.currentCase.staticDriverJob?.status ===
                "Completed") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <CheckCircle size="16px" color="rgb(6, 137, 108)" />
                <p
                  style={{
                    margin: "0px",
                    marginLeft: "9px",
                    cursor: "default",
                  }}
                  className={`new-layer-name-error new-layer-success `}
                >
                  {t("StaticDriverHasBeenCreated")}
                </p>
              </div>
            )}
            {(this.props.currentCase?.staticDriverJob?.status === 3 ||
              this.props.currentCase?.staticDriverJob?.status === "Error") && (
              <>
                <div
                  style={{
                    display: "flex",
                    height: "fit-content",
                    alignItems: "center",
                    marginTop: "12px",
                  }}
                >
                  <div>
                    <XCircle
                      color={"#a81d1dcf"}
                      size={"14px"}
                      strokeWidth={"1.8px"}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#a81d1dcf",
                      paddingLeft: "6px",
                    }}
                  >
                    {t(
                      errorCodes[this.props.currentCase.staticDriverJob.error]
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geoJsonWg: state.map.geoJsonWg,
    polygonData: state.map.polygonData,
    rasterArea: state.rasterArea,
    loadedProject: state.projects.loadedProject,
    notifications: state.notifications,
    drawnPolygonGeojsonWg: state.map.drawnPolygonGeojsonWg,
    treeGeoJsonUtm: state.map.treeGeoJsonUtm,
    allTreesGeoProps: state.map.allTreesGeoProps,
    allDrawnTreesCoordinatesUtm: state.map.allDrawnTreesCoordinatesUtm,
    drawnGeoJson: state.map.drawnGeoJson,
    currentCase: state.projects.currentCase,
    crsDef: state.map.crsDef,
    simulationNotifications: selectCaseSimulationNotifications(state),
    simulationJobs: state.projects.currentCase?.simulationJobs,
    emissionGeoJsonWg: state.map.emissionGeoJsonWg,
    mapPoiGeoJsonWg: state.map.mapPoiGeoJsonWg,
    windTurbineGeoJsonWg: state.map.windTurbineGeoJsonWg,
    emissionDrawnGeoJsonUtm: state.map.emissionDrawnGeoJsonUtm,
    mapPoiDrawnGeoJsonUtm: state.map.mapPoiDrawnGeoJsonUtm,
    windTurbineDrawnGeoJsonUtm: state.map.windTurbineDrawnGeoJsonUtm,
    language: state.userSettings?.settings?.preferredLanguage,
    files: state.projects?.loadedProject?.files,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRasterCenterLatUtm: (lat) =>
      dispatch(actions.setRasterCenterLatUtm(lat)),
    setRasterCenterLngUtm: (lng) =>
      dispatch(actions.setRasterCenterLngUtm(lng)),
    setRasterGridWidth: (gridWidth) =>
      dispatch(actions.setRasterGridWidth(gridWidth)),
    setRasterGridXNumber: (gridXNumber) =>
      dispatch(actions.setRasterGridXNumber(gridXNumber)),
    setRasterGridYNumber: (gridYNumber) =>
      dispatch(actions.setRasterGridYNumber(gridYNumber)),
    toggleRasterAreaDraggable: () =>
      dispatch(actions.toggleRasterAreaDraggable()),
    resetRasterAreaProperties: () =>
      dispatch(actions.resetRasterAreaProperties()),
    toggleRasterPreviewVisibility: () =>
      dispatch(actions.toggleRasterPreviewVisibility()),
    toggleParentRasterPreviewVisibility: () =>
      dispatch(actions.toggleParentRasterPreviewVisibility()),
    toggleChildRasterPreviewVisibility: () =>
      dispatch(actions.toggleChildRasterPreviewVisibility()),
    toggleGeoJsonVisibility: () => dispatch(actions.toggleGeoJsonVisibility()),
    toggleRasterAreaVisibility: () =>
      dispatch(actions.toggleRasterAreaVisibility()),
    toggleParentRasterAreaVisibility: () =>
      dispatch(actions.toggleParentRasterAreaVisibility()),
    toggleChildRasterAreaVisibility: () =>
      dispatch(actions.toggleChildRasterAreaVisibility()),
    getRasterPreview: (raster) => dispatch(actions.getRasterPreview(raster)),
    getNestedRasterPreview: (raster) =>
      dispatch(actions.getNestedRasterPreview(raster)),
    onSingleRasterLoadVisibility: () =>
      dispatch(actions.onSingleRasterLoadVisibility()),
    onNestedRasterLoadVisibility: () =>
      dispatch(actions.onNestedRasterLoadVisibility()),
    setDomainType: (domainType) => dispatch(actions.setDomainType(domainType)),
    setGridSizes: (gridSizes) => dispatch(actions.setGridSizes(gridSizes)),
    setParentRasterGridXNumber: (gridXNumber) =>
      dispatch(actions.setParentRasterGridXNumber(gridXNumber)),
    setParentRasterGridYNumber: (gridYNumber) =>
      dispatch(actions.setParentRasterGridYNumber(gridYNumber)),
    setChildRasterGridXNumber: (gridXNumber) =>
      dispatch(actions.setChildRasterGridXNumber(gridXNumber)),
    setChildRasterGridYNumber: (gridYNumber) =>
      dispatch(actions.setChildRasterGridYNumber(gridYNumber)),
    createSingleTreeGeoJson: () => dispatch(createSingleTreeGeoJson()),
    setCaseDrawnGeojsonUrl: (payload) =>
      dispatch(setCaseDrawnGeojsonUrl(payload)),
    setSingleRasterCaseProps: (payload) =>
      dispatch(actions.setSingleRasterCaseProps(payload)),
    setNestedRasterCaseProps: (payload) =>
      dispatch(actions.setNestedRasterCaseProps(payload)),
    setDrawnPolygonGeojsonCrs: (payload) =>
      dispatch(setDrawnPolygonGeojsonCrs(payload)),
    createSingleEmissionDrawnGeoJson: (payload) =>
      dispatch(createSingleEmissionDrawnGeoJson(payload)),
    createSingleMapPoiDrawnGeoJson: (payload) =>
      dispatch(createSingleMapPoiDrawnGeoJson(payload)),
    createSingleWindTurbineDrawnGeoJson: (payload) =>
      dispatch(createSingleWindTurbineDrawnGeoJson(payload)),
    setParentOffsetX: (payload) => dispatch(actions.setParentOffsetX(payload)),
    setParentOffsetY: (payload) => dispatch(actions.setParentOffsetY(payload)),
    toggleBufferAreaVisibility: (payload) =>
      dispatch(actions.toggleBufferAreaVisibility(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BasicRasterizationSettings)));
