import React from "react";
import { Box } from "../../Form/Box";
import { Database, HelpCircle } from "react-feather";
import { withTranslation } from "react-i18next";
import {
  selectMapPoi,
  removeMapPoi,
  updateMapPoiPositionOnInputChange,
  goToMapPoiProperties,
  updateTempInput,
  updatePolygonData,
} from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";
import "../../TreeEditor/TreeEditor";

class MapPoiEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleLiveInput = this.handleLiveInput.bind(this);
  }

  handleLiveInput(e) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value;
    const name = e.target.name;

    if (e.target.name === "name") {
      this.props.updateTempInput({
        tempKey: name,
        tempValue: inputValue,
      });
    } else if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue.slice(-1) === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "0" ||
        (name === "lat" && (inputValue < 0 || inputValue > 10000000)) ||
        (name === "lng" && (inputValue < 0 || inputValue > 1000000))
      ) {
        this.props.updateTempInput({
          tempKey: name,
          tempValue: inputValue,
        });
      } else {
        this.props.updateMapPoiPositionOnInputChange({
          windTurbineIndex: this.props.selectedWindTurbineIndex,
          e: e,
        });
      }
    }
  }

  handleInputChange = (e) => {
    const inputValue = e.target;
    console.log(inputValue)
    if (inputValue.id === "name" && inputValue.value !== "") {
      this.props.updatePolygonData({
        key: inputValue.id,
        value: inputValue.value,
      });
    }
    this.props.updateTempInput({ tempKey: "", tempValue: "" });
  };

  render() {
    const { t } = this.props;
    return (
      <div
        className="tree-editor-container"
        style={{ width: "auto", padding: 0 }}
      >
        <h3 className="tree-editor-hdr">{t("AddPoiByClickingOnMap")}</h3>
        <Box objectType={"point_of_interest"} />
        {/* {this.props.applicationField !== 3 && (
          <div
            style={{
              alignItems: "center",
              color: "#4f4f4f",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              paddingTop: "12px",
            }}
          >
            <HelpCircle
              size="14px"
              color="#4f4f4f"
              strokeWidth={"2px"}
              style={{ minWidth: "13px" }}
            />
            <div style={{ paddingLeft: "12px" }}>{t("PoiInactiveWarning")}</div>
          </div>
        )} */}
        {this.props.allDrawnMapPoiCoordinatesUtm.length > 0 && (
          <div
            className="tree-coord-label-container"
            style={{
              marginLeft: "33px",
              minWidth: "295px",
              maxWidth: "295px",
              justifyContent: "space-around",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className="long-icon minified-icon"
                style={{
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              />
              <div>
                <label style={{ marginLeft: "4px" }}>{t("Easting")}</label>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="lat-icon minified-icon"
                style={{
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              />
              <label style={{ marginLeft: "4px" }}>{t("Northing")}</label>
            </div>
            <div style={{ display: "flex" }}>
              <div className="name-icon"></div>
              <label style={{ marginLeft: "4px" }}>{t("Name")}</label>
            </div>
          </div>
        )}
        {this.props.allDrawnMapPoiCoordinatesUtm?.map((x, i) => {
          return (
            <div className="tree-coords-line">
              <div
                className={`tree-icon map-poi-icon ${
                  this.props.selectedMapPoiIndex === i
                    ? "tree-icon map-poi-highlighted-icon"
                    : ""
                }`}
              />
              <label className="tree-index">{i}</label>
              <input
                className="tree-editor-coord-row"
                style={{ width: "82px" }}
                placeholder="Longitude"
                name="lng"
                value={
                  this.props.selectedMapPoiIndex === i &&
                  this.props.tempKey === "lng" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : x.lng
                }
                onChange={(e) => this.handleLiveInput(e)}
                onFocus={() => this.props.selectMapPoi(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />
              <input
                className="tree-editor-coord-row"
                style={{ width: "82px" }}
                placeholder="Lattitude"
                name="lat"
                value={
                  this.props.selectedMapPoiIndex === i &&
                  this.props.tempKey === "lat" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : x.lat
                }
                onChange={(e) => this.handleLiveInput(e)}
                onFocus={() => this.props.selectMapPoi(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />
              <input
                className="tree-editor-coord-row"
                style={{ width: "82px" }}
                placeholder="Name"
                id="name"
                name="name"
                value={
                  this.props.selectedMapPoiIndex === i &&
                  this.props.tempValue &&
                  this.props.tempKey === "name"
                    ? this.props.tempValue
                    : this.props.polygonData.find((y) => {
                        return y?.id === x.id;
                      })?.name
                    ? this.props.polygonData.find((y) => {
                        return y?.id === x.id;
                      })?.name
                    : this.props.allDrawnMapPoiGeoProps.find((y) => {
                        return y?.id === x.id;
                      })?.name
                }
                onChange={(e) => this.handleLiveInput(e)}
                onFocus={() => this.props.selectMapPoi(i)}
                onBlur={(e) => this.handleInputChange(e)}
              />
              <button
                className="tree-form-btn"
                title={t("GoToPoiParametrization")}
                onClick={() => {
                  this.props.goToMapPoiProperties(i);
                }}
              >
                <Database size="16px" color="Gray" strokeWidth={"1.7px"} />
              </button>

              <button
                className="remove-tree-btn"
                onClick={() => this.props.removeMapPoi(i)}
                title={t("DeleteTree")}
              >
                X
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDrawnMapPoiCoordinatesUtm: state.map.allDrawnMapPoiCoordinatesUtm,
    selectedMapPoiIndex: state.map.selectedMapPoiIndex,
    tempKey: state.map.tempKey,
    tempValue: state.map.tempValue,
    polygonData: state.map.polygonData,
    applicationField: state.currentCase?.applicationField,
    allDrawnMapPoiGeoProps: state.map.allDrawnMapPoiGeoProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectMapPoi: (payload) => dispatch(selectMapPoi(payload)),
    removeMapPoi: (payload) => dispatch(removeMapPoi(payload)),
    updateMapPoiPositionOnInputChange: (payload) =>
      dispatch(updateMapPoiPositionOnInputChange(payload)),
    goToMapPoiProperties: (payload) => dispatch(goToMapPoiProperties(payload)),
    updateTempInput: (payload) => dispatch(updateTempInput(payload)),
    updatePolygonData: (payload) => dispatch(updatePolygonData(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MapPoiEditor)
);
