export const domainType = ["SingleDomain", "NestedDomain"];

  export const gridSizes = [
    "Parent 10m / Child 2m",
    "Parent 6m / Child 2m",
    "Parent 4m / Child 2m",
    "Parent 10m / Child 1m",
    "Parent 5m / Child 1m",
    "Parent 12m / Child 3m",
    "Parent 9m / Child 3m",
    "Parent 12m / Child 4m",
    "Parent 20m / Child 5m",
    "Parent 15m / Child 5m",
  ];
  
  export const staticExpertGridSizes = [
    "Parent 10m / Child 2m",
    "Parent 8m / Child 2m",
    "Parent 6m / Child 2m",
    "Parent 4m / Child 2m",
    "Parent 10m / Child 1m",
    "Parent 5m / Child 1m",
    "Parent 4m / Child 1m",
    "Parent 3m / Child 1m",
    "Parent 50m / Child 5m",
    "Parent 20m / Child 5m",
    "Parent 15m / Child 5m",
    "Parent 12m / Child 3m",
    "Parent 9m / Child 3m",
    "Parent 12m / Child 4m",
  ];

export const windGridSizes = ["Parent 10m / Child 1m", "Parent 5m / Child 1m"];

export const soilType = [
  "Coarse",
  "Medium",
  "MediumFine",
  "Fine",
  "VeryFine",
  "Organic",
  "UserDefined",
];

export const season = ["Summer", "Winter"];

export const rasterMethod = ["DefaultCentreValue", "TouchAll"];

export const vegetationFillValue = [
  { value: "VegetationBareSoil", rasterValue: "Vegetation1" },
  { value: "VegetationCropsMixedFarming", rasterValue: "Vegetation2" },
  { value: "VegetationShortGrass", rasterValue: "Vegetation3" },
  { value: "VegetationTallGrass", rasterValue: "Vegetation4" },
  { value: "VegetationDesert", rasterValue: "Vegetation5" },
  { value: "VegetationTundra", rasterValue: "Vegetation6" },
  { value: "VegetationIrrigatedCrops", rasterValue: "Vegetation7" },
  { value: "VegetationSemidesert", rasterValue: "Vegetation8" },
  { value: "VegetationBogsAndMarshes", rasterValue: "Vegetation9" },
  { value: "VegetationEvergreenShrubs", rasterValue: "Vegetation10" },
  { value: "VegetationDeciduousShrubs", rasterValue: "Vegetation11" },
];

export const pavementFillValue = [
  { value: "PavementAsphaltConcreteMix", rasterValue: "Pavement1" },
  { value: "PavementAsphaltAsphaltConcrete", rasterValue: "Pavement2" },
  { value: "PavementConcretePortlandConcrete", rasterValue: "Pavement3" },
  { value: "PavementSett", rasterValue: "Pavement4" },
  { value: "PavementPavingStones", rasterValue: "Pavement5" },
  { value: "PavementCobblestone", rasterValue: "Pavement6" },
  { value: "PavementMetal", rasterValue: "Pavement7" },
  { value: "PavementWood", rasterValue: "Pavement8" },
  { value: "PavementGravel", rasterValue: "Pavement9" },
  { value: "PavementFineGravel", rasterValue: "Pavement10" },
  { value: "PavementPebblestone", rasterValue: "Pavement11" },
  { value: "PavementWoodchips", rasterValue: "Pavement12" },
  { value: "PavementTartanSports", rasterValue: "Pavement13" },
  { value: "PavementArtificialTurfSports", rasterValue: "Pavement14" },
  { value: "PavementClaySports", rasterValue: "Pavement15" },
];

export const waterFillValue = [
  { value: "lake", rasterValue: "Water1" },
  { value: "river", rasterValue: "Water2" },
  { value: "ocean", rasterValue: "Water3" },
  { value: "pond", rasterValue: "Water4" },
  { value: "fountain", rasterValue: "Water5" },
];

export const fillValue = [
  ...vegetationFillValue,
  ...pavementFillValue,
  ...waterFillValue,
];

export const topographyForChildDomain = ["SameAsParentDomain"];

export const rasterizationPriorityOptions = [
  "building",
  "water",
  "pavement",
  "vegetation",
];

export const globalWaterTemperatureSourceOptions = [
  "FromCityModel",
  "DefineGlobal",
];

export const soilTypeOptions = ["GloballyUniform"];

export const expertSettingsGroups = [
  "GlobalParameters",
  "RasterSettings",
  "HeightSettings",
  "NestingSettings",
  "DomainBuffer",
];
