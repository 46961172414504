import CheckboxRenderer from "./ExpertUtils/ExpertCheckboxTable/CheckboxRenderer";

export const expertPages = [
  "Grid",
  "Runtime Initialization",
  "Modules",
  "Output",
];

export const expertSubpages = [
  ["Single Domain", "Nested Domain"],
  ["Runtime Template", "Constant Profiles"],
  [
    "Biometerology",
    "Indoor Climate",
    "Land Surface",
    "Plant Canopy",
    "Radiation",
    "Synthetic Turbulence",
    "Urban Surface",
    "Wind Turbine",
  ],
  [
    "General Settings",
    "2D Cross Section (xy)",
    "Terrain Following Mask",
    "3D Volume",
    "POI:Vertical Profile Chart",
    "POI:Timeseries",
  ],
];

export const helpLink = `https://palm.muk.uni-hannover.de/trac/wiki/doc/app`;

export const getCrossSectionHeightLevelOptions = () => {
  const heightLevels = [];

  for (let index = 1; index < 101; index++) {
    heightLevels.push({
      value: index,
      label: `Grid Height z = ${index}`,
    });
  }
  return heightLevels;
};

export const biometeorologyTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: false,
    headerClass: "expert-hdr",
    cellClass: "expert-cell",
    flex: 1,
    page: "modules",
    subpage: "biometeorology",
    key: "output_quantities",
  },
  columnDefs: [
    {
      headerName: "Quantity Name",
      field: "quantityName",
      cellClass: "expert-cell expert-disabled",
      headerClass: "expert-hdr",
      width: 80,
    },
    {
      headerName: "Meaning",
      field: "meaning",
      cellClass: "expert-cell expert-left",
      headerClass: "expert-hdr",
    },
    {
      headerName: "2D_xy",
      field: "d_xy",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "mask_tf",
      field: "mask_tf",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "3D",
      field: "D_3",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "POI_pr",
      field: "POI_pr",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "POI_ts",
      field: "POI_ts",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
  ],
  frameworkComponents: {
    checkboxRenderer: CheckboxRenderer,
  },
  rowData: [
    {
      quantityName: "bio_perct*_xy",
      meaning: "Perceived Temperature (PT)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "bio_pet*_xy",
      meaning: "Physiologically Equivalent Temperature (PET)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "bio_utci*_xy",
      meaning: "Universal Thermal Climate Index (UTCI)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
  ],
};

export const indoorClimateTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: false,
    headerClass: "expert-hdr",
    cellClass: "expert-cell",
    flex: 1,
    page: "modules",
    subpage: "indoor_climate",
    key: "output_quantities",
  },
  columnDefs: [
    {
      headerName: "Quantity Name",
      field: "quantityName",
      cellClass: "expert-cell expert-disabled",
      headerClass: "expert-hdr",
      width: 80,
    },
    {
      headerName: "Meaning",
      field: "meaning",
      cellClass: "expert-cell expert-left",
      headerClass: "expert-hdr",
    },
    {
      headerName: "2D_xy",
      field: "d_xy",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "mask_tf",
      field: "mask_tf",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "3D",
      field: "D_3",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "POI_pr",
      field: "POI_pr",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "POI_ts",
      field: "POI_ts",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
  ],
  frameworkComponents: {
    checkboxRenderer: CheckboxRenderer,
  },
  rowData: [
    {
      quantityName: "im_hf_roof",
      meaning: "heatflux at building roof",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "im_hf_roof_waste",
      meaning: "waste heatflux at building roof",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "im_hf_wall_win",
      meaning: "heatflux at building walls and windows",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "im_hf_wall_win_waste",
      meaning: "waste heatflux at building walls and windows",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "im_t_indoor_mean",
      meaning: "mean indoor temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
  ],
};

export const plantCanopyTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: false,
    headerClass: "expert-hdr",
    cellClass: "expert-cell",
    flex: 1,
    page: "modules",
    subpage: "plant_canopy",
    key: "output_quantities",
  },
  columnDefs: [
    {
      headerName: "Quantity Name",
      field: "quantityName",
      cellClass: "expert-cell expert-disabled",
      headerClass: "expert-hdr",
      width: 80,
    },
    {
      headerName: "Meaning",
      field: "meaning",
      cellClass: "expert-cell expert-left",
      headerClass: "expert-hdr",
    },
    {
      headerName: "2D_xy",
      field: "d_xy",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "mask_tf",
      field: "mask_tf",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "3D",
      field: "D_3",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "POI_pr",
      field: "POI_pr",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "POI_ts",
      field: "POI_ts",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
  ],
  frameworkComponents: {
    checkboxRenderer: CheckboxRenderer,
  },
  rowData: [
    {
      quantityName: "pcm_bad",
      meaning: "Basal Area Density",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "pcm_lad",
      meaning: "Leaf Area Density",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "pcm_heatrate",
      meaning: "Plant Canopy Heating Rate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "pcm_transpirationrate",
      meaning: "Plant Canopy Transpiration Rate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "pcm_latentrate",
      meaning: "Plant Canopy Latent Heat Flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
  ],
};

export const landSurfaceCheckboxTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: false,
    headerClass: "expert-hdr",
    cellClass: "expert-cell",
    flex: 1,
    page: "modules",
    subpage: "land_surface",
    key: "output_quantities",
  },
  columnDefs: [
    {
      headerName: "Quantity Name",
      field: "quantityName",
      cellClass: "expert-cell expert-disabled",
      headerClass: "expert-hdr",
      width: 80,
    },
    {
      headerName: "Meaning",
      field: "meaning",
      cellClass: "expert-cell expert-left",
      headerClass: "expert-hdr",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "2D_xy",
      field: "d_xy",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "mask_tf",
      field: "mask_tf",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "3D",
      field: "D_3",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "POI_pr",
      field: "POI_pr",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
    {
      headerName: "POI_ts",
      field: "POI_ts",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: false,
    },
  ],
  frameworkComponents: {
    checkboxRenderer: CheckboxRenderer,
  },
  rowData: [
    {
      quantityName: "c_liq*",
      meaning: "Coverage of plants with liquid water",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "c_soil*",
      meaning: "Coverage of the land surface with bare soil",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "c_veg*",
      meaning: "Coverage of the land surface with vegetation",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "ghf*",
      meaning: "Ground (soil) heat flux (from energy balance)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "lai*",
      meaning: "Leaf area index",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "m_liq*",
      meaning: "Liquid water level on plants",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "m_soil",
      meaning: "Volumetric soil moisture",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "qsws_liq*",
      meaning:
        "Surface latent heat flux due to evaporation/condensation of liquid water on plants (from energy balance)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "qsws_soil*",
      meaning:
        "Surface latent heat flux due to evaporation/precipitation of bare soil (from energy balance)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "qsws_veg*",
      meaning:
        "Surface latent heat flux due to transpiration of plants (from energy balance)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "r_s*",
      meaning: "Resistance of the surface (soil + vegetation)",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "t_soil",
      meaning: "Soil temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
  ],
};

export const landSurfaceSoilTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: true,
    headerClass: "expert-hdr",
    cellClass: "expert-cell expert-right",
    flex: 1,
  },
  columnDefs: [
    {
      headerName: "Soil Layer",
      field: "id_soil",
      cellClass: " expert-cell expert-disabled expert-center",
      width: 110,
      editable: false,
    },
    {
      headerName: "Layer Thickness [m]",
      field: "dz_soil",
      width: 190,
    },
    {
      headerName: "Soil Temperature [K]",
      field: "soil_temperature",
      width: 190,
    },
    {
      headerName: "Soil Moisture [m³/m³]",
      field: "soil_moisture",
      width: 190,
    },
    {
      headerName: "Root Fraction [-]",
      field: "root_fraction",
      width: 190,
    },
  ],
  rowData: [
    {
      id_soil: 1,
      dz_soil: 0.01,
      soil_temperature: 297.05,
      soil_moisture: 0.5,
      root_fraction: 0.49,
    },
    {
      id_soil: 2,
      dz_soil: 0.02,
      soil_temperature: 297.05,
      soil_moisture: 0.5,
      root_fraction: 0.26,
    },
    {
      id_soil: 3,
      dz_soil: 0.04,
      soil_temperature: 298.15,
      soil_moisture: 0.5,
      root_fraction: 0.25,
    },
    {
      id_soil: 4,
      dz_soil: 0.06,
      soil_temperature: 298.45,
      soil_moisture: 0.5,
      root_fraction: 0,
    },
    {
      id_soil: 5,
      dz_soil: 0.14,
      soil_temperature: 298.45,
      soil_moisture: 0.5,
      root_fraction: 0,
    },
    {
      id_soil: 6,
      dz_soil: 0.26,
      soil_temperature: 294.35,
      soil_moisture: 0.5,
      root_fraction: 0,
    },
    {
      id_soil: 7,
      dz_soil: 0.54,
      soil_temperature: 290.15,
      soil_moisture: 0.5,
      root_fraction: 0,
    },
    {
      id_soil: 8,
      dz_soil: 1.86,
      soil_temperature: 288.15,
      soil_moisture: 0.5,
      root_fraction: 0,
    },
  ],
};

export const generalSettingsTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: false,
    headerClass: "expert-hdr",
    cellClass: "expert-cell",
    flex: 1,
    page: "output",
    subpage: "general",
    key: "output_quantities",
  },
  columnDefs: [
    {
      headerName: "Quantity Name",
      field: "quantityName",
      cellClass: "expert-cell expert-disabled",
      headerClass: "expert-hdr",
      width: 80,
    },
    {
      headerName: "Meaning",
      field: "meaning",
      cellClass: "expert-cell expert-left",
      headerClass: "expert-hdr",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "2D_xy",
      field: "d_xy",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "mask_tf",
      field: "mask_tf",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "3D",
      field: "D_3",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "POI_pr",
      field: "POI_pr",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: false,
    },
    {
      headerName: "POI_ts",
      field: "POI_ts",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: false,
    },
  ],
  frameworkComponents: {
    checkboxRenderer: CheckboxRenderer,
  },
  rowData: [
    {
      quantityName: "e",
      meaning: "SGS turbulence kinetic energy",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "kfd*",
      meaning: "Katabatic flow depth",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "hr",
      meaning: "HeatingRate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "lwp*",
      meaning: "Liquid water path",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "ol*",
      meaning: "Obukhov length in the constant flux layer",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "p",
      meaning: "Perturbation pressure",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "q",
      meaning: "Water vapor mixing ratio",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "qsurf*",
      meaning: "Mixing ratio at the surface ",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "qsws*",
      meaning: "Surface latent heatflux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rh",
      meaning: "Relative humidity",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "shf*",
      meaning: "Surface sensible heatflux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "t*",
      meaning: "Near surface characteristic temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "ta",
      meaning: "Air temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "ta_2m*",
      meaning: "2-m air temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "theta",
      meaning: "Potential temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "thetav",
      meaning: "Virtual potential temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "theta_2m*",
      meaning: "2-m air potential temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "ti",
      meaning: "Turbulence intensity",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "tsurf*",
      meaning: "Surface temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "u",
      meaning: "u-component of the velocity",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "uu",
      meaning: "Product of u and u",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "uv",
      meaning: "Product of u and v",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "uw",
      meaning: "Product of u and w",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "us*",
      meaning: "(near surface) friction velocity",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "v",
      meaning: "v-component of the velocity ",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vf25m*",
      meaning: "Volume-flux rate integrated up to 25m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vf50m*",
      meaning: "Volume-flux rate integrated up to 50m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vf75m*",
      meaning: "Volume-flux rate integrated up to 75m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vf100m*",
      meaning: "Volume-flux rate integrated up to 100m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vfxxm*",
      meaning:
        "Volume-flux rate integrated up to detected katabatic flow depth",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vfd25m*",
      meaning: "Volume-flux density integrated up to 25m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vfd50m*",
      meaning: "Volume-flux densithy integrated up to 50m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vfd75m*",
      meaning: "Volume-flux density integrated up to 75m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vfd100m*",
      meaning: "Volume-flux density integrated up to 100m above surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vfdxxm*",
      meaning:
        "Volume-flux density integrated up to detected katabatic flow depth.",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vu",
      meaning: "Product of v and u",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vv",
      meaning: "Product of v and v",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "vw",
      meaning: "Product of v and w",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "w",
      meaning: "w-component of the velocity",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wu",
      meaning: "Product of w and u",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wv",
      meaning: "Product of w and v",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "ww",
      meaning: "Product of w and w",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wdir",
      meaning: "Horizontal wind direction",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wq",
      meaning: "Product of w and q",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wspeed",
      meaning: "Horizontal wind speed",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wspeed_10m*",
      meaning: "10-m wind speed",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "wtheta",
      meaning: "Product of w and theta",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "z0*",
      meaning: "Roughness length",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "z0h*",
      meaning: "Roughness length for scalar quantities",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
  ],
};

export const radiationTableData = {
  defaultColDef: {
    suppressMovable: true,
    editable: false,
    headerClass: "expert-hdr",
    cellClass: "expert-cell",
    flex: 1,
    page: "modules",
    subpage: "radiation",
    key: "output_quantities",
  },
  columnDefs: [
    {
      headerName: "Quantity Name",
      field: "quantityName",
      cellClass: "expert-cell expert-disabled",
      headerClass: "expert-hdr",
      width: 80,
    },
    {
      headerName: "Meaning",
      field: "meaning",
      cellClass: "expert-cell expert-left",
      headerClass: "expert-hdr",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "2D_xy",
      field: "d_xy",
      cellRenderer: "checkboxRenderer",
      width: 50,
      editable: "true",
    },
    {
      headerName: "mask_tf",
      field: "mask_tf",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: "true",
    },
    {
      headerName: "3D",
      field: "D_3",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: "true",
    },
    {
      headerName: "POI_pr",
      field: "POI_pr",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: "true",
    },
    {
      headerName: "POI_ts",
      field: "POI_ts",
      cellRenderer: "checkboxRenderer",
      width: 50,
      cellClass: "expert-cell expert-disabled",
      editable: "true",
    },
  ],
  frameworkComponents: {
    checkboxRenderer: CheckboxRenderer,
  },
  rowData: [
    {
      quantityName: "rad_net*",
      meaning: "Net radiation flux at the surface",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_lw_in*",
      meaning: "Incoming longwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_lw_out*",
      meaning: "Outgoing longwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_sw_in*",
      meaning: "Incoming shortwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_sw_out*",
      meaning: "Outgoing shortwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_lw_cs_hr",
      meaning: "Clear-sky longwave radiative heating rate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_lw_hr",
      meaning: "Longwave radiative heating rate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_lw_in",
      meaning: "Incoming longwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_lw_out",
      meaning: "Outgoing longwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_sw_cs_hr",
      meaning: "Clear-sky shortwave radiative heating rate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_sw_hr",
      meaning: "Shortwave radiative heating rate",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_sw_in",
      meaning: "Incoming shortwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rad_sw_out",
      meaning: "Outgoing shortwave radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rtm_mrt",
      meaning: "Mean Radiant Temperature",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rtm_mrt_sw",
      meaning: "SW fraction of MRT radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
    {
      quantityName: "rtm_mrt_lw",
      meaning: "LW fraction of MRT radiation flux",
      d_xy: false,
      mask_tf: false,
      D_3: false,
      POI_pr: false,
      POI_ts: false,
    },
  ],
};
