import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { ResultsSelect } from "../../../Components/Map/Form/Select";
import { ResultsStepInput } from "../../../Components/Map/Form/Select";
import { Loader } from "../../../Components/Loader/index";
import { PlusCircle, Maximize2, X, Image, FileText, XCircle } from "react-feather";
import { timeSeriesSetupStyle } from "./TimeseriesChart";
import {
  changeChartFileType,
  changeChartParams,
  changeChartCustomHeight,
  changeChartPoint,
  changeChartTimestep,
} from "../../../redux/actions/resultsActions";
import { getDownloadLink } from "../../../api/storageApi";
import { requestNewCustomLayer } from "../../../api/resultsApi";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const VerticalChart = () => {
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [chartPngUrl, setChartPngUrl] = useState(null);
  const [chartSvgUrl, setChartSvgUrl] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const chartFileType = useSelector((state) => state.results.chartFileType);
  const chartFileTypeOptions = useSelector(
    (state) => state.results.chartFileTypeOptions
  );
  const chartParams = useSelector((state) => state.results.chartParams);
  const chartParamsIndex = useSelector(
    (state) => state.results.chartParamsIndex
  );
  const chartParamsOptions = useSelector(
    (state) => state.results.chartParamsOptions
  );
  const chartTimestep = useSelector((state) => state.results.chartTimestep);
  const chartTimestepOptions = useSelector(
    (state) => state.results.chartTimestepOptions
  );
  const chartCustomHeight = useSelector(
    (state) => state.results.chartCustomHeight
  );

  const chartPoint = useSelector((state) => state.results.chartPoint);
  const chartPointOptions = useSelector(
    (state) => state.results.allDrawnPoiUtm
  );
  const layerSettingsJson = useSelector(
    (state) => state.results.layerSettingsJson
  );
  const loadedProject = useSelector((state) => state.projects.loadedProject);
  const currentCase = useSelector((state) => state.projects.currentCase);
  const simulationJobs = useSelector(
    (state) => state.projects.currentCase?.simulationJobs
  );
  const postProcessingJobs = useSelector(
    (state) => state.projects.currentCase?.postProcessingJobs
  );
  const language = useSelector(
    (state) => state.userSettings.settings?.prefferedLanguage
  );
  const epsg = useSelector((state) => state.projects.loadedProject.epsg);
  const chartColorPalette = useSelector(
    (state) =>
      state.userSettings.settings?.visualizationPresets?.simulationChartPalette
  );
  const postprocessNotifications = useSelector(
    (state) => state.notifications.postprocessNotifications
  );

  const fetchFiles = async (chartPngUrl, chartSvgUrl) => {
    const sasChartPngUrl = await getDownloadLink(chartPngUrl);
    const sasChartSvgUrl = await getDownloadLink(chartSvgUrl);

    setChartPngUrl(sasChartPngUrl);
    setChartSvgUrl(sasChartSvgUrl);
    setProcessing(false);
  };

  useEffect(() => {
    if (postprocessNotifications.length !== 0) {
      let lastValidNot = postprocessNotifications.findLast(
        (x) =>
          x?.body?.caseId === currentCase.id &&
          x?.body?.status === 2 &&
          x?.body?.resultUrl &&
          JSON.parse(x?.body?.resultUrl)?.chart_chart_url_vertical
      );

      if (lastValidNot) {
        const urlStr = lastValidNot.body.resultUrl;

        try {
          JSON.parse(urlStr);
        } catch {
          return;
        }

        const urlObj = JSON.parse(urlStr);

        if (urlObj.chart_data_url_timeseries) {
          return;
        }

        const chartPngUrl = urlObj.chart_chart_url_vertical;
        const chartSvgUrl = urlObj.chart_data_url_vertical;

        fetchFiles(chartPngUrl, chartSvgUrl);
      }
    }
    return () => {
      setError(false);
    };
  }, []);

  useEffect(() => {
    if (
      postprocessNotifications?.length > 0 &&
      !isProcessing &&
      (postprocessNotifications[postprocessNotifications.length - 1].body
        .status === 0 ||
        postprocessNotifications[postprocessNotifications.length - 1].body
          .status === 1)
    ) {
      setProcessing(true);
      return;
    }
    if (postprocessNotifications.length !== 0) {
      let lastValidNot = postprocessNotifications.findLast(
        (x) =>
          x?.body?.caseId === currentCase.id &&
          x?.body?.status === 2 &&
          x?.body?.resultUrl &&
          JSON.parse(x?.body?.resultUrl)?.chart_chart_url_vertical
      );

      if (lastValidNot) {
        const urlStr = lastValidNot.body.resultUrl;

        try {
          JSON.parse(urlStr);
        } catch {
          return;
        }

        const urlObj = JSON.parse(urlStr);

        if (urlObj.chart_data_url_timeseries) {
          return;
        }

        const chartPngUrl = urlObj.chart_chart_url_vertical;
        const chartSvgUrl = urlObj.chart_data_url_vertical;

        fetchFiles(chartPngUrl, chartSvgUrl);
      } else if (
        postprocessNotifications.length !== 0 &&
        postprocessNotifications[postprocessNotifications.length - 1].body
          .status === 3 &&
        !error
      ) {
        setError(
          postprocessNotifications[postprocessNotifications.length - 1].body
            .error
        );
        setProcessing(false);
      }
    }
  }, [postprocessNotifications]);

  async function requestChart() {
    setChartPngUrl(null);
    setChartSvgUrl(null);
    setProcessing(true);
    setError(false);

    const fileUrl = layerSettingsJson.find(
      (x) => x.name === chartFileType
    ).filepath;

    const poi = chartPoint.map((x) => {
      return x.value;
    });
    let poiCoords = [];
    chartPoint.map((x) => {
      if (x.coords) poiCoords.push([x.coords[0], x.coords[1]]);
    });

    const request = {
      projectId: loadedProject.id,
      caseId: currentCase.id,
      simulationJobId: simulationJobs[0].id
        ? simulationJobs[0].id
        : postProcessingJobs[0].requestId,
      task: "horizontalscalarfield",
      settings: {
        task: "verticalprofilechart",
        lng: language,
        guiversion: "PALMGUIv20230112",
        customNcFile: fileUrl,
        fileName: chartFileType,
        paramShort: chartParamsOptions[chartParamsIndex].value,
        paramName: chartParamsOptions[chartParamsIndex].label,
        timeIndex: chartTimestep.index ? chartTimestep.index + 1 : 1,
        timeName: chartTimestep.value ? chartTimestep.value : chartTimestep,
        heightIndex: null,
        heightName: chartCustomHeight,
        verticalChartHeight: chartCustomHeight,
        colPalette: chartColorPalette ? chartColorPalette : "accent",
        colCustom: "", //ncFile: ncFile,
        crs: epsg,
        poi: poi,
        poiCoords: poiCoords,
        ncStatic: currentCase?.staticDriverJob?.resultUrl,
      },
    };

    console.log(JSON.stringify(request, null, 2));
    await requestNewCustomLayer(request);
  }

  const downloadChartPng = () => {
    const link = document.createElement("a");
    link.download = "download";
    link.href = chartPngUrl;
    link.click();
  };

  const downloadChartCsv = () => {
    const link = document.createElement("a");
    link.download = "download";
    link.href = chartSvgUrl;
    link.click();
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div
      className="layer-settings-container"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <h3
        className="raster-settings-header"
        style={{ marginBottom: "15px" }}
      >{`${t("PoiVerticalProfile")}`}</h3>
      <div style={{ width: "100%", height: "100%", display: "table" }}>
        <div
          style={{
            width: "100%",
            fontSize: "12px",
            fontWeight: "600",
            marginTop: "13px",
            fontFamily: "Segoe UI",
            color: "rgb(122, 122, 122)",
            marginBottom: "12px",
          }}
        >
          {t("DataSelection")}
        </div>

        <ResultsSelect
          label={t("OutputType")}
          value={
            chartFileType.value === "NoOptions"
              ? { label: t("NoOptions"), value: "NoOptions" }
              : chartFileType.value
          }
          options={chartFileTypeOptions.map((x) => {
            return { label: t(x.label), value: x.value };
          })}
          onChange={(e) => {
            dispatch(changeChartFileType(e.target.value));
          }}
          optionsObject
        />

        {chartFileType.value !== "NoOptions" && (
          <>
            <ResultsSelect
              label={t("Parameter")}
              value={chartParams}
              options={chartParamsOptions.map((x) => {
                return { label: t(x.label), value: x.value };
              })}
              onChange={(e) =>
                dispatch(
                  changeChartParams({
                    selectedParams: e.target.value,
                    paramsIndex: e.target.selectedIndex,
                  })
                )
              }
              optionsObject
            />
            <ResultsSelect
              label={t("Timestep")}
              value={chartTimestep.value}
              options={chartTimestepOptions}
              onChange={(e) =>
                dispatch(
                  changeChartTimestep({
                    value: e.target.value,
                    index: e.target.selectedIndex,
                  })
                )
              }
            />
            <ResultsStepInput
              label={t("HeightLevel")}
              value={chartCustomHeight}
              min={20}
              max={999}
              step={2}
              onChange={(e) =>
                dispatch(changeChartCustomHeight(e.target.value))
              }
              onBlur={(e) => {
                if (chartCustomHeight > 999 || chartCustomHeight < 20) {
                  dispatch(changeChartCustomHeight(20));
                }
              }}
            />
            <div
              className="form-group form-results-group"
              style={
                chartPoint.length > 2
                  ? { marginTop: "40px", marginBottom: "40px" }
                  : {}
              }
            >
              <label className="form-label form-results-label">
                {t("Points")}
              </label>
              <Select
                isMulti
                menuPlacement="bottom"
                isClearable
                distance={4}
                closeMenuOnSelect={false}
                styles={timeSeriesSetupStyle}
                placeholder={`${t("Select")}...`}
                value={chartPoint}
                options={[
                  ...chartPointOptions.flatMap((x) => {
                    return {
                      label: x.pointName,
                      value: x.pointName,
                      coords: [x.lat, x.lng],
                    };
                  }),
                  {
                    value: "DomainMean",
                    label: t("DomainMean"),
                  },
                ]}
                onChange={(e) => dispatch(changeChartPoint(e))}
                classNamePrefix="select"
              />
            </div>
          </>
        )}
        <hr className="raster-settings-ruler" style={{ marginBottom: "4px" }} />
        {isProcessing && !error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Loader size="small" style={{ alignSelf: "center" }} />
            <div
              style={{
                fontSize: "12px",
                cursor: "default",
                textAlign: "center",
                marginTop: "12px",
              }}
            >
              {t("Processing")}
            </div>
          </div>
        )}
        {chartFileType.value !== "NoOptions" && error && (
          <div
            style={{
              display: "flex",
              height: "fit-content",
              alignItems: "center",
              marginTop: "32px",
              justifyContent: "center",
            }}
          >
            <div>
              <XCircle
                color={"#a81d1dcf"}
                size={"14px"}
                strokeWidth={"1.8px"}
              />
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "#a81d1dcf",
                paddingLeft: "6px",
              }}
            >
              {t(error)}
            </div>
          </div>
        )}
        {chartPngUrl && (
          <div style={{ marginTop: "12px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "30px",
                marginTop: "20px",
                gap: "2px",
              }}
            >
              <div title={`${t("Maximize")}`}>
                <Maximize2
                  color={"#4e4e4e"}
                  onClick={() => setModalOpen(true)}
                  size={"12px"}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
              <div title={`${t("DownloadChart")}`}>
                <Image
                  color={"#4e4e4e"}
                  onClick={downloadChartPng}
                  size={"12px"}
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div title={`${t("DownloadDataCSV")}`}>
                <FileText
                  color={"#4e4e4e"}
                  onClick={downloadChartCsv}
                  size={"12px"}
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <div>
              <a href={chartPngUrl} target="_blank" rel="noopener noreferrer">
                <img src={chartPngUrl} alt="" style={{ width: "100%" }} />
              </a>
            </div>
          </div>
        )}
      </div>
      <div>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} style={modalStyle}>
            <div>
              <img src={chartPngUrl} alt="" width={"100%"} />
              <X
                onClick={() => setModalOpen(false)}
                size={"20px"}
                style={{
                  position: "fixed",
                  top: "25px",
                  right: "35px",
                  cursor: "pointer",
                }}
              />
              <Image
                onClick={downloadChartPng}
                size={"20px"}
                style={{
                  position: "fixed",
                  top: "25px",
                  right: "60px",
                  cursor: "pointer",
                }}
              />
              <FileText
                onClick={downloadChartCsv}
                size={"20px"}
                style={{
                  position: "fixed",
                  top: "25px",
                  right: "87px",
                  cursor: "pointer",
                }}
              />
            </div>
          </Modal>
        )}
      </div>
      {chartFileType.value !== "NoOptions" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "4px",
          }}
        >
          <button
            className="raster-settings-btn"
            style={{ marginTop: "12px" }}
            onClick={requestChart}
            disabled={!chartPoint || chartPoint.length === 0 || isProcessing}
          >
            <div className="btn-content-wrapper">
              <PlusCircle
                color={"#494949"}
                size={"16px"}
                strokeWidth={"1.8px"}
              />
              <div className="raster-btn-lbl">{t(`RequestData`)}</div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default VerticalChart;

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    //marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // boxShadow: "rgb(0 0 0 / 30%) 0px 0px 8px 1px",
    borderRadius: "5px",
    //padding: "30px 38px 28px",
    padding: "40px 0px 30px",
    //width: "440px",
    width: "590px",
    border: "1px solid rgb(187 187 187)",
  },
  overlay: {
    backdropFilter: "blur(8px)",
    zIndex: 1000,
  },
};
