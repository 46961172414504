import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { ResultsSelect } from "../../../Components/Map/Form/Select";
import { Loader } from "../../../Components/Loader/index";
import { PlusCircle, Maximize2, X, Image, FileText, XCircle } from "react-feather";
import {
  changeChartFileType,
  changeChartParams,
  changeChartHeightLevel,
  changeChartPoint,
} from "../../../redux/actions/resultsActions";
import { getDownloadLink } from "../../../api/storageApi";
import { requestNewCustomLayer } from "../../../api/resultsApi";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const TimeseriesChart = () => {
  const [isProcessing, setProcessing] = useState(false);
  const [chartPngUrl, setChartPngUrl] = useState(null);
  const [chartSvgUrl, setChartSvgUrl] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const chartFileType = useSelector((state) => state.results.chartFileType);
  const chartFileTypeOptions = useSelector(
    (state) => state.results.chartFileTypeOptions
  );
  const chartParams = useSelector((state) => state.results.chartParams);
  const chartParamsIndex = useSelector(
    (state) => state.results.chartParamsIndex
  );

  const chartParamsOptions = useSelector(
    (state) => state.results.chartParamsOptions
  );
  const chartHeightLevel = useSelector(
    (state) => state.results.chartHeightLevel
  );
  const chartHeightLevelOptions = useSelector(
    (state) => state.results.chartHeightLevelOptions
  );
  const chartPoint = useSelector((state) => state.results.chartPoint);
  const chartPointOptions = useSelector(
    (state) => state.results.allDrawnPoiUtm
  );
  const layerSettingsJson = useSelector(
    (state) => state.results.layerSettingsJson
  );
  const loadedProject = useSelector((state) => state.projects.loadedProject);
  const currentCase = useSelector((state) => state.projects.currentCase);
  const simulationJobs = useSelector(
    (state) => state.projects.currentCase?.simulationJobs
  );
  const postProcessingJobs = useSelector(
    (state) => state.projects.currentCase?.postProcessingJobs
  );
  const language = useSelector(
    (state) => state.userSettings.settings?.prefferedLanguage
  );
  const epsg = useSelector((state) => state.projects.loadedProject.epsg);
  const chartColorPalette = useSelector(
    (state) =>
      state.userSettings?.settings?.visualizationPresets?.simulationChartPalette
  );

  const postprocessNotifications = useSelector(
    (state) => state.notifications.postprocessNotifications
  );

  const fetchFiles = async (chartPngUrl, chartSvgUrl) => {
    const sasChartPngUrl = await getDownloadLink(chartPngUrl);
    const sasChartSvgUrl = await getDownloadLink(chartSvgUrl);

    setChartPngUrl(sasChartPngUrl);
    setChartSvgUrl(sasChartSvgUrl);
    setProcessing(false);
  };

  useEffect(() => {
    if (postprocessNotifications.length !== 0) {
      let lastValidNot = postprocessNotifications.findLast(
        (x) =>
          x?.body?.caseId === currentCase.id &&
          x?.body?.status === 2 &&
          x?.body?.resultUrl &&
          JSON.parse(x?.body?.resultUrl)?.chart_data_url_timeseries
      );

      if (lastValidNot) {
        const urlStr = lastValidNot.body.resultUrl;

        try {
          JSON.parse(urlStr);
        } catch {
          return;
        }

        const urlObj = JSON.parse(urlStr);

        if (urlObj.chart_chart_url_vertical) {
          return;
        }

        const chartPngUrl = urlObj.chart_chart_url_timeseries;
        const chartSvgUrl = urlObj.chart_data_url_timeseries;

        fetchFiles(chartPngUrl, chartSvgUrl);
      }
    }
  }, []);

  useEffect(() => {
    if (
      postprocessNotifications?.length > 0 &&
      !isProcessing &&
      (postprocessNotifications[postprocessNotifications.length - 1].body
        .status === 0 ||
        postprocessNotifications[postprocessNotifications.length - 1].body
          .status === 1)
    ) {
      setProcessing(true);
      return;
    }

    if (postprocessNotifications.length !== 0) {
      let lastValidNot = postprocessNotifications.findLast(
        (x) =>
          x?.body?.caseId === currentCase.id &&
          x?.body?.status === 2 &&
          x?.body?.resultUrl &&
          JSON.parse(x?.body?.resultUrl)?.chart_data_url_timeseries
      );
      if (lastValidNot) {
        const urlStr = lastValidNot.body.resultUrl;

        try {
          JSON.parse(urlStr);
        } catch {
          return;
        }

        const urlObj = JSON.parse(urlStr);

        if (urlObj.chart_chart_url_vertical) {
          return;
        }

        const chartPngUrl = urlObj.chart_chart_url_timeseries;
        const chartSvgUrl = urlObj.chart_data_url_timeseries;

        fetchFiles(chartPngUrl, chartSvgUrl);
      } else if (
        postprocessNotifications.length !== 0 &&
        postprocessNotifications[postprocessNotifications.length - 1].body
          .status === 3 &&
        !error
      ) {
        setError(
          postprocessNotifications[postprocessNotifications.length - 1].body
            .error
        );
        setProcessing(false);
      }
    }
  }, [postprocessNotifications]);

  async function requestChart() {
    setProcessing(true);
    setChartPngUrl(null);
    setChartSvgUrl(null);
    setError(false);
    const poi = chartPoint.map((x) => {
      return x.value;
    });
    let poiCoords = [];
    chartPoint.map((x) => {
      if (x.coords) poiCoords.push([x.coords[0], x.coords[1]]);
    });

    const fileUrl = layerSettingsJson.find(
      (x) => x.name === chartFileType
    ).filepath;

    const request = {
      projectId: loadedProject.id,
      caseId: currentCase.id,
      simulationJobId: simulationJobs[0].id
        ? simulationJobs[0].id
        : postProcessingJobs[0].requestId,
      task: "horizontalscalarfield",
      settings: {
        task: "timeserieschart",
        lng: language,
        guiversion: "PALMGUIv20230112",
        customNcFile: fileUrl,
        fileName: chartFileType,
        paramShort: chartParamsOptions[chartParamsIndex].value,
        paramName: chartParamsOptions[chartParamsIndex].label,
        heightIndex: chartHeightLevel.index ? chartHeightLevel.index + 1 : 1,
        heightName: chartHeightLevel.value
          ? chartHeightLevel.value
          : chartHeightLevel,
        colPalette: chartColorPalette ? chartColorPalette : "accent",
        colCustom: "",
        //ncFile: ncFile,
        crs: epsg,
        poi: poi,
        poiCoords: poiCoords,
        ncStatic: currentCase?.staticDriverJob?.resultUrl,
      },
    };
    console.log(JSON.stringify(request, null, 2));
    await requestNewCustomLayer(request);
  }

  const downloadChartPng = () => {
    const link = document.createElement("a");
    link.download = "download";
    link.href = chartPngUrl;
    link.click();
  };

  const downloadChartCsv = () => {
    const link = document.createElement("a");
    link.download = "download";
    link.href = chartSvgUrl;
    link.click();
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div
      className="layer-settings-container"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <h3
        className="raster-settings-header"
        style={{ marginBottom: "15px" }}
      >{`${t("POITimeseries")}`}</h3>
      <div style={{ width: "100%", height: "100%", display: "table" }}>
        <div
          style={{
            width: "100%",
            fontSize: "12px",
            fontWeight: "600",
            marginTop: "13px",
            fontFamily: "Segoe UI",
            color: "rgb(122, 122, 122)",
            marginBottom: "12px",
          }}
        >
          {t("DataSelection")}
        </div>
        <ResultsSelect
          label={t("OutputType")}
          value={
            chartFileType.value === "NoOptions"
              ? { label: t("NoOptions"), value: "NoOptions" }
              : chartFileType.value
          }
          options={chartFileTypeOptions}
          onChange={(e) => {
            dispatch(changeChartFileType(e.target.value));
          }}
          optionsObject
        />
        {chartFileType.value !== "NoOptions" && (
          <>
            <ResultsSelect
              label={t("Parameter")}
              value={chartParams}
              options={chartParamsOptions.map((x) => {
                return { label: t(x.label), value: x.value };
              })}
              onChange={(e) => {
                dispatch(
                  changeChartParams({
                    selectedParams: e.target.value,
                    paramsIndex: e.target.selectedIndex,
                  })
                );
              }}
              optionsObject
            />
            <ResultsSelect
              label={t("MaxHeight")}
              value={chartHeightLevel.value}
              options={chartHeightLevelOptions}
              onChange={(e) =>
                dispatch(
                  changeChartHeightLevel({
                    value: e.target.value,
                    index: e.target.selectedIndex,
                  })
                )
              }
            />
            <div
              className="form-group form-results-group"
              style={
                chartPoint.length > 2
                  ? { marginTop: "40px", marginBottom: "40px" }
                  : {}
              }
            >
              <label className="form-label form-results-label">
                {t("Points")}
              </label>
              <Select
                isMulti
                menuPlacement="bottom"
                isClearable
                distance={4}
                closeMenuOnSelect={false}
                styles={timeSeriesSetupStyle}
                placeholder={`${t("Select")}...`}
                value={chartPoint}
                autosize={false}
                options={[
                  ...chartPointOptions.flatMap((x) => {
                    return {
                      label: x.pointName,
                      value: x.pointName,
                      coords: [x.lat, x.lng],
                    };
                  }),
                  {
                    value: "DomainMin",
                    label: t("DomainMin"),
                  },
                  {
                    value: "DomainMean",
                    label: t("DomainMean"),
                  },
                  {
                    value: "DomainMax",
                    label: t("DomainMax"),
                  },
                ]}
                onChange={(e) => dispatch(changeChartPoint(e))}
                classNamePrefix="select"
              />
            </div>
          </>
        )}
        <hr className="raster-settings-ruler" style={{ marginBottom: "4px" }} />
        {isProcessing && !error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Loader size="small" style={{ alignSelf: "center" }} />
            <div
              style={{
                fontSize: "12px",
                cursor: "default",
                textAlign: "center",
                marginTop: "12px",
              }}
            >
              {t("Processing")}
            </div>
          </div>
        )}
        {chartFileType.value !== "NoOptions" && error && (
          <div
            style={{
              display: "flex",
              height: "fit-content",
              alignItems: "center",
              marginTop: "32px",
              justifyContent: "center",
            }}
          >
            <div>
              <XCircle
                color={"#a81d1dcf"}
                size={"14px"}
                strokeWidth={"1.8px"}
              />
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "#a81d1dcf",
                paddingLeft: "6px",
              }}
            >
              {t(error)}
            </div>
          </div>
        )}
        {chartPngUrl && (
          <div style={{ marginTop: "12px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "30px",
                marginTop: "20px",
                gap: "2px",
              }}
            >
              <div title={`${t("Maximize")}`}>
                <Maximize2
                  color={"#4e4e4e"}
                  onClick={() => setModalOpen(true)}
                  size={"12px"}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
              <div title={`${t("DownloadChart")}`}>
                <Image
                  color={"#4e4e4e"}
                  onClick={downloadChartPng}
                  size={"12px"}
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div title={`${t("DownloadDataCSV")}`}>
                <FileText
                  color={"#4e4e4e"}
                  onClick={downloadChartCsv}
                  size={"12px"}
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <div>
              <a href={chartPngUrl} target="_blank" rel="noopener noreferrer">
                <img src={chartPngUrl} alt="" style={{ width: "100%" }} />
              </a>
            </div>
          </div>
        )}
      </div>
      <div>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} style={modalStyle}>
            <div>
              <img src={chartPngUrl} alt="" width={"100%"} />
              <X
                onClick={() => setModalOpen(false)}
                size={"20px"}
                style={{
                  position: "fixed",
                  top: "25px",
                  right: "35px",
                  cursor: "pointer",
                }}
              />
              <Image
                onClick={downloadChartPng}
                size={"20px"}
                style={{
                  position: "fixed",
                  top: "25px",
                  right: "60px",
                  cursor: "pointer",
                }}
              />
              <FileText
                onClick={downloadChartCsv}
                size={"20px"}
                style={{
                  position: "fixed",
                  top: "25px",
                  right: "87px",
                  cursor: "pointer",
                }}
              />
            </div>
          </Modal>
        )}
      </div>
      {chartFileType.value !== "NoOptions" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "4px",
          }}
        >
          <button
            className="raster-settings-btn"
            style={{ marginTop: "12px" }}
            onClick={requestChart}
            disabled={!chartPoint || chartPoint.length === 0 || isProcessing}
          >
            <div className="btn-content-wrapper">
              <PlusCircle
                color={"#494949"}
                size={"16px"}
                strokeWidth={"1.8px"}
              />
              <div className="raster-btn-lbl">{t(`RequestData`)}</div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default TimeseriesChart;

export const timeSeriesSetupStyle = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid rgba(64, 67, 69, 0.62)",
    minHeight: "30px",
    height: "auto",
    maxHeight: "auto",
    width: "250px",
    fontFamily: "Segoe UI",
    boxShadow: null,
    //fontWeight: "500",
    cursor: "pointer",
    outline: "none",
    "&:hover": {
      border: "1px solid rgba(64, 67, 69, 0.62)",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "auto",
    padding: "0 6px",
    fontSize: "12px",
    overflow: "hidden",
    cursor: "pointer",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    width: 0,
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    ///height Of select component
    height: "29px",
    cursor: "pointer",
    color: "transparent",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    width: "17px",
    padding: "0px",
    paddingRight: "2px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "12px",
      color: isSelected ? "white" : "black",
      borderColor: "rgba(100, 105, 110, 0.62)",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#06896cd9"
        : isFocused
        ? "#06896c17"
        : null,
      cursor: isDisabled ? "default" : "pointer",
      ":active": {
        backgroundColor: "#06896c99",
        color: "white",
      },
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#495057",
      cursor: "pointer",
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: "#000",
    width: "15px",
    cursor: "pointer",
    padding: "0px",
    paddingRight: "1px",
  }),
  menu: (provided) => ({
    ...provided,
    border: "1px solid #727272",
    paddingLeft: "4px",
  }),
};

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    padding: "40px 0px 30px",
    width: "590px",
    border: "1px solid rgb(187 187 187)",
  },
  overlay: {
    backdropFilter: "blur(8px)",
    zIndex: 1000,
  },
};
