export const colors = {
  building: "#ea5e5e",
  vegetation: "#4dd599",
  pavement: "#5f6769",
  water: "#42dee1",
  tree: "#007944",
  street: "#FFA500",
  emission: "#e6b300",
  windTurbine: "#1a55c2",
  rasterFilling: "#EC058E",

  selectedBuilding: "#c85e5e",
  seletedVegetation: "#2dbe7f",
  selectedPavement: "#8c9597",
  selectedWater: "#19a1a3",
  selectedTree: "#00b365",
  selectedStreet: "#FFA500",
  default: "black",
};

export const switchColor = {
  building: "#D66565",
  vegetation: "#2dbe7f",
  pavement: "#8c9597",
  water: "#19a1a3",
  treePatches: "#00b365",
  singleTree: "#00b365",
  street: "#e6b300",
  emission: "#e6b300",
  mapPoi: "#c21aa8",
  windTurbine: "#1a55c2",
  default: "#ffffff",
};
