import React from "react";
import Page from "../../Components/Page/Page";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ExternalLink,
  Mail,
  Download,
} from "../../../node_modules/react-feather/dist/index";

const HelpLink = (props) => (
  <div
    style={{
      display: "flex",
      marginTop: 6,
      alignItems: "center",
      marginBottom: 10,
    }}
  >
    {props.mail && !props.download ? (
      <Mail color="rgb(0, 102, 204)" size={14} />
    ) : props.download ? (
      <Download color="rgb(0, 102, 204)" size={14} />
    ) : (
      <ExternalLink color="rgb(0, 102, 204)" size={14} />
    )}
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: 6, fontSize: 15, color: "rgb(0, 102, 204)" }}
    >
      {props.linkText}
    </a>
  </div>
);

const Help = () => {
  const { t, i18n } = useTranslation();

  return (
    <Page>
      <h1 className="settings-hdr">{t("Help")}</h1>
      <div className="about-info">{t(`HelpSumarize`)}</div>
      <div style={{ marginBottom: 12 }} />
      <h3 className="about-info" style={{ marginBottom: 12 }}>
        {t(`ManualHelp`)}
      </h3>
      <div className="about-info">{t(`ManualExt`)}</div>
      <HelpLink
        link="https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/"
        linkText={t(`ManualLink`)}
      />
      <h3 className="about-info" style={{ marginBottom: 12 }}>
        {t(`ExampleDataset`)}
      </h3>
      <div className="about-info">{t(`ExampleDatasetExt`)}</div>
      <HelpLink
        link="https://gitlab.cc-asp.fraunhofer.de/palm_gui/palm4u_gui/-/tree/main/CityClimate.ExampleData"
        linkText={t(`ExampleDatasetLink`)}
        download
      />
      <h3 className="about-info" style={{ marginBottom: 12 }}>
        {t(`QGIS_PLugin`)}
      </h3>
      <div className="about-info">{t(`QGIS_PLuginExt`)}</div>
      <div style={{ marginBottom: 16 }} />
      <div className="about-info">{t(`QGIS_PLuginScndExt`)}</div>
      <HelpLink
        link="https://gitlab.cc-asp.fraunhofer.de/palm_gui/palmclassify"
        linkText={t(`QGIS_PLuginLink`)}
      />
      <h3 className="about-info" style={{ marginBottom: 12 }}>
        {t(`Training`)}
      </h3>
      <div className="about-info">{t(`TrainingExt`)}</div>
      <HelpLink
        link="https://www.ibp.fraunhofer.de/de/kompetenzen/hygrothermik/aktuelle-forschungsthemen/klimawandelanpassungsmassnahmen-mit-palm4u.html"
        linkText={t(`TrainingLink`)}
      />
      <h3 className="about-info" style={{ marginBottom: 12 }}>
        {t(`Support`)}
      </h3>
      <div className="about-info">{t(`SupportExt`)}</div>
      <HelpLink
        link="mailto: palm-4u-support@hereon.de"
        linkText={t(`SupportLink`)}
        mail
      />
    </Page>
  );
};

export default withRouter(Help);
