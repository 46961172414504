import React from "react";
import { CircleMarker } from "react-leaflet";
import { useSelector } from "react-redux";

export const WindTurbinePropCopies = () => {
  const assignedWindTurbineCopyCoords = useSelector(
    (state) => state.map.assignedWindTurbineCopyCoords
  );
  return (
    <>
      {assignedWindTurbineCopyCoords.length !== 0 &&
        assignedWindTurbineCopyCoords.map((x) => (
          <CircleMarker
            center={x}
            color="red"
            fillColor="white"
            fillOpacity={"1"}
            radius={12}
            dashArray={"12"}
          />
        ))}
    </>
  );
};
