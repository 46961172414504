import { types } from "../types/legend";

export function toggleTopographyVisibility() {
  return {
    type: types.TOGGLE_TOPOGRAPHY_VISIBILITY,
  };
}

export function setHiddenObjectTypesIndexes(payload) {
  return {
    type: types.SET_HIDDEN_OBJECT_TYPES_INDEXES,
    payload,
  };
}

export function toggleAllDrawnObjectsVisibility() {
  return {
    type: types.TOGGLE_ALL_DRAWN_OBJECTS_VISIBILITY,
  };
}

export function toggleAllDrawnTreesVisibility() {
  return {
    type: types.TOGGLE_ALL_DRAWN_TREES_VISIBILITY,
  };
}

export function resetLegend() {
  return {
    type: types.RESET_LEGEND,
  };
}

export function showLegend() {
  return {
    type: types.SHOW_LEGEND,
  };
}

export function hideLegend() {
  return {
    type: types.HIDE_LEGEND,
  };
}

export function hideTopography() {
  return {
    type: types.HIDE_TOPOGRAPHY,
  };
}

export function getLegendData(payload) {
  return {
    type: types.GET_LEGEND_DATA,
    payload,
  };
}

export function setExtraTopographyVisibility(payload) {
  return {
    type: types.SET_EXTRA_TOPOGRAPHY_VISIBILITY,
    payload,
  };
}

export function getExtraLegendDataProps(payload) {
  return {
    type: types.GET_EXTRA_LEGEND_DATA_PROPS,
    payload,
  };
}

export function toggleWindTurbineTooltip() {
  return { type: types.TOGGLE_WIND_TURBINE_TOOLTIP };
}

export function toggleMapPoiTooltip() {
  return { type: types.TOGGLE_MAP_POI_TOOLTIP };
}
