import React from "react";
import L from "leaflet";
import { GeoJSON } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { emissionMarker } from "../Emission/EmissionDrawing/EmissionDrawing";
import { connect } from "react-redux";

class ChemistryGeoJson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.pointToEmissionLayer = this.pointToEmissionLayer.bind(this);
    this.createEmissionClusterIcon = this.createEmissionClusterIcon.bind(this);
  }

  pointToEmissionLayer(feature, latlng) {
    return L.marker(latlng, { icon: emissionMarker });
  }

  createEmissionClusterIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-emission span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <>
        {this.props.isGeoJsonVisible && (
          <>
            <MarkerClusterGroup
              iconCreateFunction={this.createEmissionClusterIcon}
              disableClusteringAtZoom={19}
              showCoverageOnHover
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
            >
              {this.props.emissionGeoJsonWg &&
                !this.props.hiddenObjectTypesIndexes.includes(8) && (
                  <GeoJSON
                    data={this.props.emissionGeoJsonWg}
                    onEachFeature={this.props.onEachFeature}
                    pointToLayer={this.pointToEmissionLayer}
                  />
                )}
            </MarkerClusterGroup>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emissionGeoJsonWg: state.map.emissionGeoJsonWg,
    mapPoiGeoJsonWg: state.map.mapPoiGeoJsonWg,
    hiddenObjectTypesIndexes: state.legend.hiddenObjectTypesIndexes,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
  };
};

export default connect(mapStateToProps, null)(ChemistryGeoJson);
