import React from "react";
import Select from "react-select";
import {
  ExpertHelpLabel,
  ExpertInput,
  ExpertTimeAccordion,
} from "../../ExpertUtils/ExpertComponent";
import { FormSettingsSeparator } from "../../../../Components/Common/Form/FormSeparator/index";
import { selectSetupStyle } from "../../../../Components/Common/PaletteDropdown/index";
import { changeExpertInput } from "../../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

const [page, subpage] = ["modules", "wind_turbine"];
const helpLink =
  "https://docs.palm-model.org/24.04/Reference/LES_Model/Namelists/#wind_turbine_parameters--";

const WindTurbine = (props) => {
  const wind_turbine = useSelector(
    (state) => state.expert.expertJson.modules.wind_turbine
  );
  const gridWidth = useSelector((state) => state.rasterArea.rasterGridWidth);
  const childGridSize = useSelector((state) => state.rasterArea.childGridSize);
  const domainType = useSelector((state) => state.rasterArea.domainType);

  const dispatch = useDispatch();

  return (
    <>
      <ExpertHelpLabel label={"Activate Module"} helpLink={""} />
      <Select
        isSearchable={false}
        styles={selectSetupStyle}
        options={[
          {
            value: "True",
            label: "True",
          },
          { value: "False", label: "False" },
        ]}
        value={{
          label: !!wind_turbine.isActive ? "True" : "False",
          value: !!wind_turbine.isActive ? "True" : "False",
        }}
        onChange={(e) =>
          dispatch(
            changeExpertInput({
              page: page,
              subpage: subpage,
              key: "",
              subkey: "isActive",
              value: e.value === "True" ? true : false,
            })
          )
        }
      />
      {wind_turbine.isActive && (
        <>
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Tablefile"}
          />
          <ExpertHelpLabel label={"Select Tablefile"} />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "NREL 5MW Blade",
                label: "NREL 5MW Blade",
              },
              { value: "NREL 15MW Blade", label: "NREL 15MW Blade" },
              { value: "User defined", label: "User defined" },
            ]}
            value={{
              value: wind_turbine.tablefile.wtm_tablefile,
              label: wind_turbine.tablefile.wtm_tablefile,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  value: e.value,
                })
              )
            }
          />
          {/* { wind_turbine.tablefile!=="NREL 5MW Blade" &&wind_turbine.tablefile!=="NREL 15MW Blade" &&
                <>
                
                
                </>
          } */}
          <FormSettingsSeparator
            style={{ fontSize: "17px" }}
            label={"Parameters"}
          />
          <ExpertHelpLabel
            label={"Air density [kg/m³]"}
            helpLink={`${helpLink}air_density`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="air_density"
            min={0.001}
            max={9.999}
            step={0.001}
            constValue={wind_turbine.parameters.air_density}
          />
          <ExpertHelpLabel
            label={"Gear efficiency [-]"}
            helpLink={`${helpLink}/gear_efficiency`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="gear_efficiency"
            min={0.01}
            max={1}
            step={0.01}
            constValue={wind_turbine.parameters.gear_efficiency}
          />

          <ExpertHelpLabel
            label={"Gear ratio rotor to generator [-]"}
            helpLink={`${helpLink}gear_ratio`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="gear_ratio"
            min={0.1}
            max={999.9}
            step={0.1}
            constValue={wind_turbine.parameters.gear_ratio}
          />

          <ExpertHelpLabel
            label={"Generator Electric efficiency [-]"}
            helpLink={`${helpLink}generator_efficiency`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="air_density"
            min={0.001}
            max={1}
            step={0.001}
            constValue={wind_turbine.parameters.generator_efficiency}
          />

          <ExpertHelpLabel
            label={"Generator Moment of Inertia [kg·m²]"}
            helpLink={`${helpLink}generator_inertia`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="generator_inertia"
            min={1}
            max={999.9}
            step={0.001}
            constValue={wind_turbine.parameters.generator_inertia}
          />

          <ExpertHelpLabel
            label={"Generator rated mechanical power [W]"}
            helpLink={`${helpLink}generator_power_rated`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="generator_power_rated"
            min={1}
            max={99999999.9}
            step={0.1}
            constValue={wind_turbine.parameters.generator_power_rated}
          />

          <ExpertHelpLabel
            label={"Generator rated speed [rad/s]"}
            helpLink={`${helpLink}generator_speed_rated`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="generator_speed_rated"
            min={1}
            max={999.9}
            step={0.0001}
            constValue={wind_turbine.parameters.generator_speed_rated}
          />

          <ExpertHelpLabel
            label={"Generator maximum torque [Nm]"}
            helpLink={`${helpLink}generator_torque_max`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="generator_torque_max"
            min={1}
            max={999999.9}
            step={0.0001}
            constValue={wind_turbine.parameters.generator_torque_max}
          />

          <ExpertHelpLabel
            label={"Generator max torque increase rate [Nm/s]"}
            helpLink={`${helpLink}generator_torque_rate_max`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="generator_torque_rate_max"
            min={1}
            max={999999.9}
            step={0.1}
            constValue={wind_turbine.parameters.generator_torque_rate_max}
          />

          <ExpertHelpLabel
            label={"Activate Pitch Control"}
            helpLink={`${helpLink}pitch_control`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!wind_turbine.parameters.pitch_control ? "True" : "False",
              value: !!wind_turbine.parameters.pitch_control ? "True" : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "pitch_control",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Maximum pitch rate [Deg/s]"}
            helpLink={`${helpLink}pitch_rate`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="pitch_rate"
            min={0.1}
            max={360}
            step={0.1}
            constValue={wind_turbine.parameters.pitch_rate}
          />

          <ExpertHelpLabel
            label={"Lower generator speed boundary of region 1.5 [rad/s]"}
            helpLink={`${helpLink}region_15_min`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="region_15_min"
            min={0.1}
            max={360}
            step={0.000001}
            constValue={wind_turbine.parameters.region_15_min}
          />

          <ExpertHelpLabel
            label={"Lower generator speed boundary of region 2 [rad/s]"}
            helpLink={`${helpLink}region_2_min`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="region_2_min"
            min={0.1}
            max={360}
            step={0.000001}
            constValue={wind_turbine.parameters.region_2_min}
          />

          <ExpertHelpLabel
            label={`Slope constant for region 2 [Nm·(rad/s)⁻²]`}
            helpLink={`${helpLink}region_2_slope`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="region_2_slope"
            min={0.1}
            max={360}
            step={0.000001}
            constValue={wind_turbine.parameters.region_2_slope}
          />

          <ExpertHelpLabel
            label={"Rotor Moment of inertia [kg·m²]"}
            helpLink={`${helpLink}rotor_inertia`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="rotor_inertia"
            min={1}
            max={99999999.9}
            step={0.000001}
            constValue={wind_turbine.parameters.rotor_inertia}
          />

          <ExpertHelpLabel
            label={"Minimum Grid Spacing [kg·m²]"}
            //helpLink={`${helpLink}rotor_inertia`}
          />
          {/* <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="rotor_inertia"
            min={1}
            max={99999999.9}
            step={0.000001}
            constValue={wind_turbine.parameters.rotor_inertia}
          /> */}

          <ExpertHelpLabel
            label={"Tangential length of rotor segments [-]"}
            helpLink={`${helpLink}segment_length_tangential`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="segment_length_tangential"
            min={0.1}
            max={99.9}
            step={0.1}
            constValue={wind_turbine.parameters.segment_length_tangential}
          />

          <ExpertHelpLabel
            label={"Radial width of rotor segments [-]"}
            helpLink={`${helpLink}segment_width_radial`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="segment_width_radial"
            min={0.1}
            max={99.9}
            step={0.1}
            constValue={wind_turbine.parameters.segment_width_radial}
          />

          <ExpertHelpLabel
            label={"Grid Spacing dx [m]"}
            //helpLink={`${helpLink}rotor_inertia`}
          />

          <ExpertInput
            disabled
            constValue={wind_turbine.parameters.smearing_kernel_size}
          />

          <ExpertHelpLabel
            label={"Size of the smearing kernel [-]"}
            helpLink={`${helpLink}smearing_kernel_size`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="smearing_kernel_size"
            min={1}
            max={99.9}
            step={0.1}
            constValue={wind_turbine.parameters.smearing_kernel_size}
          />

          <ExpertHelpLabel
            label={"Activate Speed Control"}
            helpLink={`${helpLink}speed_control`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!wind_turbine.parameters.speed_control ? "True" : "False",
              value: !!wind_turbine.parameters.speed_control ? "True" : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "speed_control",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Rotor Plane Tilt Angle [°]"}
            helpLink={`${helpLink}tilt_angle`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="tilt_angle"
            min={0}
            max={360}
            step={0.1}
            constValue={wind_turbine.parameters.tilt_angle}
          />

          <ExpertHelpLabel
            label={"Simulation time turbines are switched on [s]"}
            helpLink={`${helpLink}time_turbine_on`}
          />
          <ExpertTimeAccordion
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="time_turbine_on"
            min={0}
            max={86400}
            constValue={wind_turbine.parameters.time_turbine_on}
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Activate Tip Loss Correction"}
            helpLink={`${helpLink}tip_loss_correction`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!wind_turbine.parameters.tip_loss_correction
                ? "True"
                : "False",
              value: !!wind_turbine.parameters.tip_loss_correction
                ? "True"
                : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "tip_loss_correction",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />

          <ExpertHelpLabel
            label={"Yaw angle [°]"}
            helpLink={`${helpLink}yaw_angle`}
          />
          <ExpertInput
            page={page}
            subpage={subpage}
            section="parameters"
            subkey="yaw_angle"
            min={0}
            max={360}
            step={0.1}
            constValue={wind_turbine.parameters.yaw_angle}
          />

          <ExpertHelpLabel
            label={"Activate Yaw Control"}
            helpLink={`${helpLink}yaw_control`}
          />
          <Select
            isSearchable={false}
            styles={selectSetupStyle}
            options={[
              {
                value: "True",
                label: "True",
              },
              { value: "False", label: "False" },
            ]}
            value={{
              label: !!wind_turbine.parameters.yaw_control ? "True" : "False",
              value: !!wind_turbine.parameters.yaw_control ? "True" : "False",
            }}
            onChange={(e) =>
              dispatch(
                changeExpertInput({
                  page: page,
                  subpage: subpage,
                  key: "parameters",
                  subkey: "yaw_control",
                  value: e.value === "True" ? true : false,
                })
              )
            }
          />
          <div style={{ marginBottom: 12 }} />
          {wind_turbine.parameters.yaw_control && (
            <>
              <ExpertHelpLabel
                label={"Minimum yaw misalignment [rad]"}
                helpLink={`${helpLink}yaw_misalignment_min`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="parameters"
                subkey="yaw_misalignment_min"
                min={0.000001}
                max={1}
                step={0.000001}
                constValue={wind_turbine.parameters.yaw_misalignment_min}
              />

              <ExpertHelpLabel
                label={"Maximum yaw misalignment [rad]"}
                helpLink={`${helpLink}yaw_misalignment_max`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="parameters"
                subkey="yaw_misalignment_max"
                min={0.000001}
                max={1}
                step={0.000001}
                constValue={wind_turbine.parameters.yaw_misalignment_max}
              />

              <ExpertHelpLabel
                label={"Yaw actuator speed [rad/s]"}
                helpLink={`${helpLink}yaw_speed`}
              />
              <ExpertInput
                page={page}
                subpage={subpage}
                section="parameters"
                subkey="yaw_speed"
                min={0.000001}
                max={1}
                step={0.000001}
                constValue={wind_turbine.parameters.yaw_speed}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default WindTurbine;
