import React from "react";
import { ResultsSelect } from "../../../../../Components/Map/Form/Select";
import { ResultsLayerInfo } from "../../../../../Components/Map/Form/Select";
import { ResultsTextInput } from "../../../../../Components/Map/Form/Select";
import { isolinesSettingsPreset } from "../../../ResultsConst";
import {
  changeIsolinesPage,
  changeIsolinesLayer,
  changeIsolinesSettingsPreset,
  changeIsolinesSettingsMin,
  changeIsolinesSettingsMax,
  changeIsolinesSettingsBreaking,
  changeIsolinesSettingsBreakInterval,
} from "../../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Isolines extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.onPageChange = this.onPageChange.bind(this);
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onIsolinesSettingsPresetChange =
      this.onIsolinesSettingsPresetChange.bind(this);
    this.onIsolinesSettingsMinChange =
      this.onIsolinesSettingsMinChange.bind(this);
    this.onIsolinesSettingsMaxChange =
      this.onIsolinesSettingsMaxChange.bind(this);
    this.onIsolinesSettingsBreakingChange =
      this.onIsolinesSettingsBreakingChange.bind(this);
    this.onIsolinesSettingsBreakIntervalChange =
      this.onIsolinesSettingsBreakIntervalChange.bind(this);
  }

  onPageChange(e) {
    this.props.changeIsolinesPage({
      isolinesPage: e.target.value,
      isolinesPageIndex: e.target.selectedIndex,
    });
  }

  onLayerChange(e) {
    this.props.changeIsolinesLayer({
      isolinesLayer: e.target.value,
      isolinesLayerIndex: e.target.selectedIndex,
    });
  }

  onIsolinesSettingsPresetChange(e) {
    this.props.changeIsolinesSettingsPreset(e.target.value);
  }
  onIsolinesSettingsMinChange(e) {
    this.props.changeIsolinesSettingsMin(e.target.value);
  }
  onIsolinesSettingsMaxChange(e) {
    this.props.changeIsolinesSettingsMax(e.target.value);
  }
  onIsolinesSettingsBreakingChange(e) {
    this.props.changeIsolinesSettingsBreaking(e.target.value);
  }
  onIsolinesSettingsBreakIntervalChange(e) {
    this.props.changeIsolinesSettingsBreakInterval(e.target.value);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <h5 style={{ marginTop: "0px" }} className="raster-settings-header">
          {`${t("LayerSelection")}`}
        </h5>
        <p className="difference-header" style={{ marginBottom: "10px" }}>{`${t(
          "Isolines are created from existing layer"
        )}`}</p>
        <ResultsSelect
          label={t("Page")}
          value={this.props.isolinesPage}
          options={this.props.diffPageOptions.map((x) => {
            return { label: t(x.name), value: x.value };
          })}
          onChange={this.onPageChange}
          optionsObject
        />
        <ResultsSelect
          label={t("Layer")}
          value={this.props.isolinesLayer}
          options={this.props.resultsJsonOrigin.pages[
            this.props.isolinesPageIndex
          ].lyrs
            .filter(
              (x) =>
                x.type !== "wind_dir" &&
                x.type !== "wind_stream" &&
                x.type !== "isolines"
            )
            .map((x) => {
              return { label: t(x.name), value: x.name };
            })}
          onChange={this.onLayerChange}
          optionsObject
        />
        <h5
          className="raster-settings-header"
          style={{ marginBottom: "16px", marginTop: "14px" }}
        >{`${t("Statistics")}`}</h5>

        <ResultsLayerInfo
          label={t("Minimum")}
          value={this.props.jsonOriginPartSelected?.stat_min}
          style={{ borderRadius: "2px 2px 0px 0px" }}
          visSettings
        />
        <ResultsLayerInfo
          label={t("25%Quantile")}
          value={this.props.jsonOriginPartSelected?.stat_q25}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Median")}
          value={this.props.jsonOriginPartSelected?.stat_median}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Mean")}
          value={this.props.jsonOriginPartSelected?.stat_mean}
          visSettings
        />
        <ResultsLayerInfo
          label={t("75%Quantile")}
          value={this.props.jsonOriginPartSelected?.stat_q75}
          visSettings
        />
        <ResultsLayerInfo
          label={t("Maximum")}
          value={this.props.jsonOriginPartSelected?.stat_max}
          style={{ borderRadius: "0px 0px 2px 2px" }}
          visSettings
        />

        <h5
          className="raster-settings-header"
          style={{ marginBottom: "16px", marginTop: "14px" }}
        >{`${t("IsolineSettings")}`}</h5>
        <ResultsSelect
          label={t("Preset")}
          value={this.props.isolinesSettingsPreset}
          options={isolinesSettingsPreset.map((x) => {
            return { label: x, value: x };
          })}
          onChange={this.onIsolinesSettingsPresetChange}
          optionsObject
        />
        {this.props.isolinesSettingsPreset !== "auto" && (
          <>
            <ResultsTextInput
              label={t("Minimum")}
              value={this.props.isolinesCustomMin}
              onChange={this.onIsolinesSettingsMinChange}
              maxLength={40}
              visSettings
            />
            <ResultsTextInput
              label={t("Maximum")}
              value={this.props.isolinesCustomMax}
              onChange={this.onIsolinesSettingsMaxChange}
              maxLength={40}
              visSettings
            />
            <ResultsTextInput
              label={t("Break number")}
              value={this.props.isolinesCustomBreak}
              onChange={this.onIsolinesSettingsBreakingChange}
              maxLength={40}
              visSettings
            />
            <ResultsTextInput
              label={t("Break interval")}
              value={this.props.isolinesCustomBreakeNumber}
              onChange={this.onIsolinesSettingsBreakIntervalChange}
              maxLength={40}
              visSettings
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    isolinesPage: state.results.isolinesPage,
    isolinesPageIndex: state.results.isolinesPageIndex,
    isolinesLayer: state.results.isolinesLayer,
    isolinesLayerIndex: state.results.isolinesLayerIndex,
    diffPageOptions: state.results.diffPageOptions,
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
    isolinesSettingsPreset: state.results.isolinesSettingsPreset,
    isolinesCustomMin: state.results.isolinesCustomMin,
    isolinesCustomMax: state.results.isolinesCustomMax,
    isolinesCustomBreak: state.results.isolinesCustomBreak,
    isolinesCustomBreakeNumber: state.results.isolinesCustomBreakeNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeIsolinesPage: (payload) => dispatch(changeIsolinesPage(payload)),
    changeIsolinesLayer: (payload) => dispatch(changeIsolinesLayer(payload)),
    changeIsolinesSettingsPreset: (payload) =>
      dispatch(changeIsolinesSettingsPreset(payload)),
    changeIsolinesSettingsMin: (payload) =>
      dispatch(changeIsolinesSettingsMin(payload)),
    changeIsolinesSettingsMax: (payload) =>
      dispatch(changeIsolinesSettingsMax(payload)),
    changeIsolinesSettingsBreaking: (payload) =>
      dispatch(changeIsolinesSettingsBreaking(payload)),
    changeIsolinesSettingsBreakInterval: (payload) =>
      dispatch(changeIsolinesSettingsBreakInterval(payload)),
    changeIsolinesSettingsBreakInterval: (payload) =>
      dispatch(changeIsolinesSettingsBreakInterval(payload)),
    changeIsolinesSettingsBreakInterval: (payload) =>
      dispatch(changeIsolinesSettingsBreakInterval(payload)),
    changeIsolinesSettingsBreakInterval: (payload) =>
      dispatch(changeIsolinesSettingsBreakInterval(payload)),
    changeIsolinesSettingsBreakInterval: (payload) =>
      dispatch(changeIsolinesSettingsBreakInterval(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Isolines)
);
