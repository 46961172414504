import React from "react";
import L from "leaflet";
import { Marker, Pane } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import {
  selectWindTurbine,
  updateWindTurbinePositionOnDragend,
  addAssignedSingleWindTurbineCopy,
  enableWindTurbineDraggable,
  setMarkerRef,
} from "../../../../redux/actions/mapActions";
import windTurbineMarkerUrl from "./wind-turbine-icon.svg";
import highlightedWindTurbineMarkerUrl from "./wind-turbine-highlighted-icon.svg";
import windTurbineDrawnMarkerUrl from "./drawn-wind-turbine-icon.svg";
import highlightedDrawnWindTurbineMarkerUrl from "./drawn-highlighted-wind-turbine-icon.svg";
import existingWindTurbineMarkerUrl from "./wind-turbine-existing-icon.svg";
import highlightedExistingWindTurbineMarkerUrl from "./wind-turbine-existing-highlighted-icon.svg";
import existingDrawnWindTurbineMarkerUrl from "./drawn-wind-turbine-existing-icon.svg";
import highlightedDrawnExistingWindTurbineMarkerUrl from "./drawn-wind-turbine-highlighted-existing-icon.svg";

import { connect } from "react-redux";

class WindTurbineDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createClusterCustomIcon = this.createClusterCustomIcon.bind(this);
  }

  componentDidMount() {
    if (
      this.props.inWindTurbineDrawingMode &&
      this.props.mapRef.current.leafletElement.getZoom() === 19
    ) {
      this.props.enableWindTurbineDraggable();
    }
  }

  createClusterCustomIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-drawn-wind-turbine span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <Pane>
        {this.props.isGeoJsonVisible && !this.props.inWindTurbineDrawingMode ? (
          <MarkerClusterGroup
            iconCreateFunction={this.createClusterCustomIcon}
            disableClusteringAtZoom={19}
            showCoverageOnHover
            zoomToBoundsOnClick
            spiderfyOnMaxZoom={false}
          >
            {this.props.windTurbineDrawnVisibility &&
              this.props.allDrawnWindTurbineCoordinatesWg.map((x, idx) => (
                <CustomMarker
                  position={[x.lat, x.lng]}
                  icon={
                    this.props.selectedWindTurbineIndex === idx &&
                    x.wtstate === 1
                      ? highlightedDrawnExistingWindTurbineMarker
                      : this.props.selectedWindTurbineIndex === idx &&
                        x.wtstate === 0
                      ? highlightedDrawnWindTurbineMarker
                      : x.wtstate === 0
                      ? windTurbineDrawnMarker
                      : existingDrawnWindTurbineMarker

                    // this.props.selectedWindTurbineIndex === idx
                    //   ? highlightedDrawnWindTurbineMarker
                    //   : windTurbineDrawnMarker
                  }
                  zIndexOffset={
                    this.props.selectedWindTurbineIndex === idx ? 10000 : 1000
                  }
                  windTurbineIndex={idx}
                  selectedWindTurbineIndex={this.props.selectedWindTurbineIndex}
                  allDrawnWindTurbineGeoProps={
                    this.props.allDrawnWindTurbineGeoProps
                  }
                  objectTypeIndex={6}
                  markerIndex={idx}
                  onClick={
                    this.props.displayWindTurbineCursor
                      ? null
                      : !this.props.inWindTurbineCopyPropsMode
                      ? (e) => {
                          this.props.selectWindTurbine(idx);
                          this.props.setMarkerRef(e.target);
                        }
                      : () =>
                          this.props.addAssignedSingleWindTurbineCopy({
                            properties: {
                              id: this.props.arrayOfWindTurbineId[idx],
                            },
                            geometry: {
                              coordinates:
                                this.props.allDrawnWindTurbineCoordinatesWg[
                                  idx
                                ],
                            },
                          })
                  }
                  draggable={false}
                  onDragend={(e) =>
                    this.props.updateWindTurbinePositionOnDragend({
                      e: e.target._latlng,
                      idx: idx,
                    })
                  }
                  windTurbineTooltipVisibility={
                    this.props.windTurbineTooltipVisibility
                  }
                  setMarkerName={this.props.setMarkerName}
                  polygonData={this.props.polygonData}
                />
              ))}
          </MarkerClusterGroup>
        ) : (
          <>
            {!this.props.inRasterSettingsMode &&
              this.props.windTurbineDrawnVisibility &&
              this.props.allDrawnWindTurbineCoordinatesWg.map((x, idx) => (
                <Marker
                  position={[x.lat, x.lng]}
                  icon={
                    this.props.selectedWindTurbineIndex === idx &&
                    x.wtstate === 1
                      ? highlightedDrawnExistingWindTurbineMarker
                      : this.props.selectedWindTurbineIndex === idx &&
                        x.wtstate === 0
                      ? highlightedDrawnWindTurbineMarker
                      : x.wtstate === 0
                      ? windTurbineDrawnMarker
                      : existingDrawnWindTurbineMarker
                  }
                  zIndexOffset={
                    this.props.selectedWindTurbineIndex === idx ? 10000 : 1000
                  }
                  objectTypeIndex={6}
                  markerIndex={idx}
                  onClick={
                    this.props.displayWindTurbineCursor
                      ? null
                      : !this.props.inWindTurbineCopyPropsMode
                      ? () => {
                          this.props.selectWindTurbine(idx);
                        }
                      : () =>
                          this.props.addAssignedSingleWindTurbineCopy({
                            properties: {
                              id: this.props.arrayOfWindTurbineId[idx],
                            },
                            geometry: {
                              coordinates:
                                this.props.allDrawnWindTurbineCoordinatesWg[
                                  idx
                                ],
                            },
                          })
                  }
                  draggable={
                    this.props.inWindTurbineDrawingMode &&
                    !this.props.displayWindTurbineCursor
                  }
                  onDragend={(e) =>
                    this.props.updateWindTurbinePositionOnDragend({
                      e: e.target._latlng,
                      idx: idx,
                    })
                  }
                />
              ))}
          </>
        )}

        {this.props.inWindTurbineDrawingMode &&
          this.props.currentCursorCoords &&
          this.props.displayWindTurbineCursor && (
            <Marker
              icon={windTurbineMarker}
              position={this.props.currentCursorCoords}
              zIndex={1}
              zIndexOffset={1}
            />
          )}
      </Pane>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCursorCoords: state.map.currentCursorCoords,
    allDrawnWindTurbineVisibility: state.legend.allDrawnWindTurbineVisibility,
    allDrawnWindTurbineCoordinatesWg:
      state.map.allDrawnWindTurbineCoordinatesWg,
    selectedWindTurbineIndex: state.map.selectedWindTurbineIndex,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    inWindTurbineDrawingMode: state.map.inWindTurbineDrawingMode,
    inWindTurbineCopyPropsMode: state.map.inWindTurbineCopyPropsMode,
    arrayOfWindTurbineId: state.map.arrayOfWindTurbineId,
    displayWindTurbineCursor: state.map.displayWindTurbineCursor,
    isWindTurbineDraggable: state.map.isWindTurbineDraggable,
    windTurbineDrawnVisibility: state.map.windTurbineDrawnVisibility,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    polygonData: state.map.polygonData,
    allDrawnWindTurbineGeoProps: state.map.allDrawnWindTurbineGeoProps,
    windTurbineTooltipVisibility: state.legend.windTurbineTooltipVisibility,
    inDrawingMode: state.map.inDrawingMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectWindTurbine: (payload) => dispatch(selectWindTurbine(payload)),
    updateWindTurbinePositionOnDragend: (payload) =>
      dispatch(updateWindTurbinePositionOnDragend(payload)),
    addAssignedSingleWindTurbineCopy: (payload) =>
      dispatch(addAssignedSingleWindTurbineCopy(payload)),
    enableWindTurbineDraggable: () => dispatch(enableWindTurbineDraggable()),
    setMarkerRef: (payload) => dispatch(setMarkerRef(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WindTurbineDrawing);

const windTurbineMarker = new L.Icon({
  iconUrl: windTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedWindTurbineMarker = new L.Icon({
  iconUrl: highlightedWindTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const windTurbineDrawnMarker = new L.Icon({
  iconUrl: windTurbineDrawnMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedDrawnWindTurbineMarker = new L.Icon({
  iconUrl: highlightedDrawnWindTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const existingWindTurbineMarker = new L.Icon({
  iconUrl: existingWindTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(23, 23),
  className: "tree-style",
});

const highlightedExistingWindTurbineMarker = new L.Icon({
  iconUrl: highlightedExistingWindTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(23, 23),
  className: "tree-style",
});

const existingDrawnWindTurbineMarker = new L.Icon({
  iconUrl: existingDrawnWindTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(23, 23),
  className: "tree-style",
});

const highlightedDrawnExistingWindTurbineMarker = new L.Icon({
  iconUrl: highlightedDrawnExistingWindTurbineMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(23, 23),
  className: "tree-style",
});

export {
  windTurbineMarker,
  highlightedWindTurbineMarker,
  windTurbineDrawnMarker,
  highlightedDrawnWindTurbineMarker,
  existingWindTurbineMarker,
  highlightedExistingWindTurbineMarker,
  existingDrawnWindTurbineMarker,
  highlightedDrawnExistingWindTurbineMarker,
};

class CustomMarker extends React.Component {
  constructor(props) {
    super(props);
    this.markerRef = React.createRef(); // Create a ref for the marker
  }

  componentDidMount() {
    if (this.markerRef.current) {
      // Access the marker instance from the ref
      const marker = this.markerRef.current.leafletElement;
      const content = this.props.polygonData.find((x) => {
        return (
          x.id ===
          this.props.allDrawnWindTurbineGeoProps[this.props.windTurbineIndex].id
        );
      })?.wtname
        ? this.props.polygonData.find((x) => {
            return (
              x.id ===
              this.props.allDrawnWindTurbineGeoProps[
                this.props.windTurbineIndex
              ].id
            );
          })?.wtname
        : this.props.allDrawnWindTurbineGeoProps[this.props.windTurbineIndex]
            .wtname;

      // Bind the tooltip using vanilla Leaflet
      marker.bindTooltip(`${content}`, {
        permanent: true,
        className: `wind-turbine-tooltip ${
          this.props.windTurbineTooltipVisibility
            ? ""
            : "wind-turbine-tooltip-hidden"
        }`,
        direction: "top",
        offset: [0, -10],
      });

      //marker.on("click", this.props.setMarkerName);
      return marker;
    }
  }

  render() {
    return (
      <Marker
        position={this.props.position}
        ref={this.markerRef}
        onClick={this.props.onClick}
        icon={this.props.icon}
      />
    );
  }
}
