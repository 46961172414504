import React from "react";
import L from "leaflet";
import { Marker, Pane } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import emissionMarkerUrl from "./emission-icon.svg";
import highlightedEmissionMarkerUrl from "./emission-highlighted-icon.svg";
import emissionDrawnMarkerUrl from "./drawn-emission-icon.svg";
import highlightedDrawnEmissionMarkerUrl from "./drawn-highlighted-emission-icon.svg";
import {
  selectEmission,
  updateEmissionPositionOnDragend,
  addAssignedSingleEmissionCopy,
  enableEmissionDraggable,
} from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";

class EmissionDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createClusterCustomIcon = this.createClusterCustomIcon.bind(this);
  }

  componentDidMount() {
    if (
      this.props.inEmissionDrawingMode &&
      this.props.mapRef.current.leafletElement.getZoom() === 19
    ) {
      this.props.enableEmissionDraggable();
    }
  }

  createClusterCustomIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-drawn-emission span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <>
        {this.props.isGeoJsonVisible && (
          <Pane className="tree-area">
            {this.props.allDrawnEmissionVisibility &&
              (this.props.allDrawnEmissionCoordinatesWg?.length > 0 &&
              this.props.isGeoJsonVisible &&
              this.props.isEmissionDraggable ? (
                this.props.allDrawnEmissionCoordinatesWg.map((x, idx) => (
                  <Marker
                    position={[x[0], x[1]]}
                    icon={
                      this.props.selectedEmissionIndex === idx
                        ? highlightedDrawnEmissionMarker
                        : drawnEmissionMarker
                    }
                    zIndexOffset={
                      this.props.selectedEmissionIndex === idx ? 10000 : 1000
                    }
                    objectTypeIndex={6}
                    markerIndex={idx}
                    onClick={
                      this.props.inRasterSettingsMode ||
                      this.props.displayEmissionCursor
                        ? null
                        : !this.props.inEmissionCopyPropsMode
                        ? () => this.props.selectEmission(idx)
                        : () =>
                            this.props.addAssignedSingleEmissionCopy({
                              properties: {
                                id: this.props.arrayOfEmissionId[idx],
                              },
                              geometry: {
                                coordinates:
                                  this.props.allDrawnEmissionCoordinatesWg[idx],
                              },
                            })
                    }
                    draggable={
                      this.props.inEmissionDrawingMode &&
                      !this.props.displayEmissionCursor
                    }
                    onDragend={(e) =>
                      this.props.updateEmissionPositionOnDragend({
                        e: e.target._latlng,
                        idx: idx,
                      })
                    }
                  />
                ))
              ) : (
                <MarkerClusterGroup
                  iconCreateFunction={this.createClusterCustomIcon}
                  disableClusteringAtZoom={19}
                  showCoverageOnHover
                  zoomToBoundsOnClick
                  spiderfyOnMaxZoom={false}
                >
                  {this.props.emissionDrawnVisibility &&
                    this.props.allDrawnEmissionCoordinatesWg.map((x, idx) => (
                      <Marker
                        position={[x[0], x[1]]}
                        icon={
                          this.props.selectedEmissionIndex === idx
                            ? highlightedDrawnEmissionMarker
                            : drawnEmissionMarker
                        }
                        zIndexOffset={
                          this.props.selectedEmissionIndex === idx
                            ? 10000
                            : 1000
                        }
                        objectTypeIndex={6}
                        markerIndex={idx}
                        onClick={
                          this.props.inRasterSettingsMode ||
                          this.props.displayEmissionCursor
                            ? null
                            : !this.props.inEmissionCopyPropsMode
                            ? () => this.props.selectEmission(idx)
                            : () =>
                                this.props.addAssignedSingleEmissionCopy({
                                  properties: {
                                    id: this.props.arrayOfEmissionId[idx],
                                  },
                                  geometry: {
                                    coordinates:
                                      this.props.allDrawnEmissionCoordinatesWg[
                                        idx
                                      ],
                                  },
                                })
                        }
                        draggable={false}
                        onDragend={(e) =>
                          this.props.updateEmissionPositionOnDragend({
                            e: e.target._latlng,
                            idx: idx,
                          })
                        }
                      />
                    ))}
                </MarkerClusterGroup>
              ))}
            {this.props.inEmissionDrawingMode &&
              this.props.currentCursorCoords &&
              this.props.displayEmissionCursor && (
                <Marker
                  icon={drawnEmissionMarker}
                  position={this.props.currentCursorCoords}
                  zIndex={1}
                  zIndexOffset={1}
                />
              )}
          </Pane>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCursorCoords: state.map.currentCursorCoords,
    allDrawnEmissionVisibility: state.legend.allDrawnEmissionVisibility,
    allDrawnEmissionCoordinatesWg: state.map.allDrawnEmissionCoordinatesWg,
    selectedEmissionIndex: state.map.selectedEmissionIndex,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    inEmissionDrawingMode: state.map.inEmissionDrawingMode,
    inEmissionCopyPropsMode: state.map.inEmissionCopyPropsMode,
    arrayOfEmissionId: state.map.arrayOfEmissionId,
    displayEmissionCursor: state.map.displayEmissionCursor,
    isEmissionDraggable: state.map.isEmissionDraggable,
    emissionDrawnVisibility: state.map.emissionDrawnVisibility,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectEmission: (payload) => dispatch(selectEmission(payload)),
    updateEmissionPositionOnDragend: (payload) =>
      dispatch(updateEmissionPositionOnDragend(payload)),
    addAssignedSingleEmissionCopy: (payload) =>
      dispatch(addAssignedSingleEmissionCopy(payload)),
    enableEmissionDraggable: () => dispatch(enableEmissionDraggable()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmissionDrawing);

const emissionMarker = new L.Icon({
  iconUrl: emissionMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedEmissionMarker = new L.Icon({
  iconUrl: highlightedEmissionMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const drawnEmissionMarker = new L.Icon({
  iconUrl: emissionDrawnMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedDrawnEmissionMarker = new L.Icon({
  iconUrl: highlightedDrawnEmissionMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

export {
  emissionMarker,
  highlightedEmissionMarker,
  drawnEmissionMarker,
  highlightedDrawnEmissionMarker,
};
