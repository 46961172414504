import React from "react";
import ApprovalTick from "../../../static/icons/approval-tick.svg";
import { useTranslation } from "react-i18next";
import "./JsonStatusPanel.scss";

const JsonStatusPanel = () => {
  const { t } = useTranslation();
  return (
    <div className="status-panel-wrapper">
      <p className="status-hdr">{t("YouHaveSuccesfullyLoadedGeoJSON")}</p>
      <img src={ApprovalTick} alt="logo" className="approval-tick" />
      <p className="status-dscr">
        {t("SelectObject(Polygon)ToEditItsProperties")}
      </p>
    </div>
  );
};

export default JsonStatusPanel;

export const ResultMapViewerStatus = () => {
  const { t } = useTranslation();
  return (
    <div className="status-panel-wrapper">
      <p className="status-hdr">{t("YouAreInMapResultsViewer")}</p>
      <img src={ApprovalTick} alt="logo" className="approval-tick" />
      <p className="status-dscr">
        {t("ChangeLayersToShowDifferentsParameters")}
      </p>
    </div>
  );
};
