import React from "react";
import Panel from "../../Panel/Panel";
import MapPanelStatusBar from "./MapPanelStatusBar";
import ActionButton from "../ActionButton/ActionButton";
import SaveActionButtonWrapper from "../ActionButton/SaveActionButtonWrapper";
import GeotiffLegend from "../GeotiffLegend/GeotiffLegend";
import {
  centerMap,
  toggleDrawingMode,
  toggleTreeDrawingMode,
  togglePolygonDrawingMode,
  toggleRasterSettingsMode,
  toggleEmissionDrawingMode,
  toggleMapPoiDrawingMode,
  toggleStreetDrawingMode,
  toggleWindTurbineDrawingMode,
} from "../../../redux/actions/mapActions";
import { showLegend } from "../../../redux/actions/legendActions";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const MapPanel = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <MapPanelStatusBar />
      {!props.inDrawingMode && (
        <Panel>
          <ActionButton
            icon="center-view"
            onClick={props.centerMap}
            title={t("CenterView")}
            disabled={props.inRasterSettingsMode}
          />
          <ActionButton
            icon="drawing-mode"
            onClick={props.toggleDrawingMode}
            title={t("DrawingMode")}
          />
          <ActionButton
            icon="settings"
            onClick={props.toggleRasterSettingsMode}
            pushed={props.inRasterSettingsMode}
            title={t("RasterizationSettings")}
          />
          <SaveActionButtonWrapper />
          <ActionButton icon="transparent-template" />
          <ActionButton icon="legend" onMouseEnter={props.showLegend} />
          {props.topographyVisibility && <GeotiffLegend />}
          <ActionButton icon="transparent-template" />
        </Panel>
      )}
      {props.inDrawingMode && (
        <Panel applicationField={props.applicationField}>
          <ActionButton
            icon="tree-btn"
            onClick={props.toggleTreeDrawingMode}
            pushed={props.inTreeDrawingMode}
            title={t("AddSingleTree")}
          />

          <ActionButton
            icon="area-draw"
            onClick={props.togglePolygonDrawingMode}
            pushed={props.inPolygonDrawingMode && !props.inStreetDrawingMode}
            title={t("DrawPolygon")}
          />
          <ActionButton
            icon="street-ico"
            onClick={props.toggleStreetDrawingMode}
            pushed={props.inStreetDrawingMode}
            title={t("DrawStreet")}
          />
          <ActionButton
            icon="home"
            onClick={props.toggleEmissionDrawingMode}
            pushed={props.inEmissionDrawingMode}
            title={t("DrawEmission")}
          />
          <ActionButton
            icon="pin-location"
            onClick={props.toggleMapPoiDrawingMode}
            pushed={props.inMapPoiDrawingMode}
            title={t("DrawMapPoi")}
          />
          <ActionButton
            icon="wind-turbine"
            onClick={props.toggleWindTurbineDrawingMode}
            pushed={props.inWindTurbineDrawingMode}
            title={t("WindTurbine")}
          />
          <ActionButton
            icon="exit-drawing-mode"
            onClick={props.toggleDrawingMode}
            title={t("ExitDrawingMode")}
          />
        </Panel>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inDrawingMode: state.map.inDrawingMode,
    inTreeDrawingMode: state.map.inTreeDrawingMode,
    inPolygonDrawingMode: state.map.inPolygonDrawingMode,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    topographyVisibility: state.legend.topographyVisibility,
    applicationField: state.projects.currentCase.applicationField,
    inEmissionDrawingMode: state.map.inEmissionDrawingMode,
    inMapPoiDrawingMode: state.map.inMapPoiDrawingMode,
    inStreetDrawingMode: state.map.inStreetDrawingMode,
    inWindTurbineDrawingMode: state.map.inWindTurbineDrawingMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawingMode: () => dispatch(toggleDrawingMode()),
    toggleTreeDrawingMode: () => dispatch(toggleTreeDrawingMode()),
    togglePolygonDrawingMode: () => dispatch(togglePolygonDrawingMode()),
    toggleRasterSettingsMode: () => dispatch(toggleRasterSettingsMode()),
    centerMap: () => dispatch(centerMap()),
    showLegend: () => dispatch(showLegend()),
    toggleEmissionDrawingMode: () => dispatch(toggleEmissionDrawingMode()),
    toggleMapPoiDrawingMode: () => dispatch(toggleMapPoiDrawingMode()),
    toggleStreetDrawingMode: () => dispatch(toggleStreetDrawingMode()),
    toggleWindTurbineDrawingMode: () =>
      dispatch(toggleWindTurbineDrawingMode()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPanel);
