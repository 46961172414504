import React from "react";
import L from "leaflet";
import {
  Map as LeafletMap,
  TileLayer,
  WMSTileLayer,
  GeoJSON,
  LayersControl,
  Pane,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import WMSCapabilities from "wms-capabilities";
import { SelectedMapItemPolygon } from "./SelectedMapItemPolygon/SelectedMapItemPolygon";
import RasterArea from "./RasterArea/RasterArea";
import BufferArea from "./BufferArea/BufferArea";
import TreeDrawing, { treeMarker } from "./TreeDrawing/TreeDrawing";
import EmissionDrawing from "./Emission/EmissionDrawing/EmissionDrawing";
import WindTurbineDrawing from "./WindTurbine/WindTurbineDrawing/WindTurbineDrawing";
import MapPoiDrawing from "./MapPoi/MapPoiDrawing/MapPoiDrawing";
import { LayerOptions } from "../../common/TileLayers";
import { stylePolygon } from "../../utils/polygons/polygonStyle";
import RasterGeoJson from "./RasterArea/RasterGeoJson";
import NestedRasterGeoJson from "./RasterArea/NestedRasterGeoJson";
import ChildRasterGeoJson from "./RasterArea/ChildRasterGeoJson";
import TopographyWrapper from "./Topography/TopographyWrapper";
import ExtraTopography from "./Topography/ExtraTopography";
import PolygonDuringDrawing from "./PolygonDrawing/PolygonDuringDrawing";
import DrawnPolygon from "./PolygonDrawing/DrawnPolygon";
import DrawnPolygonGeoJson from "./PolygonDrawing/DrawnPolygonGeoJson";
import { ObjectPropCopies } from "./ObjectPropCopies/ObjectPropCopies";
import { SingleTreePropCopies } from "./ObjectPropCopies/SingleTreePropCopies";
import { EmissionPropCopies } from "./ObjectPropCopies/EmissionPropCopies";
import { WindTurbinePropCopies } from "./ObjectPropCopies/WindTurbinePropCopies";
import ChemistryGeoJson from "./ChemistryGeoJson/ChemistryGeoJson";
import WindTurbineGeoJson from "./WindTurbine/WindTurbineGeoJson/WindTurbineGeoJson";
import MapPoiGeoJson from "./MapPoi/MapPoiGeoJson/MapPoiGeoJson";
import LeafletRuler from "./WindTurbine/LeafletRuler";
import {
  addPolygonPointWg,
  drawPolygon,
  addTree,
  getCurrentCursorCoords,
  getSelectedMapItemGeoProps,
  centerMapSuccess,
  setWmsLayerNames,
  toggleCopyPropsMode,
  addAssignedObjectCopy,
  addAssignedSingleTreeCopy,
  addAssignedSingleEmissionCopy,
  addAssignedSingleWindTurbineCopy,
  filterGeoJson,
  toggleTreeCursorDisplay,
  enableTreeDraggable,
  disableTreeDraggable,
  addEmission,
  toggleEmissionCursorDisplay,
  enableEmissionDraggable,
  disableEmissionDraggable,
  addMapPoi,
  toggleMapPoiCursorDisplay,
  enableMapPoiDraggable,
  disableMapPoiDraggable,
  addWindTurbine,
  toggleWindTurbineCursorDisplay,
  enableWindTurbineDraggable,
  disableWindTurbineDraggable,
} from "../../redux/actions/mapActions";
import { Loader } from "../Loader";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./Map.scss";

const { BaseLayer } = LayersControl;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGeoJsonRef: false,
    };

    this.mapRef = React.createRef();
    this.geoJsonRef = React.createRef();
    this.drawnPolygonGeoJsonRef = React.createRef();
    this.selectedRef = React.createRef();
    this.rasterPreviewRef = React.createRef();
    this.parentRasterPreviewRef = React.createRef();
    this.childRasterPreviewRef = React.createRef();
    this.onEachFeature = this.onEachFeature.bind(this);
    this.handleMapItemClickGeojson = this.handleMapItemClickGeojson.bind(this);
    this.setInitialViewport = this.setInitialViewport.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.pointToLayer = this.pointToLayer.bind(this);
    this.handleZoomEnd = this.handleZoomEnd.bind(this);
  }

  componentDidMount() {
    if (this.props.currentWmsMap?.url) {
      const that = this;
      fetch(
        `${this.props.currentWmsMap.url}?service=WMS&request=GetCapabilities`
      )
        .then(function (response) {
          return response.text();
        })
        .then(function (text) {
          let wmsLayersNames = [];
          const result = new WMSCapabilities().parse(text);
          const multiLayer = result.Capability.Layer.Layer;
          if (multiLayer.length === 1 && multiLayer[0].Layer) {
            multiLayer[0].Layer.map((x) => {
              wmsLayersNames.push({ name: x.Name, title: x.Title });
            });
          } else {
            multiLayer.map((x) => {
              wmsLayersNames.push({ name: x.Name, title: x.Title });
            });
          }
          that.props.setWmsLayerNames(wmsLayersNames);
        });
    }
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  }

  componentDidUpdate(prevProps) {
    if (this.geoJsonRef.current !== null && !this.state.isGeoJsonRef) {
      this.setInitialViewport();
      this.props.centerMapSuccess();
      this.setState({ isGeoJsonRef: true });
    }

    if (
      prevProps.hiddenObjectTypesIndexes.length !==
      this.props.hiddenObjectTypesIndexes.length
    ) {
      this.props.filterGeoJson(this.props.hiddenObjectTypesIndexes);

      return;
    }

    if (
      !prevProps.geoJsonWg &&
      !!this.props.geoJsonWg &&
      this.props.geoJsonWg !== null
    ) {
      this.setInitialViewport();
    } else if (
      !this.props.inRasterSettingsMode &&
      this.props.geoJsonWg !== null
    )
      if (!prevProps.isCentering && this.props.isCentering) {
        this.setInitialViewport();
        this.props.centerMapSuccess();
      }
  }

  setInitialViewport() {
    if (
      this.geoJsonRef &&
      this.props.geoJsonWg !== null &&
      this.geoJsonRef.current !== null
    ) {
      const map = this.mapRef.current.leafletElement;
      const group = this.geoJsonRef.current.leafletElement;
      map.fitBounds(group.getBounds());
    }
  }

  onEachFeature(feature, layer) {
    layer.on({
      click: this.handleMapItemClickGeojson,
    });
  }

  handleMapItemClickGeojson(e) {
    if (
      this.props.inTreeDrawingMode ||
      this.props.inDrawingMode ||
      this.props.inPolygonDrawingMode ||
      this.props.inCopyPropsMode
    ) {
      return;
    } else if (
      this.props.inSingleTreeCopyPropsMode ||
      this.props.inEmissionCopyPropsMode ||
      this.props.inWindTurbineCopyPropsMode
    ) {
      let itemFeature = e.target.feature;
      if (itemFeature.properties.t === 6) {
        this.props.addAssignedSingleTreeCopy(itemFeature);
      } else if (itemFeature.properties.t === 8) {
        this.props.addAssignedSingleEmissionCopy(itemFeature);
      } else if (itemFeature.properties.t === 10) {
        this.props.addAssignedSingleWindTurbineCopy(itemFeature);
      }
      return;
    }

    this.props.getSelectedMapItemGeoProps(e.target.feature);
  }

  onMapClick = (e) => {
    const coordinates = e.latlng;
    if (this.props.inCopyPropsMode) {
      this.props.addAssignedObjectCopy([
        coordinates.lng.toFixed(6),
        coordinates.lat.toFixed(6),
      ]);
    } else if (this.props.inTreeDrawingMode && this.props.displayTreeCursor) {
      this.props.addTree([
        coordinates.lat.toFixed(6),
        coordinates.lng.toFixed(6),
      ]);
    } else if (this.props.inPolygonDrawingMode) {
      this.props.addPolygonPointWg({
        lng: Number(coordinates.lng.toFixed(6)),
        lat: Number(coordinates.lat.toFixed(6)),
      });
    } else if (
      this.props.inEmissionDrawingMode &&
      this.props.displayEmissionCursor
    ) {
      this.props.addEmission([
        coordinates.lat.toFixed(6),
        coordinates.lng.toFixed(6),
      ]);
    } else if (
      this.props.inWindTurbineDrawingMode &&
      this.props.displayWindTurbineCursor
    ) {
      this.props.addWindTurbine([
        coordinates.lat.toFixed(6),
        coordinates.lng.toFixed(6),
      ]);
    } else if (
      this.props.inMapPoiDrawingMode &&
      this.props.displayMapPoiCursor
    ) {
      this.props.addMapPoi([
        coordinates.lat.toFixed(6),
        coordinates.lng.toFixed(6),
      ]);
    }
  };

  handleMouseMove = (e) => {
    this.props.getCurrentCursorCoords([
      e.latlng.lat.toFixed(6),
      e.latlng.lng.toFixed(6),
    ]);
  };

  handleRightClick() {
    if (this.props.inDrawingMode) {
      if (this.props.inPolygonDrawingMode) {
        this.props.drawPolygon();
      } else if (this.props.inTreeDrawingMode) {
        this.props.toggleTreeCursorDisplay();
      } else if (this.props.inEmissionDrawingMode) {
        this.props.toggleEmissionCursorDisplay();
      } else if (this.props.inWindTurbineDrawingMode) {
        this.props.toggleWindTurbineCursorDisplay();
      } else if (this.props.inMapPoiDrawingMode) {
        this.props.toggleMapPoiCursorDisplay();
      }
    }
  }

  handleZoomEnd() {
    if (this.props.inDrawingMode) {
      if (this.props.inTreeDrawingMode) {
        if (this.mapRef.current.leafletElement.getZoom() === 19) {
          this.props.enableTreeDraggable();
        } else {
          this.props.disableTreeDraggable();
        }
      } else if (this.props.inEmissionDrawingMode) {
        if (this.mapRef.current.leafletElement.getZoom() === 19) {
          this.props.enableEmissionDraggable();
        } else {
          this.props.disableEmissionDraggable();
        }
      } else if (this.props.inWindTurbineDrawingMode) {
        if (this.mapRef.current.leafletElement.getZoom() === 19) {
          this.props.enableWindTurbineDraggable();
        } else {
          this.props.disableWindTurbineDraggable();
        }
      } else if (this.props.inMapPoiDrawingMode) {
        if (this.mapRef.current.leafletElement.getZoom() === 19) {
          this.props.enableMapPoiDraggable();
        } else {
          this.props.disableMapPoiDraggable();
        }
      }
    }
  }

  pointToLayer(feature, latlng) {
    return L.marker(latlng, { icon: treeMarker });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {!this.props.geoJsonWg && <Loader />}
        <LeafletMap
          ref={this.mapRef}
          className={
            !!this.props.geoJsonWg
              ? "leaflet-map"
              : "leaflet-map leaflet-map-hidden"
          }
          maxNativeZoom={19}
          maxZoom={24}
          center={this.props.center}
          zoom={this.props.zoom}
          preferCanvas
          zoomControl
          onClick={this.onMapClick}
          onMouseMove={
            this.props.inPolygonDrawingMode ||
            (this.props.inTreeDrawingMode && this.props.displayTreeCursor) ||
            (this.props.inEmissionDrawingMode &&
              this.props.displayEmissionCursor) ||
            (this.props.inWindTurbineDrawingMode &&
              this.props.displayWindTurbineCursor) ||
            (this.props.inMapPoiDrawingMode && this.props.displayMapPoiCursor)
              ? this.handleMouseMove
              : null
          }
          onContextMenu={this.handleRightClick}
          onZoomend={this.handleZoomEnd}
        >
          <LayersControl position="bottomleft">
            {LayerOptions.map((x) => {
              return (
                <BaseLayer key={x.name} name={t(x.name)} checked={x.checked}>
                  <TileLayer attribution={x.attribution} url={x.url} />
                </BaseLayer>
              );
            })}
            {this.props.wmsLayersNames.length > 0 &&
              this.props.wmsLayersNames.map((x) => (
                <BaseLayer name={x.title} checked={false}>
                  <WMSTileLayer
                    url={this.props.currentWmsMap.url}
                    layers={x.name}
                  />
                </BaseLayer>
              ))}
          </LayersControl>
          {!this.props.inTreeDrawingMode && this.props.isGeoJsonVisible && (
            <TreeDrawing mapRef={this.mapRef} />
          )}
          {this.props.inTreeDrawingMode && <TreeDrawing mapRef={this.mapRef} />}
          {this.props.inEmissionDrawingMode && (
            <EmissionDrawing mapRef={this.mapRef} />
          )}
          {!this.props.inEmissionDrawingMode && (
            <EmissionDrawing mapRef={this.mapRef} />
          )}
          <MapPoiDrawing mapRef={this.mapRef} />
          <WindTurbineDrawing mapRef={this.mapRef} />
          {this.props.inSingleTreeCopyPropsMode && <SingleTreePropCopies />}
          {this.props.inEmissionCopyPropsMode && <EmissionPropCopies />}
          {this.props.inWindTurbineCopyPropsMode && <WindTurbinePropCopies />}

          {!!this.props.geoJsonWg &&
            this.props.isGeoJsonVisible &&
            this.props.geoJsonWg?.features.length !== 0 && (
              <MarkerClusterGroup
                key={this.props.geoKey}
                disableClusteringAtZoom={19}
                showCoverageOnHover
                zoomToBoundsOnClick
                spiderfyOnMaxZoom={false}
              >
                <GeoJSON
                  ref={this.geoJsonRef}
                  style={(x) => {
                    return stylePolygon(
                      x,
                      false,
                      this.props.inputPalmTypesPalette
                    );
                  }}
                  data={
                    !this.props.hiddenObjectTypesIndexes.length
                      ? this.props.geoJsonWg
                      : this.props.filteredGeoJson
                  }
                  onEachFeature={this.onEachFeature}
                  pointToLayer={this.pointToLayer}
                />
              </MarkerClusterGroup>
            )}
          {this.props.isRasterPreviewVisible && (
            <RasterGeoJson rasterPreviewRef={this.rasterPreviewRef} />
          )}
          {this.props.isParentRasterPreviewVisible && (
            <NestedRasterGeoJson
              parentRasterPreviewRef={this.parentRasterPreviewRef}
            />
          )}
          {this.props.isChildRasterPreviewVisible && (
            <ChildRasterGeoJson
              childRasterPreviewRef={this.childRasterPreviewRef}
            />
          )}
          {this.props.inCopyPropsMode && (
            <Pane zIndex={2}>
              <ObjectPropCopies />
            </Pane>
          )}
          {this.props.inPolygonDrawingMode && (
            <Pane>
              <DrawnPolygon />
              <PolygonDuringDrawing />
            </Pane>
          )}
          {!this.props.inPolygonDrawingMode && (
            <DrawnPolygonGeoJson
              drawnPolygonGeoJsonRef={this.drawnPolygonGeoJsonRef}
              onEachFeature={this.onEachFeature}
            />
          )}
          <ChemistryGeoJson onEachFeature={this.onEachFeature} />
          <WindTurbineGeoJson onEachFeature={this.onEachFeature} />
          <MapPoiGeoJson onEachFeature={this.onEachFeature} />
          {this.props.inRasterSettingsMode && (
            <>
              <BufferArea />
              <RasterArea
                mapRef={this.mapRef}
                geoJsonRef={this.geoJsonRef}
                drawnPolygonGeoJsonRef={this.drawnPolygonGeoJsonRef}
              />
            </>
          )}
          <TopographyWrapper />
          <ExtraTopography />
          <SelectedMapItemPolygon layerRef={this.selectedRef} />
          <LeafletRuler />
        </LeafletMap>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geoJsonWg: state.map.geoJsonWg,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    parentRasterPreview: state.rasterArea.parentRasterPreview,
    childRasterPreview: state.rasterArea.childRasterPreview,
    isParentRasterPreviewVisible: state.rasterArea.isParentRasterPreviewVisible,
    isChildRasterPreviewVisible: state.rasterArea.isChildRasterPreviewVisible,
    isRasterPreviewVisible: state.rasterArea.isRasterPreviewVisible,
    hiddenObjectTypesIndexes: state.legend.hiddenObjectTypesIndexes,
    inTreeDrawingMode: state.map.inTreeDrawingMode,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    inPolygonDrawingMode: state.map.inPolygonDrawingMode,
    inDrawingMode: state.map.inDrawingMode,
    isCentering: state.map.isCentering,
    currentWmsMap: state.userSettings?.settings?.wsMaps?.currentValue,
    wmsLayersNames: state.map.wmsLayersNames,
    inCopyPropsMode: state.map.inCopyPropsMode,
    inSingleTreeCopyPropsMode: state.map.inSingleTreeCopyPropsMode,
    geoKey: state.map.geoKey,
    filteredGeoJson: state.map.filteredGeoJson,
    inputPalmTypesPalette:
      state.userSettings?.settings?.visualizationPresets
        ?.inputPalmTypesPalette || {},
    displayTreeCursor: state.map.displayTreeCursor,
    inEmissionDrawingMode: state.map.inEmissionDrawingMode,
    displayEmissionCursor: state.map.displayEmissionCursor,
    displayMapPoiCursor: state.map.displayMapPoiCursor,
    inMapPoiDrawingMode: state.map.inMapPoiDrawingMode,
    inEmissionCopyPropsMode: state.map.inEmissionCopyPropsMode,
    inWindTurbineDrawingMode: state.map.inWindTurbineDrawingMode,
    displayWindTurbineCursor: state.map.displayWindTurbineCursor,
    inWindTurbineCopyPropsMode: state.map.inWindTurbineCopyPropsMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPolygonPointWg: (payload) => dispatch(addPolygonPointWg(payload)),
    drawPolygon: () => dispatch(drawPolygon()),
    filterGeoJson: (payload) => dispatch(filterGeoJson(payload)),
    addTree: (payload) => dispatch(addTree(payload)),
    getCurrentCursorCoords: (payload) =>
      dispatch(getCurrentCursorCoords(payload)),
    getSelectedMapItemGeoProps: (payload) =>
      dispatch(getSelectedMapItemGeoProps(payload)),
    centerMapSuccess: () => dispatch(centerMapSuccess()),
    setWmsLayerNames: (payload) => dispatch(setWmsLayerNames(payload)),
    toggleCopyPropsMode: () => dispatch(toggleCopyPropsMode()),
    addAssignedObjectCopy: (payload) =>
      dispatch(addAssignedObjectCopy(payload)),
    addAssignedSingleTreeCopy: (payload) =>
      dispatch(addAssignedSingleTreeCopy(payload)),
    addAssignedSingleEmissionCopy: (payload) =>
      dispatch(addAssignedSingleEmissionCopy(payload)),
    toggleTreeCursorDisplay: () => dispatch(toggleTreeCursorDisplay()),
    enableTreeDraggable: () => dispatch(enableTreeDraggable()),
    disableTreeDraggable: () => dispatch(disableTreeDraggable()),
    addEmission: (payload) => dispatch(addEmission(payload)),
    toggleEmissionCursorDisplay: () => dispatch(toggleEmissionCursorDisplay()),
    enableEmissionDraggable: () => dispatch(enableEmissionDraggable()),
    disableEmissionDraggable: () => dispatch(disableEmissionDraggable()),
    addMapPoi: (payload) => dispatch(addMapPoi(payload)),
    toggleMapPoiCursorDisplay: () => dispatch(toggleMapPoiCursorDisplay()),
    enableMapPoiDraggable: () => dispatch(enableMapPoiDraggable()),
    disableMapPoiDraggable: () => dispatch(disableMapPoiDraggable()),
    addWindTurbine: (payload) => dispatch(addWindTurbine(payload)),
    toggleWindTurbineCursorDisplay: (payload) =>
      dispatch(toggleWindTurbineCursorDisplay(payload)),
    enableWindTurbineDraggable: (payload) =>
      dispatch(enableWindTurbineDraggable(payload)),
    disableWindTurbineDraggable: (payload) =>
      dispatch(disableWindTurbineDraggable(payload)),
    addAssignedSingleWindTurbineCopy: (payload) =>
      dispatch(addAssignedSingleWindTurbineCopy(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Map)
);
