import React from "react";
import "./LegendItem.scss";

const LegendItem = (props) => {
  return (
    <label className="legend-row-container">
      <input
        id={props.id}
        type="checkbox"
        className="legend-checkbox"
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        disabled={props.disabled}
      />
      <span className="legend-object-name">{props.name}</span>
    </label>
  );
};

export default LegendItem;
