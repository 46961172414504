import React from "react";
import L from "leaflet";
import { Marker, Pane, Polygon } from "react-leaflet";
import { mapPlotSquareEdge } from "../../ResultsConst";
import rasterCenterPointUrl from "./custom-print-center-point.svg";
import {
  updateMapPlotPosition,
  setMapPlotCenterLatUtm,
  setMapPlotCenterLngUtm,
} from "../../../../redux/actions/resultsActions";
import {
  calculateRasterAreaPolygonPoints,
  calculateRasterCenterUtm,
} from "../../../Domain/raster-area-helpers/index";
import { reprojectWgToUtmPoint } from "../../../../common/utmDef";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class CustomPrintArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.dragMapPlotCenter = this.dragMapPlotCenter.bind(this);
    this.handleMapPlotCenterDragend =
      this.handleMapPlotCenterDragend.bind(this);
  }

  dragMapPlotCenter(e) {
    const centerLatLngWg = e.target._latlng;

    const areaSquareLength =
      mapPlotSquareEdge[this.props.mapScale][this.props.pageSize];

    const mapPlotCenterUtm = calculateRasterCenterUtm(
      centerLatLngWg.lat,
      centerLatLngWg.lng,
      this.props.crsDef
    );

    const mapPlotPolygonPoints = calculateRasterAreaPolygonPoints(
      mapPlotCenterUtm.lng,
      mapPlotCenterUtm.lat,
      1,
      areaSquareLength,
      areaSquareLength,
      this.props.crsDef
    );

    this.props.updateMapPlotPosition({
      mapPlotCenterLngWg: centerLatLngWg.lng.toFixed(6),
      mapPlotCenterLatWg: centerLatLngWg.lat.toFixed(6),
      mapPlotCenterLatUtm: mapPlotCenterUtm.lat,
      mapPlotCenterLngUtm: mapPlotCenterUtm.lng,
      mapPlotPolygonPoints: mapPlotPolygonPoints,
    });
  }

  handleMapPlotCenterDragend(e) {
    const mapPlotCenterWg = {
      lat: e.target._latlng.lat,
      lng: e.target._latlng.lng,
    };

    const mapPlotCenterUtm = reprojectWgToUtmPoint(
      mapPlotCenterWg,
      this.props.crsDef
    );

    this.props.setMapPlotCenterLatUtm({
      mapPlotCenterLatUtm: mapPlotCenterUtm.lat,
      onDragend: true,
    });
    this.props.setMapPlotCenterLngUtm({
      mapPlotCenterLngUtm: mapPlotCenterUtm.lng,
      onDragend: true,
    });
  }

  render() {
    return (
      <>
        <Pane>
          <Marker
            autoPan
            position={{
              lat: this.props.mapPlotCenterLatWg,
              lng: this.props.mapPlotCenterLngWg,
            }}
            icon={rasterCenterPoint}
            opacity={1}
            draggable={this.props.isMapPlotDraggable}
            ondrag={this.dragMapPlotCenter}
            onDragend={this.handleMapPlotCenterDragend}
          />
          <Polygon
            autoPan
            positions={this.props.mapPlotPolygonPoints}
            color="#696969"
            fill={false}
            lineJoin={"round"}
            weight={6}
            dashArray={"50 10"}
          />
        </Pane>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapPlotCenterLatWg: state.results.mapPlotCenterLatWg,
    mapPlotCenterLngWg: state.results.mapPlotCenterLngWg,
    mapPlotPolygonPoints: state.results.mapPlotPolygonPoints,
    pageSize: state.results.pageSize,
    mapScale: state.results.mapScale,
    isMapPlotDraggable: state.results.isMapPlotDraggable,
    crsDef: state.results.crsDef,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMapPlotPosition: (payload) =>
      dispatch(updateMapPlotPosition(payload)),
    setMapPlotCenterLatUtm: (payload) =>
      dispatch(setMapPlotCenterLatUtm(payload)),
    setMapPlotCenterLngUtm: (payload) =>
      dispatch(setMapPlotCenterLngUtm(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomPrintArea));

const rasterCenterPoint = new L.Icon({
  iconUrl: rasterCenterPointUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
});
