import React from "react";
import LegendItem from "../../../../Components/Map/Legend/LegendItem/LegendItem";
import {
  setResultsGeotiffVisibility,
  hideResultsGeotiffLegend,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class StaticDriverLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onLayerVisibilityChange({
    resultsGeotiffName,
    currentLayerIndex,
    type,
    checked,
  }) {
    if (type === "isolines" && checked) {
      const layerNameNoWhiteSpace = resultsGeotiffName.replace(/\s/g, "");

      const isolinesLabels = document.querySelectorAll(
        `.${layerNameNoWhiteSpace}`
      );
      isolinesLabels.forEach((element) => element.remove());

      const leafletId = this.props.isolinesLeafletId;
      const layer = this.props.mapRef.leafletElement._layers[leafletId];
      this.props.mapRef.leafletElement.removeLayer(layer);
      this.props.leafletSet.clear();
    }
    this.props.setResultsGeotiffVisibility({
      resultsGeotiffName: resultsGeotiffName,
      currentLayerIndex: currentLayerIndex,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className="legend-container"
        onMouseLeave={this.props.hideResultsGeotiffLegend}
        style={{ top: "320px", width: "auto" }}
      >
        <h5 className="legend-hdr ">{t("ResultsGeotiff")}</h5>
        {this.props.resultsJsonOrigin.pages[
          this.props.currentViewIndex
        ].lyrs.map((x, i) => {
          return (
            <LegendItem
              id={x.id}
              name={`${t(x.name) + ` ${x.set_unit}`}`}
              checked={x.checked ? true : false}
              onChange={() =>
                this.onLayerVisibilityChange({
                  resultsGeotiffName: x.name,
                  currentLayerIndex: i,
                  type: x.type,
                  checked: x.checked,
                })
              }
            />
          );
        })}
        {this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
          .length === 0 && (
          <p style={{ fontSize: "12px" }}>{t("NoLayersInCurrentPage")}</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
    mapRef: state.results.mapRef,
    isolinesLeafletId: state.results.isolinesLeafletId,
    leafletSet: state.results.leafletSet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setResultsGeotiffVisibility: (payload) =>
      dispatch(setResultsGeotiffVisibility(payload)),
    hideResultsGeotiffLegend: () => dispatch(hideResultsGeotiffLegend()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StaticDriverLegend));
