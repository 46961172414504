import React from "react";
import L from "leaflet";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import { withLeaflet, MapLayer } from "react-leaflet";

export const GeotiffLayer = withLeaflet((props) => {
  return <PlottyGeotiffLayer {...props} />;
});

export class PlottyGeotiffLayer extends MapLayer {
  createLeafletElement(props) {
    const { topographyUrl, maxElevation, minElevation } = props;

    let plottyRenderer = L.LeafletGeotiff.plotty({
      displayMin: minElevation,
      displayMax: maxElevation,
      colorScale: this.props.nameOfElevationPallete,
    });

    let leafletGeotiff = new L.leafletGeotiff(topographyUrl, {
      renderer: plottyRenderer,
      noDataValue: undefined,
      noDataKey: undefined,
      blockSize: 65536,
      opacity: 0.5,
    });

    return leafletGeotiff;
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    this.leafletElement.addTo(map);
  }
}
