import L from "leaflet";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import geoblaze from "geoblaze";
import { getDownloadLink } from "../../api/storageApi";

export default async function generateIsolinesContours(
  mapRef,
  rasterUrll,
  layerName
) {
  const rasterUrl = await getDownloadLink(rasterUrll);
  const raster = await geoblaze.load(rasterUrl);

  const labelClusterEngine = new window.labelgun.default(hideLabel, showLabel);
  const maxElevation = raster.maxs[0];
  const minElevation = raster.mins[0];
  const nBreaks = 4;
  const allBreaks = generateArrayWithBreaks(
    minElevation,
    maxElevation,
    nBreaks
  );

  try {
    const width = raster.width;
    const height = raster.height;

    const minX = raster.xmin;
    const maxX = raster.xmax;
    const minY = raster.ymin;
    const maxY = raster.ymax;

    let flatRasterValueArray = raster.values[0];

    //const array2D = [];

    // for (let i = 0; i < height; i++) {
    //   const row = flatRasterValueArray[i];

    //   array2D.push([...row]);
    // }

    for (let i = 0; i < flatRasterValueArray.length; i++) {
      for (let j = 0; j < flatRasterValueArray[i].length; j++) {
        if (
          typeof flatRasterValueArray[i][j] !== "number" ||
          isNaN(flatRasterValueArray[i][j])
        ) {
          flatRasterValueArray[i][j] = 0;
        }
      }
    }

    let regularRasterValueArray = Array.from(flatRasterValueArray, (row) =>
      Array.from(row)
    );
    let isoLines = [];

    window.MarchingSquaresJS.isoLines(regularRasterValueArray, allBreaks, {
      noFrame: true,
    }).forEach(function (isolines, i) {
      isoLines.push({
        coords: isolines,
        level: i + 1,
        val: allBreaks[i],
      });
    });

    const polylineIsolines = [];
    isoLines.forEach((bigIso, k) => {
      polylineIsolines.push([]);

      bigIso.coords.forEach((singleIsolines, j) => {
        polylineIsolines[k].push([]);

        singleIsolines.forEach((x, i) => {
          const lng = minX + (x[0] / width) * (maxX - minX);
          const lat = maxY - (x[1] / height) * (maxY - minY);

          polylineIsolines[k][j].push([lat, lng]);
        });
      });
    });
    const markerGeojson = { type: "FeatureCollection", features: [] };

    ///FASTER REMDERING!

    // polylineIsolines.forEach((lineSegment, index) => {
    //   // Create a polyline for each segment
    //   const segment = L.polyline(lineSegment, {
    //     color: "black",
    //     weight: 1,
    //     opacity: 1,
    //     className: `${layerName}`,
    //     pane: customPane,
    //   });
    //   console.log(lineSegment)
    //   // Bind a tooltip to each segment
    //   segment.bindTooltip(`dasdsadas`, {
    //     permanent: true, // Tooltip is shown on hover
    //     direction: "top", // Tooltip appears above the line
    //     //offset: [0, 0],   // No offset
    //     className: "custom-tooltip-class", // Optional custom styling
    //   });

    //   // Add each segment to the map
    //   segment.addTo(mapRef.leafletElement);
    // });

    for (let index = 0; index < polylineIsolines.length; index++) {
      for (
        let indexins = 0;
        indexins < polylineIsolines[index].length;
        indexins++
      ) {
        if (polylineIsolines[index][indexins].length > 20) {
          const nestex = polylineIsolines[index][indexins];

          const labelPointsGeojson = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: nestex,
            },
            properties: {
              value: `${allBreaks[index]}`,
            },
          };
          const labelpoint = window.turf.pointOnFeature(labelPointsGeojson);

          markerGeojson.features.push(labelpoint);

          markerGeojson.features[
            markerGeojson.features.length - 1
          ].properties.value = `${allBreaks[index]}`;
        }
      }
    }

    let labels = [];
    let totalMarkers = 0;

    const layerNameNoWhiteSpace = layerName.replace(/\s/g, "");
    let markers = L.geoJSON(markerGeojson, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(
          { lng: latlng.lat, lat: latlng.lng },
          {
            radius: 0,
            stroke: false,
            fill: false,
          }
        );
      },
      onEachFeature: function (feature, layer) {
        layer.bindTooltip(`${feature.properties.value}`, {
          permanent: true,
          color: "red",
          className: `isolines-tooltip ${layerNameNoWhiteSpace}`,
        });
        labels.push(layer);
        totalMarkers += 1;
      },
    });

    let i = 0;
    markers.eachLayer(function (layer) {
      layer.added = true;
      addIsolinesLabel(layer, i, labelClusterEngine, mapRef);
      i++;
    });

    const leafletIsolines = L.polyline(polylineIsolines, {
      color: "black",
      weight: 1,
      opacity: 1,
    });

    leafletIsolines.addTo(mapRef.leafletElement);

    markers.addTo(mapRef.leafletElement);
    mapRef.leafletElement.on("zoomend", function () {
      resetLabels(markers, labelClusterEngine, mapRef);
    });

    resetLabels(markers, labelClusterEngine, mapRef);
    return {
      min: minElevation,
      max: maxElevation,
      leafletId: leafletIsolines._leaflet_id,
    };
  } catch (error) {
    console.error("Error generating contours:", error);
  }
}

function generateArrayWithBreaks(minVal, maxVal, nBreaks) {
  const interval = (maxVal - minVal) / nBreaks;

  return Array.from(
    { length: nBreaks + 1 },
    (_, i) => +(minVal + i * interval).toFixed(3)
  );
}

function hideLabel(label) {
  label.labelObject.style.opacity = 0;
}
function showLabel(label) {
  label.labelObject.style.opacity = 1;
}

function resetLabels(markers, labelClusterEngine, mapRef) {
  let i = 0;
  markers.eachLayer((layer) => {
    addIsolinesLabel(layer, ++i, labelClusterEngine, mapRef);
  });
  labelClusterEngine.update();
}

function addIsolinesLabel(layer, id, labelClusterEngine, mapRef) {
  let label = layer.getTooltip()._source._tooltip._container;

  if (label) {
    let rect = label.getBoundingClientRect();
    let bottomLeft = mapRef.leafletElement.containerPointToLatLng([
      rect.left,
      rect.bottom,
    ]);
    let topRight = mapRef.leafletElement.containerPointToLatLng([
      rect.right,
      rect.top,
    ]);
    let boundingBox = {
      bottomLeft: [bottomLeft.lng, bottomLeft.lat],
      topRight: [topRight.lng, topRight.lat],
    };

    labelClusterEngine.ingestLabel(
      boundingBox,
      id,
      parseInt(Math.random() * (5 - 1) + 1),
      label,
      "Test " + id,
      false
    );

    if (!layer.added) {
      layer.addTo(mapRef.leafletElement);
      layer.added = true;
    }
  }
}
