import React from "react";
import { Pane } from "react-leaflet";
import { ExtraGeotiffLayer } from "../../../../Components/Map/Topography/ExtraGeotiffLayer";
import { getDownloadLink } from "../../../../api/storageApi";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import {
  updateResultsJsonOrigin,
  bringGeotiffToFront,
} from "../../../../redux/actions/resultsActions";
import { results as resultsColor } from "../../../../common/VisualizationPresets/colorPalettes";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";

class ResultsGeotiff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    if (this.props.nrOfAutoPages - 2 < this.props.currentViewIndex) {
      return;
    }

    plotty.addColorScale(
      `ylGnBu`,
      [
        "#FFFFD9",
        "#EDF8B1",
        "#C7E9B4",
        "#7FCDBB",
        "#41B6C4",
        "#1D91C0",
        "#225EA8",
        "#253494",
        "#081D58",
      ],
      [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
    );

    for (
      let pageIndex = 0;
      pageIndex < this.props.nrOfAutoPages - 1;
      pageIndex++
    ) {
      if (this.props.resultsJsonOrigin.pages[pageIndex].lyrs) {
        for (
          let layerIndex = 0;
          layerIndex <
          this.props.resultsJsonOrigin.pages[pageIndex].lyrs.length;
          layerIndex++
        ) {
          if (
            this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
              .plot_preset_cols &&
            this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
              .type !== "wind_stream"
          ) {
            plotty.addColorScale(
              this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                .plot_color,
              this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                .plot_preset_cols,
              this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                .plot_preset_breaks_perc
            );
          } else {
            if (
              this.props.resultsJsonOrigin.pages[pageIndex].lyrs[
                layerIndex
              ].plot_color.includes("inv")
            ) {
              const colorName =
                this.props.resultsJsonOrigin.pages[pageIndex].lyrs[
                  layerIndex
                ].plot_color.substring(3);

              const oldColors = resultsColor[colorName];
              const newColors = [...oldColors].reverse();
              plotty.addColorScale(
                `${"inv" + colorName}`,
                newColors,
                [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
              );

              plotty.addColorScale(
                `${colorName}`,
                oldColors,
                [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
              );
            } else if (
              this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                .type !== "wind_stream" &&
              this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                .type !== "wind_dir"
            ) {
              plotty.addColorScale(
                this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                  .plot_color,
                resultsColor[
                  this.props.resultsJsonOrigin.pages[pageIndex].lyrs[layerIndex]
                    .plot_color
                ],
                [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
              );
            }
          }
        }
      }
    }

    let resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);
    if (!resultsJsonOrigin.pages[0].lyrs[0].checked) {
      for (let index = 0; index < this.props.nrOfAutoPages - 1; index++) {
        if (
          resultsJsonOrigin.pages[index].lyrs &&
          resultsJsonOrigin.pages[index].lyrs?.length !== 0
        ) {
          const resultsGeotiffLink = await getDownloadLink(
            `${resultsJsonOrigin.pages[index].lyrs[0].geo_4326}`
          );
          resultsJsonOrigin.pages[index].lyrs[0].url = resultsGeotiffLink;
          resultsJsonOrigin.pages[index].lyrs[0].checked = true;
        }
      }
    }
    this.props.updateResultsJsonOrigin(resultsJsonOrigin);
  }

  async componentDidUpdate(prevProps) {
    if (
      (prevProps.lastResultsGeotiffNameChanged === undefined &&
        this.props.lastResultsGeotiffNameChanged &&
        this.props.lastResultsGeotiffNameChanged !==
          prevProps.lastResultsGeotiffNameChanged) ||
      (prevProps.lastResultsGeotiffNameChanged !== undefined &&
        this.props.lastResultsGeotiffNameChanged !==
          prevProps.lastResultsGeotiffNameChanged)
    ) {
      const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);
      if (
        resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.lastResultsGeotiffLayerIndexChanged
        ].url === undefined
      ) {
        const resultsGeotiffLayerProps =
          resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
            this.props.lastResultsGeotiffLayerIndexChanged
          ];

        const resultsGeotiffLink = await getDownloadLink(
          `${resultsGeotiffLayerProps.geo_4326}`
        );
        resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.lastResultsGeotiffLayerIndexChanged
        ].url = resultsGeotiffLink;
        this.props.updateResultsJsonOrigin(resultsJsonOrigin);
      }
    }

    if (
      this.props.inLayerAppearance &&
      prevProps.inLayerAppearance !== this.props.inLayerAppearance
    ) {
      const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);
      const resultsGeotiffLayerProps =
        resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
          this.props.jsonOriginLayerIndex
        ];

      if (resultsGeotiffLayerProps.checked) {
        return;
      }

      const resultsGeotiffLink = await getDownloadLink(
        `${resultsGeotiffLayerProps.geo_4326}`
      );

      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.jsonOriginLayerIndex
      ].url = resultsGeotiffLink;
      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.jsonOriginLayerIndex
      ].checked = true;
      this.props.updateResultsJsonOrigin(resultsJsonOrigin);
    } else if (
      prevProps.jsonOriginPartSelected &&
      this.props.jsonOriginPartSelected &&
      prevProps.jsonOriginPartSelected !== this.props.jsonOriginPartSelected
    ) {
      if (this.props.jsonOriginPartSelected.plot_preset_breaks) {
        plotty.addColorScale(
          this.props.jsonOriginPartSelected.plot_color,
          this.props.jsonOriginPartSelected.plot_preset_cols,
          this.props.jsonOriginPartSelected.plot_preset_breaks_perc
        );
      }
      //this.props.bringGeotiffToFront();
    }
  }

  render() {
    return (
      <>
        {this.props.resultsJsonOrigin.pages[
          this.props.currentViewIndex
        ].lyrs.map((x, i) => {
          if (
            x.url &&
            x.checked &&
            x.type !== "wind_stream" &&
            x.type !== "wind_dir" &&
            x.type !== "isolines"
          ) {
            return (
              <Pane>
                <ExtraGeotiffLayer
                  onResultsReady={(ref) => this.props.onResultsReady(ref)}
                  key={
                    x.plot_preset_cols
                      ? x.plot_preset_cols + x.plot_opacity + x.plot_min
                      : x.geo_4326 +
                        x.plot_opacity +
                        x.plot_min +
                        x.plot_max +
                        x.plot_color
                  }
                  zIndex={100 - i}
                  topographyUrl={x.url}
                  colorName={x.plot_color}
                  displayMin={
                    x.plot_preset_breaks ? x.plot_preset_breaks[0] : x.plot_min
                  }
                  displayMax={
                    x.plot_preset_breaks
                      ? x.plot_preset_breaks[x.plot_preset_breaks.length - 1]
                      : x.plot_max
                  }
                  opacity={x.plot_opacity}
                  name={x.name}
                  unit={x.set_unit}
                />
              </Pane>
            );
          }
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
    lastResultsGeotiffNameChanged: state.results.lastResultsGeotiffNameChanged,
    lastResultsGeotiffLayerIndexChanged:
      state.results.lastResultsGeotiffLayerIndexChanged,
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
    jsonOriginLayerIndex: state.results.jsonOriginLayerIndex,
    inLayerAppearance: state.results.inLayerAppearance,
    nrOfAutoPages: state.results.nrOfAutoPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateResultsJsonOrigin: (payload) =>
      dispatch(updateResultsJsonOrigin(payload)),
    bringGeotiffToFront: () => dispatch(bringGeotiffToFront()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsGeotiff);
