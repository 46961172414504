import React from "react";
import { Select } from "../Form/Select";
import RasterPriority from "./RasterPriority";
import { NumericInput } from "../Form/NumericInput";
import AccordionSection from "../../Common/AccordionSection/index";
import { HelpCircle } from "../../../../node_modules/react-feather/dist/index";
import {
  expertSettingsGroups,
  soilTypeOptions,
} from "./RasterizationSettingsConst";
import {
  soilType,
  season,
  rasterMethod,
  globalWaterTemperatureSourceOptions,
  fillValue,
} from "./RasterizationSettingsConst";
import * as actions from "../../../redux/actions/rasterAreaActions";
import { cloneDeep } from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./RasterizationSettings.scss";

const helpUrl =
  "https://palm_gui.pages.fraunhofer.de/palmgui_handbuch/domainmap.html#sec-domainmap-raster-expertsettings";

const soilHelpUrl =
  " https://palm.muk.uni-hannover.de/trac/wiki/doc/app/land_surface_parameters#";

class ExpertRasterizationSettings extends React.Component {
  render() {
    const { t } = this.props;
    let topoForChildDomain = [
      {
        name: "SameAsParentDomain",
        path: null,
      },
    ];
    let sameAsChild = [
      {
        name: "FromCityModel",
        path: null,
      },
    ];

    let heightsFilesOptions = null;
    if (this.props.files?.some((x) => x.type === "image/tiff")) {
      topoForChildDomain = this.props.files.filter((x) => {
        return x.type === "image/tiff";
      });

      sameAsChild = cloneDeep(topoForChildDomain);
      heightsFilesOptions = cloneDeep(sameAsChild);

      topoForChildDomain.unshift({
        name: "SameAsParentDomain",
        path: null,
      });
      sameAsChild.unshift({
        name: "FromCityModel",
        path: null,
      });
    }
    return (
      <div className="raster-settings-container raster-settins-expert-container">
        <div
          className="building-accordion-wrapper"
          style={{ borderTop: "0px solid Childwhite" }}
        >
          {expertSettingsGroups.map((y, i) => {
            if (
              this.props.rasterArea.domainType === "SingleDomain" &&
              y === "NestingSettings"
            ) {
              return;
            }
            return (
              <AccordionSection title={t(y)} buildingGroup>
                {y === "GlobalParameters" ? (
                  <>
                    <Select
                      label={t("SoilType")}
                      value={this.props.rasterArea.soilType}
                      options={soilTypeOptions.map((x) => {
                        return { label: t(`${x}`), value: x };
                      })}
                      onChange={(e) => this.props.setSoilType(e.target.value)}
                      help={helpUrl}
                      // disabled={
                      //   this.props.applicationField !== 0 ||
                      //   this.props.applicationField !== 4
                      // }
                      disabled
                    />
                    <Select
                      label={t("SelectSoilType")}
                      value={this.props.rasterArea.soilType}
                      options={soilType.map((x) => {
                        return { label: t(`${x}`), value: x };
                      })}
                      onChange={(e) => this.props.setSoilType(e.target.value)}
                      help={helpUrl}
                      // disabled={
                      //   this.props.applicationField !== 0 ||
                      //   this.props.applicationField !== 4
                      // }
                    />

                    {this.props.rasterArea.soilType === "UserDefined" && (
                      <>
                        <NumericInput
                          name={"SoilAlphaCoefficient"}
                          label={t("SoilAlphaCoefficient")}
                          value={this.props.rasterArea.soilAlphaCoefficient}
                          min={0}
                          max={9.99}
                          step={0.01}
                          maxLength={3}
                          unit={"-"}
                          onChange={(e) =>
                            this.props.setSoilAlphaCoefficient(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.soilAlphaCoefficient <
                                Number(e.target.min) ||
                              this.props.rasterArea.soilAlphaCoefficient >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilAlphaCoefficient(3.14);
                            }
                          }}
                          help={soilHelpUrl + "alpha_vangenuchten"}
                        />

                        <NumericInput
                          name={"SoilLCoefficient"}
                          label={t("SoilLCoefficient")}
                          value={this.props.rasterArea.lSoilCoefficient}
                          min={-9.999}
                          max={9.999}
                          step={0.001}
                          maxLength={4}
                          unit={"-"}
                          onChange={(e) =>
                            this.props.setSoilLCoefficient(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.lSoilCoefficient <
                                Number(e.target.min) ||
                              this.props.rasterArea.lSoilCoefficient >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilLCoefficient(-2.342);
                            }
                          }}
                          help={soilHelpUrl + "l_vangenuchten"}
                        />

                        <NumericInput
                          name={"SoilNCoefficient"}
                          label={t("SoilNCoefficient")}
                          value={this.props.rasterArea.nSoilCoefficient}
                          min={0}
                          max={9.99}
                          step={0.01}
                          maxLength={4}
                          unit={"-"}
                          onChange={(e) =>
                            this.props.setSoilNCoefficient(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.nSoilCoefficient <
                                Number(e.target.min) ||
                              this.props.rasterArea.nSoilCoefficient >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilNCoefficient(1.28);
                            }
                          }}
                          help={soilHelpUrl + "n_vangenuchten"}
                        />

                        <NumericInput
                          name={"SoilHydraulicConductivity"}
                          label={t("SoilHydraulicConductivity")}
                          value={
                            this.props.rasterArea.soilHydraulicConductivity
                          }
                          min={0}
                          max={0.99999999}
                          step={0.00000001}
                          maxLength={9}
                          unit={"m/s"}
                          onChange={(e) =>
                            this.props.setSoilHydraulicConductivity(
                              e.target.value
                            )
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.soilHydraulicConductivity <
                                Number(e.target.min) ||
                              this.props.rasterArea.soilHydraulicConductivity >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilHydraulicConductivity(
                                0.00000116
                              );
                            }
                          }}
                          help={soilHelpUrl + "hydraulic_conductivity"}
                        />

                        <NumericInput
                          name={"SoilMoistureSaturation"}
                          label={t("SoilMoistureSaturation")}
                          value={this.props.rasterArea.soilMoistureSaturation}
                          min={0}
                          max={0.999}
                          step={0.001}
                          maxLength={4}
                          unit={"m³/m³"}
                          onChange={(e) =>
                            this.props.setSoilMoistureSaturation(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.soilMoistureSaturation <
                                Number(e.target.min) ||
                              this.props.rasterArea.soilMoistureSaturation >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilMoistureSaturation(0.439);
                            }
                          }}
                          help={soilHelpUrl + "alpha_vangenuchten"}
                        />

                        <NumericInput
                          name={"SoilMoistureCapacity"}
                          label={t("SoilMoistureCapacity")}
                          value={this.props.rasterArea.soilMoistureCapacity}
                          min={0}
                          max={0.999}
                          step={0.001}
                          maxLength={4}
                          unit={"m³/m³"}
                          onChange={(e) =>
                            this.props.setSoilMoistureCapacity(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.soilMoistureCapacity <
                                Number(e.target.min) ||
                              this.props.rasterArea.soilMoistureCapacity >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilMoistureCapacity(0.347);
                            }
                          }}
                          help={soilHelpUrl + "alpha_vangenuchten"}
                        />

                        <NumericInput
                          name={"SoilMoistureWilting"}
                          label={t("SoilMoistureWilting")}
                          value={this.props.rasterArea.soilMoistureWilting}
                          min={0}
                          max={0.999}
                          step={0.001}
                          maxLength={4}
                          unit={"m³/m³"}
                          onChange={(e) =>
                            this.props.setSoilMoistureWilting(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.soilMoistureWilting <
                                Number(e.target.min) ||
                              this.props.rasterArea.soilMoistureWilting >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilMoistureWilting(0.151);
                            }
                          }}
                          help={soilHelpUrl + "alpha_vangenuchten"}
                        />

                        <NumericInput
                          name={"SoilMoistureContent"}
                          label={t("SoilMoistureContent")}
                          value={this.props.rasterArea.soilMoistureContent}
                          min={0}
                          max={0.999}
                          step={0.001}
                          maxLength={4}
                          unit={"m³/m³"}
                          onChange={(e) =>
                            this.props.setSoilMoistureContent(e.target.value)
                          }
                          onBlur={(e) => {
                            if (
                              e.target.value === "" ||
                              this.props.rasterArea.soilMoistureContent <
                                Number(e.target.min) ||
                              this.props.rasterArea.soilMoistureContent >
                                Number(e.target.max)
                            ) {
                              this.props.setSoilMoistureContent(0.01);
                            }
                          }}
                          help={soilHelpUrl + "alpha_vangenuchten"}
                        />
                      </>
                    )}

                    <Select
                      label={t("SelectSeasonForLAI")}
                      value={this.props.rasterArea.season}
                      options={season.map((x) => {
                        return { label: t(`${x}`), value: x };
                      })}
                      onChange={(e) => this.props.setSeason(e.target.value)}
                      help={helpUrl}
                    />
                    <Select
                      label={t("GlobalWaterTemperatureSource")}
                      value={this.props.rasterArea.globalWaterTemperatureSource}
                      options={globalWaterTemperatureSourceOptions.map((x) => {
                        return { label: t(`${x}`), value: x };
                      })}
                      onChange={(e) =>
                        this.props.setGlobalWaterTemperatureSource(
                          e.target.value
                        )
                      }
                      help={helpUrl}
                    />
                    {this.props.rasterArea.globalWaterTemperatureSource !==
                      "FromCityModel" && (
                      <NumericInput
                        name={"GlobalSettingsWaterTemperature"}
                        label={t("GlobalWaterTemperature")}
                        value={this.props.rasterArea.globalWaterTemperature}
                        help={helpUrl}
                        maxLength={"3"}
                        min={-50}
                        max={100}
                        step={0.01}
                        unit={"°C"}
                        onChange={(e) =>
                          this.props.setGlobalWaterTemperature(e.target.value)
                        }
                        onBlur={(e) => {
                          if (
                            this.props.rasterArea.globalWaterTemperature <
                              parseFloat(e.target.min) ||
                            this.props.rasterArea.globalWaterTemperature >
                              parseFloat(e.target.max) ||
                            this.props.rasterArea.globalWaterTemperature === ""
                          ) {
                            this.props.setGlobalWaterTemperature(9.85);
                          }
                        }}
                      />
                    )}

                    <Select
                      label={t("VerticalResolutionMode")}
                      value={this.props.rasterArea.verticalResolutionMode}
                      options={[
                        { label: t("AsHorizontal"), value: "AsHorizontal" },
                        { label: t("Manual"), value: "Manual" },
                      ]}
                      onChange={(e) =>
                        this.props.setVerticalResolutionMode(e.target.value)
                      }
                      help={helpUrl}
                      disabled={
                        this.props.applicationField !== 0 &&
                        this.props.applicationField !== 1 &&
                        this.props.applicationField !== 4
                      }
                    />
                    {this.props.rasterArea.verticalResolutionMode ===
                      "Manual" && (
                      <NumericInput
                        name={"VerticalResolutionMeter"}
                        label={t("VerticalResolutionMeter")}
                        value={this.props.rasterArea.verticalResolutionMeter}
                        defaultValue={this.props.rasterArea.rasterGridWidth}
                        maxLength={"3"}
                        min={0.5}
                        max={20}
                        step={0.25}
                        unit={"m"}
                        help={helpUrl}
                        onChange={(e) =>
                          this.props.setVerticalResolutionMeter(e.target.value)
                        }
                        onBlur={(e) => {
                          if (
                            this.props.rasterArea.verticalResolutionMeter <
                              parseFloat(e.target.min) ||
                            this.props.rasterArea.verticalResolutionMeter >
                              parseFloat(e.target.max) ||
                            this.props.rasterArea.verticalResolutionMeter === ""
                          ) {
                            this.props.setVerticalResolutionMeter(9.85);
                          }
                        }}
                      />
                    )}
                    <Select
                      label={t("NormalizeTopography")}
                      value={this.props.rasterArea.normalizeTopography}
                      options={[
                        { label: t("true"), value: "true" },
                        { label: t("false"), value: "false" },
                      ]}
                      onChange={(e) =>
                        this.props.setNormalizeTopography(e.target.value)
                      }
                      //disabled
                      //help={helpUrl}
                    />
                  </>
                ) : y === "RasterSettings" ? (
                  <>
                    <Select
                      label={t("RasterMethod")}
                      value={this.props.rasterArea.rasterMethod}
                      options={rasterMethod.map((x) => {
                        return { label: t(`${x}`), value: x };
                      })}
                      onChange={(e) =>
                        this.props.setRasterMethod(e.target.value)
                      }
                      help={helpUrl}
                    />
                    <div
                      className={"form-group"}
                      style={{
                        height: "auto",
                        marginTop: "3px",
                        marginBottom: "3px",
                      }}
                    >
                      <label className={`form-label`}>{`${t(
                        "RasterPriority"
                      )}`}</label>
                      <div
                        className={"form-select"}
                        style={{ height: "68px", maxHeight: "120px" }}
                      >
                        <RasterPriority />
                      </div>
                      <a
                        className="help-icon-link"
                        href={helpUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <HelpCircle
                          color="rgba(0, 0, 0, 0.75)"
                          strokeWidth="2"
                          size="26px"
                          style={{
                            alignSelf: "center",
                            paddingLeft: "4px",
                          }}
                        />
                      </a>
                    </div>
                    <Select
                      label={t("FillValue")}
                      value={this.props.rasterArea.fillValue.rasterValue}
                      options={fillValue.map((x) => {
                        return { label: t(`${x.value}`), value: x.rasterValue };
                      })}
                      onChange={(e) => this.props.setFillValue(e.target.value)}
                      help={helpUrl}
                    />
                    <Select
                      label={t("FillingBelowTreePatches")}
                      value={
                        this.props.rasterArea.fillValueBelowTreePatches
                          .rasterValue
                      }
                      options={fillValue.map((x) => {
                        return { label: t(`${x.value}`), value: x.rasterValue };
                      })}
                      onChange={(e) =>
                        this.props.setFillValueBelowTreePatches(e.target.value)
                      }
                      help={helpUrl}
                    />
                  </>
                ) : y === "HeightSettings" ? (
                  <>
                    <Select
                      isSearchable={false}
                      label={t("BuildingHeights")}
                      value={this.props.rasterArea.buildingHeights}
                      options={[
                        {
                          label: t(`fromCityModel`),
                          value: "fromCityModel",
                        },
                        {
                          label: t(`fromGeotiff`),
                          value: "fromGeotiff",
                        },
                      ]}
                      disabled={!heightsFilesOptions}
                      onChange={(e) =>
                        this.props.setBuildingHeights(e.target.value)
                      }
                      help={helpUrl}
                    />

                    {this.props.rasterArea.buildingHeights ===
                      "fromGeotiff" && (
                      <Select
                        isSearchable={false}
                        label={t("BuildingHeightsFile")}
                        defaultValue={heightsFilesOptions[0]}
                        value={this.props.rasterArea.buildingHeightsFile}
                        options={heightsFilesOptions.map((x) => {
                          return { label: t(`${x.name}`), value: x.path };
                        })}
                        onChange={(e) =>
                          this.props.setBuildingHeightsFile(e.target.value)
                        }
                        help={helpUrl}
                      />
                    )}

                    {this.props.rasterArea.domainType === "NestedDomain" && (
                      <Select
                        label={t("BuildingHeightsChild")}
                        value={this.props.rasterArea.childBuildingHeights}
                        options={[
                          {
                            label: t(`fromCityModel`),
                            value: "fromCityModel",
                          },
                          {
                            label: t(`fromGeotiff`),
                            value: "fromGeotiff",
                          },
                        ]}
                        disabled={!heightsFilesOptions}
                        onChange={(e) =>
                          this.props.setChildBuildingHeights(e.target.value)
                        }
                        help={helpUrl}
                      />
                    )}

                    {this.props.rasterArea.childBuildingHeights ===
                      "fromGeotiff" && (
                      <Select
                        isSearchable={false}
                        label={t("BuildingHeightsChildFile")}
                        defaultValue={heightsFilesOptions[0]}
                        value={this.props.rasterArea.childBuildingHeightsFile}
                        options={heightsFilesOptions.map((x) => {
                          return { label: t(`${x.name}`), value: x.path };
                        })}
                        onChange={(e) =>
                          this.props.setChildBuildingHeightsFile(e.target.value)
                        }
                        help={helpUrl}
                      />
                    )}

                    <Select
                      label={t("TreeHeights")}
                      value={this.props.rasterArea.treeHeights}
                      options={[
                        {
                          label: t(`fromCityModel`),
                          value: "fromCityModel",
                        },
                        {
                          label: t(`fromGeotiff`),
                          value: "fromGeotiff",
                        },
                      ]}
                      disabled={!heightsFilesOptions}
                      onChange={(e) =>
                        this.props.setTreeHeights(e.target.value)
                      }
                      help={helpUrl}
                    />

                    {this.props.rasterArea.treeHeights === "fromGeotiff" && (
                      <Select
                        isSearchable={false}
                        label={t("TreeHeightsFile")}
                        defaultValue={heightsFilesOptions[0]}
                        value={this.props.rasterArea.treeHeightsFile}
                        options={heightsFilesOptions.map((x) => {
                          return { label: t(`${x.name}`), value: x.path };
                        })}
                        onChange={(e) =>
                          this.props.setTreeHeightsFile(e.target.value)
                        }
                        help={helpUrl}
                      />
                    )}
                    {this.props.rasterArea.domainType === "NestedDomain" && (
                      <Select
                        label={t("TreeHeightsChild")}
                        value={this.props.rasterArea.childTreeHeights}
                        options={[
                          {
                            label: t(`fromCityModel`),
                            value: "fromCityModel",
                          },
                          {
                            label: t(`fromGeotiff`),
                            value: "fromGeotiff",
                          },
                        ]}
                        disabled={!heightsFilesOptions}
                        onChange={(e) =>
                          this.props.setChildTreeHeights(e.target.value)
                        }
                        help={helpUrl}
                      />
                    )}

                    {this.props.rasterArea.childTreeHeights ===
                      "fromGeotiff" && (
                      <Select
                        isSearchable={false}
                        label={t("TreeHeightsChildFile")}
                        defaultValue={heightsFilesOptions[0]}
                        value={this.props.rasterArea.childTreeHeightsFile}
                        options={heightsFilesOptions.map((x) => {
                          return { label: t(`${x.name}`), value: x.path };
                        })}
                        onChange={(e) =>
                          this.props.setChildTreeHeightsFile(e.target.value)
                        }
                        help={helpUrl}
                      />
                    )}
                  </>
                ) : y === "NestingSettings" &&
                  this.props.rasterArea.domainType === "NestedDomain" ? (
                  <Select
                    label={t("TopographyForChildDomain")}
                    value={this.props.rasterArea.topographyForChildDomain}
                    options={topoForChildDomain.map((x) => {
                      return { label: t(`${x.name}`), value: x.path };
                    })}
                    onChange={(e) =>
                      this.props.setTopographyForChildDomain(e.target.value)
                    }
                    help={helpUrl}
                  />
                ) : y === "DomainBuffer" ? (
                  <>
                    <NumericInput
                      name={"Grid Points Buffer North"}
                      label={t("GridPointsBufferNorth")}
                      value={this.props.rasterArea.gridPointsNorth}
                      min={0}
                      max={
                        this.props.rasterArea.domainType === "SingleDomain"
                          ? this.props.rasterArea.rasterGridYNumber / 2
                          : this.props.rasterArea.parentRasterGridYNumber / 2
                      }
                      step={1}
                      maxLength={3}
                      unit={"-"}
                      onChange={(e) =>
                        this.props.setBufferNorth({
                          gridPointsNorth: e.target.value,
                          crsDef: this.props.crsDef,
                        })
                      }
                      onBlur={(e) => {
                        if (
                          e.target.value === "" ||
                          this.props.rasterArea.gridPointsNorth <
                            Number(e.target.min) ||
                          this.props.rasterArea.gridPointsNorth >
                            Number(e.target.max)
                        ) {
                          this.props.setBufferNorth({
                            gridPointsNorth: 0,
                            crsDef: this.props.crsDef,
                          });
                        }
                      }}
                      help={helpUrl}
                      //disabled={isStaticDriverProcessing}
                    />
                    <NumericInput
                      name={"Grid Points Buffer South"}
                      label={t("GridPointsBufferSouth")}
                      value={this.props.rasterArea.gridPointsSouth}
                      min={0}
                      max={
                        this.props.rasterArea.domainType === "SingleDomain"
                          ? this.props.rasterArea.rasterGridYNumber / 2
                          : this.props.rasterArea.parentRasterGridYNumber / 2
                      }
                      step={1}
                      maxLength={3}
                      unit={"-"}
                      onChange={(e) =>
                        this.props.setBufferSouth({
                          gridPointsSouth: e.target.value,
                          crsDef: this.props.crsDef,
                        })
                      }
                      onBlur={(e) => {
                        if (
                          e.target.value === "" ||
                          this.props.rasterArea.gridPointsSouth <
                            Number(e.target.min) ||
                          this.props.rasterArea.gridPointsSouth >
                            Number(e.target.max)
                        ) {
                          this.props.setBufferSouth({
                            gridPointsSouth: 0,
                            crsDef: this.props.crsDef,
                          });
                        }
                      }}
                      help={helpUrl}
                    />
                    <NumericInput
                      name={"Grid Points Buffer West"}
                      label={t("GridPointsBufferWest")}
                      value={this.props.rasterArea.gridPointsWest}
                      min={0}
                      max={
                        this.props.rasterArea.domainType === "SingleDomain"
                          ? this.props.rasterArea.rasterGridXNumber / 2
                          : this.props.rasterArea.parentRasterGridXNumber / 2
                      }
                      step={1}
                      maxLength={3}
                      unit={"-"}
                      onChange={(e) =>
                        this.props.setBufferWest({
                          gridPointsWest: e.target.value,
                          crsDef: this.props.crsDef,
                        })
                      }
                      onBlur={(e) => {
                        if (
                          e.target.value === "" ||
                          this.props.rasterArea.gridPointsWest <
                            Number(e.target.min) ||
                          this.props.rasterArea.gridPointsWest >
                            Number(e.target.max)
                        ) {
                          this.props.setBufferWest({
                            gridPointsWest: 0,
                            crsDef: this.props.crsDef,
                          });
                        }
                      }}
                      help={helpUrl}
                    />
                    <NumericInput
                      name={"Grid Points Buffer East"}
                      label={t("GridPointsBufferEast")}
                      value={this.props.rasterArea.gridPointsEast}
                      min={0}
                      max={
                        this.props.rasterArea.domainType === "SingleDomain"
                          ? this.props.rasterArea.rasterGridXNumber / 2
                          : this.props.rasterArea.parentRasterGridXNumber / 2
                      }
                      step={1}
                      maxLength={3}
                      unit={"-"}
                      onChange={(e) =>
                        this.props.setBufferEast({
                          gridPointsEast: e.target.value,
                          crsDef: this.props.crsDef,
                        })
                      }
                      onBlur={(e) => {
                        if (
                          e.target.value === "" ||
                          this.props.rasterArea.gridPointsEast <
                            Number(e.target.min) ||
                          this.props.rasterArea.gridPointsEast >
                            Number(e.target.max)
                        ) {
                          this.props.setBufferEast({
                            gridPointsEast: 0,
                            crsDef: this.props.crsDef,
                          });
                        }
                      }}
                      help={helpUrl}
                    />
                    <Select
                      label={t("FillType")}
                      value={this.props.rasterArea.bufferFillType}
                      options={[
                        { label: t("pavement"), value: "pavement" },
                        { label: t("vegetation"), value: "vegetation" },
                        { label: t("water"), value: "water" },
                      ]}
                      onChange={(e) =>
                        this.props.setBufferFillType(e.target.value)
                      }
                      help={helpUrl}
                    />
                    <Select
                      label={t("FillValue")}
                      value={this.props.rasterArea.bufferFillValue?.rasterValue}
                      options={this.props.rasterArea.bufferFillValueOptions.map(
                        (x) => {
                          return {
                            label: t(`${x.value}`),
                            value: x.rasterValue,
                          };
                        }
                      )}
                      onChange={(e) =>
                        this.props.setBufferFillValue(e.target.value)
                      }
                      help={helpUrl}
                    />
                    <Select
                      label={t("BufferMethod")}
                      value={"Default"}
                      options={[{ label: t("Default"), value: "Default" }]}
                      onChange={(e) =>
                        this.props.setBufferMethod(e.target.value)
                      }
                      help={helpUrl}
                    />
                    <Select
                      label={t("BufferTopographyInterpolation")}
                      value={this.props.rasterArea.bufferTopographyInterpMethod}
                      options={[
                        { label: t("off"), value: "off" },
                        { label: t("bilinear"), value: "bilinear" },
                        { label: t("bicubic"), value: "bicubic" },
                      ]}
                      onChange={(e) =>
                        this.props.setBufferTopographyInterpMethod(
                          e.target.value
                        )
                      }
                      disabled
                      help={helpUrl}
                    />
                  </>
                ) : (
                  <></>
                )}
              </AccordionSection>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rasterArea: state.rasterArea,
    files: state.projects.loadedProject.files,
    crsDef: state.map.crsDef,
    applicationField: state.projects?.currentCase?.applicationField,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSoilType: (payload) => dispatch(actions.setSoilType(payload)),
    setSeason: (payload) => dispatch(actions.setSeason(payload)),
    setRasterMethod: (payload) => dispatch(actions.setRasterMethod(payload)),
    setFillValue: (payload) => dispatch(actions.setFillValue(payload)),
    setFillValueBelowTreePatches: (payload) =>
      dispatch(actions.setFillValueBelowTreePatches(payload)),
    setRasterizationPriority: (payload) =>
      dispatch(actions.setRasterizationPriority(payload)),
    setTopographyForChildDomain: (payload) =>
      dispatch(actions.setTopographyForChildDomain(payload)),
    setGlobalWaterTemperatureSource: (payload) =>
      dispatch(actions.setGlobalWaterTemperatureSource(payload)),
    setGlobalWaterTemperature: (payload) =>
      dispatch(actions.setGlobalWaterTemperature(payload)),
    setBufferNorth: (payload) => dispatch(actions.setBufferNorth(payload)),
    setBufferSouth: (payload) => dispatch(actions.setBufferSouth(payload)),
    setBufferWest: (payload) => dispatch(actions.setBufferWest(payload)),
    setBufferEast: (payload) => dispatch(actions.setBufferEast(payload)),
    setVerticalResolutionMode: (payload) =>
      dispatch(actions.setVerticalResolutionMode(payload)),
    setVerticalResolutionMeter: (payload) =>
      dispatch(actions.setVerticalResolutionMeter(payload)),
    setBuildingHeights: (payload) =>
      dispatch(actions.setBuildingHeights(payload)),
    setBuildingHeightsFile: (payload) =>
      dispatch(actions.setBuildingHeightsFile(payload)),
    setChildBuildingHeights: (payload) =>
      dispatch(actions.setChildBuildingHeights(payload)),
    setChildBuildingHeightsFile: (payload) =>
      dispatch(actions.setChildBuildingHeightsFile(payload)),
    setTreeHeights: (payload) => dispatch(actions.setTreeHeights(payload)),
    setTreeHeightsFile: (payload) =>
      dispatch(actions.setTreeHeightsFile(payload)),
    setChildTreeHeights: (payload) =>
      dispatch(actions.setChildTreeHeights(payload)),
    setChildTreeHeightsFile: (payload) =>
      dispatch(actions.setChildTreeHeightsFile(payload)),
    setBufferFillType: (payload) =>
      dispatch(actions.setBufferFillType(payload)),
    setBufferFillValue: (payload) =>
      dispatch(actions.setBufferFillValue(payload)),
    setBufferMethod: (payload) => dispatch(actions.setBufferMethod(payload)),
    setBufferTopographyInterpMethod: (payload) =>
      dispatch(actions.setBufferTopographyInterpMethod(payload)),

    setSoilAlphaCoefficient: (payload) =>
      dispatch(actions.setSoilAlphaCoefficient(payload)),
    setSoilLCoefficient: (payload) =>
      dispatch(actions.setSoilLCoefficient(payload)),
    setSoilNCoefficient: (payload) =>
      dispatch(actions.setSoilNCoefficient(payload)),
    setSoilHydraulicConductivity: (payload) =>
      dispatch(actions.setSoilHydraulicConductivity(payload)),
    setSoilMoistureSaturation: (payload) =>
      dispatch(actions.setSoilMoistureSaturation(payload)),
    setSoilMoistureCapacity: (payload) =>
      dispatch(actions.setSoilMoistureCapacity(payload)),
    setSoilMoistureWilting: (payload) =>
      dispatch(actions.setSoilMoistureWilting(payload)),
    setSoilMoistureContent: (payload) =>
      dispatch(actions.setSoilMoistureContent(payload)),

    setNormalizeTopography: (payload) =>
      dispatch(actions.setNormalizeTopography(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ExpertRasterizationSettings)));
