import React from "react";
import * as L from "leaflet";
import {
  Map as LeafletMap,
  TileLayer,
  LayersControl,
  ScaleControl,
  Popup,
  CircleMarker,
} from "react-leaflet";
import PoiAreaDrawing from "./PoiAreaDrawing/PoiAreaDrawing";
import StaticDriverGeotiff from "./StaticDriverGeotiff/StaticDriverGeotiff";
import CustomPrintArea from "./CustomPrintArea/CustomPrintArea";
import SavingStatusIndicator from "../../../Components/Map/MapPanel/SavingStatusIndicator";
import LeafletRuler from "../../../Components/Map/WindTurbine/LeafletRuler";
import AnimationRecord from "./AnimationRecord/AnimationRecord";
import { LayerOptions } from "../../../common/TileLayers";
import {
  getPoiCursorCoords,
  addPoi,
  togglePoiCursorDisplay,
  addAoiPointWg,
  drawAoi,
  setResultsGeotiffVisibility,
  setResultsMapRef,
} from "../../../redux/actions/resultsActions";
import "leaflet-geotiff-2";
import "leaflet.browser.print/dist/leaflet.browser.print";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const { BaseLayer } = LayersControl;

const InfoData = (props) => {
  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  return <CircleMarker ref={initMarker} {...props} />;
};

class StaticDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refs: [],
      currentPos: null,
      value: null,
      layerName: null,
    };

    this.valuePopup = React.createRef();
    this.arrayOfGeotiffRefs = React.createRef([]);
    this.staticDriverMapRef = React.createRef();
    this.canvasRef = React.createRef();

    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.pointToLayer = this.pointToLayer.bind(this);
  }

  componentDidMount() {
    L.control
      .browserPrint({
        title: "PrintMap",
      })
      .addTo(this.staticDriverMapRef.current.leafletElement);

    L.BrowserPrint.Utils.registerLayer(
      L.LeafletGeotiff,
      "L.LeafletGeotiff",
      function (layer, utils) {
        // We need to clone options to properly handle multiple renderers.
        return new L.leafletGeotiff(layer._url, layer.options);
      }
    );

    this.staticDriverMapRef.current.leafletElement.on(
      "browser-print-end",
      function (e) {
        const arrayBool = that.props.resultsJsonOrigin.pages[
          that.props.currentViewIndex
        ].lyrs.map((x) => {
          return x.checked;
        });

        for (let index = 0; index < arrayBool.length; index++) {
          if (arrayBool[index]) {
            that.props.setResultsGeotiffVisibility({
              resultsGeotiffName:
                that.props.resultsJsonOrigin.pages[that.props.currentViewIndex]
                  .lyrs[index].name,
              currentLayerIndex: index,
            });
            // that.props.setResultsGeotiffVisibility({
            //   resultsGeotiffName:
            //     that.props.resultsJsonOrigin.pages[that.props.currentViewIndex]
            //       .lyrs[index].name,
            //   currentLayerIndex: index,
            // });
          }
        }
      }
    );
    const that = this;

    const saveAsImage = () => {
      var node = document.getElementById("-print");
      html2canvas(node, { allowTaint: true, useCORS: true }).then(function (
        canvas
      ) {
        canvas.toBlob(function (blob) {
          let blobUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = blobUrl;
          link.download = "aDefaultFileName.png";
          link.click();
        });
      });

      that.staticDriverMapRef.current.leafletElement.on(
        "browser-print-end",
        function (e) {
          const arrayBool = that.props.resultsJsonOrigin.pages[
            that.props.currentViewIndex
          ].lyrs.map((x) => {
            return x.checked;
          });

          for (let index = 0; index < arrayBool.length; index++) {
            if (arrayBool[index]) {
              that.props.setResultsGeotiffVisibility({
                resultsGeotiffName:
                  that.props.resultsJsonOrigin.pages[
                    that.props.currentViewIndex
                  ].lyrs[index].name,
                currentLayerIndex: index,
              });
              // that.props.setResultsGeotiffVisibility({
              //   resultsGeotiffName:
              //     that.props.resultsJsonOrigin.pages[
              //       that.props.currentViewIndex
              //     ].lyrs[index].name,
              //   currentLayerIndex: index,
              // });
            }
          }
        }
      );
    };

    L.control
      .browserPrint({
        documentTitle: "SaveImage",
        title: "SaveImage",
        printFunction: saveAsImage,
      })
      .addTo(this.staticDriverMapRef.current.leafletElement);

    this.props.setResultsMapRef(this.staticDriverMapRef.current);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.state.currentPos &&
        this.props.currentViewIndex !== prevProps.currentViewIndex) ||
      (this.props.resultsJsonOrigin &&
        this.props.resultsJsonOrigin !== prevProps.resultsJsonOrigin)
    ) {
      this.setState({ currentPos: null });
    }

    if (
      prevProps.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length <
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length
    ) {
      // this.props.setResultsGeotiffVisibility({
      //   resultsGeotiffName:
      //     this.props.resultsJsonOrigin.pages[this.props.currentViewIndex]
      //       .lyrs[0].name,
      //   currentLayerIndex: 0,
      //});
    }
  }

  handleRightClick() {
    if (this.props.inPoi) {
      this.props.togglePoiCursorDisplay();
    } else if (this.props.inAoi) {
      this.props.drawAoi();
    }
  }

  onMapClick = (e) => {
    if (this.props.inPoi && this.props.displayPoiOnMouseMove) {
      this.props.addPoi({
        lat: e.latlng.lat.toFixed(6),
        lng: e.latlng.lng.toFixed(6),
      });
    } else if (this.props.inAoi) {
      this.props.addAoiPointWg({
        lng: Number(e.latlng.lng.toFixed(6)),
        lat: Number(e.latlng.lat.toFixed(6)),
      });
    } else {
      const latLng = e.latlng;
      const frontCheckedGeotiff = this.props.resultsJsonOrigin.pages[
        this.props.currentViewIndex
      ].lyrs.findLast((x) => x.checked);

      if (latLng && frontCheckedGeotiff) {
        const frontCheckedGeotiffName = frontCheckedGeotiff.name;
        const frontCheckedGeotiffRef = this.state.refs.find(
          (x) => x.props.name === frontCheckedGeotiffName
        );

        if (frontCheckedGeotiffRef) {
          const selectedPointValue =
            frontCheckedGeotiffRef.leafletElement.getValueAtLatLng(
              e.latlng.lat,
              e.latlng.lng
            );
          const unit = frontCheckedGeotiffRef.props.unit;
          let digPrec = 1;
          if (unit === "[m/s]") {
            digPrec = 2;
          }
          if (selectedPointValue !== null && selectedPointValue !== undefined) {
            const value =
              ` :` +
              "\xa0\xa0" +
              `${selectedPointValue.toFixed(digPrec)} ${unit}`;

            if (!isNaN(selectedPointValue)) {
              this.setState({
                value: value,
                currentPos: latLng,
                layerName: frontCheckedGeotiffName,
              });
            }
          }
        }
      }
    }
  };

  handleMouseMove = (e) => {
    this.props.getPoiCursorCoords([
      e.latlng.lat.toFixed(6),
      e.latlng.lng.toFixed(6),
    ]);
  };

  pointToLayer(feature, latlng) {
    //return L.marker(latlng, { icon: treeMarker });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <LeafletMap
          ref={this.staticDriverMapRef}
          center={this.props.center}
          zoom={this.props.zoom}
          maxZoom={19}
          className={"leaflet-map"}
          onClick={this.onMapClick}
          onMouseMove={
            (this.props.inPoi && this.props.displayPoiOnMouseMove) ||
            this.props.inAoi
              ? this.handleMouseMove
              : null
          }
          onContextMenu={this.handleRightClick}
          preferCanvas
        >
          {this.state.currentPos && (
            <InfoData center={this.state.currentPos} radius={0} opacity={0}>
              <Popup>
                <div className="popup-container">
                  {t(this.state.layerName) + this.state.value}
                </div>
              </Popup>
            </InfoData>
          )}
          <ScaleControl position="bottomleft" imperial={false} />
          <LayersControl position="bottomleft">
            {LayerOptions.map((x) => {
              return (
                <BaseLayer key={x.name} name={t(x.name)} checked={x.checked}>
                  <TileLayer attribution={x.attribution} url={x.url} />
                </BaseLayer>
              );
            })}
          </LayersControl>
          <PoiAreaDrawing />
          <StaticDriverGeotiff
            staticDriverMapRef={this.staticDriverMapRef}
            onResultsReady={(ref) => {
              if (
                ref !== null &&
                ref !== undefined &&
                !this.state.refs.some((x) => x.props.name === ref.props.name)
              ) {
                this.setState({
                  refs: [...this.state.refs, ref],
                });
              }
            }}
          />
          {this.props.inExportSettings && <CustomPrintArea />}

          <LeafletRuler style={{ top: "194px" }} />
          <SavingStatusIndicator
            style={{
              top: "214px",
              position: "fixed",
              zIndex: "inherit",
              left: "194px",
            }}
          />
        </LeafletMap>
        {this.props.inRecordMode && (
          <AnimationRecord
            isOpen={this.props.inRecordMode}
            canvasRef={this.canvasRef}
            staticDriverMapRef={this.staticDriverMapRef}
          />
        )}
        <canvas ref={this.canvasRef} style={{ display: "none" }} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inPoi: state.results.inPoi,
    inAoi: state.results.inAoi,
    displayPoiOnMouseMove: state.results.displayPoiOnMouseMove,
    currentViewIndex: state.results.currentViewIndex,
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    inExportSettings: state.results.inExportSettings,
    currentLayerIndex: state.results.currentLayerIndex,
    inRecordMode: state.results.inRecordMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPoiCursorCoords: (payload) => dispatch(getPoiCursorCoords(payload)),
    addPoi: (payload) => dispatch(addPoi(payload)),
    addAoiPointWg: (payload) => dispatch(addAoiPointWg(payload)),
    togglePoiCursorDisplay: (payload) =>
      dispatch(togglePoiCursorDisplay(payload)),
    drawAoi: (payload) => dispatch(drawAoi(payload)),
    setResultsGeotiffVisibility: (payload) =>
      dispatch(setResultsGeotiffVisibility(payload)),
    setResultsMapRef: (payload) => dispatch(setResultsMapRef(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(StaticDriver)
);
