import React from "react";
import L from "leaflet";
import { Marker, Polygon, Polyline, Pane } from "react-leaflet";
import vertexAoiMarkerUrl from "./vertex-aoi.svg";
import {
  drawAoi,
  dragAoiPointWg,
  dragendAoiPointWg,
  selectAoi,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";

class AreaDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {!this.props.inAoiSelectMode &&
          this.props.aoiPolygonPointsWg.length > 0 &&
          !this.props.aoiPolygonDrawn && (
            <Pane zIndex={1}>
              <Polyline
                positions={[
                  ...this.props.aoiPolygonPointsWg,
                  this.props.poiCursorCoords,
                ]}
                color={"#c21aa8ff"}
              />
            </Pane>
          )}
        {this.props.aoiPolygonPointsWg.map((x, idx) => (
          <Pane zIndex={100}>
            <Marker
              position={[x[0], x[1]]}
              icon={vertexAoiMarker}
              onClick={
                idx === 0 && this.props.canDrawAoiPolygon
                  ? () => this.props.drawAoi()
                  : null
              }
              draggable={this.props.inAoiSelectMode ? true : false}
              ondrag={() =>
                this.props.dragAoiPointWg({
                  pointCoords: this.props.poiCursorCoords,
                  indexOfPoint: idx,
                })
              }
              onDragend={(e) =>
                this.props.dragendAoiPointWg({
                  pointCoords: e.target._latlng,
                  idx: idx,
                })
              }
            />
          </Pane>
        ))}
        {!this.props.inAoiSelectMode &&
          this.props.poiCursorCoords !== null &&
          !this.props.aoiPolygonDrawn && (
            <Pane zIndex={200}>
              <Marker
                position={this.props.poiCursorCoords}
                icon={vertexAoiMarker}
              />
            </Pane>
          )}
        {this.props.allDrawnAoiPolygonsPointsWg.length !== 0 && (
          <>
            {this.props.allDrawnAoiPolygonsPointsWg.map((x, idx) => (
              <Polygon
                zIndex={200000000}
                className="raster-area"
                positions={x}
                onclick={() => this.props.selectAoi(idx)}
                color={"#c21aa8ff"}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inAoi: state.results.inAoi,
    inAoiSelectMode: state.results.inAoiSelectMode,
    aoiPolygonDrawn: state.results.aoiPolygonDrawn,
    aoiPolygonPointsWg: state.results.aoiPolygonPointsWg,
    poiCursorCoords: state.results.poiCursorCoords,
    canDrawAoiPolygon: state.results.canDrawAoiPolygon,
    allDrawnAoiPolygonsPointsWg: state.results.allDrawnAoiPolygonsPointsWg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    drawAoi: () => dispatch(drawAoi()),
    dragAoiPointWg: (payload) => dispatch(dragAoiPointWg(payload)),
    dragendAoiPointWg: (payload) => dispatch(dragendAoiPointWg(payload)),
    selectAoi: (payload) => dispatch(selectAoi(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaDrawing);

export const vertexAoiMarker = new L.Icon({
  iconUrl: vertexAoiMarkerUrl,
  iconAnchor: null,
  popupAnchor: [0, -20],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(15, 15),
  className: "tree-style",
});
