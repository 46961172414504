import React from "react";
import L from "leaflet";
import { Marker, Pane } from "react-leaflet";
import poiMarkerUrl from "../../StaticDriverMapPanel/pin-location-map.svg";
import selectedPoiMarkerUrl from "../../StaticDriverMapPanel/selected-pin-location-map.svg";
import {
  selectPoi,
  updatePoiPositionOnDragend,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";

class PoiDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onPoiClick = this.onPoiClick.bind(this);
  }

  onPoiClick = (i) => {
    if (!this.props.displayPoiOnMouseMove) {
      this.props.selectPoi(i);
    }
  };

  render() {
    return (
      <>
        <Pane className="raster-area">
          {this.props.inPoi &&
            this.props.displayPoiOnMouseMove &&
            this.props.poiCursorCoords !== null && (
              <Marker position={this.props.poiCursorCoords} icon={poiMarker} />
            )}
          {this.props.allDrawnPoiWg.length !== 0 &&
            this.props.allDrawnPoiWg.map((x, i) => {
              return (
                <Marker
                  position={[x.lat, x.lng]}
                  icon={
                    i === this.props.selectedPoiIndex && this.props.inPoi
                      ? selectedPoiMarker
                      : poiMarker
                  }
                  zIndexOffset={
                    this.props.selectedPoiIndex === i ? 10000 : 1000
                  }
                  markerIndex={i}
                  onClick={() => this.onPoiClick(i)}
                  draggable={
                    this.props.inPoi && !this.props.displayPoiOnMouseMove
                  }
                  onDragend={(e) =>
                    this.props.updatePoiPositionOnDragend({
                      e: e.target._latlng,
                      idx: i,
                    })
                  }
                />
              );
            })}
        </Pane>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inPoi: state.results.inPoi,
    poiCursorCoords: state.results.poiCursorCoords,
    selectedPoiIndex: state.results.selectedPoiIndex,
    allDrawnPoiWg: state.results.allDrawnPoiWg,
    displayPoiOnMouseMove: state.results.displayPoiOnMouseMove,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePoiPositionOnDragend: (payload) =>
      dispatch(updatePoiPositionOnDragend(payload)),
    selectPoi: (payload) => dispatch(selectPoi(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PoiDrawing);

const poiMarker = new L.Icon({
  iconUrl: poiMarkerUrl,
  iconAnchor: [40, 60],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(80, 80),
  className: "pin-location-map",
});

export { poiMarker };

const selectedPoiMarker = new L.Icon({
  iconUrl: selectedPoiMarkerUrl,
  iconAnchor: [40, 60],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(80, 80),
  className: "pin-location-map-highlighted",
});

export { selectedPoiMarker };
