import React from "react";
import L from "leaflet";
import { Marker, Pane } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import treeMarkerUrl from "./tree-map-icon.svg";
import highlightedTreeMarkerUrl from "./tree-map-highlighted-icon.svg";
import treeDrawnMarkerUrl from "./drawn-tree-icon.svg";
import treeDrawnHighlightedMarkerUrl from "./drawn-tree-highlighted-icon.svg";

import {
  addTree,
  selectTree,
  updateTreePositionOnDragend,
  addAssignedSingleTreeCopy,
  enableTreeDraggable,
} from "../../../redux/actions/mapActions";
import { connect } from "react-redux";

class TreeDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createClusterCustomIcon = this.createClusterCustomIcon.bind(this);
  }

  componentDidMount() {
    if (
      this.props.inTreeDrawingMode &&
      this.props.mapRef.current.leafletElement.getZoom() === 19
    ) {
      this.props.enableTreeDraggable();
    }
  }

  createClusterCustomIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-drawn-tree span",
      iconSize: L.point(40, 40, true),
    });
  }
  render() {
    return (
      <Pane className="tree-area">
        {this.props.allDrawnTreesVisibility &&
          (this.props.allDrawnTreesCoordinatesWg.length > 0 &&
          this.props.isGeoJsonVisible &&
          this.props.isTreeDraggable ? (
            this.props.allDrawnTreesCoordinatesWg.map((x, idx) => (
              <Marker
                position={[x[0], x[1]]}
                icon={
                  this.props.selectedTreeIndex === idx
                    ? treeDrawnHighlightedMarker
                    : treeDrawnMarker
                }
                zIndexOffset={
                  this.props.selectedTreeIndex === idx ? 10000 : 1000
                }
                objectTypeIndex={6}
                markerIndex={idx}
                onClick={
                  this.props.inRasterSettingsMode ||
                  this.props.displayTreeCursor
                    ? null
                    : !this.props.inSingleTreeCopyPropsMode
                    ? () => this.props.selectTree(idx)
                    : () =>
                        this.props.addAssignedSingleTreeCopy({
                          properties: {
                            id: this.props.arrayOfTreesId[idx],
                          },
                          geometry: {
                            coordinates:
                              this.props.allDrawnTreesCoordinatesWg[idx],
                          },
                        })
                }
                draggable={
                  this.props.inTreeDrawingMode && !this.props.displayTreeCursor
                }
                onDragend={(e) =>
                  this.props.updateTreePositionOnDragend({
                    e: e.target._latlng,
                    idx: idx,
                  })
                }
              />
            ))
          ) : (
            <MarkerClusterGroup
              iconCreateFunction={this.createClusterCustomIcon}
              disableClusteringAtZoom={19}
              showCoverageOnHover
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
            >
              {this.props.allDrawnTreesCoordinatesWg.map((x, idx) => (
                <Marker
                  position={[x[0], x[1]]}
                  icon={
                    this.props.selectedTreeIndex === idx
                      ? treeDrawnHighlightedMarker
                      : treeDrawnMarker
                  }
                  zIndexOffset={
                    this.props.selectedTreeIndex === idx ? 10000 : 1000
                  }
                  objectTypeIndex={6}
                  markerIndex={idx}
                  onClick={
                    this.props.inRasterSettingsMode ||
                    this.props.displayTreeCursor
                      ? null
                      : !this.props.inSingleTreeCopyPropsMode
                      ? () => this.props.selectTree(idx)
                      : () =>
                          this.props.addAssignedSingleTreeCopy({
                            properties: {
                              id: this.props.arrayOfTreesId[idx],
                            },
                            geometry: {
                              coordinates:
                                this.props.allDrawnTreesCoordinatesWg[idx],
                            },
                          })
                  }
                  draggable={false}
                  onDragend={(e) =>
                    this.props.updateTreePositionOnDragend({
                      e: e.target._latlng,
                      idx: idx,
                    })
                  }
                />
              ))}
            </MarkerClusterGroup>
          ))}
        {this.props.inTreeDrawingMode &&
          this.props.currentCursorCoords &&
          this.props.displayTreeCursor && (
            <Marker
              icon={treeDrawnMarker}
              position={this.props.currentCursorCoords}
              zIndex={1}
              zIndexOffset={1}
            />
          )}
      </Pane>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCursorCoords: state.map.currentCursorCoords,
    allDrawnTreesVisibility: state.legend.allDrawnTreesVisibility,
    allDrawnTreesCoordinatesWg: state.map.allDrawnTreesCoordinatesWg,
    selectedTreeIndex: state.map.selectedTreeIndex,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    inTreeDrawingMode: state.map.inTreeDrawingMode,
    inSingleTreeCopyPropsMode: state.map.inSingleTreeCopyPropsMode,
    arrayOfTreesId: state.map.arrayOfTreesId,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    displayTreeCursor: state.map.displayTreeCursor,
    isTreeDraggable: state.map.isTreeDraggable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTree: (payload) => dispatch(addTree(payload)),
    selectTree: (payload) => dispatch(selectTree(payload)),
    updateTreePositionOnDragend: (payload) =>
      dispatch(updateTreePositionOnDragend(payload)),
    addAssignedSingleTreeCopy: (payload) =>
      dispatch(addAssignedSingleTreeCopy(payload)),
    enableTreeDraggable: () => dispatch(enableTreeDraggable()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeDrawing);

const treeMarker = new L.Icon({
  iconUrl: treeMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedTreeMarker = new L.Icon({
  iconUrl: highlightedTreeMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const treeDrawnMarker = new L.Icon({
  iconUrl: treeDrawnMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const treeDrawnHighlightedMarker = new L.Icon({
  iconUrl: treeDrawnHighlightedMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

export { treeMarker, highlightedTreeMarker };
