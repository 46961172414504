import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { X, Video, Save, AlertCircle, Info } from "react-feather";
import { Loader } from "../../../../Components/Loader/index";
import { toggleRecordMode } from "../../../../redux/actions/resultsActions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./AnimationRecord.scss";

const AnimationRecord = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const animRef = useRef(null); // Create a ref for the modal-wrapper div
  const [value, setValue] = useState(5); // Initial slider value
  const min = 5; // Minimum value
  const max = 20; // Maximum value
  const [hasAnimation, checkAnimation] = useState(true);
  const [isRecording, setRecording] = useState(false);
  const [isFinished, setFinished] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);

  const recordVideo = async () => {
    const animationCanvas = document.querySelector(".wind-animation");
    const isFirefox = /firefox/i.test(navigator.userAgent);
    if (!animationCanvas) {
      //alert("There is no animated element in window");
      checkAnimation(false);
      return;
    }
    setRecording(true);
    setFinished(false);
    checkAnimation(true);

    const prevVideo = document.querySelector(".animation-video"); // Replace with your actual class name

    if (prevVideo) {
      prevVideo.remove();
    }

    const canvas = props.canvasRef.current;
    const ctx = canvas.getContext("2d");
    const leafletMap = props.staticDriverMapRef.current.leafletElement;
    const mapContainer = leafletMap.getContainer();

    canvas.width = mapContainer.clientWidth;
    canvas.height = mapContainer.clientHeight;

    await new Promise((resolve) => setTimeout(resolve, 700));

    const tiles = mapContainer.querySelectorAll(
      ".leaflet-tile-loaded, .leaflet-image-layer, .wind-direction"
    );

    if (tiles.length === 0) {
      console.log("No tiles to draw.");
    }
    const duration = Number(value) * 1000;
    const startTime = performance.now();
    function animate(timestamp) {
      const elapsed = timestamp - startTime;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (!isFirefox) {
        tiles.forEach((tile) => {
          const { x, y } = tile.getBoundingClientRect();
          const mapRect = mapContainer.getBoundingClientRect();

          const offsetX = x - mapRect.left;
          const offsetY = y - mapRect.top;
          tile.crossOrigin = "anonymous";

          ctx.drawImage(tile, offsetX, offsetY);
        });
      }
      ctx.drawImage(animationCanvas, 0, 0);

      if (elapsed < duration) {
        requestAnimationFrame(animate);
      }
    }
    requestAnimationFrame(animate);

    const options = {
      mimeType: "video/webm; codecs=vp9",
      videoBitsPerSecond: Number(value) * 1000000,
    };
    const chunks = [];
    const stream = canvas.captureStream();
    const rec = new MediaRecorder(stream);
    rec.ondataavailable = (e) => chunks.push(e.data);
    rec.onstop = (e) => saveVideo(new Blob(chunks, { type: "video/webm" }));
    rec.start();

    setTimeout(() => rec.stop(), duration);
  };

  const saveVideo = (blob) => {
    const vid = document.createElement("video");
    vid.src = URL.createObjectURL(blob);
    vid.controls = true;
    vid.className = "animation-video";

    if (animRef.current) {
      vid.style.width = "100%";
      vid.style.border = "1px solid rgb(187, 187, 187)";
      vid.style.height = "55vh";
      animRef.current.appendChild(vid);
    }

    //document.body.appendChild(a);
    setRecording(false);
    setFinished(true);
    setDownloadLink(vid.src);
  };

  const handleDownload = () => {
    if (downloadLink) {
      const link = document.createElement("a");
      link.href = `${downloadLink}`; // Replace with the file URL
      link.download = "myvid.webm"; // Replace with the desired file name
      link.click();
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value); // Update the state with the slider value
  };

  const calculateLeft = () => {
    const percentage = ((Number(value) - min) / (max - min)) * 100;
    const offset = Number(value) === min ? 0 : Number(value) === max ? 20 : 20;

    return `calc(${percentage}% - ${percentage * 0.24}px`;
  };

  return (
    <Modal style={customStyles} isOpen={props.isOpen}>
      <div className="modal-wrapper">
        <div className="modal-hdr-wrapper">
          <div className="modal-hdr">Record Menu</div>
          <X
            onClick={() => dispatch(toggleRecordMode())}
            size={"20px"}
            style={{ cursor: "pointer" }}
            className="exit-modal"
          />
        </div>
        {/firefox/i.test(navigator.userAgent) && (
          <div
            style={{
              marginBottom: "10px",
              backgroundColor: "#dedede",
              borderRadius: "4px",
              padding: "12px",
              fontSize: "13px",
              color: "#6a6a6a",
              display: "flex",
            }}
          >
            <div
              style={{
                minWidth: 14,
                display: "flex",
                marginRight: "6px",
                alignItems: "center",
              }}
            >
              <Info size="14" stroke="#6a6a6a" strokeWidth="1.6" />
            </div>
            Firefox forbids to record background tiles. For full recording
            please switch to other browser (Chrome, Edge).
          </div>
        )}
        {!hasAnimation && (
          <div
            className="sim-warning"
            style={{
              marginBottom: "28px",
              padding: "12px",
              fontSize: "13px",
              display: "flex",
            }}
          >
            <div
              style={{
                minWidth: 14,
                display: "flex",
                marginRight: "6px",
                alignItems: "center",
              }}
            >
              <AlertCircle size="14" stroke="#a81d1dcf" strokeWidth="1.6" />
            </div>
            There is no animation displayed on map. First activate it on map.
          </div>
        )}
        <div
          style={{ display: "flex", marginBottom: "14px", marginTop: "17px" }}
        >
          <div
            style={{
              fontSize: "13px",
              fontWeight: "600",
              marginBottom: "12px",
            }}
          >
            Recording Time [s]
          </div>
          <div
            style={{
              width: "200px",
              textAlign: "center",
              position: "relative",
              marginLeft: "12px",
              marginLeft: "48px",
            }}
          >
            <div className="range">
              <div
                style={{
                  position: "absolute",
                  bottom: "36px",
                  left: calculateLeft(), // Dynamically computed left
                  backgroundColor: "#06896c",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  fontSize: "11px",
                  pointerEvents: "none", // Prevent interfering with slider dragging
                  whiteSpace: "nowrap", // Prevent text wrapping
                  marginTop: "-2px",
                  paddingBottom: "6px",
                }}
              >
                {value}
              </div>
              <input
                type="range"
                min={min}
                max={max}
                value={value}
                onChange={handleChange}
                style={{
                  fontSize: "11px",
                  width: "100%",
                  margin: "0px",
                  //marginTop: "10px", // Space between slider and indicator
                  background: "#06896c",
                }}
              />
            </div>
          </div>
        </div>
        {isRecording && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "46px",
              marginTop: "33px",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <Loader size="big" />
            <div
              style={{ marginTop: "14px", fontSize: "13px", fontWeight: 600 }}
            >
              Recording...
            </div>
          </div>
        )}
        <div
          ref={animRef}
          style={{ display: "flex", justifyContent: "center" }}
        ></div>
        <div className="modal-btn-wrapper" style={{ marginTop: "8px" }}>
          <button
            className="btn-modal-cancel"
            onClick={handleDownload}
            disabled={!isFinished}
            style={{
              display: "flex",
              height: "fit-content",
              alignItems: "center",
              marginTop: "12px",
              padding: "7px 4px 7px 4px",
              minWidth: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ display: "flex" }}>
              <Save size={"14px"} strokeWidth={"1.8px"} />
            </div>
            {t(`Download`)}
          </button>
          <button
            className="btn-modal-delete"
            onClick={recordVideo}
            disabled={isRecording}
            style={{
              display: "flex",
              height: "fit-content",
              alignItems: "center",
              marginTop: "12px",
              padding: "7px 4px 7px 4px",
              minWidth: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ display: "flex" }}>
              <Video size={"14px"} strokeWidth={"2.2px"} />
            </div>
            <div style={{ marginRight: "7px" }}>{t(`Record`)}</div>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AnimationRecord;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // boxShadow: "rgb(0 0 0 / 30%) 0px 0px 8px 1px",
    borderRadius: "5px",
    padding: "30px 38px 28px",
    //width: "440px",
    border: "1px solid rgb(187 187 187)",
    maxHeight: "90%",
    minWidth: "600px",
    maxWidth: "75%",
  },
  overlay: {
    backdropFilter: "blur(8px)",
    zIndex: 1000,
  },
};
