import React from "react";
import FormFileBrowser from "../../../Components/Common/Form/FormFileBrowser/FormFileBrowser";
import {
  ResultsStepInput,
  ResultsSelect,
} from "../../../Components/Map/Form/Select";
import {scalePosition } from "../ResultsConst";
import {
  toggleIncludeNorthArrow,
  toggleIncludeScale,
  toggleIncludeAssesmentPoints,
  changeNorthArrowPosition,
  changeScalePosition,
  changeBackgroundMapColor,
  changeBackgroundHeaderColor,
  changeAreaToCrop,
  toggleArrowScale,
  changeArrowGridpointSkip,
  changeArrowLength,
  changeArrowMinMagnitude,
  changeArrowScalePosition,
  setMapPlotLogo,
  clearMapPlotLogo,
} from "../../../redux/actions/resultsActions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ExpertExportSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isBackMapCol: false,
      isBackHdrCol: false,
    };

    this.openBackMapColorPicker = this.openBackMapColorPicker.bind(this);
    this.openBackHdrColorPicker = this.openBackHdrColorPicker.bind(this);
    this.closeColorPicker = this.closeColorPicker.bind(this);
    this.onLogoChange = this.onLogoChange.bind(this);
    this.onLogoClear = this.onLogoClear.bind(this);
  }

  openBackMapColorPicker() {
    this.setState({ isBackMapCol: true, isBackHdrCol: false });
  }

  openBackHdrColorPicker() {
    this.setState({ isBackMapCol: false, isBackHdrCol: true });
  }

  closeColorPicker() {
    this.setState({ isBackMapCol: false, isBackHdrCol: false });
  }

  onLogoChange(file) {
    if (!file) {
      return;
    } else if (file.type === "image/png") {
      this.props.setMapPlotLogo({ name: file.name, logo: file });
    } else {
      this.props.setMapPlotLogo({
        logo: {
          name: file.name,
          size: null,
        },
      });
    }
  }

  onLogoClear() {
    this.props.clearMapPlotLogo();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <h4
          className="raster-settings-header"
          style={{ marginBottom: "16px", marginTop: "12px" }}
        >{`${t("ExtendedSettings")}`}</h4>
        <label
          style={{
            width: "100%",
            fontSize: "11px",
            fontWeight: "600",
            fontFamily: "Segoe UI",
            color: "rgb(122, 122, 122)",
            marginBottom: "2px",
          }}
        >
          {t("Annotations")}
        </label>

        <label
          className="legend-row-container legend-wrapper"
          style={{ marginTop: "6px" }}
        >
          <input
            className="raster-checkbox"
            name={"invert-color"}
            type="checkbox"
            checked={this.props.annNorthArrow}
            onChange={() => {
              this.props.toggleIncludeNorthArrow();
            }}
          />
          <label
            style={{ width: "auto", marginLeft: "8px" }}
            className="raster-object-name layer-object-name"
          >
            {t("IncludeNorthArrow")}
          </label>
        </label>

        <label
          className="legend-row-container legend-wrapper"
          style={{ marginTop: "6px" }}
        >
          <input
            className="raster-checkbox"
            name={"invert-color"}
            type="checkbox"
            checked={this.props.annScale}
            onChange={() => {
              this.props.toggleIncludeScale();
            }}
          />
          <label
            style={{ width: "auto", marginLeft: "8px" }}
            className="raster-object-name layer-object-name"
          >
            {t("IncludeScale")}
          </label>
        </label>

        <label
          className="legend-row-container legend-wrapper"
          style={{ marginTop: "6px" }}
        >
          <input
            className="raster-checkbox"
            name={"invert-color"}
            type="checkbox"
            checked={this.props.annAssessmentPoints}
            onChange={() => {
              this.props.toggleIncludeAssesmentPoints();
            }}
          />
          <label
            style={{ width: "auto", marginLeft: "8px" }}
            className="raster-object-name layer-object-name"
          >
            {t("IncludeAssesmentPoints")}
          </label>
        </label>
        <div style={{ marginTop: "12px" }} />
        {/* <ResultsSelect
          label={t("NorthArrowPosition")}
          style={{ height: "32px" }}
          value={this.props.annNorthArrowPos}
          options={itemPosition.map((x) => {
            return { label: t(`${x}`), value: x };
          })}
          onChange={(e) => {
            this.props.changeNorthArrowPosition(e.target.value);
          }}
          visSettings
          expSettings
          optionsObject
        /> */}

        <ResultsSelect
          label={t("ScalePosition")}
          style={{ height: "32px" }}
          value={this.props.annScalePos}
          options={scalePosition.map((x) => {
            return { label: t(`${x}`), value: x };
          })}
          onChange={(e) => {
            this.props.changeScalePosition(e.target.value);
          }}
          visSettings
          expSettings
          optionsObject
        />
        <hr className="raster-settings-ruler" style={{ marginBottom: "0px" }} />

        <div
          style={{
            width: "100%",
            fontSize: "11px",
            fontWeight: "600",
            marginTop: "12px",
            fontFamily: "Segoe UI",
            color: "rgb(122, 122, 122)",
            marginBottom: "4px",
          }}
        >
          {t("Styling")}
        </div>
        {/* <div
          className={`form-group form-results-group`}
          style={{
            justifyContent: "space-between",
          }}
        >
          <label
            key={"color_wind_dir"}
            style={{ width: "auto" }}
            className={`form-label form-results-label`}
          >
            {t("BackgroundMapColor")}
          </label>
          <div
            onClick={() => this.openBackMapColorPicker()}
            style={{
              width: "180px",
              height: "26px",
              backgroundColor: `#fff`,
              borderRadius: "2px",
              cursor: "pointer",
              border: "1px solid #999999",
              backgroundColor: `${this.props.styBackMapCol}`,
            }}
          />
        </div> */}

        {/* {this.state.isBackMapCol && (
          <div
            style={{
              position: "fixed",
              zIndex: "2",
              right: "110px",
              top: "330px",
            }}
            onDoubleClick={this.closeColorPicker}
          >
            <div style={cover} onClick={this.closeColorPicker} />
            <ChromePicker
              color={this.props.styBackMapCol}
              onChange={(e) => this.props.changeBackgroundMapColor(e.hex)}
            />
          </div>
        )}

        <div
          className={`form-group form-results-group`}
          style={{
            justifyContent: "space-between",
          }}
        >
          <label
            key={"color_wind_dir"}
            style={{ width: "auto" }}
            className={`form-label form-results-label`}
          >
            {t("BackgroundHeaderColor")}
          </label>
          <div
            onClick={() => this.openBackHdrColorPicker()}
            style={{
              width: "180px",
              height: "26px",
              backgroundColor: `#fff`,
              borderRadius: "2px",
              cursor: "pointer",
              border: "1px solid #999999",
              backgroundColor: `${this.props.styBackHeaderCol}`,
            }}
          />
        </div>

        {this.state.isBackHdrCol && (
          <div
            style={{
              position: "fixed",
              zIndex: "2",
              right: "110px",
              top: "360px",
            }}
            onDoubleClick={this.closeColorPicker}
          >
            <div style={cover} onClick={this.closeColorPicker} />
            <ChromePicker
              color={this.props.styBackHeaderCol}
              onChange={(e) => this.props.changeBackgroundHeaderColor(e.hex)}
            />
          </div>
        )} */}

        <FormFileBrowser
          id="UploadLogo"
          name="UploadLogo"
          label={`${t("UploadLogo")}`}
          fileName={this.props.mapPlotLogo?.name}
          isClearable
          setup
          accept="image/x-png"
          error={""}
          resultsStyle
          expResultsStyle
          onChange={this.onLogoChange}
          clearUploadedFile={this.onLogoClear}
        />
        <div style={{ marginTop: "5px" }} />
        <ResultsSelect
          label={t("CropToArea")}
          style={{ height: "32px" }}
          value={this.props.styCropName}
          options={["Domain", ...this.props.arrayOfDrawnAoiLayerName]}
          onChange={(e) => {
            this.props.changeAreaToCrop(e.target.value);
          }}
          visSettings
          expSettings
        />
        <hr className="raster-settings-ruler" style={{ marginBottom: "0px" }} />
        <div
          style={{
            width: "100%",
            fontSize: "11px",
            fontWeight: "600",
            marginTop: "12px",
            fontFamily: "Segoe UI",
            color: "rgb(122, 122, 122)",
            marginBottom: "12px",
          }}
        >
          {t("VectorLayerSettings")}
        </div>
        <label
          className="legend-row-container legend-wrapper"
          style={{ marginTop: "6px", marginBottom: "6px" }}
        >
          <input
            className="raster-checkbox"
            name={"invert-color"}
            type="checkbox"
            checked={this.props.vecArrowScale}
            onChange={() => {
              this.props.toggleArrowScale();
            }}
          />
          <label
            style={{ width: "auto", marginLeft: "8px" }}
            className="raster-object-name layer-object-name"
          >
            {t("IncludeArrowScale")}
          </label>
        </label>
        <ResultsStepInput
          label={t("ArrowGridpointSkip")}
          value={this.props.vecArrowSkip}
          style={{ width: "180px" }}
          labelStyle={{ width: "max-content" }}
          min={1}
          max={40}
          step={1}
          onChange={(e) => {
            this.props.changeArrowGridpointSkip(e.target.value);
          }}
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              Number(e.target.value) < 1 ||
              Number(e.target.value) > 40
            ) {
              this.props.changeArrowGridpointSkip(4);
            }
          }}
          visSettings
          expSettings
        />
        <ResultsStepInput
          label={t("ArrowLength")}
          value={this.props.vecArrowSize}
          style={{ width: "180px" }}
          labelStyle={{ width: "max-content" }}
          min={0.1}
          max={5}
          step={0.1}
          onChange={(e) => {
            this.props.changeArrowLength(e.target.value);
          }}
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              Number(e.target.value) < 0.1 ||
              Number(e.target.value) > 5
            ) {
              this.props.changeArrowLength(0.5);
            }
          }}
          visSettings
          expSettings
        />
        <ResultsStepInput
          label={t("ArrowMinMagnitude")}
          value={this.props.vecMinMag}
          style={{ width: "180px" }}
          labelStyle={{ width: "max-content" }}
          min={0}
          step={1}
          onChange={(e) => {
            this.props.changeArrowMinMagnitude(e.target.value);
          }}
          onBlur={(e) => {
            if (e.target.value === "" || Number(e.target.value) < 0) {
              this.props.changeArrowMinMagnitude(0);
            }
          }}
          visSettings
          expSettings
        />
        {/* <ResultsSelect
          label={t("ArrowScalePosition")}
          style={{ height: "32px" }}
          value={this.props.vecArrowScalePos}
          options={itemPosition.map((x) => {
            return t(x);
          })}
          onChange={(e) => {
            this.props.changeArrowScalePosition(e.target.value);
          }}
          visSettings
          expSettings
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    annNorthArrow: state.results.annNorthArrow,
    annNorthArrowPos: state.results.annNorthArrowPos,
    annScale: state.results.annScale,
    annScalePos: state.results.annScalePos,
    annAssessmentPoints: state.results.annAssessmentPoints,
    annAssessmentPointsArray: state.results.annAssessmentPointsArray,
    annAssessmentPointsCol: state.results.annAssessmentPointsCol,
    styBackMapCol: state.results.styBackMapCol,
    styBackHeaderCol: state.results.styBackHeaderCol,
    styLogoPath: state.results.styLogoPath,
    styCropName: state.results.styCropName,
    styCropPolygon: state.results.styCropPolygon,
    vecArrowScale: state.results.vecArrowScale,
    vecArrowSkip: state.results.vecArrowSkip,
    vecArrowSize: state.results.vecArrowSize,
    vecMinMag: state.results.vecMinMag,
    vecArrowScalePos: state.results.vecArrowScalePos,
    arrayOfDrawnAoiLayerName: state.results.arrayOfDrawnAoiLayerName,
    mapPlotLogo: state.results.mapPlotLogo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleIncludeNorthArrow: () => dispatch(toggleIncludeNorthArrow()),
    toggleIncludeScale: () => dispatch(toggleIncludeScale()),
    toggleIncludeAssesmentPoints: () =>
      dispatch(toggleIncludeAssesmentPoints()),
    changeNorthArrowPosition: (payload) =>
      dispatch(changeNorthArrowPosition(payload)),
    changeScalePosition: (payload) => dispatch(changeScalePosition(payload)),
    changeBackgroundMapColor: (payload) =>
      dispatch(changeBackgroundMapColor(payload)),
    changeBackgroundHeaderColor: (payload) =>
      dispatch(changeBackgroundHeaderColor(payload)),
    changeAreaToCrop: (payload) => dispatch(changeAreaToCrop(payload)),
    toggleArrowScale: () => dispatch(toggleArrowScale()),
    changeArrowGridpointSkip: (payload) =>
      dispatch(changeArrowGridpointSkip(payload)),
    changeArrowLength: (payload) => dispatch(changeArrowLength(payload)),
    changeArrowMinMagnitude: (payload) =>
      dispatch(changeArrowMinMagnitude(payload)),
    changeArrowScalePosition: (payload) =>
      dispatch(changeArrowScalePosition(payload)),
    setMapPlotLogo: (payload) => dispatch(setMapPlotLogo(payload)),
    clearMapPlotLogo: () => dispatch(clearMapPlotLogo()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ExpertExportSettings)));

// const cover = {
//   position: "fixed",
//   top: "0px",
//   right: "0px",
//   bottom: "0px",
//   left: "0px",
// };
