import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";
import "./leaflet-ruler.scss";
import "./leaflet-ruler";

class LeafletRuler extends MapControl {
  createLeafletElement(props) {
    return L.control.ruler(props);
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    this.leafletElement.addTo(map);

    const { style } = this.props;
    if (style) {
      const container = this.leafletElement.getContainer();
      if (container) {
        Object.assign(container.style, style);
      }
    }
  }
}

export default withLeaflet(LeafletRuler);
