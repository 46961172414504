import { colors } from "../../common/Colors";

export function getPolygonColor(objectType, inputPalmTypesPalette) {
  if (!inputPalmTypesPalette || !Object.keys(inputPalmTypesPalette).length) {
    switch (objectType) {
      case 1:
        return colors.building;
      case 2:
        return colors.vegetation;
      case 3:
        return colors.pavement;
      case 4:
        return colors.water;
      case 5:
        return colors.tree;
      case 7:
        return colors.street;
      case 8:
        return colors.emission;
      case 10:
        return colors.windTurbine;
      case 99:
        return colors.rasterFilling;
      default:
        return colors.default;
    }
  } else {
    const userColorPalette = JSON.parse(inputPalmTypesPalette);
    switch (objectType) {
      case 1:
        if (userColorPalette?.building?.unselected) {
          return userColorPalette.building.unselected;
        }
        return colors.building;
      case 2:
        if (userColorPalette?.vegetation?.unselected) {
          return userColorPalette.vegetation.unselected;
        }
        return colors.vegetation;
      case 3:
        if (userColorPalette?.pavement?.unselected) {
          return userColorPalette.pavement.unselected;
        }
        return colors.pavement;
      case 4:
        if (userColorPalette?.water?.unselected) {
          return userColorPalette.water.unselected;
        }
        return colors.water;
      case 5:
        if (userColorPalette?.treePatches?.unselected) {
          return userColorPalette.treePatches.unselected;
        }
        return colors.tree;
      case 7:
        if (userColorPalette?.street?.unselected) {
          return userColorPalette.street.unselected;
        }
        return colors.street;
      case 8:
        return colors.emission;
      case 10:
        return colors.windTurbine;
      case 99:
        return colors.rasterFilling;
      default:
        return colors.default;
    }
  }
}

export function getSelectedPolygonColor(objectType, inputPalmTypesPalette) {
  const isUserSaved = isObjectEmpty(inputPalmTypesPalette);

  if (
    isUserSaved ||
    inputPalmTypesPalette === "{}" ||
    inputPalmTypesPalette === undefined
  ) {
    switch (objectType) {
      case 1:
        return colors.selectedBuilding;
      case 2:
        return colors.seletedVegetation;
      case 3:
        return colors.selectedPavement;
      case 4:
        return colors.selectedWater;
      case 5:
        return colors.selectedTree;
      case 7:
        return colors.selectedStreet;
      default:
        return colors.default;
    }
  } else {
    const userColorPalette = JSON.parse(inputPalmTypesPalette);
    switch (objectType) {
      case 1:
        if (userColorPalette?.building?.selected) {
          return userColorPalette.building.selected;
        }
        return colors.selectedBuilding;
      case 2:
        if (userColorPalette?.vegetation?.selected) {
          return userColorPalette.vegetation.selected;
        }
        return colors.seletedVegetation;
      case 3:
        if (userColorPalette?.pavement?.selected) {
          return userColorPalette.pavement.selected;
        }
        return colors.selectedPavement;
      case 4:
        if (userColorPalette?.water?.selected) {
          return userColorPalette.water.selected;
        }
        return colors.selectedWater;
      case 5:
        if (userColorPalette?.treePatches?.selected) {
          return userColorPalette.treePatches.selected;
        }
        return colors.selectedTree;
      case 7:
        if (userColorPalette?.street?.selected) {
          return userColorPalette.street.selected;
        }
        return colors.selectedStreet;
      case 99:
        return colors.rasterFilling;
      default:
        return colors.default;
    }
  }
}

function isObjectEmpty(obj) {
  for (const i in obj) return false;
  return true;
}
