import React from "react";
import L from "leaflet";
import { updateResultsJsonOrigin } from "../../../../redux/actions/resultsActions";
import { getDownloadLink } from "../../../../api/storageApi";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";

class WindDirection extends React.Component {
  constructor(props) {
    super(props);
    this.loaded = this.loaded.bind(this);
  }

  async componentDidMount() {
    const map = this.props.mapRef.current.leafletElement;
    if (map === null) {
      return;
    }
    map.eachLayer(function (layer) {
      if (layer.options.arrowDirection) {
        layer.remove();
      }
    });
    const windDirectionScript = document.getElementById("wind-animation");
    if (windDirectionScript === null) {
      const script = document.createElement("script");
      script.id = "wind-animation";

      script.src =
        "https://ihcantabria.github.io/Leaflet.CanvasLayer.Field/dist/leaflet.canvaslayer.field.js";
      script.async = true;
      script.onload = () => this.loaded(map);

      document.body.appendChild(script);
    } else {
      this.loaded(map);
    }
  }
  async loaded(map) {
    const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);
    const windDirLayer =
      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ];

    let arrowSize = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_arr_size
    );

    let opacity = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_opacity
    );

    let color = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_color
    );

    let tiffU = null;
    let tiffV = null;

    if (
      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].urlU
    ) {
      tiffU = await getDownloadLink(`${windDirLayer.geo_u_4326}`);
      tiffV = await getDownloadLink(`${windDirLayer.geo_v_4326}`);
    } else {
      const resultsGeotiffULink = await getDownloadLink(
        `${windDirLayer.geo_u_4326}`
      );
      const resultsGeotiffVLink = await getDownloadLink(
        `${windDirLayer.geo_v_4326}`
      );

      windDirLayer.urlU = resultsGeotiffULink;
      windDirLayer.urlV = resultsGeotiffVLink;

      tiffU = windDirLayer.urlU;
      tiffV = windDirLayer.urlV;

      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ] = windDirLayer;

      this.props.updateResultsJsonOrigin(resultsJsonOrigin);
    }

    let urls = [tiffU, tiffV];
    let promises = urls.map((url) => fetch(url).then((r) => r.arrayBuffer()));

    let filter_nodata = function (v) {
      return v >= 0;
    };

    Promise.all(promises).then(function (arrays) {
      let vectorField = L.VectorField.fromGeoTIFFs(arrays[0], arrays[1], 0.001);

      const windDir = L.canvasLayer
        .scalarField(vectorField.getScalarField("directionFrom"), {
          type: "vector", //color: chroma.scale("spectral").domain([0, 100]),
          color: color ? color : "black",
          vectorSize: arrowSize ? arrowSize : 20,
          arrowDirection: "from",
          opacity: opacity,
          interpolate: false,
          inFilter: filter_nodata,
          width: 20,
        })
        .addTo(map);
        
        windDir._canvas.classList.add("wind-direction");
    });

  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateResultsJsonOrigin: (payload) =>
      dispatch(updateResultsJsonOrigin(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WindDirection);
