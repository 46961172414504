import React from "react";
import L from "leaflet";
import { Marker, Pane } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import drawnMapPoiMarkerUrl from "./drawn-map-poi-icon.svg";
import highlightedDrawnMapPoiMarkerUrl from "./drawn-highlighted-map-poi-icon.svg";
import {
  selectMapPoi,
  updateMapPoiPositionOnDragend,
  enableMapPoiDraggable,
  setMarkerRef,
} from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";

class MapPoiDrawing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createClusterCustomIcon = this.createClusterCustomIcon.bind(this);
  }

  componentDidMount() {
    if (
      this.props.inMapPoiDrawingMode &&
      this.props.mapRef.current.leafletElement.getZoom() === 19
    ) {
      this.props.enableMapPoiDraggable();
    }
  }

  createClusterCustomIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-drawn-map-poi span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <Pane>
        {this.props.isGeoJsonVisible && !this.props.inMapPoiDrawingMode ? (
          <MarkerClusterGroup
            iconCreateFunction={this.createClusterCustomIcon}
            disableClusteringAtZoom={19}
            showCoverageOnHover
            zoomToBoundsOnClick
            spiderfyOnMaxZoom={false}
          >
            {this.props.mapPoiDrawnVisibility &&
              this.props.allDrawnMapPoiCoordinatesWg.map((x, idx) => (
                <CustomMarker
                  position={[x[0], x[1]]}
                  icon={
                    this.props.selectedMapPoiIndex !== idx
                      ? drawnMapPoiMarker
                      : highlightedDrawnMapPoiMarker
                  }
                  zIndexOffset={
                    this.props.selectedMapPoiIndex === idx ? 10000 : 1000
                  }
                  mapPoiIndex={idx}
                  selectedMapPoiIndex={this.props.selectedMapPoiIndex}
                  allDrawnMapPoiGeoProps={this.props.allDrawnMapPoiGeoProps}
                  objectTypeIndex={6}
                  markerIndex={idx}
                  onClick={
                    this.props.displayMapPoiCursor
                      ? null
                      : (e) => {
                          this.props.selectMapPoi(idx);
                          this.props.setMarkerRef(e.target);
                        }
                  }
                  draggable={false}
                  onDragend={(e) =>
                    this.props.updateMapPoiPositionOnDragend({
                      e: e.target._latlng,
                      idx: idx,
                    })
                  }
                  mapPoiTooltipVisibility={this.props.mapPoiTooltipVisibility}
                  setMarkerName={this.props.setMarkerName}
                  polygonData={this.props.polygonData}
                />
              ))}
          </MarkerClusterGroup>
        ) : (
          <>
            {!this.props.inRasterSettingsMode &&
              this.props.mapPoiDrawnVisibility &&
              this.props.allDrawnMapPoiCoordinatesWg.map((x, idx) => (
                <Marker
                  position={[x[0], x[1]]}
                  icon={
                    this.props.selectedMapPoiIndex !== idx
                      ? drawnMapPoiMarker
                      : highlightedDrawnMapPoiMarker
                  }
                  zIndexOffset={
                    this.props.selectedMapPoiIndex === idx ? 10000 : 1000
                  }
                  objectTypeIndex={6}
                  markerIndex={idx}
                  onClick={
                    this.props.displayMapPoiCursor
                      ? null
                      : () => {
                          this.props.selectMapPoi(idx);
                        }
                  }
                  draggable={
                    this.props.inMapPoiDrawingMode &&
                    !this.props.displayMapPoiCursor
                  }
                  onDragend={(e) =>
                    this.props.updateMapPoiPositionOnDragend({
                      e: e.target._latlng,
                      idx: idx,
                    })
                  }
                />
              ))}
          </>
        )}

        {this.props.inMapPoiDrawingMode &&
          this.props.currentCursorCoords &&
          this.props.displayMapPoiCursor && (
            <Marker
              icon={drawnMapPoiMarker}
              position={this.props.currentCursorCoords}
              zIndex={1}
              zIndexOffset={1}
            />
          )}
      </Pane>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCursorCoords: state.map.currentCursorCoords,
    allDrawnMapPoiCoordinatesWg: state.map.allDrawnMapPoiCoordinatesWg,
    selectedMapPoiIndex: state.map.selectedMapPoiIndex,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    inMapPoiDrawingMode: state.map.inMapPoiDrawingMode,
    displayMapPoiCursor: state.map.displayMapPoiCursor,
    mapPoiDrawnVisibility: state.map.mapPoiDrawnVisibility,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    polygonData: state.map.polygonData,
    allDrawnMapPoiGeoProps: state.map.allDrawnMapPoiGeoProps,
    mapPoiTooltipVisibility: state.legend.mapPoiTooltipVisibility,
    inDrawingMode: state.map.inDrawingMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectMapPoi: (payload) => dispatch(selectMapPoi(payload)),
    updateMapPoiPositionOnDragend: (payload) =>
      dispatch(updateMapPoiPositionOnDragend(payload)),
    enableMapPoiDraggable: () => dispatch(enableMapPoiDraggable()),
    setMarkerRef: (payload) => dispatch(setMarkerRef(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPoiDrawing);

const drawnMapPoiMarker = new L.Icon({
  iconUrl: drawnMapPoiMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedDrawnMapPoiMarker = new L.Icon({
  iconUrl: highlightedDrawnMapPoiMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

export { drawnMapPoiMarker, highlightedDrawnMapPoiMarker };

class CustomMarker extends React.Component {
  constructor(props) {
    super(props);
    this.markerRef = React.createRef();
  }

  componentDidMount() {
    if (this.markerRef.current) {
      const marker = this.markerRef.current.leafletElement;
      const content = this.props.polygonData.find((x) => {
        return (
          x.id === this.props.allDrawnMapPoiGeoProps[this.props.mapPoiIndex].id
        );
      })?.name
        ? this.props.polygonData.find((x) => {
            return (
              x.id ===
              this.props.allDrawnMapPoiGeoProps[this.props.mapPoiIndex].id
            );
          })?.name
        : this.props.allDrawnMapPoiGeoProps[this.props.mapPoiIndex].name;

      marker.bindTooltip(`${content}`, {
        permanent: true,
        className: `map-poi-tooltip ${
          this.props.mapPoiTooltipVisibility ? "" : "map-poi-tooltip-hidden"
        }`,
        direction: "top",
        offset: [0, -10],
      });

      return marker;
    }
  }

  render() {
    return (
      <Marker
        position={this.props.position}
        ref={this.markerRef}
        onClick={this.props.onClick}
        icon={this.props.icon}
      />
    );
  }
}
