import React from "react";
import { HelpCircle } from "react-feather";
import "./Form.scss";

const Select = (props) => {
  return (
    <div className="form-group">
      <label className={`form-label`}>{props.label}</label>
      <select
        id={props.name}
        name={props.name}
        onChange={props.onChange}
        className={`form-select`}
        value={props.value}
        disabled={props.disabled}
      >
        {props.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <a
        className="help-icon-link"
        href={props.help}
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpCircle
          color="rgba(0, 0, 0, 0.75)"
          strokeWidth="2"
          size="26px"
          style={{
            alignSelf: "center",
            paddingLeft: "4px",
          }}
        />
      </a>
    </div>
  );
};
export { Select };

export const ResultsSelect = (props) => {
  return (
    <div className="form-group form-results-group">
      <label className={`form-label form-results-label`}>{props.label}</label>
      <select
        className={`form-select form-results-select ${
          props.expSettings ? "form-results-exp-vis" : ""
        }
          ${props.visSettings ? "form-results-sel-vis" : ""}
        `}
        id={props.name}
        name={props.name}
        onChange={props.onChange}
        disabled={props.disabled}
        value={props.value}
        style={props.style}
      >
        {typeof props.options !== "string" && props.optionsObject !== true ? (
          props.options.map((option) => {
            return (
              <option key={option + "2"} value={option}>
                {option}
              </option>
            );
          })
        ) : props.optionsObject ? (
          props.options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label ? option.label : option.value}
              </option>
            );
          })
        ) : (
          <option key={props.options} value={props.options}>
            {props.options}
          </option>
        )}
      </select>
    </div>
  );
};

export const ResultsTextInput = (props) => {
  return (
    <div
      className={`form-group form-results-group ${
        props.visSettings ? "form-results-group-vis" : ""
      }`}
    >
      <label className={`form-label form-results-label`}>{props.label}</label>
      <input
        id={props.name}
        className={`form-select form-results-select ${
          props.visSettings ? "form-results-inp-vis" : ""
        }
        ${props.expSettings ? "form-results-inp-exp" : ""}
        `}
        style={props.style}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        maxLength={props.maxLength}
      />
    </div>
  );
};

export const ResultsLayerInfo = (props) => {
  return (
    <div className="form-group form-results-group form-layer-group">
      <div>
        <label className={`form-label form-results-label`}>{props.label}</label>
      </div>
      <input
        id={props.name}
        className={`form-select form-results-select form-layer-select `}
        name={props.name}
        style={props.style}
        onChange={props.onChange}
        value={props.value}
        type="text"
        disabled
      />
    </div>
  );
};

export const ResultsStepInput = (props) => {
  return (
    <div
      className={`form-group form-results-group ${
        props.visSettings ? "form-results-group-vis" : ""
      }`}
    >
      <label
        style={props.labelStyle}
        className={`form-label form-results-label`}
      >
        {props.label}
      </label>
      <input
        id={props.name}
        className={`form-select form-results-select ${
          props.visSettings ? "form-results-inp-vis" : ""
        }`}
        style={props.style}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        type="number"
        onBlur={props.onBlur}
        disabled={props.disabled}
        min={props.min}
        max={props.max}
        step={props.step}
        {...props}
      />
    </div>
  );
};

export const NumericOsmInput = (props) => {
  return (
    <div
      className="form-group"
      style={{ justifyContent: "space-between", width: "100%", height: "36px" }}
    >
      <label className="form-label" style={{ width: "auto" }}>
        {props.label}
      </label>
      <div
        className={`input-wrapper`}
        style={{ height: "32px", width: "200px" }}
      >
        <input
          id={props.name}
          name={props.name}
          style={{ margin: "0px", paddingLeft: "5px" }}
          className={`form-input`}
          value={props.value ? props.value : ""}
          type={props.type ? props.type : "number"}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength.toString()}
          onChange={props.onChange}
          autoComplete="off"
          step={props.step ? props.step : ""}
          disabled={props.disabled ? props.disabled : false}
          {...props}
        ></input>
      </div>
    </div>
  );
};
