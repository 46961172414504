import React from "react";
import ActionButton from "../../../Components/Map/ActionButton/ActionButton";
import StaticDriverLegend from "../StaticDriver/StaticDriverLegend/StaticDriverLegend";
import {
  toggleResultsMapPoiMode,
  toggleResultsMapExportMode,
  toggleLayerSettings,
  toggleGeodataDownload,
  togglePoi,
  toggleAoi,
  showResultsGeotiffLegend,
  togglePoiVisibility,
  centerMap,
  toggleExportSettings,
  toggleTimeChart,
  toggleVerticalChart,
  toggleRecordMode,
} from "../../../redux/actions/resultsActions";
import { setMapDataSavingStatus } from "../../../redux/actions/mapActions";
import { cloneDeep } from "lodash";
import { uploadBlobToAzure } from "../../../api/storageApi";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./StaticDriverMapPanel.scss";

const StaticDriverMapPanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inResultsMapPoiMode = useSelector(
    (state) => state.results.inResultsMapPoiMode
  );
  const inResultsMapExportMode = useSelector(
    (state) => state.results.inResultsMapExportMode
  );
  const inLayerSettings = useSelector((state) => state.results.inLayerSettings);
  const inGeodataDownload = useSelector(
    (state) => state.results.inGeodataDownload
  );
  const inExportSettings = useSelector(
    (state) => state.results.inExportSettings
  );

  const isPoiVisible = useSelector((state) => state.results.isPoiVisible);
  const inPoi = useSelector((state) => state.results.inPoi);
  const inAoi = useSelector((state) => state.results.inAoi);
  const isResultsGeotiffLegendVisible = useSelector(
    (state) => state.results.isResultsGeotiffLegendVisible
  );
  const inTimeChart = useSelector((state) => state.results.inTimeChart);
  const inVerticalChart = useSelector((state) => state.results.inVerticalChart);

  const postProcessingJobs = useSelector(
    (state) => state.projects.currentCase.postProcessingJobs
  );
  const resultsJsonOrigin = useSelector(
    (state) => state.results.resultsJsonOrigin
  );

  const saveResultsChanges = async () => {
    dispatch(setMapDataSavingStatus("pending"));
    const copyResultsJsonOrigin = cloneDeep(resultsJsonOrigin);
    const resetResultsJsonOrigin = resetVisibility(copyResultsJsonOrigin);

    const resultsUrl = postProcessingJobs.find(
      (x) => x.status === 2
    ).reportJsonUrl;
    console.log(JSON.stringify(resetResultsJsonOrigin, null, 2));

    await uploadBlobToAzure(resultsUrl, JSON.stringify(resetResultsJsonOrigin));
    dispatch(setMapDataSavingStatus("ResultsSaveSuccess"));
    setTimeout(() => {
      dispatch(setMapDataSavingStatus("false"));
    }, 10000);
  };

  return (
    <>
      {!inResultsMapPoiMode && !inResultsMapExportMode ? (
        <>
          <div className="static-driver-btn-panel">
            <ActionButton
              icon="center-view"
              onClick={() => dispatch(centerMap())}
              title={t("CenterView")}
              disabled={false}
            />
            <ActionButton
              icon="layer-settings"
              onClick={() => dispatch(toggleLayerSettings())}
              title={t("LayerSettings")}
              disabled={false}
              pushed={inLayerSettings}
            />
            <ActionButton
              icon="area"
              onClick={() => dispatch(toggleResultsMapPoiMode())}
              title={t("PointOfInterest")}
              disabled={false}
              pushed={inResultsMapPoiMode}
            />
            <ActionButton
              icon="export"
              onClick={() => dispatch(toggleResultsMapExportMode())}
              title={t("Export")}
              disabled={false}
            />
            <ActionButton
              icon="layer-save"
              onClick={saveResultsChanges}
              title={t("LayerSave")}
            />
            <ActionButton
              icon="legend"
              title={t("ResultsLayers")}
              onMouseEnter={() => dispatch(showResultsGeotiffLegend())}
            />
          </div>
          {isResultsGeotiffLegendVisible && <StaticDriverLegend />}
        </>
      ) : inResultsMapPoiMode ? (
        <div className="static-driver-btn-panel" style={{ height: "324px" }}>
          <ActionButton
            icon="pin-location"
            onClick={() => dispatch(togglePoi())}
            title={t("PointsOfInterest")}
            pushed={inPoi}
            disabled={!isPoiVisible}
            className="no-opacity"
          />
          <ActionButton
            icon="area-poi"
            onClick={() => dispatch(toggleAoi())}
            title={t("AreaOfInterest")}
            pushed={inAoi}
            disabled={!isPoiVisible}
            className="no-opacity"
          />
          <ActionButton
            icon="hid-area-poi"
            onClick={() => dispatch(togglePoiVisibility())}
            title={t("Show/HidePointOfInterest")}
            disabled={false}
            pushed={!isPoiVisible}
          />
          <ActionButton
            icon="chart-timeseries"
            onClick={() => dispatch(toggleTimeChart())}
            title={t("ChartTimeSeries")}
            pushed={inTimeChart}
            className="no-opacity"
          />
          <ActionButton
            icon="chart-vertical"
            onClick={() => dispatch(toggleVerticalChart())}
            title={t("ChartVertical")}
            pushed={inVerticalChart}
            className="no-opacity"
          />
          <ActionButton
            icon="exit-drawing-mode"
            onClick={() => dispatch(toggleResultsMapPoiMode())}
            title={t("ExitDrawingMode")}
          />
          <ActionButton icon="transparent-template" />
        </div>
      ) : (
        <div className="static-driver-btn-panel" style={{ height: "276px" }}>
          <ActionButton icon="transparent-template" />
          <ActionButton icon="transparent-template" />
          <ActionButton
            icon="custom-map-export"
            onClick={() => dispatch(toggleExportSettings())}
            title={t("CustomDownloadImage")}
            pushed={inExportSettings}
            disabled
          />
          <ActionButton
            icon="geodata-download"
            onClick={() => dispatch(toggleGeodataDownload())}
            title={t("DownloadGeodata")}
            disabled={false}
            pushed={inGeodataDownload}
          />
          <ActionButton
            icon="camera"
            title={t("Record")}
            onClick={() => dispatch(toggleRecordMode())}
          />
          <ActionButton
            icon="exit-drawing-mode"
            onClick={() => dispatch(toggleResultsMapExportMode())}
            title={t("ExitDownloadMode")}
          />
        </div>
      )}
    </>
  );
};

export default StaticDriverMapPanel;

export const resetVisibility = (resultsJsonOrigin) => {
  for (
    let pageIndex = 0;
    pageIndex < resultsJsonOrigin.pages.length;
    pageIndex++
  ) {
    if (resultsJsonOrigin.pages[pageIndex].type === "Map") {
      for (
        let lyrsIndex = 0;
        lyrsIndex < resultsJsonOrigin.pages[pageIndex].lyrs.length;
        lyrsIndex++
      ) {
        resultsJsonOrigin.pages[pageIndex].lyrs[lyrsIndex].checked = false;
        if (resultsJsonOrigin.pages[pageIndex].lyrs[lyrsIndex].url)
          delete resultsJsonOrigin.pages[pageIndex].lyrs[lyrsIndex].url;
      }
    }
  }

  return resultsJsonOrigin;
};
