import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSteeringNotification } from "../../redux/actions/notificationActions";
import authService from "../../utils/auth/authService";

const notificationHubUrl = `${process.env.REACT_APP_FRONT_SERVICE_API}/notifications/steering`;

const SteeringProvider = () => {
  const [connection, setConnection] = useState(null);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const start = () => {
    if (connection) {
      if (connection.connectionState === "Connected") {
        return;
      }
      connection
        .start()
        .then(() => {
          connection.on("SteeringNotificationReceived", (message) => {
            const steeringNotification = {
              id: message.requestId,
              type: "steering",
              body: message.response.steeringSummary,
              resultUrl: message.response.resultUrl,
              childResultUrl: message.response.childResultUrl,
              ts: message.timestampUtc,
              additionalData: message.response.additionalData,
            };
            console.log(message);
            dispatch(addSteeringNotification(steeringNotification));
          });
          connection.onclose(() => {
            setTimeout(() => start(), 3000);
          });
        })
        .catch((e) => {
          console.log(
            "Connection failed: ",
            JSON.stringify(e),
            e.statusCode,
            new Date()
          );
          setTimeout(() => start(), 3000);
        });
    }
  };
  useEffect(() => {
    async function subscribeToHub() {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${notificationHubUrl}`, {
          accessTokenFactory: () => {
            return authService.acquireToken();
          },
        })
        .build();
      setConnection(newConnection);
    }
    if (!user || !user.identity) {
      return;
    }
    subscribeToHub();
  }, [user]);

  useEffect(() => {
    start();
  }, [connection]);

  return null;
};

export default SteeringProvider;
