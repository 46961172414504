import React from "react";
import DifferenceLayerSettingsForm from "./DifferenceLayerSettingsForm";
import SpatiotemporalAnalysisForm from "./SpatiotemporalAnalysisForm";
import CustomCategory from "./CustomCategory";
import Windfield from "./Windfield";
import Isolines from "./Isolines";
import {
  ResultsSelect,
  ResultsTextInput,
} from "../../../../../Components/Map/Form/Select";
import {
  PlusCircle,
  ArrowLeftCircle,
  AlertCircle,
  CheckCircle,
} from "react-feather";
import { Loader } from "../../../../../Components/Loader/index";
import { newLayerTypeField } from "../../../ResultsConst";
import {
  requestNewLayer,
  requestNewCustomLayer,
} from "../../../../../api/resultsApi";
import {
  toggleReadOnlyLayerForm,
  toggleCreateLayerForm,
  changeNewLayerCase,
  changeNewLayerType,
  changeNewLayerParams,
  changeNewLayerHeightLevel,
  changeNewLayerExclBldgs,
  changeNewLayerPostproc,
  changeNewLayerTimestep,
  addNewLayer,
  changeNewLayerTypeField,
  resetNewLayerSettings,
} from "../../../../../redux/actions/resultsActions";
import { downloadBlobAsync } from "../../../../../api/storageApi";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class LayerSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newLayerName: "layer default",
      layerNameError: null,
      isProcessing: false,
      newLayerResultInfo: null,
    };

    this.createNewLayer = this.createNewLayer.bind(this);
    this.onNewLayerNameChange = this.onNewLayerNameChange.bind(this);
    this.onCaseChange = this.onCaseChange.bind(this);
    this.onNewLayerTypeChange = this.onNewLayerTypeChange.bind(this);
    this.onNewLayerParamsChange = this.onNewLayerParamsChange.bind(this);
    this.onNewLayerHeightLevelChange =
      this.onNewLayerHeightLevelChange.bind(this);
    this.onNewLayerTimestepChange = this.onNewLayerTimestepChange.bind(this);
    this.onNewLayerExclBldgsChange = this.onNewLayerExclBldgsChange.bind(this);
    this.onNewLayerPostprocChange = this.onNewLayerPostprocChange.bind(this);
    this.onNewLayerTypeFieldChange = this.onNewLayerTypeFieldChange.bind(this);
  }

  componentDidMount() {
    plotty.addColorScale(
      "category",
      [
        "#0070c0",
        "#00b0f0",
        "#00b050",
        "#92d050",
        "#ffff00",
        "#ffbf00",
        "#ff0000",
        "#c00000",
        "#6f30a0",
      ],
      [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]
    );

    if (this.props.inReadOnlyLayerForm) {
      return;
    }

    if (
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length === 8
    ) {
      this.setState({
        newLayerResultInfo: "LayerExceedsLimit",
      });
      return;
    }

    const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);

    const filteredLayerNames = resultsJsonOrigin.pages[
      this.props.currentViewIndex
    ].lyrs.map((x) => {
      return x.name;
    });

    if (filteredLayerNames.some((x) => x === this.state.newLayerName)) {
      const layerNameError = "PleaseInsertUniqueLayerName";
      this.setState({ layerNameError: layerNameError });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ] !== undefined &&
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ].body.status === 1 &&
      this.props.postprocessNotifications[
        this.props.postprocessNotifications.length - 1
      ].body.status === 2
    ) {
      const newLayerUrl =
        this.props.postprocessNotifications[
          this.props.postprocessNotifications.length - 1
        ].body.resultUrl;
      const newLayerJson = await downloadBlobAsync(newLayerUrl);

      this.props.addNewLayer(newLayerJson.data);

      const isProcessing = false;
      const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);

      const filteredLayerNames = resultsJsonOrigin.pages[
        this.props.currentViewIndex
      ].lyrs.map((x) => {
        return x.name;
      });

      if (this.state.newLayerName === "layer default") {
        this.setState({
          layerNameError: "PleaseInsertUniqueLayerName",
          isProcessing: isProcessing,
          newLayerResultInfo: "NewLayerHasBeenCreated",
        });
      } else if (filteredLayerNames.some((x) => x === "layer default")) {
        this.setState({
          newLayerName: "layer default",
          layerNameError: "PleaseInsertUniqueLayerName",
          isProcessing: isProcessing,
          newLayerResultInfo: "NewLayerHasBeenCreated",
        });
      } else {
        this.setState({
          newLayerName: "layer default",
          layerNameError: null,
          isProcessing: isProcessing,
          newLayerResultInfo: "NewLayerHasBeenCreated",
        });
      }
    } else if (
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ] !== undefined &&
      prevProps.postprocessNotifications[
        prevProps.postprocessNotifications.length - 1
      ].body.status === 1 &&
      this.props.postprocessNotifications[
        this.props.postprocessNotifications.length - 1
      ].body.status === 3
    ) {
      const isProcessing = false;

      this.setState({
        layerNameError: null,
        isProcessing: isProcessing,
        newLayerResultInfo: "LayerCouldNotBeCreated",
      });
    } else if (prevProps.leafletSet !== this.props.leafletSet) {
      const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);

      const filteredLayerNames = resultsJsonOrigin.pages[
        this.props.currentViewIndex
      ].lyrs.map((x) => {
        return x.name;
      });

      if (this.state.newLayerName === "layer default") {
        this.setState({
          layerNameError: "PleaseInsertUniqueLayerName",
          newLayerResultInfo: "NewLayerHasBeenCreated",
        });
      } else if (filteredLayerNames.some((x) => x === "layer default")) {
        this.setState({
          newLayerName: "layer default",
          layerNameError: "PleaseInsertUniqueLayerName",
          newLayerResultInfo: "NewLayerHasBeenCreated",
        });
      } else {
        this.setState({
          newLayerName: "layer default",
          layerNameError: null,
          newLayerResultInfo: "NewLayerHasBeenCreated",
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetNewLayerSettings();
  }

  async createNewLayer() {
    this.setState({ isProcessing: true });

    if (this.props.newLayerTypeField === "CustomCategory") {
      const selectedLayerJsonOrigin =
        this.props.resultsJsonOrigin.pages[this.props.catCustomPageIndex].lyrs[
          this.props.catCustomLayerIndex
        ];

      const windU = selectedLayerJsonOrigin.geo_u_utm;
      let geotiffurl = [];

      if (windU) {
        geotiffurl = [
          windU,
          selectedLayerJsonOrigin.geo_v_utm,
          selectedLayerJsonOrigin.geo_u_4326,
          selectedLayerJsonOrigin.geo_v_4326,
        ];
      } else if (windU === undefined) {
        geotiffurl = [
          selectedLayerJsonOrigin.geo_utm,
          selectedLayerJsonOrigin.geo_4326,
        ];
      }

      const request = {
        projectId: this.props.loadedProject.id,
        caseId: this.props.currentCase.id,
        simulationJobId: this.props.simulationJobs[0].id
          ? this.props.simulationJobs[0].id
          : this.props.postProcessingJobs[0].requestId,
        settings: {
          task: "category",
          layerId: this.state.newLayerName.replace(/ +/g, "_"),
          layerName: this.state.newLayerName,
          pageName: this.props.catCustomPage,
          dataLayerId: this.props.catCustomLayer,
          nrOfCats: this.props.catCustomNr,
          colorPalette: this.props.isCatCustomColorPaletteInverted
            ? "inv" + this.props.catCustomColorPalette
            : this.props.catCustomColorPalette,
          dataparam: selectedLayerJsonOrigin.set_param,
          geotiffurl: geotiffurl,
          crs: this.props.epsg,
        },
      };
      console.log(JSON.stringify(request, null, 2));
      await requestNewCustomLayer(request);

      return;
    } else if (this.props.newLayerTypeField === "Difference") {
      const selectedLayerJsonOrigin =
        this.props.diffCaseResultsJsonOrigin.pages[this.props.diffPageIndex]
          .lyrs[this.props.diffLayerIndex];
      const parentWindU = selectedLayerJsonOrigin.geo_u_utm;
      let geotiffurl1 = [];

      if (parentWindU) {
        geotiffurl1 = [
          parentWindU,
          selectedLayerJsonOrigin.geo_v_utm,
          selectedLayerJsonOrigin.geo_u_4326,
          selectedLayerJsonOrigin.geo_v_4326,
        ];
      } else if (parentWindU === undefined) {
        geotiffurl1 = [
          selectedLayerJsonOrigin.geo_utm,
          selectedLayerJsonOrigin.geo_4326,
        ];
      }

      const selectedChildLayerJsonOrigin =
        this.props.diffChildCaseResultsJsonOrigin.pages[
          this.props.diffChildPageIndex
        ].lyrs[this.props.diffChildLayerIndex];

      const childWindU = selectedChildLayerJsonOrigin.geo_u_utm;

      let geotiffurl2 = [];

      if (childWindU) {
        geotiffurl2 = [
          childWindU,
          selectedChildLayerJsonOrigin.geo_v_utm,
          selectedChildLayerJsonOrigin.geo_v_utm,
          selectedChildLayerJsonOrigin.geo_u_4326,
          selectedChildLayerJsonOrigin.geo_v_4326,
        ];
      } else if (childWindU === undefined) {
        geotiffurl2 = [
          selectedChildLayerJsonOrigin.geo_utm,
          selectedChildLayerJsonOrigin.geo_4326,
        ];
      }
      const diffCaseId = this.props.diffCaseId
        ? this.props.diffCaseId
        : this.props.currentCase.id;

      const diffChildCaseId = this.props.diffChildCaseId
        ? this.props.diffChildCaseId
        : this.props.currentCase.id;

      const simulationJobId = this.props.caseDetailsDto
        .find((x) => {
          return x.id === diffCaseId;
        })
        .simulationJobs.find((y) => {
          return y.progress === 100;
        }).id;

      const request = {
        projectId: this.props.loadedProject.id,
        caseId: diffCaseId,

        simulationJobId: simulationJobId
          ? simulationJobId
          : this.props.postProcessingJobs[0].requestId,
        settings: {
          task: "difference",
          caseId2: diffChildCaseId,
          layerId: this.state.newLayerName.replace(/ +/g, "_"),
          layerName: this.state.newLayerName,
          pageName1: this.props.diffPage,
          dataLayerId1: this.props.diffLayer,
          geotiffurl1: geotiffurl1,
          pageName2: this.props.diffChildPage,
          dataLayerId2: this.props.diffChildLayer,
          geotiffurl2: geotiffurl2,
          diffType: this.props.diffType,
          diffUnit: this.props.diffUnit,
          crs: this.props.epsg,
        },
      };

      console.log(JSON.stringify(request, null, 2));
      await requestNewCustomLayer(request);

      return;
    } else if (this.props.newLayerTypeField === "SpatiotemporalAnalysis") {
      let fileType = null;

      if (this.props.spatFileType === "InstantaneousMaskedOverSurface") {
        fileType = "mskRes";
      } else if (this.props.spatFileType === "AveragedMaskedOverSurface") {
        fileType = "mskResAv";
      } else if (this.props.spatFileType === "Instantaneous2DCrossSectionxy") {
        fileType = "xyRes";
      } else if (this.props.spatFileType === "Averaged2DCrossSectionxy") {
        fileType = "xyResAv";
      } else {
        fileType = "xyResAv";
      }

      const ncFile = this.props.simulationJobs[0].results.find((x) => {
        if (x.fileType === fileType) {
          return x.filePath;
        }
      });

      let spatCoords = null;
      let spatAreaIndex = this.props.spatAreaOptions.findIndex(
        (x) => x === this.props.spatArea
      );

      if (spatAreaIndex !== 0) {
        spatCoords = [];
        spatCoords = this.props.allDrawnAoiPolygonsPointsUtm[
          spatAreaIndex - 1
        ].map((x) => {
          return [x.lat, x.lng];
        });
      }

      const request = {
        projectId: this.props.loadedProject.id,
        caseId: this.props.currentCase.id,
        simulationJobId: this.props.simulationJobs[0].id
          ? this.props.simulationJobs[0].id
          : this.props.postProcessingJobs[0].requestId,
        task: "horizontalscalarfield",
        settings: {
          task: "spatiotemp",
          layerId: this.state.newLayerName.replace(/ +/g, "_"),
          layerName: this.state.newLayerName,
          spatParams: this.props.spatParams,
          spatHeightFrom: this.props.spatHeightFrom.index
            ? this.props.spatHeightFrom.index + 1
            : 1,
          spatHeightTo: this.props.spatHeightTo.index
            ? this.props.spatHeightTo.index + 1
            : 1,
          spatTimeFrom: this.props.spatTimeFrom.index
            ? this.props.spatTimeFrom.index + 1
            : 1,
          spatTimeTo: this.props.spatTimeTo.index
            ? this.props.spatTimeTo.index + 1
            : 1,
          spatArea: this.props.spatArea,
          spatCoords: spatCoords,
          spatProcessing: this.props.spatProcessing,
          fileType: this.props.spatFileType,
          customNcFile: ncFile.filePath,
          //fileType: "Averaged2DCrossSectionxy",
          crs: this.props.epsg,
        },
      };
      console.log(JSON.stringify(request, null, 2));
      await requestNewCustomLayer(request);

      return;
    } else if (this.props.newLayerTypeField === "Windfield") {
      let fileType = null;

      if (this.props.windfieldFileType === "InstantaneousMaskedOverSurface") {
        fileType = "mskRes";
      } else if (this.props.windfieldFileType === "AveragedMaskedOverSurface") {
        fileType = "mskResAv";
      } else if (
        this.props.windfieldFileType === "Instantaneous2DCrossSectionxy"
      ) {
        fileType = "xyRes";
      } else if (this.props.windfieldFileType === "Averaged2DCrossSectionxy") {
        fileType = "xyResAv";
      } else {
        fileType = "mskResAv";
      }

      const ncFile = this.props.simulationJobs[0].results.find((x) => {
        if (x.fileType === fileType) {
          return x.filePath;
        }
      });
      const request = {
        projectId: this.props.loadedProject.id,
        caseId: this.props.currentCase.id,
        simulationJobId: this.props.simulationJobs[0].id
          ? this.props.simulationJobs[0].id
          : this.props.postProcessingJobs[0].requestId,
        settings: {
          task:
            this.props.windfieldParams === "Direction"
              ? "create_horizontal_wind_dir"
              : "create_horizontal_wind_stream",
          layerId: this.state.newLayerName.replace(/ +/g, "_"),
          layerName: this.state.newLayerName,
          params: this.props.windfieldParams,
          heightLevel: this.props.windfieldHeight.index
            ? this.props.windfieldHeight.index + 1
            : 1,
          timestep: this.props.windfieldTimestep.index
            ? this.props.windfieldTimestep.index + 1
            : 1,
          fileType: this.props.windfieldFileType,
          customNcFile: ncFile.filePath,
          ncStatic: this.props.currentCase?.staticDriverJob?.resultUrl,
          crs: this.props.epsg,
        },
      };
      console.log(JSON.stringify(request, null, 2));
      await requestNewCustomLayer(request);

      return;
    } else if (this.props.newLayerTypeField === "Isolines") {
      const jsonOriginPartSelected = cloneDeep(
        this.props.jsonOriginPartSelected
      );
      jsonOriginPartSelected.id = this.state.newLayerName;
      jsonOriginPartSelected.name = this.state.newLayerName;
      jsonOriginPartSelected.type = "isolines";

      jsonOriginPartSelected.iso_breaks = "isolines";
      jsonOriginPartSelected.iso_color = "black";
      jsonOriginPartSelected.iso_opacity = 1;
      this.props.addNewLayer(jsonOriginPartSelected);
      this.setState({ isProcessing: false });

      return;
    }

    const layerSettingsFormDataValue = cloneDeep(
      this.props.layerSettingsFormDataValue
    );

    const newLayerName = cloneDeep(this.state.newLayerName);

    const exclbldgs =
      layerSettingsFormDataValue.exclbldgs === "no" ? false : true;
    const newLayerBodyRequest = {
      projectId: this.props.loadedProject.id,
      caseId: this.props.currentCase.id,
      simulationJobId: this.props.simulationJobs[0].id
        ? this.props.simulationJobs[0].id
        : this.props.postProcessingJobs[0].requestId,
      settings: {
        layerId: newLayerName.replace(/ +/g, "_"),
        layerName: this.state.newLayerName,
        resultVariable: layerSettingsFormDataValue.resultsVariable, //palmId layerSettingsJson
        heightLevel: layerSettingsFormDataValue.heightsIndex
          ? layerSettingsFormDataValue.heightsIndex + 1
          : 1,
        timestep: layerSettingsFormDataValue.timestepIndex
          ? layerSettingsFormDataValue.timestepIndex + 1
          : 1,
        excludeBuildings: exclbldgs,
        postProcessing: layerSettingsFormDataValue.processing
          ? layerSettingsFormDataValue.processing
          : "",
        crs: this.props.epsg,
      },
    };
    if (layerSettingsFormDataValue.fileType === "static") {
      newLayerBodyRequest.settings.useStatic = true;
    } else {
      newLayerBodyRequest.settings.fileType =
        layerSettingsFormDataValue.fileType;
    }

    console.log(JSON.stringify(newLayerBodyRequest, null, 2));
    await requestNewLayer(newLayerBodyRequest);
  }

  onNewLayerNameChange(e) {
    if (
      this.state.newLayerResultInfo === "LayerExceedsLimit" ||
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs
        .length === 8
    ) {
      this.setState({ newLayerResultInfo: "LayerExceedsLimit" });
      return;
    }

    let layerNameError = null;
    const layerNamesInPage = cloneDeep(this.props.resultsJsonOrigin);
    const filteredLayerNames = layerNamesInPage.pages[
      this.props.currentViewIndex
    ].lyrs.map((x) => {
      return x.name;
    });

    if (e.target.value.length < 3 || e.target.value.length > 30) {
      layerNameError = "LayerNameLengthError";
    } else if (filteredLayerNames.some((x) => x === e.target.value)) {
      layerNameError = "PleaseInsertUniqueLayerName";
    }

    this.setState({
      newLayerName: e.target.value,
      layerNameError: layerNameError,
      newLayerResultInfo: null,
    });
  }

  onCaseChange(e) {
    this.props.changeNewLayerCase(e.target.value);
  }

  onNewLayerTypeChange(e) {
    this.props.changeNewLayerType(e.target);
  }

  onNewLayerParamsChange(e) {
    this.props.changeNewLayerParams(e.target.value);
  }

  onNewLayerHeightLevelChange(e) {
    this.props.changeNewLayerHeightLevel({
      heights: e.target.value,
      heightsIndex: e.target.selectedIndex,
    });
  }

  onNewLayerExclBldgsChange(e) {
    this.props.changeNewLayerExclBldgs(e.target.value);
  }

  onNewLayerPostprocChange(e) {
    this.props.changeNewLayerPostproc(e.target.value);
  }

  onNewLayerTimestepChange(e) {
    this.props.changeNewLayerTimestep({
      timestep: e.target.value,
      timestepIndex: e.target.selectedIndex,
    });
  }

  onNewLayerTypeFieldChange(e) {
    this.props.changeNewLayerTypeField(e.target);
  }

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "95%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3
            className="raster-settings-header"
            style={{ marginBottom: "25px" }}
          >{`${t("LayerSettings")}`}</h3>
          <ResultsTextInput
            label={t("LayerName")}
            value={
              this.props.inReadOnlyLayerForm
                ? t(this.props.layerSettingsFormDataValue?.name)
                : this.state.newLayerName
            }
            onChange={this.onNewLayerNameChange}
            disabled={
              this.props.inReadOnlyLayerForm ||
              this.state.isProcessing ||
              this.state.newLayerResultInfo === "LayerExceedsLimit"
            }
          />

          <ResultsSelect
            label={t("LayerType")}
            value={this.props.newLayerTypeField}
            options={newLayerTypeField.map((x) => {
              return { label: t(`${x}`), value: x };
            })}
            disabled={this.props.inReadOnlyLayerForm}
            onChange={(e) => this.props.changeNewLayerTypeField(e.target.value)}
            optionsObject
          />
          {this.state.layerNameError && (
            <p className="new-layer-name-error" style={{ marginBottom: "0px" }}>
              {t(this.state.layerNameError)}
            </p>
          )}
          <hr className="raster-settings-ruler" style={{ width: "300px" }} />

          {this.props.newLayerTypeField === "Scalar" && (
            <>
              <h4
                className="raster-settings-header"
                style={{ marginBottom: "25px" }}
              >{`${t("DataSelection")}`}</h4>
              {this.props.caseDetailsDto && (
                <ResultsSelect
                  label={t("Case")}
                  value={this.props.currentCase.name}
                  options={[this.props.currentCase.name]}
                  disabled={this.props.inReadOnlyLayerForm}
                  onChange={this.onCaseChange}
                />
              )}

              {this.props.layerSettingsFormDataValue?.type && (
                <ResultsSelect
                  label={t("FileType")}
                  value={this.props.layerSettingsFormDataValue?.type}
                  options={
                    this.props.inReadOnlyLayerForm
                      ? this.props.layerSettingsFormDataValue?.type
                      : this.props.layerSettingsTypes
                  }
                  onChange={this.onNewLayerTypeChange}
                  disabled={this.props.inReadOnlyLayerForm}
                />
              )}

              {this.props.layerSettingsFormDataValue?.params && (
                <ResultsSelect
                  label={t("Parameter")}
                  value={this.props.layerSettingsFormDataValue?.params}
                  options={
                    this.props.inReadOnlyLayerForm
                      ? this.props.layerSettingsFormDataOptions?.lankey
                      : this.props.layerSettingsFormDataOptions?.params
                  }
                  onChange={this.onNewLayerParamsChange}
                  disabled={this.props.inReadOnlyLayerForm}
                />
              )}

              {this.props.layerSettingsFormDataValue?.heights && (
                <ResultsSelect
                  label={t("Height")}
                  value={this.props.layerSettingsFormDataValue?.heights}
                  options={this.props.layerSettingsFormDataOptions?.heights}
                  disabled={this.props.inReadOnlyLayerForm}
                  onChange={this.onNewLayerHeightLevelChange}
                />
              )}

              {this.props.layerSettingsFormDataOptions?.exclbldgs && (
                <ResultsSelect
                  label={t("Exclbldgs")}
                  value={this.props.layerSettingsFormDataValue?.exclbldgs}
                  options={this.props.layerSettingsFormDataOptions.exclbldgs}
                  disabled={this.props.inReadOnlyLayerForm}
                  onChange={this.onNewLayerExclBldgsChange}
                />
              )}

              {this.props.layerSettingsFormDataOptions?.processing && (
                <ResultsSelect
                  label={t("Postprocessing")}
                  value={this.props.layerSettingsFormDataValue?.processing}
                  options={this.props.layerSettingsFormDataOptions?.processing}
                  disabled={this.props.inReadOnlyLayerForm}
                  onChange={this.onNewLayerPostprocChange}
                />
              )}

              {this.props.layerSettingsFormDataValue?.timestep !== null && (
                <ResultsSelect
                  label={t("Timestep")}
                  value={this.props.layerSettingsFormDataValue?.timestep}
                  options={this.props.layerSettingsFormDataOptions?.timestep}
                  disabled={this.props.inReadOnlyLayerForm}
                  onChange={this.onNewLayerTimestepChange}
                />
              )}
            </>
          )}

          {this.props.newLayerTypeField === "Difference" && (
            <DifferenceLayerSettingsForm />
          )}

          {this.props.newLayerTypeField === "SpatiotemporalAnalysis" && (
            <SpatiotemporalAnalysisForm />
          )}
          {this.props.newLayerTypeField === "CustomCategory" && (
            <CustomCategory />
          )}
          {this.props.newLayerTypeField === "Windfield" && <Windfield />}
          {this.props.newLayerTypeField === "Isolines" && <Isolines />}

          {this.state.isProcessing && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // marginLeft: "-63px",
                }}
              >
                <Loader size="small" />
              </div>
              <p
                style={{
                  fontSize: "13px",
                  cursor: "default",
                  textAlign: "center",
                }}
              >
                {t("Processing")}...
              </p>
            </>
          )}

          {!this.state.isProcessing && this.state.newLayerResultInfo && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {this.state.newLayerResultInfo === "NewLayerHasBeenCreated" ? (
                <CheckCircle size="16px" color="rgb(6, 137, 108)" />
              ) : (
                <AlertCircle size="16px" color="#ac4141" />
              )}
              <p
                className={`new-layer-name-error ${
                  this.state.newLayerResultInfo === "NewLayerHasBeenCreated"
                    ? "new-layer-success"
                    : ""
                }`}
              >
                {t(this.state.newLayerResultInfo)}
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {!this.props.inReadOnlyLayerForm && (
            <button
              className="raster-settings-btn"
              onClick={this.createNewLayer}
              disabled={
                this.state.newLayerName.length < 3 ||
                this.state.layerNameError ||
                this.state.isProcessing ||
                this.state.newLayerResultInfo === "LayerExceedsLimit"
              }
            >
              <div className="btn-content-wrapper">
                <PlusCircle
                  color={"#494949"}
                  size={"16px"}
                  strokeWidth={"1.8px"}
                />
                <div className="raster-btn-lbl">
                  {this.props.inCreateLayerForm
                    ? t(`CreateNewLayer`)
                    : t(`SaveExistingLayer`)}
                </div>
              </div>
            </button>
          )}
          <button
            className="raster-settings-btn"
            onClick={
              this.props.inCreateLayerForm
                ? this.props.toggleCreateLayerForm
                : this.props.toggleReadOnlyLayerForm
            }
            disabled={this.state.isProcessing}
          >
            <div className="btn-content-wrapper">
              <ArrowLeftCircle
                color={"#494949"}
                size={"16px"}
                strokeWidth={"2px"}
              />
              <div className="raster-btn-lbl">{t("Back")}</div>
            </div>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layerSettingsTypes: state.results.layerSettingsTypes,
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    inCreateLayerForm: state.results.inCreateLayerForm,
    layerSettingsFormDataOptions: state.results.layerSettingsFormDataOptions,
    loadedProject: state.projects.loadedProject,
    caseDetailsDto: state.projects.loadedProject?.caseDetailsDto,
    layerSettingsFormDataValue: state.results.layerSettingsFormDataValue,
    inReadOnlyLayerForm: state.results.inReadOnlyLayerForm,
    postProcessingJobs: state.projects.currentCase?.postProcessingJobs,
    simulationJobs: state.projects.currentCase?.simulationJobs,
    postprocessNotifications: state.notifications?.postprocessNotifications,
    currentViewIndex: state.results.currentViewIndex,
    currentCase: state.projects.currentCase,
    newLayerTypeField: state.results.newLayerTypeField,
    catCustomPage: state.results.catCustomPage,
    catCustomPageIndex: state.results.catCustomPageIndex,
    catCustomLayer: state.results.catCustomLayer,
    catCustomLayerIndex: state.results.catCustomLayerIndex,
    catCustomNr: state.results.catCustomNr,
    catCustomColorPalette: state.results.catCustomColorPalette,
    isCatCustomColorPaletteInverted:
      state.results.isCatCustomColorPaletteInverted,

    diffCaseResultsJsonOrigin: state.results.diffCaseResultsJsonOrigin,
    diffChildCaseResultsJsonOrigin:
      state.results.diffChildCaseResultsJsonOrigin,
    diffCase: state.results.diffCase,
    diffCaseId: state.results.diffCaseId,
    diffPage: state.results.diffPage,
    diffPageIndex: state.results.diffPageIndex,
    diffChildCaseId: state.results.diffChildCaseId,
    diffChildPage: state.results.diffChildPage,
    diffChildPageIndex: state.results.diffChildPageIndex,
    diffLayer: state.results.diffLayer,
    diffLayerIndex: state.results.diffLayerIndex,
    diffChildLayer: state.results.diffChildLayer,
    diffChildLayerIndex: state.results.diffChildLayerIndex,
    diffType: state.results.diffType,
    diffUnit: state.results.diffUnit,

    spatFileType: state.results.spatFileType,
    spatParams: state.results.spatParams,
    spatHeightFrom: state.results.spatHeightFrom,
    spatHeightTo: state.results.spatHeightTo,
    spatTimeFrom: state.results.spatTimeFrom,
    spatTimeTo: state.results.spatTimeTo,
    spatArea: state.results.spatArea,
    spatAreaOptions: state.results.spatAreaOptions,
    spatProcessing: state.results.spatProcessing,

    arrayOfDrawnAoiLayerName: state.results.arrayOfDrawnAoiLayerName,
    allDrawnAoiPolygonsPointsUtm: state.results.allDrawnAoiPolygonsPointsUtm,

    windfieldFileType: state.results.windfieldFileType,
    windfieldParams: state.results.windfieldParams,
    windfieldHeight: state.results.windfieldHeight,
    windfieldTimestep: state.results.windfieldTimestep,

    staticDriverJob: state.projects.currentCase.staticDriverJob,
    epsg: state.projects.loadedProject.epsg,
    jsonOriginPartSelected: state.results.jsonOriginPartSelected,
    leafletSet: state.results.leafletSet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleReadOnlyLayerForm: () => dispatch(toggleReadOnlyLayerForm()),
    toggleCreateLayerForm: () => dispatch(toggleCreateLayerForm()),
    changeNewLayerCase: (payload) => dispatch(changeNewLayerCase(payload)),
    changeNewLayerType: (payload) => dispatch(changeNewLayerType(payload)),
    changeNewLayerParams: (payload) => dispatch(changeNewLayerParams(payload)),
    changeNewLayerHeightLevel: (payload) =>
      dispatch(changeNewLayerHeightLevel(payload)),
    changeNewLayerExclBldgs: (payload) =>
      dispatch(changeNewLayerExclBldgs(payload)),
    changeNewLayerPostproc: (payload) =>
      dispatch(changeNewLayerPostproc(payload)),
    changeNewLayerTimestep: (payload) =>
      dispatch(changeNewLayerTimestep(payload)),
    addNewLayer: (payload) => dispatch(addNewLayer(payload)),
    changeNewLayerTypeField: (payload) =>
      dispatch(changeNewLayerTypeField(payload)),
    resetNewLayerSettings: () => dispatch(resetNewLayerSettings()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LayerSettingsForm)
);
