import React from "react";
import Page from "../../Components/Page/Page";
import ibp_Fraunhofer from "./ibp_Fraunhofer.gif";
import ProPolisLogo from "./ProPolisLogo.png";
import Logo_ECSD from "./Logo_ECSD.jpg";
import BMBF_en from "../Projects/BMBF_en.jpg";
import BMBF_de from "../Projects/BMBF_de.jpg";
import Fona_en from "./Fona_en.png";
import Fona_de from "./Fona_de.png";
import DLR from "./DLR.jfif";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import "./About.scss";

const GNU_LICENSE_LINK =
  "https://www.gnu.org/licenses/agpl-3.0.html.en#license-text";
const GITLAB_REPOSITORY_LINK =
  "https://gitlab.cc-asp.fraunhofer.de/palm_gui/palm4u_gui";
const PRO_POLIS_LINK = "https://propolis.azurewebsites.net/";

export const LinkText = (props) => {
  return (
    <a
      href={props.to || "#"}
      target="_blank"
      rel="noopener noreferrer"
      title={props.title || ""}
    >
      {props.children}
    </a>
  );
};

const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <Page>
      <h1 className="settings-hdr">{t("About")}</h1>
      <div className="about-info">
        <Trans
          i18nKey="AboutDevelopment"
          components={{
            link1: <LinkText to={GNU_LICENSE_LINK} title="My link1" />,
            link2: (
              <LinkText to={GITLAB_REPOSITORY_LINK} title="Another link" />
            ),
          }}
        />
      </div>
      <div className="sponsor-img-section sponsor-img-section-gap">
        <img src={ibp_Fraunhofer} height={50} alt={"ibp_img"} />
        <img src={Logo_ECSD} height={70} alt={"ecsd_img"} />
        {/* <img
          src={i18n.language === "en-US" ? Logo_GERICS_en : Logo_GERICS_de}
          height={70}
          alt={"flag_img"}
        /> */}
      </div>
      <h2 className="settings-hdr">{t("Acknowledgements")}</h2>
      <div className="about-info">{t(`AboutMinistry`)}</div>
      <div className="sponsor-img-section">
        <img
          src={ProPolisLogo}
          height={70}
          alt={"pro_polis_logo"}
          style={{ marginRight: "60px" }}
        />
        <img
          className="about-img"
          src={i18n.language === "en-US" ? BMBF_en : BMBF_de}
          alt={"min_img"}
        />
        <img
          className="about-img"
          src={i18n.language === "en-US" ? Fona_en : Fona_de}
          alt={"fon"}
          style={{ marginRight: "24px" }}
        />
        <div className="dlr-img-wrapper">
          <img className="about-img dlr-img" src={DLR} alt={"dlr_img"} />
        </div>
      </div>
    </Page>
  );
};

export default withRouter(About);
