import React from "react";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import { GeotiffLayer } from "./GeotiffLayer";
import * as plotty from "leaflet-geotiff-2/node_modules/plotty";
import { palettes } from "../../../common/VisualizationPresets/colorPalettes";
import geoblaze from "geoblaze";
import { connect } from "react-redux";
import { getDownloadLink } from "../../../api/storageApi";
import {
  hideTopography,
  getLegendData,
} from "../../../redux/actions/legendActions";

class TopographyWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    if (!!this.props.currentCase.topographyUrl) {
      const topographyUrl = await getDownloadLink(
        this.props.currentCase.topographyUrl
      );
      const nameOfElevationStyle = this.props.inputElevationPalette;
      const elevationPallete =
        palettes.colors.elevation[`${nameOfElevationStyle}`];
      plotty.addColorScale(
        `${nameOfElevationStyle}`,
        elevationPallete,
        [0, 0.12, 0.24, 0.36, 0.48, 0.6, 0.72, 0.84, 1]
      );
      const raster = await geoblaze.load(topographyUrl);
      let maxElevation = ~~geoblaze.max(raster)[0] + 1;
      const minElevation = ~~geoblaze.min(raster)[0] - 1;
      const remainder = (maxElevation - minElevation) % 9;
      maxElevation += 9 - remainder;
      this.props.getLegendData({
        topographyUrl,
        maxElevation,
        minElevation,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.topographyVisibility) {
      this.props.hideTopography();
    }
  }

  render() {
    return (
      <>
        {!!this.props.currentCase.topographyUrl &&
          this.props.topographyVisibility && (
            <GeotiffLayer
              topographyUrl={this.props.topographyUrl}
              nameOfElevationPallete={this.props.inputElevationPalette}
              maxElevation={this.props.maxElevation}
              minElevation={this.props.minElevation}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCase: state.projects.currentCase,
    topographyUrl: state.legend.topographyUrl,
    topographyVisibility: state.legend.topographyVisibility,
    inputElevationPalette:
      state.userSettings?.settings?.visualizationPresets
        ?.inputElevationPalette || palettes.defaults.elevation,
    maxElevation: state.legend.maxElevation,
    minElevation: state.legend.minElevation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideTopography: () => dispatch(hideTopography()),
    getLegendData: (payload) => dispatch(getLegendData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopographyWrapper);
