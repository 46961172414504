import React from "react";
import { LayerSettingsInput } from "../../../../Components/Common/Form/Form";
import { Box } from "../../../../Components/Map/Form/Box";
import { Trash, MapPin } from "react-feather";
import { withTranslation } from "react-i18next";
import {
  selectPoi,
  removePoi,
  updatePoiPositionOnInputChange,
  updateTempPoiInput,
  onLayerNameChange,
} from "../../../../redux/actions/resultsActions";
import { connect } from "react-redux";
import "../StaticDriver.scss";

class PoiEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value;
    const name = e.target.name;
    if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue.slice(-1) === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "0"
      ) {
        this.props.updateTempPoiInput({
          tempPoiKey: name,
          tempPoiValue: inputValue,
        });
      } else {
        this.props.updatePoiPositionOnInputChange({
          selectedPoiIndex: this.props.selectedPoiIndex,
          e: e,
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="tree-editor-container" style={{ width: "100%" }}>
        <h3 className="tree-editor-hdr">{t("AddPOIByClickingOnMap")}</h3>
        <Box objectType={"point_of_interest"} />
        <div
          className="tree-coord-label-container"
          style={{
            marginTop: "20px",
            marginLeft: "-10px",
            marginRight: "-10px",
          }}
        >
          <div
            style={{ width: "30px", fontSize: "12px", letterSpacing: "0.2px" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "85px",
            }}
          >
            <label>{t("Easting")}</label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "106px",
            }}
          >
            <label> {t("Northing")}</label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "130px",
            }}
          >
            <label>{t("PoiName")}</label>
          </div>
        </div>

        {this.props.allDrawnPoiUtm.length !== 0 &&
          this.props.allDrawnPoiUtm.map((poi, i) => {
            return (
              <div
                className="tree-coords-line"
                style={{
                  justifyContent: "space-between",
                  marginLeft: "-10px",
                  marginRight: "-10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "4px",
                    }}
                  >
                    <MapPin
                      size="12px"
                      color={
                        this.props.selectedPoiIndex === i ? "#c21aa8ff" : "gray"
                      }
                      strokeWidth={"1.7px"}
                    />
                    <label
                      className="tree-index"
                      style={{ color: "gray" }}
                    >{`${i}`}</label>
                  </div>
                  <input
                    className="tree-editor-coord-row"
                    placeholder="Longitude"
                    name="lng"
                    key={`lng${i}`}
                    style={{ width: "85px" }}
                    value={
                      this.props.selectedPoiIndex === i &&
                      this.props.tempPoiKey === "lng" &&
                      (this.props.tempPoiValue ||
                        this.props.tempPoiValue === "")
                        ? this.props.tempPoiValue
                        : poi.lng
                    }
                    onChange={(e) => this.handleInputChange(e)}
                    onFocus={() => this.props.selectPoi(i)}
                    onBlur={() =>
                      this.props.updateTempPoiInput({
                        tempPoiValue: "",
                        tempPoiKey: "",
                      })
                    }
                  />
                </div>
                <input
                  className="tree-editor-coord-row"
                  placeholder="Lattitude"
                  key={`lat${i}`}
                  name="lat"
                  style={{ width: "85px" }}
                  value={
                    this.props.selectedPoiIndex === i &&
                    this.props.tempPoiKey === "lat" &&
                    (this.props.tempPoiValue || this.props.tempPoiValue === "")
                      ? this.props.tempPoiValue
                      : poi.lat
                  }
                  onChange={(e) => this.handleInputChange(e)}
                  onFocus={() => this.props.selectPoi(i)}
                  onBlur={() =>
                    this.props.updateTempPoiInput({
                      tempPoiValue: "",
                      tempPoiKey: "",
                    })
                  }
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <LayerSettingsInput
                    name={"poi"}
                    value={poi.pointName}
                    onChange={(e) =>
                      this.props.onLayerNameChange({
                        inputValue: e.target.value,
                        nameIndex: i,
                      })
                    }
                    onFocus={() => this.props.selectPoi(i)}
                    style={{
                      height: "27px",
                      width: "130px",
                      color: "black",
                    }}
                  />
                  <button
                    className="results-form-btn"
                    onClick={() => this.props.removePoi(i)}
                    title={t("DeletePOI")}
                  >
                    <Trash
                      size="16px"
                      color={
                        this.props.selectedPoiIndex === i ? "#cc8585" : "gray"
                      }
                      strokeWidth={"1.7px"}
                    />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDrawnPoiUtm: state.results.allDrawnPoiUtm,
    selectedPoiIndex: state.results.selectedPoiIndex,
    tempPoiKey: state.results.tempPoiKey,
    tempPoiValue: state.results.tempPoiValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectPoi: (payload) => dispatch(selectPoi(payload)),
    removePoi: (payload) => dispatch(removePoi(payload)),
    updatePoiPositionOnInputChange: (payload) =>
      dispatch(updatePoiPositionOnInputChange(payload)),
    updateTempPoiInput: (payload) => dispatch(updateTempPoiInput(payload)),
    onLayerNameChange: (payload) => dispatch(onLayerNameChange(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PoiEditor)
);
