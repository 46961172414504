import React from "react";
import { Box } from "../../Form/Box";
import { Database, HelpCircle } from "react-feather";
import { withTranslation } from "react-i18next";
import {
  selectWindTurbine,
  removeWindTurbine,
  updateWindTurbinePositionOnInputChange,
  goToWindTurbineProperties,
  updateTempInput,
  updatePolygonData,
} from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";
import "../../TreeEditor/TreeEditor.scss";

class WindTurbineEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleLiveInput = this.handleLiveInput.bind(this);
  }

  handleLiveInput(e) {
    const reg = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    const inputValue = e.target.value;
    const name = e.target.name;

    if (e.target.name === "wtname") {
      this.props.updateTempInput({
        tempKey: name,
        tempValue: inputValue,
      });
    } else if (reg.test(inputValue) || inputValue === "") {
      if (
        inputValue.slice(-1) === "" ||
        inputValue.slice(-1) === "." ||
        inputValue.slice(-1) === "0" ||
        (name === "lat" && (inputValue < 0 || inputValue > 10000000)) ||
        (name === "lng" && (inputValue < 0 || inputValue > 1000000))
      ) {
        this.props.updateTempInput({
          tempKey: name,
          tempValue: inputValue,
        });
      } else {
        this.props.updateWindTurbinePositionOnInputChange({
          windTurbineIndex: this.props.selectedWindTurbineIndex,
          e: e,
        });
      }
    }
  }

  handleInputChange = (e) => {
    const inputValue = e.target;
    if (inputValue.id === "wtname" && inputValue.value !== "") {
      this.props.updatePolygonData({
        key: inputValue.id,
        value: inputValue.value,
      });
    }
    this.props.updateTempInput({ tempKey: "", tempValue: "" });
  };

  render() {
    const { t } = this.props;
    return (
      <div
        className="tree-editor-container"
        style={{ width: "auto", padding: 0 }}
      >
        <h3 className="tree-editor-hdr">
          {t("AddWindTurbineByClickingOnMap")}
        </h3>
        <Box objectType={"wind_turbine"} />
        {this.props.allDrawnWindTurbineCoordinatesUtm.length > 0 && (
          <div
            className="tree-coord-label-container"
            style={{
              marginLeft: "33px",
              minWidth: "295px",
              maxWidth: "295px",
              justifyContent: "space-around",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className="long-icon minified-icon"
                style={{
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              />
              <div>
                <label style={{ marginLeft: "4px" }}>{t("Easting")}</label>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="lat-icon minified-icon"
                style={{
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              />
              <label style={{ marginLeft: "4px" }}>{t("Northing")}</label>
            </div>
            <div style={{ display: "flex" }}>
              <div className="name-icon"></div>
              <label style={{ marginLeft: "4px" }}>{t("Name")}</label>
            </div>
          </div>
        )}

        {this.props.allDrawnWindTurbineCoordinatesUtm?.map((x, i) => {
          return (
            <div className="tree-coords-line">
              <div
                className={`tree-icon wind-turbine-icon ${
                  this.props.selectedWindTurbineIndex === i
                    ? "tree-icon wind-turbine-highlighted-icon"
                    : ""
                }`}
              />
              <label className="tree-index">{`${i}`}</label>
              <input
                className="tree-editor-coord-row"
                style={{ width: "82px" }}
                placeholder="Longitude"
                name="lng"
                value={
                  this.props.selectedWindTurbineIndex === i &&
                  this.props.tempKey === "lng" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : x.lng
                }
                onChange={(e) => this.handleLiveInput(e)}
                onFocus={() => this.props.selectWindTurbine(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />

              <input
                className="tree-editor-coord-row"
                style={{ width: "82px" }}
                placeholder="Lattitude"
                name="lat"
                value={
                  this.props.selectedWindTurbineIndex === i &&
                  this.props.tempKey === "lat" &&
                  (this.props.tempValue || this.props.tempValue === "")
                    ? this.props.tempValue
                    : x.lat
                }
                onChange={(e) => this.handleLiveInput(e)}
                onFocus={() => this.props.selectWindTurbine(i)}
                onBlur={() =>
                  this.props.updateTempInput({ tempValue: "", tempKey: "" })
                }
              />
              <input
                className="tree-editor-coord-row"
                style={{ width: "82px" }}
                placeholder="Name"
                id="wtname"
                name="wtname"
                value={
                  this.props.selectedWindTurbineIndex === i &&
                  this.props.tempValue &&
                  this.props.tempKey === "wtname"
                    ? this.props.tempValue
                    : this.props.polygonData.find((y) => {
                        return y?.id === x.id;
                      })?.wtname
                    ? this.props.polygonData.find((y) => {
                        return y?.id === x.id;
                      })?.wtname
                    : this.props.allDrawnWindTurbineGeoProps.find((y) => {
                        return y?.id === x.id;
                      })?.wtname
                }
                onChange={(e) => this.handleLiveInput(e)}
                onFocus={() => this.props.selectWindTurbine(i)}
                onBlur={(e) => this.handleInputChange(e)}
              />

              <button
                className="tree-form-btn"
                title={t("GoToWindTurbineParametrization")}
                onClick={() => {
                  this.props.goToWindTurbineProperties(i);
                }}
              >
                <Database size="16px" color="Gray" strokeWidth={"1.7px"} />
              </button>

              <button
                className="remove-tree-btn"
                onClick={() => this.props.removeWindTurbine(i)}
                title={t("DeleteHome")}
              >
                X
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDrawnWindTurbineCoordinatesUtm:
      state.map.allDrawnWindTurbineCoordinatesUtm,
    selectedWindTurbineIndex: state.map.selectedWindTurbineIndex,
    allDrawnWindTurbineGeoProps: state.map.allDrawnWindTurbineGeoProps,
    tempKey: state.map.tempKey,
    tempValue: state.map.tempValue,
    polygonData: state.map.polygonData,
    selectedMapItemGeoProps: state.map.selectedMapItemGeoProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectWindTurbine: (payload) => dispatch(selectWindTurbine(payload)),
    removeWindTurbine: (payload) => dispatch(removeWindTurbine(payload)),
    updateWindTurbinePositionOnInputChange: (payload) =>
      dispatch(updateWindTurbinePositionOnInputChange(payload)),
    goToWindTurbineProperties: (payload) =>
      dispatch(goToWindTurbineProperties(payload)),
    updateTempInput: (payload) => dispatch(updateTempInput(payload)),
    updatePolygonData: (payload) => dispatch(updatePolygonData(payload)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WindTurbineEditor)
);
