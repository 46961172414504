import React from "react";
import ActionButton from "./ActionButton";
import { connect } from "react-redux";
import { uploadBlobToAzure } from "../../../api/storageApi";
import { wgDef } from "../../../common/utmDef";
import { reproject } from "reproject";
import { withRouter } from "react-router";
import gp from "geojson-precision";
import { selectCaseSimulationNotifications } from "../../../redux/selectors/simulationSelector";
import {
  createSingleTreeGeoJson,
  setDrawnPolygonGeojsonCrs,
  createSingleEmissionDrawnGeoJson,
  createSingleMapPoiDrawnGeoJson,
  createSingleWindTurbineDrawnGeoJson,
  setMapDataSavingStatus,
} from "../../../redux/actions/mapActions";
import { setCaseDrawnGeojsonUrl } from "../../../redux/actions/caseActions";
import { putCaseGeo } from "../../../api/caseApi";
import { withTranslation } from "react-i18next";

class SaveActionButtonWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.saveMapObject = this.saveMapObject.bind(this);
  }

  async saveMapObject() {
    this.props.setMapDataSavingStatus("pending");
    await this.props.createSingleTreeGeoJson(this.props.loadedProject.epsg);

    await this.props.createSingleEmissionDrawnGeoJson(
      this.props.loadedProject.epsg
    );
    console.log("createSingleEmissionDrawnGeoJson");
    await this.props.createSingleMapPoiDrawnGeoJson(
      this.props.loadedProject.epsg
    );
    console.log("createSingleMapPoiDrawnGeoJson");

    await this.props.createSingleWindTurbineDrawnGeoJson(
      this.props.loadedProject.epsg
    );
    console.log("createSingleWindTurbineDrawnGeoJson");

    const caseEntity = this.props.loadedProject.caseDetailsDto.find(
      (c) => c.id === this.props.match.params.caseId
    );
    let geoJson = null;

    if (this.props.geoJsonWg?.features?.length !== 0) {
      geoJson = reproject(this.props.geoJsonWg, wgDef, this.props.crsDef);
      console.log("reproject");

      if (this.props.emissionGeoJsonWg.features.length !== 0) {
        let emissionGeoJson = reproject(
          this.props.emissionGeoJsonWg,
          wgDef,
          this.props.crsDef
        );
        geoJson.features.push(...emissionGeoJson.features);
      }
      console.log("emissionGeoJsonWg");

      if (this.props.mapPoiGeoJsonWg.features.length !== 0) {
        let mapPoiGeoJson = reproject(
          this.props.mapPoiGeoJsonWg,
          wgDef,
          this.props.crsDef
        );
        geoJson.features.push(...mapPoiGeoJson.features);
      }
      console.log("mapPoiGeoJsonWg");

      if (this.props.windTurbineGeoJsonWg.features.length !== 0) {
        let windTurbineGeoJson = reproject(
          this.props.windTurbineGeoJsonWg,
          wgDef,
          this.props.crsDef
        );
        geoJson.features.push(...windTurbineGeoJson.features);
      }
      console.log("windTurbineGeoJsonWg");

      const geoJsonUTM = gp.parse(geoJson, 2);
      await uploadBlobToAzure(
        `${caseEntity.verifiedGeojsonUrl}`,
        JSON.stringify(geoJsonUTM)
      );
    }
    console.log("geoJsonUTM");

    const drawnPolygonGeojsonWg = this.props.drawnPolygonGeojsonWg;
    drawnPolygonGeojsonWg.crs.properties.name = `urn:ogc:def:crs:EPSG::${this.props.loadedProject.epsg}`;
    console.log("drawnPolygonGeojsonWg");

    const drawnPolygonGeoJsonConv = reproject(
      drawnPolygonGeojsonWg,
      wgDef,
      this.props.crsDef
    );
    const drawnPolygonGeoJsonUtm = gp.parse(drawnPolygonGeoJsonConv, 2);
    console.log("drawnPolygonGeoJsonConv");

    if (this.props.treeGeoJsonUtm.length !== 0) {
      this.props.treeGeoJsonUtm.features.map((x) => {
        return drawnPolygonGeoJsonUtm.features.push(x);
      });
    }
    console.log("treeGeoJsonUtm");

    if (this.props.emissionDrawnGeoJsonUtm.features.length !== 0) {
      this.props.emissionDrawnGeoJsonUtm.features.map((x) => {
        return drawnPolygonGeoJsonUtm.features.push(x);
      });
    }
    console.log(this.props.emissionDrawnGeoJsonUtm);
    console.log("emissionDrawnGeoJsonUtm");
    if (
      this.props.mapPoiDrawnGeoJsonUtm &&
      this.props.mapPoiDrawnGeoJsonUtm.features.length !== 0
    ) {
      this.props.mapPoiDrawnGeoJsonUtm.features.map((x) => {
        return drawnPolygonGeoJsonUtm.features.push(x);
      });
    }
    console.log("mapPoiDrawnGeoJsonUtm");

    if (
      this.props.windTurbineDrawnGeoJsonUtm &&
      this.props.windTurbineDrawnGeoJsonUtm.features.length !== 0
    ) {
      this.props.windTurbineDrawnGeoJsonUtm.features.map((x) => {
        return drawnPolygonGeoJsonUtm.features.push(x);
      });
    }
    console.log(this.props.windTurbineDrawnGeoJsonUtm);
    console.log("windTurbineDrawnGeoJsonUtm");

    const drawnGeojsonUrl = `${this.props.loadedProject.id}/${caseEntity.id}/drawnGeojsonUrl.json`;
    const polygonDataUrl = `${this.props.loadedProject.id}/${caseEntity.id}/polygonData.json`;

    await uploadBlobToAzure(
      drawnGeojsonUrl,
      JSON.stringify(drawnPolygonGeoJsonUtm)
    );

    await uploadBlobToAzure(
      polygonDataUrl,
      JSON.stringify(this.props.polygonData)
    );
    console.log("uploadBlobToAzure");

    const request = {
      polygonDataUrl: polygonDataUrl,
      drawnGeojsonUrl: drawnGeojsonUrl,
    };
    console.log("request");

    if (this.props.geoJsonWg.features.length !== 0) {
      request.geojsonUrl = `${caseEntity.verifiedGeojsonUrl}`;
    } else {
      request.geojsonUrl = "";
    }
    console.log("geojsonUrl");

    await putCaseGeo(caseEntity.id, request);
    this.props.setCaseDrawnGeojsonUrl({
      drawnGeojsonUrl: drawnGeojsonUrl,
      polygonDataUrl: polygonDataUrl,
    });
    console.log("putCaseGeo");

    this.props.setDrawnPolygonGeojsonCrs(this.props.loadedProject.epsg);
    console.log("setDrawnPolygonGeojsonCrs");

    this.props.setMapDataSavingStatus("success");
    setTimeout(() => {
      this.props.setMapDataSavingStatus("false");
    }, 10000);
  }

  render() {
    const { t } = this.props;

    return (
      <ActionButton
        icon="save"
        onClick={this.saveMapObject}
        title={t("SaveData")}
        disabled={
          this.props.simulationJobs?.length ||
          !!this.props.simulationNotifications
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geoJsonWg: state.map.geoJsonWg,
    drawnPolygonGeojsonWg: state.map.drawnPolygonGeojsonWg,
    polygonData: state.map.polygonData,
    loadedProject: state.projects.loadedProject,
    treeGeoJsonUtm: state.map.treeGeoJsonUtm,
    crsDef: state.map.crsDef,
    simulationNotifications: selectCaseSimulationNotifications(state),
    simulationJobs: state.projects.currentCase?.simulationJobs,
    emissionDrawnGeoJsonUtm: state.map.emissionDrawnGeoJsonUtm,
    mapPoiDrawnGeoJsonUtm: state.map.mapPoiDrawnGeoJsonUtm,
    currentCase: state.projects.currentCase,
    emissionGeoJsonWg: state.map.emissionGeoJsonWg,
    mapPoiGeoJsonWg: state.map.mapPoiGeoJsonWg,
    windTurbineGeoJsonWg: state.map.windTurbineGeoJsonWg,
    windTurbineDrawnGeoJsonUtm: state.map.windTurbineDrawnGeoJsonUtm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSingleTreeGeoJson: (payload) =>
      dispatch(createSingleTreeGeoJson(payload)),
    setCaseDrawnGeojsonUrl: (payload) =>
      dispatch(setCaseDrawnGeojsonUrl(payload)),
    setDrawnPolygonGeojsonCrs: (payload) =>
      dispatch(setDrawnPolygonGeojsonCrs(payload)),
    createSingleEmissionDrawnGeoJson: (payload) =>
      dispatch(createSingleEmissionDrawnGeoJson(payload)),
    createSingleMapPoiDrawnGeoJson: (payload) =>
      dispatch(createSingleMapPoiDrawnGeoJson(payload)),
    createSingleWindTurbineDrawnGeoJson: (payload) =>
      dispatch(createSingleWindTurbineDrawnGeoJson(payload)),
    setMapDataSavingStatus: (payload) =>
      dispatch(setMapDataSavingStatus(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SaveActionButtonWrapper)));
