import React from "react";
import { GeoJSON } from "react-leaflet";
import { reprojectWgToUtmPoint } from "../../../common/utmDef";
import {
  getCurrentObjectType,
  getCurrentObjectSubtype,
} from "../../../containers/Domain/geo-selectors/index";
import { stylePolygon } from "../../../utils/polygons/polygonStyle";
import { connect } from "react-redux";
import { throttle } from "lodash";
import { withTranslation } from "react-i18next";

class RasterGeoJson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onEachRasterGeoJsonFeature =
      this.onEachRasterGeoJsonFeature.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);

    this.activeTooltip = null;
  }

  componentDidMount() {
    if (
      this.props.rasterPreviewRef?.current?.leafletElement &&
      this.props.rasterPreview
    )
      this.props.rasterPreviewRef.current.leafletElement
        .clearLayers()
        .addData(this.props.rasterPreview);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.isRasterPreviewVisible &&
        this.props.isRasterPreviewVisible) ||
      (this.props.isRasterPreviewVisible &&
        this.props.rasterPreview &&
        this.props.rasterPreview !== prevProps.rasterPreview)
    ) {
      this.props.rasterPreviewRef.current.leafletElement
        .clearLayers()
        .addData(this.props.rasterPreview);
    }
  }

  onEachRasterGeoJsonFeature = (feature, layer) => {
    this.activeTooltip = null;
    const throttledMouseMove = throttle(
      this.handleMouseMove(layer, feature),
      25
    );

    layer.on("mousemove", throttledMouseMove);
    layer.on("mouseout", this.handleMouseOut(layer));
  };

  handleMouseMove = (layer, feature) => (e) => {
    const { lat, lng } = e.latlng;
    const properties = feature.properties;
    const currentCursorCoodsUtm = reprojectWgToUtmPoint(
      {
        lat: lat,
        lng: lng,
      },
      this.props.crsDef
    );

    const coordsInfoLat = Math.ceil(
      (currentCursorCoodsUtm.lat - this.props.rasterPreviewBbox[1]) /
        this.props.rasterGridWidth
    );
    const coordsInfoLng = Math.ceil(
      (currentCursorCoodsUtm.lng - this.props.rasterPreviewBbox[0]) /
        this.props.rasterGridWidth
    );

    const content =
      "<div style='z-index:100000'><div style='background:white; min-width:120px; padding:1px 3px 1px 3px'>" +
      `<div><strong> ${
        properties.t === 99
          ? "Raster filling"
          : properties.t === 98
          ? "Buffered area"
          : this.props.t(getCurrentObjectType(properties))
      }</strong>
 </div>
 ` +
      `<div> ${
        properties.t === 99 || properties.t === 98
          ? ""
          : this.props.t(getCurrentObjectSubtype(properties))
      }</div>` +
      `<div>x: ${coordsInfoLng}</div>` +
      `<div>y: ${coordsInfoLat}</div>` +
      "</div>";

    if (this.activeTooltip) {
      this.activeTooltip.unbindTooltip();
    }

    this.activeTooltip = layer
      .bindTooltip(content, {
        permanent: false,
        direction: "top",
        sticky: true,
        opacity: 0.9,
      })
      .openTooltip(e.latlng);
  };

  handleMouseOut = (layer) => () => {
    if (this.activeTooltip) {
      this.activeTooltip.unbindTooltip();
      this.activeTooltip = null;
    }
  };

  render() {
    return (
      <>
        {this.props.isRasterPreviewVisible && (
          <GeoJSON
            ref={this.props.rasterPreviewRef}
            style={(x) => {
              return stylePolygon(x, false, this.props.inputPalmTypesPalette);
            }}
            onEachFeature={this.onEachRasterGeoJsonFeature}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rasterPreview: state.rasterArea.rasterPreview,
    isRasterPreviewVisible: state.rasterArea.isRasterPreviewVisible,
    inputPalmTypesPalette:
      state.userSettings?.settings?.visualizationPresets
        ?.inputPalmTypesPalette || {},
    rasterPreviewBbox: state.rasterArea.rasterPreviewBbox,
    crsDef: state.map.crsDef,
    rasterGridWidth: state.rasterArea.rasterGridWidth,
  };
};

export default withTranslation()(connect(mapStateToProps, null)(RasterGeoJson));
