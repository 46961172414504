import React from "react";
import L from "leaflet";
import { GeoJSON } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
  windTurbineMarker,
  existingWindTurbineMarker,
} from "../WindTurbineDrawing/WindTurbineDrawing";
import { setMarkerRef } from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";

class WindTurbineGeojson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.pointToWindTurbineLayer = this.pointToWindTurbineLayer.bind(this);

    this.createWindTurbineClusterIcon =
      this.createWindTurbineClusterIcon.bind(this);
    this.setMarkerName = this.setMarkerName.bind(this);
  }

  pointToWindTurbineLayer(feature, latlng) {
    const windTurbineProperties = this.props.polygonData.find((x) => {
      return x.id === feature.properties.id;
    });
    let tooltipContent = feature.properties.wtname;
    if (windTurbineProperties && windTurbineProperties.wtname) {
      tooltipContent = windTurbineProperties.wtname;
    }

    const icon =
      feature.properties.wtstate === 0
        ? windTurbineMarker
        : existingWindTurbineMarker;
    const marker = L.marker(latlng, { icon: icon, zIndexOffset: 10 });

    marker.bindTooltip(`${tooltipContent}`, {
      permanent: true,
      className: `wind-turbine-tooltip ${
        this.props.windTurbineTooltipVisibility
          ? ""
          : "wind-turbine-tooltip-hidden"
      }`,
      direction: "top",
      offset: [0, -10],
    });
    marker.on("click", this.setMarkerName);
    return marker;
  }

  setMarkerName(marker) {
    this.props.setMarkerRef(marker.target);
  }

  createWindTurbineClusterIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-wind-turbine span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <>
        {this.props.isGeoJsonVisible &&
          this.props.polygonData &&
          !this.props.windTurbineTooltipVisibility && (
            <MarkerClusterGroup
              iconCreateFunction={this.createWindTurbineClusterIcon}
              disableClusteringAtZoom={19}
              showCoverageOnHover
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
              // onAnimationEnd={() => {}}
            >
              {this.props.windTurbineGeoJsonWg &&
                !this.props.hiddenObjectTypesIndexes.includes(10) && (
                  <GeoJSON
                    key={this.props.windTurbineGeoJsonWg ? Date.now() : null}
                    data={this.props.windTurbineGeoJsonWg}
                    onEachFeature={this.props.onEachFeature}
                    pointToLayer={this.pointToWindTurbineLayer}
                  />
                )}
            </MarkerClusterGroup>
          )}
        {this.props.isGeoJsonVisible &&
          this.props.polygonData &&
          this.props.windTurbineGeoJsonWg &&
          !this.props.hiddenObjectTypesIndexes.includes(10) &&
          this.props.windTurbineTooltipVisibility && (
            <GeoJSON
              key={this.props.windTurbineGeoJsonWg ? Date.now() : null}
              data={this.props.windTurbineGeoJsonWg}
              onEachFeature={this.props.onEachFeature}
              pointToLayer={this.pointToWindTurbineLayer}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windTurbineGeoJsonWg: state.map.windTurbineGeoJsonWg,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    polygonData: state.map.polygonData,
    hiddenObjectTypesIndexes: state.legend.hiddenObjectTypesIndexes,
    windTurbineTooltipVisibility: state.legend.windTurbineTooltipVisibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMarkerRef: (payload) => dispatch(setMarkerRef(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WindTurbineGeojson);
