import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Thermometer, Clock } from "react-feather";
import { HelpCircle, ChevronsRight } from "react-feather";
import {
  changeExpertInput,
  changeExpertTempInput,
  changeExpertTimeInput,
  changeExpertTemperatureInput,
  updateLandSurfaceTableRows,
  upddateVectorTableRows,
} from "../../../redux/actions/expertActions";
import { useSelector, useDispatch } from "react-redux";

export const ExpertHelpLabel = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <label
        className="form-label user-settings-form-label"
        style={{ width: "auto", marginRight: "6px" }}
      >
        {props.label}
      </label>
      {props.helpLink && (
        <a
          title={"More information"}
          href={props.helpLink}
          target="_blank"
          style={{ cursor: "pointer" }}
          rel="noopener noreferrer"
        >
          <HelpCircle size={15} color={"#429c88ed"} />
        </a>
      )}
    </div>
  );
};

export const SummaryLabel = (props) => {
  return (
    <>
      <label
        className="form-label user-settings-form-label"
        style={{ width: "auto", marginRight: "6px" }}
      >
        {props.label}
      </label>
      <div
        style={{
          minWidth: 805,
          display: "flex",
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <input
          className="setup-input disabled"
          name="num"
          type="text"
          disabled
          value={props.value}
          style={{
            width: 400,
            marginBottom: 0,
            marginRight: 6,
          }}
        />

        {props.route ? (
          <NavLink
            style={{ color: "rgba(0, 0, 0, 0.65)" }}
            exact
            to={props.route}
          >
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
              className="case-sdb-btn"
            >
              <ChevronsRight
                size={15}
                strokeWidth={1.6}
                color={"rgba(0, 0, 0, 0.65)"}
              />
              <div
                className="case-btn-lbl"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  marginBottom: 2,
                  paddingLeft: 4,
                }}
              >
                {props.refText}
              </div>
            </div>
          </NavLink>
        ) : (
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
            className="case-sdb-btn"
            onClick={props.onClick}
          >
            <ChevronsRight
              size={15}
              strokeWidth={1.6}
              color={"rgba(0, 0, 0, 0.65)"}
            />
            <div
              className="case-btn-lbl"
              style={{
                fontSize: 12,
                fontWeight: 600,
                marginBottom: 2,
                paddingLeft: 4,
              }}
            >
              {props.refText}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const ExpertInput = (props) => {
  const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const dispatch = useDispatch();

  return (
    <input
      className="setup-input"
      name="num"
      type="number"
      min={props.min}
      max={props.max}
      step={props.step}
      value={
        expertTempKey === props.subkey ? expertTempValue : props.constValue
      }
      disabled={props.disabled}
      onInput={(e) => {
        dispatch(
          changeExpertInput({
            page: props.page,
            subpage: props.subpage,
            key: props.section,
            subkey: props.subkey,
            value: e.target.value,
            min: props.min,
            max: props.max,
          })
        );
      }}
      onChange={(e) =>
        dispatch(
          changeExpertTempInput({
            subkey: props.subkey,
            value:
              props.step === 1
                ? e.target.value.replace(/\D/, "")
                : e.target.value,
          })
        )
      }
      onBlur={(e) =>
        dispatch(
          changeExpertInput({
            page: props.page,
            subpage: props.subpage,
            key: props.section,
            subkey: props.subkey,
            value: e.target.value,
            min: props.min,
            max: props.max,
            step: props.step,
          })
        )
      }
    />
  );
};

export const ExpertLandSurfaceSoilInput = (props) => {
  const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const dispatch = useDispatch();

  return (
    <input
      className="setup-input"
      name="num"
      type="number"
      min={1}
      max={20}
      step={1}
      value={
        expertTempKey === props.subkey ? expertTempValue : props.constValue
      }
      onInput={(e) => {
        dispatch(updateLandSurfaceTableRows(e.target.value));
      }}
      onChange={(e) =>
        dispatch(
          changeExpertTempInput({
            subkey: props.subkey,
            value: e.target.value.replace(/\D/, ""),
          })
        )
      }
      onBlur={(e) => dispatch(updateLandSurfaceTableRows(e.target.value))}
    />
  );
};

export const ExpertVectorInput = (props) => {
  const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const dispatch = useDispatch();

  return (
    <input
      className="setup-input"
      name="num"
      type="number"
      min={1}
      max={props.maxRowsCounter}
      step={1}
      value={
        expertTempKey === props.mainKey ? expertTempValue : props.constValue
      }
      onInput={(e) => {
        dispatch(
          upddateVectorTableRows({
            newRowsCounter: e.target.value,
            template: props.template,
            subkey: props.subkey,
            firstRowKey: props.firstRowKey,
            secondRowKey: props.secondRowKey,
            thirdRowKey: props.thirdRowKey,
            maxRowsCounter: props.maxRowsCounter,
            mainKey: props.mainKey,
          })
        );
      }}
      onChange={(e) =>
        dispatch(
          changeExpertTempInput({
            subkey: props.mainKey,
            value: e.target.value.replace(/\D/, ""),
          })
        )
      }
      onBlur={(e) =>
        dispatch(
          upddateVectorTableRows({
            newRowsCounter: e.target.value,
            template: props.template,
            subkey: props.subkey,
            firstRowKey: props.firstRowKey,
            secondRowKey: props.secondRowKey,
            thirdRowKey: props.thirdRowKey,
            maxRowsCounter: props.maxRowsCounter,
            mainKey: props.mainKey,
          })
        )
      }
    />
  );
};

export const ExpertTempAccordion = (props) => {
  const [expanded, setExpanded] = useState(!!props.expanded || false);
  const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        minWidth: 805,
        height: 31,
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          minWidth: 805,
          height: 31,
          alignItems: "center",
        }}
      >
        <input
          className="setup-input"
          style={{ width: 400, marginBottom: 0, marginRight: 6 }}
          type="number"
          min={props.min}
          max={props.max}
          step={props.step}
          value={
            expertTempKey === props.subkey ? expertTempValue : props.constValue
          }
          onInput={(e) => {
            dispatch(
              changeExpertInput({
                page: props.page,
                subpage: props.subpage,
                key: props.section,
                subkey: props.subkey,
                value: e.target.value,
                min: props.min,
                max: props.max,
                step: props.step,
              })
            );
          }}
          onChange={(e) =>
            dispatch(
              changeExpertTempInput({
                subkey: props.subkey,
                value: e.target.value,
              })
            )
          }
          onBlur={(e) =>
            dispatch(
              changeExpertInput({
                page: props.page,
                subpage: props.subpage,
                key: props.section,
                subkey: props.subkey,
                value: e.target.value,
                min: props.min,
                max: props.max,
                step: props.step,
              })
            )
          }
        />
        <Thermometer
          size={15}
          color={"#06896c"}
          style={{ cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
        />

        {expanded && (
          <>
            <div>
              <input
                className="setup-input"
                name="num"
                type="number"
                min={0}
                max={100}
                step={0.01}
                value={(props.constValue - 273.15).toFixed(2)}
                style={{
                  width: 159,
                  marginBottom: 0,
                  marginLeft: 5,
                  marginRight: 6,
                }}
                onChange={(e) =>
                  dispatch(
                    changeExpertTemperatureInput({
                      page: props.page,
                      subpage: props.subpage,
                      key: props.section,
                      subkey: props.subkey,
                      value: e.target.value,
                      max: 100,
                      temperature: "Celsius",
                    })
                  )
                }
              />
              <span style={{ fontSize: 12, fontWeight: 600 }}>[°C]</span>
            </div>
            <div>
              <input
                className="setup-input"
                name="num"
                min={32}
                max={212}
                step={1}
                disabled
                value={(((props.constValue - 273.15) * 9) / 5 + 32).toFixed(2)}
                style={{
                  width: 159,
                  marginBottom: 0,
                  marginLeft: 8,
                  marginRight: 6,
                }}
              />
              <span style={{ fontSize: 12, fontWeight: 600 }}>[℉]</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ExpertTimeAccordion = (props) => {
  const [expanded, setExpanded] = useState(!!props.expanded || false);
  const expertTempKey = useSelector((state) => state.expert.expertTempKey);
  const expertTempValue = useSelector((state) => state.expert.expertTempValue);

  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: "flex",
        minWidth: 805,
        height: 31,
        alignItems: "center",
      }}
    >
      <input
        id={props.subkey}
        className="setup-input"
        style={{ width: 400, marginBottom: 0, marginRight: 6 }}
        type={"number"}
        min={props.min}
        max={props.max}
        step={1}
        value={
          expertTempKey === props.subkey ? expertTempValue : props.constValue
        }
        onInput={(e) => {
          dispatch(
            changeExpertInput({
              page: props.page,
              subpage: props.subpage,
              key: props.section,
              subkey: props.subkey,
              value: e.target.value,
              min: props.min,
              max: props.max,
            })
          );
        }}
        onChange={(e) =>
          dispatch(
            changeExpertTempInput({
              subkey: props.subkey,
              value: e.target.value.replace(/\D/, ""),
            })
          )
        }
        onBlur={(e) =>
          dispatch(
            changeExpertInput({
              page: props.page,
              subpage: props.subpage,
              key: props.section,
              subkey: props.subkey,
              value: e.target.value,
              min: props.min,
              max: props.max,
            })
          )
        }
      />

      <Clock
        size={15}
        color={"#06896c"}
        style={{ cursor: "pointer" }}
        onClick={() => setExpanded(!expanded)}
      />
      {expanded && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 378,
            alignItems: "center",
            marginLeft: 6,
          }}
        >
          <input
            className="setup-input"
            name="num"
            type="number"
            min={0}
            max={(props.max / 60).toFixed(0)}
            step={1}
            value={Math.floor(props.constValue / 3600)}
            style={{
              width: 100,
              marginBottom: 0,
            }}
            disabled={Number((props.max / 60).toFixed(0)) < 1.1}
            onChange={(e) =>
              dispatch(
                changeExpertTimeInput({
                  page: props.page,
                  subpage: props.subpage,
                  key: props.section,
                  subkey: props.subkey,
                  value: e.target.value.replace(/\D/, ""),
                  max: 16666,
                  time: "h",
                })
              )
            }
          />
          <span style={{ fontSize: 12, fontWeight: 600 }}>[h]</span>
          <input
            className="setup-input"
            name="num"
            type="number"
            min={0}
            max={59}
            step={1}
            value={Math.floor((props.constValue % 3600) / 60)}
            style={{
              width: 100,
              marginBottom: 0,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertTimeInput({
                  page: props.page,
                  subpage: props.subpage,
                  key: props.section,
                  subkey: props.subkey,
                  value: e.target.value.replace(/\D/, ""),
                  max: 59,
                  time: "min",
                })
              )
            }
          />
          <span style={{ fontSize: 12, fontWeight: 600 }}>[min]</span>
          <input
            className="setup-input"
            name="num"
            type="number"
            min={props.min}
            max={59}
            step={1}
            value={(props.constValue % 3600) % 60}
            style={{
              width: 100,
              marginBottom: 0,
            }}
            onChange={(e) =>
              dispatch(
                changeExpertTimeInput({
                  page: props.page,
                  subpage: props.subpage,
                  key: props.section,
                  subkey: props.subkey,
                  value: e.target.value.replace(/\D/, ""),
                  max: 59,
                  time: "s",
                })
              )
            }
          />
          <span style={{ fontSize: 12, fontWeight: 600 }}>[s]</span>
        </div>
      )}
    </div>
  );
};
