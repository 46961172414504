export const newLayerTypeField = [
  "Scalar",
  "Difference",
  "SpatiotemporalAnalysis",
  //"CustomCategory",
  "Windfield",
  "Isolines",
];

export const spatProcessOptions = ["Min", "Mean", "Max", "Sum"];

export const customCategoryNr = [3, 4, 5, 6, 7, 8, 9];

export const windfieldParamsOptions = ["Direction", "StreamAnimation"];

export const isolinesSettingsPreset = [
  "Auto",
  "User-defined (by Break Number)",
  "User-defined (by Break Interval)",
  "Solingen GAP - Height Lines",
];

export const itemPosition = [
  "top",
  "topright",
  "right",
  "bottomright",
  "bottom",
  "bottomleft",
  "left",
  "topleft",
];

export const scalePosition = [
  "topright",
  "bottomright",
  "bottomleft",
  "topleft",
];

export const itemPositionAbbr = {
  top: "t",
  topright: "tr",
  right: "r",
  bottomright: "br",
  bottom: "b",
  bottomleft: "bl",
  left: "l",
  topleft: "tl",
};
export const mapPlotSquareEdge = {
  "1:500": {
    DINA4: 80,
    DINA3: 110,
  },
  "1:1000": {
    DINA4: 160,
    DINA3: 220,
  },
  "1:2000": {
    DINA4: 320,
    DINA3: 440,
  },
  "1:5000": {
    DINA4: 800,
    DINA3: 1100,
  },
  "1:10000": {
    DINA4: 1600,
    DINA3: 2200,
  },
  "1:20000": {
    DINA4: 3200,
    DINA3: 4400,
  },
  "1:50000": {
    DINA4: 8000,
    DINA3: 11000,
  },
};
