export const unitsJson = {
  l: [
    ["0", "-"],
    ["1", "1/h"],
    ["2", "1/hPa"],
    ["3", "J/m²/K"],
    ["4", "K"],
    ["5", "m"],
    ["6", "m²/m²"],
    ["7", "s/m"],
    ["8", "W"],
    ["9", "W/m/K"],
    ["10", "W/m²"],
    ["11", "W/m²/K"],
    ["12", "y"],
    ["13", "kg/m²/d"],
    ["14", "°C"],
    ["15", "kg/year"],
    ["16", "°"],
    ["17", "rad s-1"],
  ],
};
