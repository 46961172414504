import React from "react";
import { Check, X } from "react-feather";
import { Loader as CssLoader } from "../../../Components/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SavingStatusIndicator(props) {
  const { t } = useTranslation();

  const mapDataSavingStatus = useSelector(
    (state) => state.map.mapDataSavingStatus
  );

  if (mapDataSavingStatus === "false") {
    return <></>;
  }

  return (
    <div
      className="static-driver-indicator saving-status-indicator"
      style={props.style}
    >
      {mapDataSavingStatus === "pending" ? (
        <CssLoader
          size="status-panel"
          style={{
            position: "relative",
            fontSize: "1.25px",
            marginRight: "1px",
          }}
        />
      ) : mapDataSavingStatus === "success" ||
        mapDataSavingStatus === "ResultsSaveSuccess" ? (
        <Check strokeWidth={"3px"} size={"12px"} color={"#6e6e6e"} />
      ) : (
        <X strokeWidth={"3px"} size={"12px"} color={"#6e6e6e"} />
      )}
      <div className="static-driver-info" style={{ paddingLeft: "4px" }}>
        {mapDataSavingStatus === "pending"
          ? t(`Map Data saving ...`)
          : mapDataSavingStatus === "success"
          ? t(`Map Data has been saved`)
          : mapDataSavingStatus === "ResultsSaveSuccess"
          ? t(`ResultsSaveSuccess`)
          : "Saving error"}
      </div>
    </div>
  );
}

export default SavingStatusIndicator;
