import React from "react";
import { ResultsSelect } from "../../../Components/Map/Form/Select";
import { NumericOsmInput } from "../../../Components/Map/Form/Select";
import {
  BUILDING_WITH_MISSING_HEIGHT_OPTIONS,
  TREE_WITH_MISSING_HEIGHT_OPTIONS,
  ROAD_WIDTH_METHOD_OPTIONS,
} from "./ToolsConsts";
import {
  setBldgMethod,
  setBldgStoreyHeight,
  setBldgFillValue,
  setTreeMethod,
  setTreePatchMissValue,
  setTreeMissValue,
  setRoadWidthMethod,
  setRoadWidthLanes,
} from "../../../redux/actions/toolsActions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ExpertOsmDownloadSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <h4
          className="raster-settings-header"
          style={{ marginBottom: "12px", marginTop: "12px", width: "425px" }}
        >{`${t("ExtendedSettings")}`}</h4>
        <label
          style={{
            width: "100%",
            fontSize: "11px",
            fontWeight: "600",
            fontFamily: "Segoe UI",
            color: "rgb(124, 124, 124)",
            marginBottom: "4px",
            marginTop: "12px",
          }}
        >
          {t("BuildingHeights")}
        </label>
        <ResultsSelect
          label={t("BuildingWithMissingHeightOptions")}
          style={{ width: "200px" }}
          value={BUILDING_WITH_MISSING_HEIGHT_OPTIONS[this.props.bldMethod]}
          options={BUILDING_WITH_MISSING_HEIGHT_OPTIONS.map((x) => {
            return t(x);
          })}
          onChange={(e) => {
            this.props.setBldgMethod(e.target.selectedIndex);
          }}
        />

        <NumericOsmInput
          label={t("DefaultStoreyHeight")}
          value={this.props.bldStorey}
          min={1}
          max={99}
          step={0.1}
          onChange={(e) => {
            this.props.setBldgStoreyHeight(e.target.value);
          }}
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              Number(e.target.value) < 1 ||
              Number(e.target.value) > 99
            ) {
              this.props.setBldgStoreyHeight(3);
            }
          }}
          unit={"m"}
          maxLength={10}
        />

        <NumericOsmInput
          label={t("FillValueBuildingHeight")}
          value={this.props.bldFillVal}
          min={1}
          max={999}
          step={0.1}
          onChange={(e) => {
            this.props.setBldgFillValue(e.target.value);
          }}
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              Number(e.target.value) < 1 ||
              Number(e.target.value) > 999
            ) {
              this.props.setBldgFillValue(20);
            }
          }}
          unit={"m"}
          maxLength={10}
        />
        <hr
          className="raster-settings-ruler"
          style={{ marginBottom: "20px", width: "300px" }}
        />
        <label
          style={{
            width: "100%",
            fontSize: "11px",
            fontWeight: "600",
            fontFamily: "Segoe UI",
            color: "rgb(124, 124, 124)",
            marginBottom: "4px",
          }}
        >
          {t("TreeHeights")}
        </label>

        <ResultsSelect
          label={t("TreeWithMissingHeights")}
          style={{ width: "200px" }}
          value={TREE_WITH_MISSING_HEIGHT_OPTIONS[this.props.trMethod]}
          options={TREE_WITH_MISSING_HEIGHT_OPTIONS.map((x) => {
            return t(x);
          })}
          onChange={(e) => {
            this.props.setTreeMethod(e.target.selectedIndex);
          }}
        />
        <NumericOsmInput
          label={t("FillValueTreePatches")}
          value={this.props.trFillValPatches}
          min={1}
          max={999}
          step={0.1}
          onChange={(e) => {
            this.props.setTreePatchMissValue(e.target.value);
          }}
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              Number(e.target.value) < 1 ||
              Number(e.target.value) > 99
            ) {
              this.props.setTreePatchMissValue(12);
            }
          }}
          unit={"m"}
          maxLength={10}
        />
        <NumericOsmInput
          label={t("FillValueSingleTrees")}
          value={this.props.trFillValSingle}
          min={1}
          max={999}
          step={0.1}
          onChange={(e) => {
            this.props.setTreeMissValue(e.target.value);
          }}
          onBlur={(e) => {
            if (
              e.target.value === "" ||
              Number(e.target.value) < 1 ||
              Number(e.target.value) > 999
            ) {
              this.props.setTreeMissValue(12);
            }
          }}
          unit={"m"}
          maxLength={10}
        />
        <hr
          className="raster-settings-ruler"
          style={{ marginBottom: "20px", width: "300px" }}
        />
        <label
          style={{
            width: "100%",
            fontSize: "11px",
            fontWeight: "600",
            fontFamily: "Segoe UI",
            color: "rgb(124, 124, 124)",
            marginBottom: "4px",
          }}
        >
          {t("RoadWidth")}
        </label>
        <ResultsSelect
          label={t("AssumeRoadWidth")}
          style={{ width: "200px" }}
          value={ROAD_WIDTH_METHOD_OPTIONS[this.props.rwMethod]}
          options={ROAD_WIDTH_METHOD_OPTIONS}
          onChange={(e) => {
            this.props.setRoadWidthMethod(e.target.selectedIndex);
          }}
        />
        {this.props.rwLanes.map((x, i) => {
          return (
            <NumericOsmInput
              label={`${t("BufferWidth")} ${i + 1} ${t("Lanes")}`}
              value={x}
              min={1}
              max={99}
              step={0.1}
              onChange={(e) => {
                this.props.setRoadWidthLanes({
                  index: i,
                  value: e.target.value,
                });
              }}
              onBlur={(e) => {
                if (
                  e.target.value === "" ||
                  Number(e.target.value) < 1 ||
                  Number(e.target.value) > 99
                ) {
                  this.props.setRoadWidthLanes({
                    index: i,
                    value: [4, 9, 12, 16, 20, 24][i],
                  });
                }
              }}
              unit={"m"}
              maxLength={10}
            />
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bldMethod: state.tools.bldMethod,
    bldStorey: state.tools.bldStorey,
    bldFillVal: state.tools.bldFillVal,
    trMethod: state.tools.trMethod,
    trFillValPatches: state.tools.trFillValPatches,
    trFillValSingle: state.tools.trFillValSingle,
    rwMethod: state.tools.rwMethod,
    rwLanes: state.tools.rwLanes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBldgMethod: (payload) => dispatch(setBldgMethod(payload)),
    setBldgStoreyHeight: (payload) => dispatch(setBldgStoreyHeight(payload)),
    setBldgFillValue: (payload) => dispatch(setBldgFillValue(payload)),
    setTreeMethod: (payload) => dispatch(setTreeMethod(payload)),
    setTreePatchMissValue: (payload) =>
      dispatch(setTreePatchMissValue(payload)),
    setTreeMissValue: (payload) => dispatch(setTreeMissValue(payload)),
    setRoadWidthMethod: (payload) => dispatch(setRoadWidthMethod(payload)),
    setRoadWidthLanes: (payload) => dispatch(setRoadWidthLanes(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ExpertOsmDownloadSettings)));
