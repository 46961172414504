import React from "react";
import L from "leaflet";
import chroma from "chroma-js";
import { updateResultsJsonOrigin } from "../../../../redux/actions/resultsActions";
import { getDownloadLink } from "../../../../api/storageApi";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

class WindAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loaded = this.loaded.bind(this);
  }

  async componentDidMount() {
    const map = this.props.mapRef.current.leafletElement;
    map.eachLayer(function (layer) {
      if (layer.options.id === "windAnimation") {
        layer.remove();
      }
    });
    const windAnimationScript = document.getElementById("wind-animation");
    if (windAnimationScript === null) {
      const script = document.createElement("script");
      script.id = "wind-animation";

      script.src =
        "https://ihcantabria.github.io/Leaflet.CanvasLayer.Field/dist/leaflet.canvaslayer.field.js";
      script.async = true;
      script.onload = () => this.loaded(map);

      document.body.appendChild(script);
    } else {
      this.loaded(map);
    }
  }

  async loaded(map) {
    const resultsJsonOrigin = cloneDeep(this.props.resultsJsonOrigin);
    const windAnimLayer =
      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ];
    const plotPaths = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_paths
    );
    const plotMaxAge = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_max_age
    );
    const plotVScale = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_v_scale
    );
    const plotFade = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_fade
    );

    let plotColor = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_color
    );

    const plotStrokeWidth = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_stroke_width
    );

    const plotOpacity = cloneDeep(
      this.props.resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].plot_opacity
    );

    let tiffU = null;
    let tiffV = null;

    if (
      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ].urlU
    ) {
      tiffU = await getDownloadLink(`${windAnimLayer.geo_u_4326}`);
      tiffV = await getDownloadLink(`${windAnimLayer.geo_v_4326}`);
    } else {
      const resultsGeotiffULink = await getDownloadLink(
        `${windAnimLayer.geo_u_4326}`
      );
      const resultsGeotiffVLink = await getDownloadLink(
        `${windAnimLayer.geo_v_4326}`
      );

      windAnimLayer.urlU = resultsGeotiffULink;
      windAnimLayer.urlV = resultsGeotiffVLink;

      tiffU = windAnimLayer.urlU;
      tiffV = windAnimLayer.urlV;

      resultsJsonOrigin.pages[this.props.currentViewIndex].lyrs[
        this.props.layerIndex
      ] = windAnimLayer;

      this.props.updateResultsJsonOrigin(resultsJsonOrigin);
    }
    const urls = [tiffU, tiffV];

    const promises = urls.map((url) => fetch(url).then((r) => r.arrayBuffer()));
    Promise.all(promises).then(function (arrays) {
      let vectorField = L.VectorField.fromGeoTIFFs(arrays[0], arrays[1], 0.001);
      //const range = vectorField.range;
      const scale = chroma.scale(plotColor).domain([0, 0.8]);
      const windAnimation = L.canvasLayer
        .vectorFieldAnim(vectorField, {
          id: "windAnimation",
          paths: plotPaths ? plotPaths : 5000,
          fade: plotFade ? plotFade : 0.96,
          maxAge: plotMaxAge ? plotMaxAge : 100,
          velocityScale: plotVScale ? plotVScale : 1 / 10000,
          color: scale,
          //caretColor: "blue",
          opacity: plotOpacity,
          width: plotStrokeWidth,
        })
        .addTo(map);

      windAnimation._canvas.classList.add("wind-animation");

      //fastest method
      // L.control
      //   .layers(
      //     {},
      //     {
      //       "Derived direction": streamLayer,
      //     },
      //     {
      //       position: "bottomleft",
      //       collapsed: true,
      //     }
      //   )
      //   .addTo(map);
      //map.fitBounds(streamLayer.getBounds());

      // const element = document.getElementsByClassName(
      //   "leaflet-control-colorBar"
      // );

      // ///remove this one jezeli jest juz i treba dodac nowy
      // if (element === 1) {
      //   element.remove();
      // }

      // if (element.length !== 1) {
      //   var bar = L.control
      //     .colorBar(scale, range, {
      //       className: "wind-anim-color-bar",
      //       title: "Currents surface velocity (m/s)",
      //       units: "m/s",
      //       steps: 1000,
      //       decimals: 1,
      //       width: 350,
      //       height: 20,
      //       position: "bottomright",
      //       caretColor: "red",
      //       background: "rgba(255, 255, 255, 0.8)",
      //       textColor: "black",
      //       labels: [0, 4.808326044390434e38],
      //       labelFontSize: 9,
      //     })
      //     .addTo(map);
      // }
    });
  }

  render() {
    return <></>;
  }
}
const mapStateToProps = (state) => {
  return {
    resultsJsonOrigin: state.results.resultsJsonOrigin,
    currentViewIndex: state.results.currentViewIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateResultsJsonOrigin: (payload) =>
      dispatch(updateResultsJsonOrigin(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WindAnimation);
