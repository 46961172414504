import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    console.log(this.state);
    if (this.state.hasError) {
      return (
        <div>
          <h1>
            Error has occured. In order to continue please refresh page. If
            error happens again please contact a@gmail.com
          </h1>
          <button onClick={this.handleRefresh}>Refresh Page</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
