import { types } from "../types/map";

export function setGeoJsonWg(payload) {
  return {
    type: types.SET_GEOJSON_WG,
    payload,
  };
}

export function setPolygonData(payload) {
  return {
    type: types.SET_POLYGON_DATA,
    payload,
  };
}

export function changeObjectType(payload) {
  return {
    type: types.CHANGE_OBJECT_TYPE,
    payload,
  };
}

export function changeObjectSubtype(payload) {
  return {
    type: types.CHANGE_OBJECT_SUBTYPE,
    payload,
  };
}
export function updateBuildingHeight(payload) {
  return {
    type: types.UPDATE_BUILDING_HEIGHT,
    payload,
  };
}

export function updateTreeHeight(payload) {
  return {
    type: types.UPDATE_TREE_HEIGHT,
    payload,
  };
}

export function updatePolygonData(payload) {
  return {
    type: types.UPDATE_POLYGON_DATA,
    payload,
  };
}

export function resetGeoData() {
  return {
    type: types.RESET_GEODATA,
  };
}

export function centerMap() {
  return {
    type: types.CENTER_MAP,
  };
}

export function centerMapSuccess() {
  return {
    type: types.CENTER_MAP_SUCCESS,
  };
}

export function setDrawnGeoJson(payload) {
  return {
    type: types.SET_DRAWN_GEOJSON,
    payload,
  };
}

export function startNewPolygon() {
  return {
    type: types.START_NEW_POLYGON,
  };
}

export function drawPolygon(payload) {
  return {
    type: types.DRAW_POLYGON,
    payload,
  };
}

export function selectPolygon(payload) {
  return {
    type: types.SELECT_POLYGON,
    payload,
  };
}

export function removeDrawnPolygon(payload) {
  return {
    type: types.REMOVE_DRAWN_POLYGON,
    payload,
  };
}

export function setObjectTypeIndex(payload) {
  return {
    type: types.SET_OBJECT_TYPE_INDEX,
    payload,
  };
}

export function setObjectSubtypeIndex(payload) {
  return {
    type: types.SET_OBJECT_SUBTYPE_INDEX,
    payload,
  };
}

export function addCoordsRow(payload) {
  return {
    type: types.ADD_COORDS_ROW,
    payload,
  };
}

export function removeCoordsRow(payload) {
  return {
    type: types.REMOVE_COORDS_ROW,
    payload,
  };
}

export function handleCoordInputChange(payload) {
  return {
    type: types.HANDLE_COORD_INPUT_CHANGE,
    payload,
  };
}

export function addPolygonPointWg(payload) {
  return {
    type: types.ADD_POLYGON_POINT_ONCLICK_WG,
    payload: payload,
  };
}

export function dragPointWg(payload) {
  return {
    type: types.DRAG_POINTWG,
    payload,
  };
}

export function dragendPointWg(payload) {
  return {
    type: types.DRAGEND_POINTWG,
    payload,
  };
}

export function getSelectedMapDrawnPolygonGeoProps(payload) {
  return { type: types.GET_SELECTED_MAP_DRAWN_POLYGON_GEOPROPS, payload };
}

export function changeSelectedDrawnPolygonType(payload) {
  return { type: types.CHANGE_SELECTED_DRAWN_POLYGON_TYPE, payload };
}

export function changeSelectedDrawnPolygonSubtype(payload) {
  return { type: types.CHANGE_SELECTED_DRAWN_POLYGON_SUBTYPE, payload };
}
export function unselectSelectedDrawnPolygonGeoProps() {
  return { type: types.UNSELECT_SELECTED_DRAWN_POLYGON_GEO_PROPS };
}

export function initializePolygonEditor() {
  return { type: types.INITIALIZE_POLYGON_EDITOR };
}

export function resetPolygonEditorData() {
  return {
    type: types.RESET_POLYGON_EDITOR_DATA,
  };
}

export function addTree(payload) {
  return {
    type: types.ADD_TREE,
    payload,
  };
}

export function removeTree(payload) {
  return { type: types.REMOVE_TREE, payload };
}

export function selectTree(payload) {
  return {
    type: types.SELECT_TREE,
    payload,
  };
}

export function changeSelectedTreeSubtype(payload) {
  return { type: types.CHANGE_SELECTED_TREE_SUBTYPE, payload };
}

export function updateTreePositionOnInputChange(payload) {
  return {
    type: types.UPDATE_TREE_POSITION_ON_INPUT_CHANGE,
    payload,
  };
}

export function goToTreeCoordinates() {
  return { type: types.GO_TO_TREE_COORDINATES };
}

export function goToTreeProperties(payload) {
  return { type: types.GO_TO_TREE_PROPERTIES, payload };
}

// export function toggleSingleTreeVisibility() {
//   return { type: types.TOGGLE_SINGLE_TREE_VISIBILITY };
// }

export function updateTreePositionOnDragend(payload) {
  return { type: types.UPDATE_TREE_POSITION_ON_DRAGEND, payload };
}

export function resetTreeDrawingData() {
  return { type: types.RESET_TREE_DRAWING_DATA };
}

export function getCurrentCursorCoords(payload) {
  return { type: types.GET_CURRENT_CURSOR_COORDS, payload };
}

export function toggleDrawingMode() {
  return { type: types.TOGGLE_DRAWING_MODE };
}

export function togglePolygonDrawingMode() {
  return { type: types.TOGGLE_POLYGON_DRAWING_MODE };
}

export function toggleTreeDrawingMode() {
  return {
    type: types.TOGGLE_TREE_DRAWING_MODE,
  };
}

export function toggleRasterSettingsMode() {
  return { type: types.TOGGLE_RASTER_SETTINGS_MODE };
}

export function getSelectedMapItemGeoProps(payload) {
  return { type: types.GET_SELECTED_MAP_ITEM_GEOPROPS, payload };
}

export function unselectSelectedMapItemGeoProps() {
  return { type: types.UNSELECT_SELECTED_MAP_ITEM_GEO_PROPS };
}

export function createSingleTreeGeoJson() {
  return { type: types.CREATE_SINGLE_TREE_GEOJSON };
}

export function updateTempInput(payload) {
  return { type: types.UPDATE_TEMP_INPUT, payload };
}

export function setWmsLayerNames(payload) {
  return { type: types.SET_WMS_LAYER_NAMES, payload };
}

export function toggleCopyPropsMode() {
  return { type: types.TOGGLE_COPY_PROPS_MODE };
}

export function addAssignedObjectCopy(payload) {
  return { type: types.ADD_ASSIGNED_OBJECT_COPY, payload };
}

export function copyProps(payload) {
  return { type: types.COPY_PROPS, payload };
}

export function toggleSingleTreeCopyPropsMode() {
  return { type: types.TOGGLE_SINGLE_TREE_COPY_PROPS_MODE };
}

export function addAssignedSingleTreeCopy(payload) {
  return { type: types.ADD_ASSIGNED_SINGLE_TREE_COPY, payload };
}

export function copySingleTreeProps(payload) {
  return { type: types.COPY_SINGLE_TREE_PROPS, payload };
}

export function filterGeoJson(payload) {
  return { type: types.FILTER_GEOJSON, payload };
}

export function setDrawnPolygonGeojsonCrs(payload) {
  return { type: types.SET_DRAWN_POLYGON_GEOJSON_CRS, payload };
}

export function resetAllMapData() {
  return { type: types.RESET_ALL_MAP_DATA };
}

export function toggleTreeCursorDisplay() {
  return { type: types.TOGGLE_TREE_CURSOR_DISPLAY };
}

export function enableTreeDraggable() {
  return { type: types.ENABLE_TREE_DRAGGABLE };
}

export function disableTreeDraggable() {
  return { type: types.DISABLE_TREE_DRAGGABLE };
}

export function toggleEmissionDrawingMode() {
  return { type: types.TOGGLE_EMISSION_DRAWING_MODE };
}

export function addEmission(payload) {
  return { type: types.ADD_EMISSION, payload };
}

export function selectEmission(payload) {
  return {
    type: types.SELECT_EMISSION,
    payload,
  };
}

export function removeEmission(payload) {
  return {
    type: types.REMOVE_EMISSION,
    payload,
  };
}

export function updateEmissionPositionOnDragend(payload) {
  return {
    type: types.UPDATE_EMISSION_POSITION_ON_DRAGEND,
    payload,
  };
}

export function changeSelectedDrawnEmissionSubtype(payload) {
  return {
    type: types.CHANGE_SELECTED_DRAWN_EMISSION_SUBTYPE,
    payload,
  };
}

export function addAssignedSingleEmissionCopy(payload) {
  return { type: types.ADD_ASSIGNED_SINGLE_EMISSION_COPY, payload };
}

export function enableEmissionDraggable() {
  return { type: types.ENABLE_EMISSION_DRAGGABLE };
}

export function disableEmissionDraggable() {
  return { type: types.DISABLE_EMISSION_DRAGGABLE };
}

export function toggleEmissionCursorDisplay() {
  return { type: types.TOGGLE_EMISSION_CURSOR_DISPLAY };
}

export function updateEmissionPositionOnInputChange(payload) {
  return {
    type: types.UPDATE_EMISSION_POSITION_ON_INPUT_CHANGE,
    payload,
  };
}

export function goToEmissionProperties(payload) {
  return { type: types.GO_TO_EMISSION_PROPERTIES, payload };
}

export function goToEmissionCoordinates() {
  return { type: types.GO_TO_EMISSION_COORDINATES };
}

export function toggleMapPoiDrawingMode() {
  return { type: types.TOGGLE_MAP_POI_DRAWING_MODE };
}

export function addMapPoi(payload) {
  return { type: types.ADD_MAP_POI, payload };
}

export function selectMapPoi(payload) {
  return {
    type: types.SELECT_MAP_POI,
    payload,
  };
}

export function removeMapPoi(payload) {
  return {
    type: types.REMOVE_MAP_POI,
    payload,
  };
}

export function updateMapPoiPositionOnDragend(payload) {
  return {
    type: types.UPDATE_MAP_POI_POSITION_ON_DRAGEND,
    payload,
  };
}

export function enableMapPoiDraggable() {
  return { type: types.ENABLE_MAP_POI_DRAGGABLE };
}

export function disableMapPoiDraggable() {
  return { type: types.DISABLE_MAP_POI_DRAGGABLE };
}

export function toggleMapPoiCursorDisplay() {
  return { type: types.TOGGLE_MAP_POI_CURSOR_DISPLAY };
}

export function updateMapPoiPositionOnInputChange(payload) {
  return {
    type: types.UPDATE_MAP_POI_POSITION_ON_INPUT_CHANGE,
    payload,
  };
}

export function goToMapPoiProperties(payload) {
  return { type: types.GO_TO_MAP_POI_PROPERTIES, payload };
}

export function goToMapPoiCoordinates(payload) {
  return { type: types.GO_TO_MAP_POI_COORDINATES, payload };
}

export function toggleStreetDrawingMode(payload) {
  return { type: types.TOGGLE_STREET_DRAWING_MODE, payload };
}

export function removeObjectFromUploadedGeoJson() {
  return { type: types.REMOVE_OBJECT_FROM_UPLOADED_GEOJSON };
}

export function createSingleEmissionDrawnGeoJson() {
  return { type: types.CREATE_SINGLE_EMISSION_DRAWN_GEOJSON };
}

export function createSingleMapPoiDrawnGeoJson() {
  return { type: types.CREATE_SINGLE_MAP_POI_DRAWN_GEOJSON };
}

export function changeSelectedGeoJsonEmissionSubtype(payload) {
  return { type: types.CHANGE_SELECTED_GEOJSON_EMISSION_SUBTYPE, payload };
}

export function mergeChemistryGeoJson() {
  return { type: types.MERGE_CHEMISTRY_GEOJSON };
}

export function toggleEmissionDrawnVisibility() {
  return { type: types.TOGGLE_EMISSION_DRAWN_VISIBILITY };
}

export function toggleMapPoiDrawnVisibility() {
  return { type: types.TOGGLE_MAP_POI_DRAWN_VISIBILITY };
}

export function updateEmissionGeoJsonProperties() {
  return { type: types.UPDATE_EMISSION_GEOJSON_PROPERTIES };
}

export function toggleEmissionCopyPropsMode() {
  return { type: types.TOGGLE_EMISSION_COPY_PROPS_MODE };
}

export function copyEmissionProps(payload) {
  return { type: types.COPY_EMISSION_PROPS, payload };
}

export function setMapDataSavingStatus(payload) {
  return { type: types.SET_MAP_DATA_SAVING_STATUS, payload };
}

export function selectPolygonPoint(payload) {
  return {
    type: types.SELECT_POLYGON_POINT,
    payload,
  };
}

export function selectEditorPoint(payload) {
  return { type: types.SELECT_EDITOR_POINT, payload };
}

export function toggleWindTurbineDrawingMode() {
  return { type: types.TOGGLE_WIND_TURBINE_DRAWING_MODE };
}

export function addWindTurbine(payload) {
  return { type: types.ADD_WIND_TURBINE, payload };
}

export function selectWindTurbine(payload) {
  return {
    type: types.SELECT_WIND_TURBINE,
    payload,
  };
}

export function removeWindTurbine(payload) {
  return {
    type: types.REMOVE_WIND_TURBINE,
    payload,
  };
}

export function updateWindTurbinePositionOnDragend(payload) {
  return {
    type: types.UPDATE_WIND_TURBINE_POSITION_ON_DRAGEND,
    payload,
  };
}

export function changeSelectedDrawnWindTurbineSubtype(payload) {
  return {
    type: types.CHANGE_SELECTED_DRAWN_WIND_TURBINE_SUBTYPE,
    payload,
  };
}

export function toggleWindTurbineCopyPropsMode(payload) {
  return { type: types.TOGGLE_WIND_TURBINE_COPY_PROPS_MODE, payload };
}

export function copyWindTurbineProps(payload) {
  return { type: types.COPY_WIND_TURBINE_PROPS, payload };
}

export function addAssignedSingleWindTurbineCopy(payload) {
  return { type: types.ADD_ASSIGNED_WIND_TURBINE_COPY, payload };
}

export function enableWindTurbineDraggable() {
  return { type: types.ENABLE_WIND_TURBINE_DRAGGABLE };
}

export function disableWindTurbineDraggable() {
  return { type: types.DISABLE_WIND_TURBINE_DRAGGABLE };
}

export function toggleWindTurbineCursorDisplay() {
  return { type: types.TOGGLE_WIND_TURBINE_CURSOR_DISPLAY };
}

export function updateWindTurbinePositionOnInputChange(payload) {
  return {
    type: types.UPDATE_WIND_TURBINE_POSITION_ON_INPUT_CHANGE,
    payload,
  };
}

export function toggleWindTurbineVisibility() {
  return {
    type: types.TOGGLE_WIND_TURBINE_VISIBILITY,
  };
}

export function toggleWindTurbineDrawnVisibility() {
  return {
    type: types.TOGGLE_WIND_TURBINE_DRAWN_VISIBILITY,
  };
}

export function goToWindTurbineProperties(payload) {
  return { type: types.GO_TO_WIND_TURBINE_PROPERTIES, payload };
}

export function goToWindTurbineCoordinates() {
  return { type: types.GO_TO_WIND_TURBINE_COORDINATES };
}

export function changeSelectedGeoJsonWindTurbineSubtype(payload) {
  return { type: types.CHANGE_SELECTED_GEOJSON_WIND_TURBINE_SUBTYPE, payload };
}

export function createSingleWindTurbineDrawnGeoJson() {
  return { type: types.CREATE_SINGLE_WIND_TURBINE_DRAWN_GEOJSON };
}

export function setMarkerRef(payload) {
  return { type: types.SET_MARKER_REF, payload };
}

export function changeSelectedWindTurbineState(payload) {
  return { type: types.CHANGE_SELECTED_WIND_TURBINE_STATE, payload };
}
