import React from "react";
import LegendItem from "./LegendItem/LegendItem";
import { allObjectTypesNames } from "../../../Components/Map/consts/consts";
import {
  toggleEmissionDrawnVisibility,
  toggleMapPoiDrawnVisibility,
  toggleWindTurbineDrawnVisibility,
} from "../../../redux/actions/mapActions";
import { setMarkerRef } from "../../../redux/actions/mapActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as legendActions from "../../../redux/actions/legendActions";
import "./Legend.scss";

class Legend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleLegendItemChange = this.handleLegendItemChange.bind(this);
    this.toggleSingleTreeVisibility =
      this.toggleSingleTreeVisibility.bind(this);
    this.showAllObjectTypes = this.showAllObjectTypes.bind(this);
    this.toggleStreetVisibility = this.toggleStreetVisibility.bind(this);
    this.toggleEmissionVisibility = this.toggleEmissionVisibility.bind(this);
    this.toggleMapPoiVisibility = this.toggleMapPoiVisibility.bind(this);
    this.toggleWindTurbineVisibility =
      this.toggleWindTurbineVisibility.bind(this);
    this.toggleDrawnWindTurbineVisibility =
      this.toggleDrawnWindTurbineVisibility.bind(this);
    this.toggleWindTurbineTooltipVisibility =
      this.toggleWindTurbineTooltipVisibility.bind(this);
    this.toggleMapPoiTooltipVisibility =
      this.toggleMapPoiTooltipVisibility.bind(this);
  }

  handleLegendItemChange(e) {
    const objectTypeName = e.target.name;
    const objectTypeIndex = allObjectTypesNames.indexOf(objectTypeName) + 1;

    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.includes(objectTypeIndex)) {
      hiddenObjectTypesIndexes = hiddenObjectTypesIndexes.filter(
        (x) => x !== objectTypeIndex
      );
    } else {
      hiddenObjectTypesIndexes.push(objectTypeIndex);
    }
    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  toggleSingleTreeVisibility() {
    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.includes(6)) {
      hiddenObjectTypesIndexes = hiddenObjectTypesIndexes.filter(
        (x) => x !== 6
      );
    } else {
      hiddenObjectTypesIndexes.push(6);
    }

    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  toggleStreetVisibility() {
    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.includes(7)) {
      hiddenObjectTypesIndexes = hiddenObjectTypesIndexes.filter(
        (x) => x !== 7
      );
    } else {
      hiddenObjectTypesIndexes.push(7);
    }

    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  toggleEmissionVisibility() {
    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.includes(8)) {
      hiddenObjectTypesIndexes = hiddenObjectTypesIndexes.filter(
        (x) => x !== 8
      );
    } else {
      hiddenObjectTypesIndexes.push(8);
    }

    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  toggleMapPoiVisibility() {
    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.includes(9)) {
      hiddenObjectTypesIndexes = hiddenObjectTypesIndexes.filter(
        (x) => x !== 9
      );
    } else {
      hiddenObjectTypesIndexes.push(9);
    }

    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  showAllObjectTypes() {
    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.length === 0) {
      hiddenObjectTypesIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    } else hiddenObjectTypesIndexes = [];

    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  toggleWindTurbineVisibility() {
    let hiddenObjectTypesIndexes = [
      ...this.props.legend.hiddenObjectTypesIndexes,
    ];

    if (hiddenObjectTypesIndexes.includes(10)) {
      hiddenObjectTypesIndexes = hiddenObjectTypesIndexes.filter(
        (x) => x !== 10
      );
      this.props.setMarkerRef(null);
    } else {
      hiddenObjectTypesIndexes.push(10);
    }

    this.props.setHiddenObjectTypesIndexes(hiddenObjectTypesIndexes);
  }

  toggleDrawnWindTurbineVisibility() {
    this.props.toggleWindTurbineDrawnVisibility();
    this.props.setMarkerRef(null);
  }

  toggleWindTurbineTooltipVisibility() {
    const tooltipPane = document.querySelector(".leaflet-tooltip-pane");
    const descendants = tooltipPane.querySelectorAll(".wind-turbine-tooltip");
    if (this.props.legend.windTurbineTooltipVisibility) {
      descendants.forEach((descendant) => {
        descendant.classList.add("wind-turbine-tooltip-hidden");
      });
    } else {
      descendants.forEach((descendant) => {
        descendant.classList.remove("wind-turbine-tooltip-hidden");
      });
    }

    this.props.toggleWindTurbineTooltip();
  }

  toggleMapPoiTooltipVisibility() {
    const tooltipPane = document.querySelector(".leaflet-tooltip-pane");
    const descendants = tooltipPane.querySelectorAll(".map-poi-tooltip");
    if (this.props.legend.mapPoiTooltipVisibility) {
      descendants.forEach((descendant) => {
        descendant.classList.add("map-poi-tooltip-hidden");
      });
    } else {
      descendants.forEach((descendant) => {
        descendant.classList.remove("map-poi-tooltip-hidden");
      });
    }

    this.props.toggleMapPoiTooltip();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="legend-container" onMouseLeave={this.props.hideLegend}>
        <h5 className="legend-hdr ">{t("Legend")}</h5>
        {allObjectTypesNames.map((objectTypeName) => (
          <LegendItem
            name={objectTypeName}
            checked={
              !this.props.legend.hiddenObjectTypesIndexes.includes(
                allObjectTypesNames.indexOf(objectTypeName) + 1
              )
            }
            onChange={this.handleLegendItemChange}
            disabled={this.props.inRasterSettingsMode}
          />
        ))}
        <LegendItem
          name="SingleTrees"
          checked={!this.props.legend.hiddenObjectTypesIndexes.includes(6)}
          disabled={this.props.inRasterSettingsMode}
          onChange={this.toggleSingleTreeVisibility}
        />
        <LegendItem
          name="Street"
          checked={!this.props.legend.hiddenObjectTypesIndexes.includes(7)}
          disabled={this.props.inRasterSettingsMode}
          onChange={this.toggleStreetVisibility}
        />
        <LegendItem
          name="Emission"
          checked={!this.props.legend.hiddenObjectTypesIndexes.includes(8)}
          disabled={this.props.inRasterSettingsMode}
          onChange={this.toggleEmissionVisibility}
        />
        <LegendItem
          name="MapPoi"
          checked={!this.props.legend.hiddenObjectTypesIndexes.includes(9)}
          disabled={this.props.inRasterSettingsMode}
          onChange={this.toggleMapPoiVisibility}
        />
        <LegendItem
          name="WindTurbine"
          checked={!this.props.legend.hiddenObjectTypesIndexes.includes(10)}
          disabled={this.props.inRasterSettingsMode}
          onChange={this.toggleWindTurbineVisibility}
        />
        <hr className="break-line" />
        <LegendItem
          name="AllArea"
          checked={this.props.legend.hiddenObjectTypesIndexes.length === 0}
          disabled={this.props.inRasterSettingsMode}
          onChange={this.showAllObjectTypes}
        />
        {this.props.allDrawnPolygonsPointsWg.length !== 0 && (
          <LegendItem
            name="DrawnArea"
            checked={
              this.props.legend.allDrawnObjectsVisibility ||
              this.props.inRasterSettingsMode
            }
            disabled={this.props.inRasterSettingsMode}
            onChange={this.props.toggleAllDrawnObjectsVisibility}
          />
        )}
        {this.props.allDrawnTreesCoordinatesWg.length !== 0 && (
          <LegendItem
            name="DrawnTrees"
            checked={
              this.props.legend.allDrawnTreesVisibility ||
              this.props.inRasterSettingsMode
            }
            disabled={this.props.inRasterSettingsMode}
            onChange={this.props.toggleAllDrawnTreesVisibility}
          />
        )}
        {this.props.allDrawnEmissionCoordinatesWg.length !== 0 && (
          <LegendItem
            name="DrawnEmission"
            checked={
              this.props.emissionDrawnVisibility ||
              this.props.inRasterSettingsMode
            }
            disabled={this.props.inRasterSettingsMode}
            onChange={this.props.toggleEmissionDrawnVisibility}
          />
        )}
        {this.props.allDrawnMapPoiCoordinatesWg.length !== 0 && (
          <LegendItem
            name="DrawnMapPoi"
            checked={
              this.props.mapPoiDrawnVisibility ||
              this.props.inRasterSettingsMode
            }
            disabled={this.props.inRasterSettingsMode}
            onChange={this.props.toggleMapPoiDrawnVisibility}
          />
        )}
        {this.props.allDrawnWindTurbineCoordinatesWg.length !== 0 && (
          <LegendItem
            name="DrawnWindTurbine"
            checked={
              this.props.windTurbineDrawnVisibility ||
              this.props.inRasterSettingsMode
            }
            disabled={this.props.inRasterSettingsMode}
            onChange={this.toggleDrawnWindTurbineVisibility}
          />
        )}
        {this.props.currentCase.topographyUrl !== "" &&
          this.props.currentCase.topographyUrl !== null && (
            <LegendItem
              name="Topography"
              checked={this.props.legend.topographyVisibility}
              onChange={this.props.toggleTopographyVisibility}
            />
          )}
        <hr className="break-line" />
        <LegendItem
          name="WindTurbineTooltip"
          checked={this.props.legend.windTurbineTooltipVisibility}
          onChange={this.toggleWindTurbineTooltipVisibility}
        />
        <LegendItem
          name="PoiTooltip"
          checked={this.props.legend.mapPoiTooltipVisibility}
          onChange={this.toggleMapPoiTooltipVisibility}
        />
        <hr className="break-line" />
        {this.props.legend.extraTopo?.map((x) => {
          return (
            <LegendItem
              name={x.name}
              checked={x.checked}
              onChange={() => this.props.setExtraTopographyVisibility(x.name)}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedProjectId: state.projects.loadedProject.id,
    currentCase: state.projects.currentCase,
    legend: state.legend,
    inRasterSettingsMode: state.map.inRasterSettingsMode,
    allDrawnPolygonsPointsWg: state.map.allDrawnPolygonsPointsWg,
    allDrawnTreesCoordinatesWg: state.map.allDrawnTreesCoordinatesWg,
    allDrawnEmissionCoordinatesWg: state.map.allDrawnEmissionCoordinatesWg,
    allDrawnMapPoiCoordinatesWg: state.map.allDrawnMapPoiCoordinatesWg,
    allDrawnWindTurbineCoordinatesWg:
      state.map.allDrawnWindTurbineCoordinatesWg,
    emissionVisibility: state.map.emissionVisibility,
    mapPoiVisibility: state.map.mapPoiVisibility,
    windTurbineVisibilitiy: state.map.windTurbineVisibilitiy,
    emissionDrawnVisibility: state.map.emissionDrawnVisibility,
    mapPoiDrawnVisibility: state.map.mapPoiDrawnVisibility,
    windTurbineDrawnVisibility: state.map.windTurbineDrawnVisibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTopographyVisibility: () =>
      dispatch(legendActions.toggleTopographyVisibility()),
    setHiddenObjectTypesIndexes: (payload) =>
      dispatch(legendActions.setHiddenObjectTypesIndexes(payload)),
    hideLegend: () => dispatch(legendActions.hideLegend()),
    toggleAllDrawnObjectsVisibility: () =>
      dispatch(legendActions.toggleAllDrawnObjectsVisibility()),
    toggleAllDrawnTreesVisibility: () =>
      dispatch(legendActions.toggleAllDrawnTreesVisibility()),
    setExtraTopographyVisibility: (payload) =>
      dispatch(legendActions.setExtraTopographyVisibility(payload)),
    toggleEmissionDrawnVisibility: () =>
      dispatch(toggleEmissionDrawnVisibility()),
    toggleMapPoiDrawnVisibility: () => dispatch(toggleMapPoiDrawnVisibility()),
    toggleWindTurbineDrawnVisibility: () =>
      dispatch(toggleWindTurbineDrawnVisibility()),
    toggleWindTurbineTooltip: () =>
      dispatch(legendActions.toggleWindTurbineTooltip()),
    toggleMapPoiTooltip: () => dispatch(legendActions.toggleMapPoiTooltip()),
    setMarkerRef: (payload) => dispatch(setMarkerRef(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Legend));
