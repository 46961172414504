import { cloneDeep } from "lodash";

export const idThresholds = {
  drawnPolygon: 0,
  singleTree: -1000,
  emission: -2000,
  mapPoi: -3000,
  street: -4000,
  windTurbine: -5000,
};

export const objectTypeIndex = {
  building: 1,
  vegetation: 2,
  pavement: 3,
  water: 4,
  treePatches: 5,
  singleTree: 6,
  street: 7,
  emission: 8,
  mapPointOfInterest: 9,
  windTurbine: 10,
};

export const objectSubtypeKey = {
  building: "t1",
  singleTree: "t6",
};

export const geoJsonPrecision = {
  toUtm: 2,
  toWg: 6,
};

export const allObjectTypesNames = [
  "building",
  "vegetation",
  "pavement",
  "water",
  "tree patches",
];

export const errorCodes = {
  "00001": "Geojson (geojsonUrl) could not be downloaded",
  "00002": "Geojson (drawnGeojsonUrl) could not be downloaded",
  "00003": "Problems parsing the contract from rabbitmq",
  "00004":
    "Topography has values < -10m or domain is outside of available topography",
  "00005": "Geojson (geojsonUrl) could not be downloaded",
  "00006": "Topography file could not be downloaded",
  "00007": "Geojson (geojsonUrl) could not be downloaded",
  "00008": "Geojson for geojson check could not be written",
  "00009": "Geojson not in allowed EPSG",
  "00010": "Necessary key in Geojson is None",
  "00011": "Necessary key in Geojson is not available",
  "00012": "CRS settings cant be read, is file in UTM?",
  "00013": "Static driver file could not be found in file system",
  "00014": "Static driver could not be openend",
  "00015": "Static driver could not be created",
  "00099": "Geojson (drawnGeojsonUrl) could not be downloaded or created",
  90001: "Unknown Error. Neither or both preview and static driver created",
  90002:
    "Unknown Error. Child domain has been invoked, but no file has been created",
  99990: "Initizalization of rasterization task has crashed",
  99999: "Unknown Error.",
};

export const smallWindTableHeader = [
  { field: "a", headerName: "0-1.3" },
  { field: "b", headerName: "1.3-1.8" },
  { field: "c", headerName: "1.8-2.3" },
  { field: "d", headerName: "2.3-3.8" },
  { field: "e", headerName: "3.8-5.4" },
  { field: "f", headerName: "5.4-6.9" },
  { field: "g", headerName: "6.9-8.5" },
  { field: "h", headerName: "8.5-10" },
  { field: "i", headerName: "> 10" },
  { field: "j", headerName: "mean" },
];

export const bigWindTableHeader = [
  { field: "a", headerName: "0-1.3" },
  { field: "b", headerName: "1.4-1.8" },
  { field: "c", headerName: "1.9-2.3" },
  { field: "d", headerName: "2.4-3.8" },
  { field: "e", headerName: "3.9-5.4" },
  { field: "f", headerName: "5.5-6.9" },
  { field: "g", headerName: "7.0-8.4" },
  { field: "h", headerName: "8.5-10.0" },
  { field: "i", headerName: "> 10" },
  { field: "j", headerName: "mean" },
];

export const smallWindTable = [
  {
    direction: "N",
    a: 0.007,
    b: 0.006,
    c: 0.006,
    d: 0.018,
    e: 0.016,
    f: 0.006,
    g: 0.002,
    h: 0.0,
    i: 0.0,
    j: 3.332,
  },
  {
    direction: "NO",
    a: 0.006,
    b: 0.004,
    c: 0.005,
    d: 0.02,
    e: 0.019,
    f: 0.006,
    g: 0.001,
    h: 0.0,
    i: 0.0,
    j: 3.297,
  },
  {
    direction: "O",
    a: 0.011,
    b: 0.01,
    c: 0.014,
    d: 0.054,
    e: 0.042,
    f: 0.016,
    g: 0.003,
    h: 0.001,
    i: 0.0,
    j: 2.939,
  },
  {
    direction: "SO",
    a: 0.009,
    b: 0.009,
    c: 0.014,
    d: 0.047,
    e: 0.021,
    f: 0.003,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 2.301,
  },
  {
    direction: "S",
    a: 0.015,
    b: 0.021,
    c: 0.026,
    d: 0.052,
    e: 0.018,
    f: 0.003,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 2.33,
  },
  {
    direction: "SW",
    a: 0.009,
    b: 0.01,
    c: 0.018,
    d: 0.061,
    e: 0.042,
    f: 0.015,
    g: 0.003,
    h: 0.001,
    i: 0.0,
    j: 3.174,
  },
  {
    direction: "W",
    a: 0.006,
    b: 0.007,
    c: 0.013,
    d: 0.059,
    e: 0.07,
    f: 0.043,
    g: 0.019,
    h: 0.005,
    i: 0.002,
    j: 4.052,
  },
  {
    direction: "NW",
    a: 0.005,
    b: 0.005,
    c: 0.006,
    d: 0.028,
    e: 0.033,
    f: 0.018,
    g: 0.008,
    h: 0.002,
    i: 0.001,
    j: 4.018,
  },
];

export const bigWindTable = [
  {
    direction: "0",
    a: 0.0173,
    b: 0.0103,
    c: 0.0056,
    d: 0.006,
    e: 0.0001,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "30",
    a: 0.023,
    b: 0.0187,
    c: 0.0,
    d: 0.0001,
    e: 0.0,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "60",
    a: 0.0236,
    b: 0.0125,
    c: 0.0011,
    d: 0.0036,
    e: 0.0,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "90",
    a: 0.051,
    b: 0.0055,
    c: 0.0006,
    d: 0.0018,
    e: 0.0,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "120",
    a: 0.05,
    b: 0.0133,
    c: 0.0015,
    d: 0.0018,
    e: 0.0,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "150",
    a: 0.0201,
    b: 0.0124,
    c: 0.0016,
    d: 0.0027,
    e: 0.0,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "180",
    a: 0.0303,
    b: 0.0168,
    c: 0.0267,
    d: 0.0168,
    e: 0.0011,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "210",
    a: 0.0461,
    b: 0.0191,
    c: 0.0322,
    d: 0.0185,
    e: 0.0015,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "240",
    a: 0.0831,
    b: 0.0172,
    c: 0.0133,
    d: 0.0221,
    e: 0.0003,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "270",
    a: 0.0379,
    b: 0.035,
    c: 0.0092,
    d: 0.0293,
    e: 0.0071,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "300",
    a: 0.0232,
    b: 0.0169,
    c: 0.0124,
    d: 0.068,
    e: 0.0131,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
  {
    direction: "330",
    a: 0.026,
    b: 0.0173,
    c: 0.0211,
    d: 0.0502,
    e: 0.004,
    f: 0.0,
    g: 0.0,
    h: 0.0,
    i: 0.0,
    j: 1.8,
  },
];

export const nightTimeAssesment = {
  hour: [
    {
      value: "03:00",
      label: "03:00",
    },
    {
      value: "04:00",
      label: "04:00",
    },
    {
      value: "05:00",
      label: "05:00",
    },
    {
      value: "06:00",
      label: "06:00",
    },
  ],
  halfHour: [
    {
      value: "03:00",
      label: "03:00",
    },
    {
      value: "03:30",
      label: "03:30",
    },
    {
      value: "04:00",
      label: "04:00",
    },
    {
      value: "04:30",
      label: "04:30",
    },
    {
      value: "05:00",
      label: "05:00",
    },
    {
      value: "05:30",
      label: "05:30",
    },
    {
      value: "06:00",
      label: "06:00",
    },
  ],
};

export const daytimeAssesment = {
  hour: [
    {
      value: "12:00",
      label: "12:00",
    },
    {
      value: "13:00",
      label: "13:00",
    },
    {
      value: "14:00",
      label: "14:00",
    },
    {
      value: "15:00",
      label: "15:00",
    },
    {
      value: "16:00",
      label: "16:00",
    },
    {
      value: "17:00",
      label: "17:00",
    },
    {
      value: "18:00",
      label: "18:00",
    },
  ],
  halfHour: [
    {
      value: "12:00",
      label: "12:00",
    },
    {
      value: "12:30",
      label: "12:30",
    },
    {
      value: "13:00",
      label: "13:00",
    },
    {
      value: "13:30",
      label: "13:30",
    },
    {
      value: "14:00",
      label: "14:00",
    },
    {
      value: "14:30",
      label: "14:30",
    },
    {
      value: "15:00",
      label: "15:00",
    },
    {
      value: "15:30",
      label: "15:30",
    },
    {
      value: "16:00",
      label: "16:00",
    },
    {
      value: "16:30",
      label: "16:30",
    },
    {
      value: "17:00",
      label: "17:00",
    },
    {
      value: "17:30",
      label: "17:30",
    },
    {
      value: "18:00",
      label: "18:00",
    },
  ],
};

export const emissionsInitFactorTable = [
  {
    factor: "emiss_factor_main",
    pm10: 1.667,
    pm25: 0.334,
  },
  {
    factor: "emiss_factor_side",
    pm10: 1.667,
    pm25: 0.334,
  },
];
export const hoursFromUtcOptions = [
  { value: "-14", label: "-14" },
  { value: "-13", label: "-13" },
  { value: "-12", label: "-12" },
  { value: "-11", label: "-11" },
  { value: "-10", label: "-10" },
  { value: "-9", label: "-9" },
  { value: "-8", label: "-8" },
  { value: "-7", label: "-7" },
  { value: "-6", label: "-6" },
  { value: "-5", label: "-5" },
  { value: "-4", label: "-4" },
  { value: "-3", label: "-3" },
  { value: "-2", label: "-2" },
  { value: "-1", label: "-1" },
  { value: "0", label: "0" },
  { value: "+1", label: "+1" },
  { value: "+2", label: "+2" },
  { value: "+3", label: "+3" },
  { value: "+4", label: "+4" },
  { value: "+5", label: "+5" },
  { value: "+6", label: "+6" },
  { value: "+7", label: "+7" },
  { value: "+8", label: "+8" },
  { value: "+9", label: "+9" },
  { value: "+10", label: "+10" },
  { value: "+11", label: "+11" },
  { value: "+12", label: "+12" },
];

export const windMultiDirections = [
  { value: "North", label: "North" },
  { value: "NorthNorthEast", label: "NorthNorthEast" },
  { value: "NorthEast", label: "NorthEast" },
  { value: "EastNorthEast", label: "EastNorthEast" },
  { value: "East", label: "East" },
  { value: "EastSouthEast", label: "EastSouthEast" },
  { value: "SouthEast", label: "SouthEast" },
  { value: "SouthSouthEast", label: "SouthSouthEast" },
  { value: "South", label: "South" },
  { value: "SouthSouthWest", label: "SouthSouthWest" },
  { value: "SouthWest", label: "SouthWest" },
  { value: "WestSouthWest", label: "WestSouthWest" },
  { value: "West", label: "West" },
  { value: "WestNorthWest", label: "WestNorthWest" },
  { value: "NorthWest", label: "NorthWest" },
  { value: "NorthNorthWest", label: "NorthNorthWest" },
];

export const emissionsSubtypes = [
  { value: "GasFiredDomesticHeating", label: "GasFiredDomesticHeating" },
  { value: "OilFiredDomesticHeating", label: "OilFiredDomesticHeating" },
  { value: "CoalFiredDomesticHeating", label: "CoalFiredDomesticHeating" },
  { value: "WoodFiredDomesticHeating", label: "WoodFiredDomesticHeating" },
  { value: "Other", label: "Other" },
];

export const generatePointEmissionsTable = (duration) => {
  const maxDuration = 54;
  const durationNum = Number(duration);
  let itemToDelete = durationNum - maxDuration;
  let arrayOfPointEmissionTable = cloneDeep(defaultPointEmissionTable);

  for (let index = 0; index < 5; index++) {
    arrayOfPointEmissionTable[index].splice(itemToDelete);
  }
  return arrayOfPointEmissionTable;
};

export const getAssesmentTimeOptions = (valuesToAsses) => {
  const duration = Number(valuesToAsses.duration) - 6;
  const interval = valuesToAsses.mapOutputInterval;
  const values = [];

  for (let index = 9; index < duration + 9; index++) {
    let day = 1;
    let adjustedIndex = index;
    let preZero = false;

    if (index > 23 && index < 48) {
      adjustedIndex = index - 24;
      day = 2;
    } else if (index > 47 && index < 63) {
      adjustedIndex = index - 48;
      day = 3;
    }

    if (adjustedIndex === 9) {
      preZero = true;
    }

    const element = {
      value: `Day ${day} - ${preZero ? "0" : ""}${adjustedIndex}:00`,
      label: `Day ${day} - ${preZero ? "0" : ""}${adjustedIndex}:00`,
    };

    values.push(element);

    if (interval === 30) {
      const halfHour = {
        value: `Day ${day} - ${preZero ? "0" : ""}${adjustedIndex}:30`,
        label: `Day ${day} - ${preZero ? "0" : ""}${adjustedIndex}:30`,
      };
      values.push(halfHour);
    }
  }
  return values;
};

export const defaultPointEmissionTable = [
  [
    { factor: "PointEmissionFactor", pm10: 0.00000001, pm25: 0.00000001 },
    { factor: "Hourly Factor: Day 1 | 02 - 02:59", pm10: 3.37, pm25: 3.37 },
    { factor: "Hourly Factor: Day 1 | 03 - 03:59", pm10: 3.46, pm25: 3.46 },
    { factor: "Hourly Factor: Day 1 | 04 - 04:59", pm10: 4.68, pm25: 4.68 },
    { factor: "Hourly Factor: Day 1 | 05 - 05:59", pm10: 11.14, pm25: 11.14 },
    { factor: "Hourly Factor: Day 1 | 06 - 06:59", pm10: 14.33, pm25: 14.33 },
    { factor: "Hourly Factor: Day 1 | 07 - 07:59", pm10: 14.7, pm25: 14.7 },
    { factor: "Hourly Factor: Day 1 | 08 - 08:59", pm10: 14.61, pm25: 14.61 },
    { factor: "Hourly Factor: Day 1 | 09 - 09:59", pm10: 12.64, pm25: 12.64 },
    { factor: "Hourly Factor: Day 1 | 10 - 10:59", pm10: 10.86, pm25: 10.86 },
    { factor: "Hourly Factor: Day 1 | 11 - 11:59", pm10: 10.02, pm25: 10.02 },
    { factor: "Hourly Factor: Day 1 | 12 - 12:59", pm10: 9.93, pm25: 9.93 },
    { factor: "Hourly Factor: Day 1 | 13 - 13:59", pm10: 9.36, pm25: 9.36 },
    { factor: "Hourly Factor: Day 1 | 14 - 14:59", pm10: 9.18, pm25: 9.18 },
    { factor: "Hourly Factor: Day 1 | 15 - 15:59", pm10: 9.27, pm25: 9.27 },
    { factor: "Hourly Factor: Day 1 | 16 - 16:59", pm10: 10.49, pm25: 10.49 },
    { factor: "Hourly Factor: Day 1 | 17 - 17:59", pm10: 13.2, pm25: 13.2 },
    { factor: "Hourly Factor: Day 1 | 18 - 18:59", pm10: 14.23, pm25: 14.23 },
    { factor: "Hourly Factor: Day 1 | 19 - 19:59", pm10: 13.02, pm25: 13.02 },
    { factor: "Hourly Factor: Day 1 | 20 - 20:59", pm10: 12.64, pm25: 12.64 },
    { factor: "Hourly Factor: Day 1 | 21 - 21:59", pm10: 9.36, pm25: 9.36 },
    { factor: "Hourly Factor: Day 1 | 22 - 22:59", pm10: 3.93, pm25: 3.93 },
    { factor: "Hourly Factor: Day 1 | 23 - 23:59", pm10: 3.56, pm25: 3.56 },
    { factor: "Hourly Factor: Day 2 | 00 - 00:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 01 - 01:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 02 - 02:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 03 - 03:59", pm10: 3.09, pm25: 3.09 },
    { factor: "Hourly Factor: Day 2 | 04 - 04:59", pm10: 4.18, pm25: 4.18 },
    { factor: "Hourly Factor: Day 2 | 05 - 05:59", pm10: 9.95, pm25: 9.95 },
    { factor: "Hourly Factor: Day 2 | 06 - 06:59", pm10: 12.79, pm25: 12.79 },
    { factor: "Hourly Factor: Day 2 | 07 - 07:59", pm10: 13.12, pm25: 13.12 },
    { factor: "Hourly Factor: Day 2 | 08 - 08:59", pm10: 13.04, pm25: 13.04 },
    { factor: "Hourly Factor: Day 2 | 09 - 09:59", pm10: 11.28, pm25: 11.28 },
    { factor: "Hourly Factor: Day 2 | 10 - 10:59", pm10: 9.7, pm25: 9.7 },
    { factor: "Hourly Factor: Day 2 | 11 - 11:59", pm10: 8.94, pm25: 8.94 },
    { factor: "Hourly Factor: Day 2 | 12 - 12:59", pm10: 8.86, pm25: 8.86 },
    { factor: "Hourly Factor: Day 2 | 13 - 13:59", pm10: 8.36, pm25: 8.36 },
    { factor: "Hourly Factor: Day 2 | 14 - 14:59", pm10: 8.19, pm25: 8.19 },
    { factor: "Hourly Factor: Day 2 | 15 - 15:59", pm10: 8.27, pm25: 8.27 },
    { factor: "Hourly Factor: Day 2 | 16 - 16:59", pm10: 9.36, pm25: 9.36 },
    { factor: "Hourly Factor: Day 2 | 17 - 17:59", pm10: 11.79, pm25: 11.79 },
    { factor: "Hourly Factor: Day 2 | 18 - 18:59", pm10: 12.7, pm25: 12.7 },
    { factor: "Hourly Factor: Day 2 | 19 - 19:59", pm10: 11.62, pm25: 11.62 },
    { factor: "Hourly Factor: Day 2 | 20 - 20:59", pm10: 11.28, pm25: 11.28 },
    { factor: "Hourly Factor: Day 2 | 21 - 21:59", pm10: 8.36, pm25: 8.36 },
    { factor: "Hourly Factor: Day 2 | 22 - 22:59", pm10: 3.51, pm25: 3.51 },
    { factor: "Hourly Factor: Day 2 | 23 - 23:59", pm10: 3.18, pm25: 3.18 },
    { factor: "Hourly Factor: Day 3 | 00 - 00:59", pm10: 3.4, pm25: 3.4 },
    { factor: "Hourly Factor: Day 3 | 01 - 01:59", pm10: 3.4, pm25: 3.4 },
    { factor: "Hourly Factor: Day 3 | 02 - 02:59", pm10: 3.4, pm25: 3.4 },
    { factor: "Hourly Factor: Day 3 | 03 - 03:59", pm10: 3.5, pm25: 3.5 },
    { factor: "Hourly Factor: Day 3 | 04 - 04:59", pm10: 4.73, pm25: 4.73 },
    { factor: "Hourly Factor: Day 3 | 05 - 05:59", pm10: 11.25, pm25: 11.25 },
    { factor: "Hourly Factor: Day 3 | 06 - 06:59", pm10: 14.47, pm25: 14.47 },
    { factor: "Hourly Factor: Day 3 | 07 - 07:59", pm10: 14.84, pm25: 14.84 },
  ],
  [
    { factor: "PointEmissionFactor", pm10: 0.00000001, pm25: 0.00000001 },
    { factor: "Hourly Factor: Day 1 | 02 - 02:59", pm10: 3.37, pm25: 3.37 },
    { factor: "Hourly Factor: Day 1 | 03 - 03:59", pm10: 3.46, pm25: 3.46 },
    { factor: "Hourly Factor: Day 1 | 04 - 04:59", pm10: 4.68, pm25: 4.68 },
    { factor: "Hourly Factor: Day 1 | 05 - 05:59", pm10: 11.14, pm25: 11.14 },
    { factor: "Hourly Factor: Day 1 | 06 - 06:59", pm10: 14.33, pm25: 14.33 },
    { factor: "Hourly Factor: Day 1 | 07 - 07:59", pm10: 14.7, pm25: 14.7 },
    { factor: "Hourly Factor: Day 1 | 08 - 08:59", pm10: 14.61, pm25: 14.61 },
    { factor: "Hourly Factor: Day 1 | 09 - 09:59", pm10: 12.64, pm25: 12.64 },
    { factor: "Hourly Factor: Day 1 | 10 - 10:59", pm10: 10.86, pm25: 10.86 },
    { factor: "Hourly Factor: Day 1 | 11 - 11:59", pm10: 10.02, pm25: 10.02 },
    { factor: "Hourly Factor: Day 1 | 12 - 12:59", pm10: 9.93, pm25: 9.93 },
    { factor: "Hourly Factor: Day 1 | 13 - 13:59", pm10: 9.36, pm25: 9.36 },
    { factor: "Hourly Factor: Day 1 | 14 - 14:59", pm10: 9.18, pm25: 9.18 },
    { factor: "Hourly Factor: Day 1 | 15 - 15:59", pm10: 9.27, pm25: 9.27 },
    { factor: "Hourly Factor: Day 1 | 16 - 16:59", pm10: 10.49, pm25: 10.49 },
    { factor: "Hourly Factor: Day 1 | 17 - 17:59", pm10: 13.2, pm25: 13.2 },
    { factor: "Hourly Factor: Day 1 | 18 - 18:59", pm10: 14.23, pm25: 14.23 },
    { factor: "Hourly Factor: Day 1 | 19 - 19:59", pm10: 13.02, pm25: 13.02 },
    { factor: "Hourly Factor: Day 1 | 20 - 20:59", pm10: 12.64, pm25: 12.64 },
    { factor: "Hourly Factor: Day 1 | 21 - 21:59", pm10: 9.36, pm25: 9.36 },
    { factor: "Hourly Factor: Day 1 | 22 - 22:59", pm10: 3.93, pm25: 3.93 },
    { factor: "Hourly Factor: Day 1 | 23 - 23:59", pm10: 3.56, pm25: 3.56 },
    { factor: "Hourly Factor: Day 2 | 00 - 00:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 01 - 01:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 02 - 02:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 03 - 03:59", pm10: 3.01, pm25: 3.01 },
    { factor: "Hourly Factor: Day 2 | 04 - 04:59", pm10: 4.18, pm25: 4.18 },
    { factor: "Hourly Factor: Day 2 | 05 - 05:59", pm10: 9.95, pm25: 9.95 },
    { factor: "Hourly Factor: Day 2 | 06 - 06:59", pm10: 12.79, pm25: 12.79 },
    { factor: "Hourly Factor: Day 2 | 07 - 07:59", pm10: 13.12, pm25: 13.12 },
    { factor: "Hourly Factor: Day 2 | 08 - 08:59", pm10: 13.04, pm25: 13.04 },
    { factor: "Hourly Factor: Day 2 | 09 - 09:59", pm10: 11.28, pm25: 11.28 },
    { factor: "Hourly Factor: Day 2 | 10 - 10:59", pm10: 9.7, pm25: 9.7 },
    { factor: "Hourly Factor: Day 2 | 11 - 11:59", pm10: 8.94, pm25: 8.94 },
    { factor: "Hourly Factor: Day 2 | 12 - 12:59", pm10: 8.86, pm25: 8.86 },
    { factor: "Hourly Factor: Day 2 | 13 - 13:59", pm10: 8.36, pm25: 8.36 },
    { factor: "Hourly Factor: Day 2 | 14 - 14:59", pm10: 8.19, pm25: 8.19 },
    { factor: "Hourly Factor: Day 2 | 15 - 15:59", pm10: 8.27, pm25: 8.27 },
    { factor: "Hourly Factor: Day 2 | 16 - 16:59", pm10: 9.36, pm25: 9.36 },
    { factor: "Hourly Factor: Day 2 | 17 - 17:59", pm10: 11.79, pm25: 11.79 },
    { factor: "Hourly Factor: Day 2 | 18 - 18:59", pm10: 12.7, pm25: 12.7 },
    { factor: "Hourly Factor: Day 2 | 19 - 19:59", pm10: 11.62, pm25: 11.62 },
    { factor: "Hourly Factor: Day 2 | 20 - 20:59", pm10: 11.28, pm25: 11.28 },
    { factor: "Hourly Factor: Day 2 | 21 - 21:59", pm10: 8.36, pm25: 8.36 },
    { factor: "Hourly Factor: Day 2 | 22 - 22:59", pm10: 3.51, pm25: 3.51 },
    { factor: "Hourly Factor: Day 2 | 23 - 23:59", pm10: 3.18, pm25: 3.18 },
    { factor: "Hourly Factor: Day 3 | 00 - 00:59", pm10: 3.4, pm25: 3.4 },
    { factor: "Hourly Factor: Day 3 | 01 - 01:59", pm10: 3.4, pm25: 3.4 },
    { factor: "Hourly Factor: Day 3 | 02 - 02:59", pm10: 3.4, pm25: 3.4 },
    { factor: "Hourly Factor: Day 3 | 03 - 03:59", pm10: 3.5, pm25: 3.5 },
    { factor: "Hourly Factor: Day 3 | 04 - 04:59", pm10: 4.73, pm25: 4.73 },
    { factor: "Hourly Factor: Day 3 | 05 - 05:59", pm10: 11.25, pm25: 11.25 },
    { factor: "Hourly Factor: Day 3 | 06 - 06:59", pm10: 14.47, pm25: 14.47 },
    { factor: "Hourly Factor: Day 3 | 07 - 07:59", pm10: 14.84, pm25: 14.84 },
  ],
  [
    { factor: "PointEmissionFactor", pm10: 0.0000001, pm25: 0.0000001 },
    { factor: "Hourly Factor: Day 1 | 02 - 02:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 03 - 03:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 04 - 04:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 05 - 05:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 06 - 06:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 07 - 07:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 08 - 08:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 09 - 09:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 10 - 10:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 11 - 11:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 12 - 12:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 13 - 13:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 14 - 14:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 15 - 15:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 16 - 16:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 17 - 17:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 18 - 18:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 19 - 19:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 20 - 20:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 21 - 21:59", pm10: 1.87, pm25: 1.87 },
    { factor: "Hourly Factor: Day 1 | 22 - 22:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 23 - 23:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 00 - 00:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 01 - 01:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 02 - 02:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 03 - 03:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 04 - 04:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 05 - 05:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 06 - 06:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 07 - 07:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 08 - 08:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 09 - 09:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 10 - 10:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 11 - 11:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 12 - 12:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 13 - 13:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 14 - 14:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 15 - 15:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 16 - 16:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 17 - 17:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 18 - 18:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 19 - 19:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 20 - 20:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 21 - 21:59", pm10: 1.67, pm25: 1.67 },
    { factor: "Hourly Factor: Day 2 | 22 - 22:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 23 - 23:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 00 - 00:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 01 - 01:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 02 - 02:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 03 - 03:59", pm10: 1.89, pm25: 1.89 },
    { factor: "Hourly Factor: Day 3 | 04 - 04:59", pm10: 1.89, pm25: 1.89 },
    { factor: "Hourly Factor: Day 3 | 05 - 05:59", pm10: 1.89, pm25: 1.89 },
    { factor: "Hourly Factor: Day 3 | 06 - 06:59", pm10: 1.89, pm25: 1.89 },
    { factor: "Hourly Factor: Day 3 | 07 - 07:59", pm10: 1.89, pm25: 1.89 },
  ],
  [
    { factor: "PointEmissionFactor", pm10: 0.0000001, pm25: 0.0000001 },
    { factor: "Hourly Factor: Day 1 | 02 - 02:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 03 - 03:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 04 - 04:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 05 - 05:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 06 - 06:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 07 - 07:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 08 - 08:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 09 - 09:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 10 - 10:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 11 - 11:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 12 - 12:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 13 - 13:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 14 - 14:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 15 - 15:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 16 - 16:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 17 - 17:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 1 | 18 - 18:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 19 - 19:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 20 - 20:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 21 - 21:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 22 - 22:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 1 | 23 - 23:59", pm10: 1.4, pm25: 1.4 },
    { factor: "Hourly Factor: Day 2 | 00 - 00:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 01 - 01:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 02 - 02:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 03 - 03:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 04 - 04:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 05 - 05:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 06 - 06:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 07 - 07:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 08 - 08:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 09 - 09:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 10 - 10:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 11 - 11:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 12 - 12:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 13 - 13:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 14 - 14:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 15 - 15:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 16 - 16:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 17 - 17:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 2 | 18 - 18:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 19 - 19:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 20 - 20:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 21 - 21:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 22 - 22:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 2 | 23 - 23:59", pm10: 1.25, pm25: 1.25 },
    { factor: "Hourly Factor: Day 3 | 00 - 00:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 01 - 01:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 02 - 02:59", pm10: 0.0, pm25: 0.0 },
    { factor: "Hourly Factor: Day 3 | 03 - 03:59", pm10: 1.42, pm25: 1.42 },
    { factor: "Hourly Factor: Day 3 | 04 - 04:59", pm10: 1.42, pm25: 1.42 },
    { factor: "Hourly Factor: Day 3 | 05 - 05:59", pm10: 1.42, pm25: 1.42 },
    { factor: "Hourly Factor: Day 3 | 06 - 06:59", pm10: 1.42, pm25: 1.42 },
    { factor: "Hourly Factor: Day 3 | 07 - 07:59", pm10: 1.42, pm25: 1.42 },
  ],
  [
    { factor: "PointEmissionFactor", pm10: 0.0000001, pm25: 0.0000001 },
    { factor: "Hourly Factor: Day 1 | 02 - 02:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 03 - 03:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 04 - 04:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 05 - 05:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 06 - 06:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 07 - 07:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 08 - 08:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 09 - 09:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 10 - 10:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 11 - 11:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 12 - 12:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 13 - 13:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 14 - 14:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 15 - 15:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 16 - 16:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 17 - 17:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 18 - 18:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 19 - 19:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 20 - 20:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 21 - 21:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 22 - 22:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 1 | 23 - 23:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 00 - 00:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 01 - 01:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 02 - 02:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 03 - 03:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 04 - 04:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 05 - 05:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 06 - 06:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 07 - 07:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 08 - 08:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 09 - 09:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 10 - 10:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 11 - 11:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 12 - 12:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 13 - 13:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 14 - 14:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 15 - 15:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 16 - 16:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 17 - 17:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 18 - 18:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 19 - 19:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 20 - 20:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 21 - 21:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 22 - 22:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 2 | 23 - 23:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 00 - 00:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 01 - 01:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 02 - 02:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 03 - 03:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 04 - 04:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 05 - 05:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 06 - 06:59", pm10: 1.0, pm25: 1.0 },
    { factor: "Hourly Factor: Day 3 | 07 - 07:59", pm10: 1.0, pm25: 1.0 },
  ],
];
