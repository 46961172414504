import { types } from "../types/map";
import { types as projectTypes } from "../types/projects";
import { allCrsOptions } from "../../containers/Tools/OsmDownload/osmConst";
import {
  reprojectWgToUtmPoint,
  reprojectUtmToWgPoint,
} from "../../common/utmDef";
import gp from "geojson-precision";
import { utm31Def, utm32Def, utm33Def, wgDef } from "../../common/utmDef";
import { reproject } from "reproject";
import {
  idThresholds,
  objectSubtypeKey,
} from "../../Components/Map/consts/consts";
import {
  getCurrentObjectType,
  getCurrentObjectSubtype,
  getAllCorrespondingSubtypes,
} from "../../containers/Domain/geo-selectors";
import { objectTypeIndex as objectTypeIndexTemplate } from "../../Components/Map/consts/consts";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";
import { templatesJson } from "../../JSON/templates";
import { cloneDeep } from "lodash";

export const allObjectTypes = {
  fieldName: "t",
  value: ["building", "vegetation", "pavement", "water", "tree patches"],
};

const buildingSubtypes = getAllCorrespondingSubtypes(1);
const streetSubtypes = getAllCorrespondingSubtypes(7);

export const singleTreeObjectType = {
  fieldName: "t",
  value: ["singleTree"],
};

export const streetObjectType = {
  fieldName: "t",
  value: ["street"],
};

export const pointEmissionObjectType = {
  fieldName: "t",
  value: ["emission"],
};

export const pointOfInterestObjectType = {
  fieldName: "t",
  value: ["point_of_interest"],
};

export const windTurbineObjectType = {
  fieldName: "t",
  value: ["wind_turbine"],
};

const geojsonInitialState = {
  ///GEOJSON
  geoJsonWg: null,
  currentObjectType: null,
  currentObjectSubtype: null,
  isCentering: false,
  crsDef: null,
};

const polygonEditorInitialState = {
  ///POLYGON_EDITOR
  allObjectSubtypes: buildingSubtypes,
  objectType: "building",
  objectSubtype: "Residential <1950",
  objectTypeIndex: 0,
  objectSubtypeIndex: 0,
  inputCoords: [
    { lng: "", lat: "" },
    { lng: "", lat: "" },
    { lng: "", lat: "" },
  ],
  polygonPointsWg: [],
  polygonPointsUtm: [],
  drawnPolygonGeojsonWg: {
    type: "FeatureCollection",
    name: "DrawnGeoJSON",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:EPSG::25832",
      },
    },
    features: [],
  },
  polygonDrawn: false,
  currentIncomingPolygonId: idThresholds.drawnPolygon,
  allDrawnPolygonsPointsUtm: [],
  allDrawnPolygonsPointsWg: [],
  arrayOfDrawnPolygonId: [],
  selectedPolygonIndex: null,
  allInputCoords: [],
  allDrawnPolygonGeoProps: [],
  inSelectMode: true,
  drawnPolyCoordsIndex: null,
  canDrawPolygon: false,
  currentIncomingStreetId: idThresholds.street,
  selectedPolygonPoint: null,
  isPolygonEditorInputFocused: false,
};

const treeDrawingInitialState = {
  currentTreeId: idThresholds.singleTree,
  arrayOfTreesId: [],
  allDrawnTreesCoordinatesWg: [],
  allDrawnTreesCoordinatesUtm: [],
  allTreesGeoProps: [],
  treeGeoJsonUtm: null,
};

const copyPropertiesInitialState = {
  inCopyPropsMode: false,
  assignedObjectCopyId: [],
  assignedCopyCoords: [],
  inSingleTreeCopyPropsMode: false,
  assignedSingleTreeCopyId: [],
  assignedSingleTreeCopyCoords: [],
  inEmissionCopyPropsMode: false,
  assignedEmissionCopyId: [],
  assignedEmissionCopyCoords: [],
  inWindTurbineCopyPropsMode: false,
  assignedWindTurbineCopyId: [],
  assignedWindTurbineCopyCoords: [],
};

const emissionInitialState = {
  emissionGeoJsonWg: null,
  currentIncomingEmissionId: idThresholds.emission,
  arrayOfEmissionId: [],
  allDrawnEmissionCoordinatesWg: [],
  allDrawnEmissionCoordinatesUtm: [],
  allDrawnEmissionGeoProps: [],
  emissionDrawnGeoJsonUtm: null,
  emissionDrawnVisibility: true,
};

const mapPoiInitialState = {
  mapPoiGeoJsonWg: null,
  currentIncomingMapPoiId: idThresholds.mapPoi,
  arrayOfMapPoiId: [],
  allDrawnMapPoiCoordinatesWg: [],
  allDrawnMapPoiCoordinatesUtm: [],
  allDrawnMapPoiGeoProps: [],
  mapPoiDrawnGeoJsonUtm: null,
  mapPoiDrawnVisibility: true,
};

const windTurbineInitialState = {
  windTurbineGeoJsonWg: null,
  currentIncomingWindTurbineId: idThresholds.windTurbine,
  arrayOfWindTurbineId: [],
  allDrawnWindTurbineCoordinatesWg: [],
  allDrawnWindTurbineCoordinatesUtm: [],
  allDrawnWindTurbineGeoProps: [],
  windTurbineDrawnGeoJsonUtm: null,
  windTurbineDrawnVisibility: true,
};

const drawingInitialState = {
  inPolygonDrawingMode: false,
  inTreeDrawingMode: false,
  inStreetDrawingMode: false,
  inEmissionDrawingMode: false,
  inMapPoiDrawingMode: false,
  inWindTurbineDrawingMode: false,
  displayTreeCursor: false,
  displayEmissionCursor: false,
  displayMapPoiCursor: false,
  displayWindTurbineCursor: false,
  selectedEmissionIndex: null,
  selectedMapPoiIndex: null,
  selectedWindTurbineIndex: null,
  isEmissionDraggable: false,
  isMapPoiDraggable: false,
  selectedTreeIndex: null,
  isWindTurbineDraggable: false,
  isTreeDraggable: false,
};

const initialState = {
  geoKey: 23,
  filteredGeoJson: null,
  selectedMapItemGeoProps: null,
  selectedMapItemGeoJson: null,
  tempKey: "",
  tempValue: "",
  polygonData: [],
  wmsLayersNames: [],
  inDrawingMode: false,
  inRasterSettingsMode: false,
  currentCursorCoords: null,
  mapDataSavingStatus: "false",
  markerRef: null,
  ...geojsonInitialState,
  ...polygonEditorInitialState,
  ...drawingInitialState,
  ...treeDrawingInitialState,
  ...copyPropertiesInitialState,
  ...emissionInitialState,
  ...mapPoiInitialState,
  ...windTurbineInitialState,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GEOJSON_WG: {
      let geoJsonWg = action.payload.geoJson;
      const crsNumber = action.payload.crs;
      const wmsLayersNames = cloneDeep(state.wmsLayersNames);
      const inRasterSettingsMode = cloneDeep(state.inRasterSettingsMode);

      let crsDef = null;
      if (crsNumber === "25831") {
        crsDef = utm31Def;
      } else if (crsNumber === "25832") {
        crsDef = utm32Def;
      } else if (crsNumber === "25833") {
        crsDef = utm33Def;
      } else {
        crsDef = allCrsOptions.find((x) => x.value === `EPSG:${crsNumber}`).def;
      }

      let emissionGeoJsonWg = {
        type: "FeatureCollection",
        name: `urn:ogc:def:crs:EPSG::${crsNumber}`,
        crs: {
          type: "name",
          properties: {
            name: `urn:ogc:def:crs:EPSG::${crsNumber}`,
          },
        },
        features: [],
      };

      let mapPoiGeoJsonWg = cloneDeep(emissionGeoJsonWg);
      let windTurbineGeoJsonWg = cloneDeep(emissionGeoJsonWg);

      emissionGeoJsonWg.features = geoJsonWg.features.filter((x) => {
        return x.properties.t === 8;
      });
      mapPoiGeoJsonWg.features = geoJsonWg.features.filter((x) => {
        return x.properties.t === 9;
      });
      windTurbineGeoJsonWg.features = geoJsonWg.features.filter((x) => {
        return x.properties.t === 10;
      });

      geoJsonWg.features = geoJsonWg.features.filter((x) => {
        return (
          x.properties.t !== 8 && x.properties.t !== 9 && x.properties.t !== 10
        );
      });

      return {
        ...initialState,
        geoJsonWg: geoJsonWg,
        crsDef: crsDef,
        wmsLayersNames: wmsLayersNames,
        emissionGeoJsonWg: emissionGeoJsonWg,
        mapPoiGeoJsonWg: mapPoiGeoJsonWg,
        windTurbineGeoJsonWg: windTurbineGeoJsonWg,
        inRasterSettingsMode: inRasterSettingsMode,
      };
    }

    case types.SET_POLYGON_DATA:
      return {
        ...state,
        polygonData: action.payload,
      };

    case types.CHANGE_OBJECT_TYPE:
      const { objectId, objectTypeIndex } = action.payload;

      const geoJsonWg = { ...state.geoJsonWg };

      const featureIndexInGeoJson = geoJsonWg.features.findIndex(
        (x) => x.properties.id === objectId
      );

      let objectGeojsonProperties =
        geoJsonWg.features[featureIndexInGeoJson].properties;
      const prevObjectTypeIndex = objectGeojsonProperties.t;

      delete objectGeojsonProperties[`t${prevObjectTypeIndex}`];
      objectGeojsonProperties.t = objectTypeIndex;
      objectGeojsonProperties[`t${objectTypeIndex}`] = 1;

      if (prevObjectTypeIndex === objectTypeIndexTemplate.building) {
        delete objectGeojsonProperties.bh;
      } else if (prevObjectTypeIndex === objectTypeIndexTemplate.treePatches) {
        delete objectGeojsonProperties.th;
      }

      if (
        objectTypeIndex === objectTypeIndexTemplate.building ||
        objectTypeIndex === objectTypeIndexTemplate.treePatches
      ) {
        const currentObjectTemplate = templatesJson.find(
          (x) =>
            x.t === objectGeojsonProperties.t &&
            x[`t${objectGeojsonProperties.t}`] ===
              objectGeojsonProperties[`t${objectGeojsonProperties.t}`]
        );
        if (objectTypeIndex === objectTypeIndexTemplate.building) {
          objectGeojsonProperties.bh = Number(currentObjectTemplate.bh);
        } else if (objectTypeIndex === objectTypeIndexTemplate.treePatches) {
          objectGeojsonProperties.th = Number(currentObjectTemplate.th);
        }
      }

      const indexOfGeoJsonObject = geoJsonWg.features.findIndex(
        (x) => x.properties.id === objectId
      );
      geoJsonWg.features[indexOfGeoJsonObject].properties =
        objectGeojsonProperties;

      const currentObjectType = getCurrentObjectType(
        state.selectedMapItemGeoProps
      );
      const currentObjectSubtype = getCurrentObjectSubtype(
        state.selectedMapItemGeoProps
      );
      const allObjectSubtypes = getAllCorrespondingSubtypes(
        state.selectedMapItemGeoProps.t
      );

      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        if (polygonData.length === 1) {
          polygonData = [];
        } else {
          polygonData.filter((x) => {
            return x.id !== objectId;
          });
        }
      }

      return {
        ...state,
        geoJsonWg: geoJsonWg,
        currentObjectType: currentObjectType,
        currentObjectSubtype: currentObjectSubtype,
        allObjectSubtypes: allObjectSubtypes,
        polygonData: polygonData,
      };

    case types.CHANGE_OBJECT_SUBTYPE: {
      const objectId = action.payload.objectId;
      const subtypeIndex = action.payload.subtypeIndex;
      let geoJsonWg = state.geoJsonWg;
      const featureIndexInGeoJson = geoJsonWg.features.findIndex(
        (x) => x.properties.id === objectId
      );

      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        if (polygonData.length === 1) {
          polygonData = [];
        } else {
          polygonData = polygonData.filter((x) => x.id !== objectId);
        }
      }

      const objectGeojsonProperties =
        geoJsonWg.features[featureIndexInGeoJson].properties;

      objectGeojsonProperties[`t${objectGeojsonProperties.t}`] = subtypeIndex;
      return {
        ...state,
        geoJsonWg: geoJsonWg,
        polygonData: polygonData,
        currentObjectSubtype: state.allObjectSubtypes.value[subtypeIndex - 1],
      };
    }

    case types.UPDATE_BUILDING_HEIGHT: {
      const buildingHeight = Number(action.payload);
      if (state.selectedMapItemGeoProps.id > 0) {
        const geoJsonWg = state.geoJsonWg;
        const featureIndexInGeoJson = geoJsonWg.features.findIndex(
          (x) => x.properties.id === state.selectedMapItemGeoProps.id
        );
        geoJsonWg.features[featureIndexInGeoJson].properties.bh =
          buildingHeight;

        return {
          ...state,
          geoJsonWg: geoJsonWg,
        };
      } else {
        const drawnPolygonGeojsonWg = state.drawnPolygonGeojsonWg;
        const allDrawnPolygonGeoProps = state.allDrawnPolygonGeoProps;
        const featureIndexInGeoJson = drawnPolygonGeojsonWg.features.findIndex(
          (x) => x.properties.id === state.selectedMapItemGeoProps.id
        );
        drawnPolygonGeojsonWg.features[featureIndexInGeoJson].properties.bh =
          Number(action.payload);
        allDrawnPolygonGeoProps[featureIndexInGeoJson].bh = buildingHeight;
        return {
          ...state,
          drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
          allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        };
      }
    }

    case types.UPDATE_TREE_HEIGHT: {
      const treeHeight = Number(action.payload);
      if (state.selectedMapItemGeoProps.id > 0) {
        const geoJsonWg = state.geoJsonWg;
        const featureIndexInGeoJson = geoJsonWg.features.findIndex(
          (x) => x.properties.id === state.selectedMapItemGeoProps.id
        );
        geoJsonWg.features[featureIndexInGeoJson].properties.th = treeHeight;

        return {
          ...state,
          geoJsonWg: geoJsonWg,
        };
      } else if (state.selectedMapItemGeoProps.id > -1000) {
        const drawnPolygonGeojsonWg = state.drawnPolygonGeojsonWg;
        const allDrawnPolygonGeoProps = state.allDrawnPolygonGeoProps;
        const featureIndexInGeoJson = drawnPolygonGeojsonWg.features.findIndex(
          (x) => x.properties.id === state.selectedMapItemGeoProps.id
        );
        drawnPolygonGeojsonWg.features[featureIndexInGeoJson].properties.th =
          treeHeight;
        allDrawnPolygonGeoProps[featureIndexInGeoJson].th = treeHeight;
        const selectedMapItemGeoProps = cloneDeep(
          state.selectedMapItemGeoProps
        );
        selectedMapItemGeoProps.th = treeHeight;
        return {
          ...state,
          drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
          allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
        };
      } else {
        const allTreesGeoProps = state.allTreesGeoProps;
        const featureIndexInAllTreesGeoProps = allTreesGeoProps.findIndex(
          (x) => x.id === state.selectedMapItemGeoProps.id
        );
        allTreesGeoProps[featureIndexInAllTreesGeoProps].th = treeHeight;

        return {
          ...state,
          allTreesGeoProps: allTreesGeoProps,
        };
      }
    }

    case types.UPDATE_POLYGON_DATA: {
      let { key, value } = action.payload;
      if (Number(value)) {
        value = Number(value);
      }
      let polygonData = state.polygonData;
      const indexOfObjectData = state.polygonData.findIndex(
        (x) => x.id === state.selectedMapItemGeoProps.id
      );

      if (indexOfObjectData === -1) {
        polygonData = [
          ...state.polygonData,
          {
            id: Number(`${state.selectedMapItemGeoProps.id}`),
            [key]: value,
          },
        ];
      } else {
        polygonData[indexOfObjectData][key] = value;
      }

      const defaultDataValue = templatesJson.find(
        (x) =>
          x.t === state.selectedMapItemGeoProps.t &&
          x[`t${x.t}`] ===
            state.selectedMapItemGeoProps[`t${state.selectedMapItemGeoProps.t}`]
      )[key];

      if (defaultDataValue === value) {
        if (
          indexOfObjectData !== -1 &&
          polygonData &&
          Object.keys(polygonData[indexOfObjectData]).length === 2
        ) {
          polygonData = polygonData.filter(
            (x) => x.id !== polygonData[indexOfObjectData].id
          );
        } else if (polygonData) {
          const polygonObjectData = state.polygonData.find(
            (x) => x.id === state.selectedMapItemGeoProps.id
          );
          if (polygonObjectData) {
            const { [key]: value, ...newPolygonObjectData } = polygonObjectData;
            polygonData[indexOfObjectData] = newPolygonObjectData;
          }
        }
      }
      return {
        ...state,
        tempData: "",
        tempKey: "",
        polygonData: polygonData,
      };
    }

    case types.RESET_GEODATA:
      return {
        ...state,
        geoJsonWg: null,
        polygonData: [],
        isCentering: false,
      };

    case types.CENTER_MAP:
      return {
        ...state,
        isCentering: true,
      };

    case types.CENTER_MAP_SUCCESS:
      return {
        ...state,
        isCentering: false,
      };

    case types.SET_DRAWN_GEOJSON:
      const drawnGeoJsonUtm = action.payload;
      const drawnGeojsonWgConv = reproject(
        drawnGeoJsonUtm,
        state.crsDef,
        wgDef
      );

      const drawnGeojsonWg = gp.parse(drawnGeojsonWgConv, 6);
      let arrayOfDrawnPolygonId = [];
      let allDrawnPolygonGeoProps = [];
      let allDrawnPolygonsPointsUtm = [];
      let allDrawnPolygonsPointsWg = [];
      let allInputCoords = [];
      let singlePolygonGeometry = [];
      let simplePolygonCoords = [];

      let arrayOfTreesId = [];
      let allTreesGeoProps = [];
      let allDrawnTreesCoordinatesUtm = [];
      let allDrawnTreesCoordinatesWg = [];

      let arrayOfEmissionId = [];
      let allDrawnEmissionGeoProps = [];
      let allDrawnEmissionCoordinatesWg = [];
      let allDrawnEmissionCoordinatesUtm = [];

      let arrayOfMapPoiId = [];
      let allDrawnMapPoiGeoProps = [];
      let allDrawnMapPoiCoordinatesWg = [];
      let allDrawnMapPoiCoordinatesUtm = [];

      let arrayOfWindTurbineId = [];
      let allDrawnWindTurbineGeoProps = [];
      let allDrawnWindTurbineCoordinatesWg = [];
      let allDrawnWindTurbineCoordinatesUtm = [];

      drawnGeoJsonUtm.features.map((x) => {
        if (x.properties.id > -1001 || x.properties.id < -6000) {
          arrayOfDrawnPolygonId.push(x.properties.id);
          allDrawnPolygonGeoProps.push(x.properties);
        } else if (x.properties.id > -2001) {
          arrayOfTreesId.push(x.properties.id);
          allTreesGeoProps.push(x.properties);
        } else if (x.properties.t === 8) {
          arrayOfEmissionId.push(x.properties.id);
          allDrawnEmissionGeoProps.push(x.properties);
        } else if (x.properties.t === 9) {
          arrayOfMapPoiId.push(x.properties.id);
          allDrawnMapPoiGeoProps.push(x.properties);
        } else if (x.properties.t === 10) {
          arrayOfWindTurbineId.push(x.properties.id);
          allDrawnWindTurbineGeoProps.push(x.properties);
        }
      });

      for (let index = 0; index < drawnGeoJsonUtm.features.length; index++) {
        if (
          drawnGeoJsonUtm.features[index].properties.id < -1000 &&
          drawnGeoJsonUtm.features[index].properties.id > -2001
        ) {
          let treeCoordinatesUtm =
            drawnGeoJsonUtm.features[index].geometry.coordinates;
          let treeCoordinatesWg =
            drawnGeojsonWg.features[index].geometry.coordinates;

          allDrawnTreesCoordinatesUtm.push({
            lat: treeCoordinatesUtm[1],
            lng: treeCoordinatesUtm[0],
          });
          allDrawnTreesCoordinatesWg.push([
            treeCoordinatesWg[1],
            treeCoordinatesWg[0],
          ]);
        } else if (drawnGeoJsonUtm.features[index].properties.t === 8) {
          let emissionCoordinatesUtm =
            drawnGeoJsonUtm.features[index].geometry.coordinates;
          let emissionCoordinatesWg =
            drawnGeojsonWg.features[index].geometry.coordinates;

          allDrawnEmissionCoordinatesUtm.push({
            lat: emissionCoordinatesUtm[1],
            lng: emissionCoordinatesUtm[0],
          });
          allDrawnEmissionCoordinatesWg.push([
            emissionCoordinatesWg[1],
            emissionCoordinatesWg[0],
          ]);
        } else if (drawnGeoJsonUtm.features[index].properties.t === 9) {
          let mapPoiCoordinatesUtm =
            drawnGeoJsonUtm.features[index].geometry.coordinates;
          let mapPoiCoordinatesWg =
            drawnGeojsonWg.features[index].geometry.coordinates;

          allDrawnMapPoiCoordinatesUtm.push({
            id: drawnGeoJsonUtm.features[index].properties.id,
            lat: mapPoiCoordinatesUtm[1],
            lng: mapPoiCoordinatesUtm[0],
            name: drawnGeoJsonUtm.features[index].properties.name,
          });
          allDrawnMapPoiCoordinatesWg.push([
            mapPoiCoordinatesWg[1],
            mapPoiCoordinatesWg[0],
          ]);
        } else if (drawnGeoJsonUtm.features[index].properties.t === 10) {
          let windTurbineCoordinatesUtm =
            drawnGeoJsonUtm.features[index].geometry.coordinates;
          let windTurbineCoordinatesWg =
            drawnGeojsonWg.features[index].geometry.coordinates;

          allDrawnWindTurbineCoordinatesUtm.push({
            id: drawnGeoJsonUtm.features[index].properties.id,
            lat: windTurbineCoordinatesUtm[1],
            lng: windTurbineCoordinatesUtm[0],
            wtname: drawnGeoJsonUtm.features[index].properties.wtname,
          });

          // allDrawnWindTurbineCoordinatesWg.push([
          //   windTurbineCoordinatesWg[1],
          //   windTurbineCoordinatesWg[0],
          // ]);
          allDrawnWindTurbineCoordinatesWg.push({
            id: drawnGeoJsonUtm.features[index].properties.id,
            lat: windTurbineCoordinatesWg[1],
            lng: windTurbineCoordinatesWg[0],
            wtstate: drawnGeoJsonUtm.features[index].properties.wtstate,
          });
        }
      }

      const polygonDrawnFeaturesUtm = drawnGeoJsonUtm.features.filter(
        (x) => x.properties.id > -1001 || x.properties.id < -6000
      );
      drawnGeoJsonUtm.features = polygonDrawnFeaturesUtm;

      const polygonDrawnFeaturesWg = drawnGeojsonWg.features.filter(
        (x) => x.properties.id > -1001 || x.properties.id < -6000
      );
      drawnGeojsonWg.features = polygonDrawnFeaturesWg;

      for (let index = 0; index < drawnGeoJsonUtm.features.length; index++) {
        singlePolygonGeometry = [];
        drawnGeojsonWg.features[index].geometry.coordinates[0][0].map((x) => {
          singlePolygonGeometry.push([x[1], x[0]]);
        });

        if (
          singlePolygonGeometry[singlePolygonGeometry.length - 1][0] ===
          singlePolygonGeometry[0][0]
        ) {
          singlePolygonGeometry.pop();
        }

        allDrawnPolygonsPointsWg.push(singlePolygonGeometry);

        singlePolygonGeometry = [];
        drawnGeoJsonUtm.features[index].geometry.coordinates[0][0].map((x) => {
          singlePolygonGeometry.push({ lng: x[0], lat: x[1] });
        });

        if (
          singlePolygonGeometry[singlePolygonGeometry.length - 1][0] ===
          singlePolygonGeometry[0][0]
        ) {
          singlePolygonGeometry.pop();
        }

        allDrawnPolygonsPointsUtm.push(singlePolygonGeometry);
      }

      for (let index = 0; index < drawnGeoJsonUtm.features.length; index++) {
        simplePolygonCoords = [];

        for (
          let coordsIndex = 0;
          coordsIndex <
          drawnGeoJsonUtm.features[index].geometry.coordinates[0][0].length;
          coordsIndex++
        ) {
          if (coordsIndex !== 0) {
            simplePolygonCoords.push({
              lng: (
                drawnGeoJsonUtm.features[index].geometry.coordinates[0][0][
                  coordsIndex
                ][0] -
                drawnGeoJsonUtm.features[index].geometry.coordinates[0][0][
                  coordsIndex - 1
                ][0]
              ).toFixed(2),
              lat: (
                drawnGeoJsonUtm.features[index].geometry.coordinates[0][0][
                  coordsIndex
                ][1] -
                drawnGeoJsonUtm.features[index].geometry.coordinates[0][0][
                  coordsIndex - 1
                ][1]
              ).toFixed(2),
            });
          } else {
            simplePolygonCoords.push({
              lng: drawnGeoJsonUtm.features[index].geometry.coordinates[0][0][
                coordsIndex
              ][0],
              lat: drawnGeoJsonUtm.features[index].geometry.coordinates[0][0][
                coordsIndex
              ][1],
            });
          }
        }

        simplePolygonCoords.pop();

        allInputCoords.push(simplePolygonCoords);
      }

      let currentIncomingPolygonId = cloneDeep(state.currentIncomingPolygonId);
      let currentIncomingStreetId = cloneDeep(state.currentIncomingStreetId);

      if (arrayOfDrawnPolygonId.length > 0) {
        let arrayOfDrawnStreetId = arrayOfDrawnPolygonId.filter((x) => {
          return x < -4000 && x > -5001;
        });

        let arrayOfPolygonId = arrayOfDrawnPolygonId.filter((x) => {
          return x > -1001;
        });

        if (arrayOfPolygonId.length > 0) {
          currentIncomingPolygonId = Math.min(...arrayOfPolygonId) - 1;
          ///should be arrayOfPolygonId
        }

        if (arrayOfDrawnStreetId.length > 0) {
          currentIncomingStreetId = Math.min(...arrayOfDrawnStreetId) - 1;
        }
      }

      let currentTreeId = cloneDeep(state.currentTreeId);

      if (arrayOfTreesId.length > 0) {
        currentTreeId = Math.min(...arrayOfTreesId) - 1;
      }

      let currentIncomingEmissionId = cloneDeep(
        state.currentIncomingEmissionId
      );

      if (arrayOfEmissionId.length > 0) {
        currentIncomingEmissionId = Math.min(...arrayOfEmissionId) - 1;

        if (currentIncomingEmissionId > -2000) {
          currentIncomingEmissionId = -2000;
        }
      }

      let currentIncomingMapPoiId = cloneDeep(state.currentIncomingMapPoiId);

      if (arrayOfMapPoiId.length > 0) {
        currentIncomingMapPoiId = Math.min(...arrayOfMapPoiId) - 1;
        if (currentIncomingMapPoiId > -3000) {
          currentIncomingMapPoiId = -3000;
        }
      }

      let currentIncomingWindTurbineId = cloneDeep(
        state.currentIncomingWindTurbineId
      );

      if (arrayOfWindTurbineId.length > 0) {
        currentIncomingWindTurbineId = Math.min(...arrayOfWindTurbineId) - 1;
        if (currentIncomingWindTurbineId > -5000) {
          currentIncomingWindTurbineId = -5001;
        }
      }

      return {
        ...state,
        drawnPolygonGeojsonWg: drawnGeojsonWg,
        arrayOfDrawnPolygonId: arrayOfDrawnPolygonId,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allInputCoords: allInputCoords,
        currentIncomingPolygonId: currentIncomingPolygonId,
        arrayOfTreesId: arrayOfTreesId,
        allTreesGeoProps: allTreesGeoProps,
        allDrawnTreesCoordinatesUtm: allDrawnTreesCoordinatesUtm,
        allDrawnTreesCoordinatesWg: allDrawnTreesCoordinatesWg,
        currentTreeId: currentTreeId,

        currentIncomingStreetId: currentIncomingStreetId,
        currentIncomingEmissionId: currentIncomingEmissionId,
        currentIncomingMapPoiId: currentIncomingMapPoiId,
        currentIncomingWindTurbineId: currentIncomingWindTurbineId,

        arrayOfEmissionId: arrayOfEmissionId,
        arrayOfMapPoiId: arrayOfMapPoiId,
        arrayOfWindTurbineId: arrayOfWindTurbineId,

        allDrawnEmissionGeoProps: allDrawnEmissionGeoProps,
        allDrawnMapPoiGeoProps: allDrawnMapPoiGeoProps,
        allDrawnWindTurbineGeoProps: allDrawnWindTurbineGeoProps,

        allDrawnEmissionCoordinatesUtm: allDrawnEmissionCoordinatesUtm,
        allDrawnEmissionCoordinatesWg: allDrawnEmissionCoordinatesWg,

        allDrawnMapPoiCoordinatesUtm: allDrawnMapPoiCoordinatesUtm,
        allDrawnMapPoiCoordinatesWg: allDrawnMapPoiCoordinatesWg,

        allDrawnWindTurbineCoordinatesUtm: allDrawnWindTurbineCoordinatesUtm,
        allDrawnWindTurbineCoordinatesWg: allDrawnWindTurbineCoordinatesWg,
      };

    case types.START_NEW_POLYGON: {
      const objectType = cloneDeep(state.objectType);
      const objectSubtype = cloneDeep(state.objectSubtype);
      const objectTypeIndex = cloneDeep(state.objectTypeIndex);
      const objectSubtypeIndex = cloneDeep(state.objectSubtypeIndex);
      const allObjectSubtypes = cloneDeep(state.allObjectSubtypes);

      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);
      const allInputCoords = cloneDeep(state.allInputCoords);
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);
      const arrayOfDrawnPolygonId = cloneDeep(state.arrayOfDrawnPolygonId);
      const currentIncomingPolygonId = cloneDeep(
        state.currentIncomingPolygonId
      );
      const currentIncomingStreetId = cloneDeep(state.currentIncomingStreetId);

      return {
        ...state,
        ...polygonEditorInitialState,
        objectType: objectType,
        objectSubtype: objectSubtype,
        objectTypeIndex: objectTypeIndex,
        objectSubtypeIndex: objectSubtypeIndex,
        allObjectSubtypes: allObjectSubtypes,
        selectedMapItemGeoProps: null,
        inSelectMode: false,
        allInputCoords: allInputCoords,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        arrayOfDrawnPolygonId: arrayOfDrawnPolygonId,
        currentIncomingPolygonId: currentIncomingPolygonId,
        canDrawPolygon: false,
        currentIncomingStreetId: currentIncomingStreetId,
      };
    }
    case types.DRAW_POLYGON: {
      if (
        state.polygonPointsUtm.length < 3 &&
        state.polygonPointsUtm.length !== 0
      ) {
        return {
          ...state,
          polygonPointsUtm: [],
          polygonPointsWg: [],
          inputCoords: [
            { lng: "", lat: "" },
            { lng: "", lat: "" },
            { lng: "", lat: "" },
          ],
        };
      } else if (state.inSelectMode || state.polygonDrawn) {
        const objectType = cloneDeep(state.objectType);
        const objectSubtype = cloneDeep(state.objectSubtype);
        const objectTypeIndex = cloneDeep(state.objectTypeIndex);
        const objectSubtypeIndex = cloneDeep(state.objectSubtypeIndex);
        const allObjectSubtypes = cloneDeep(state.allObjectSubtypes);

        const allDrawnPolygonsPointsWg = cloneDeep(
          state.allDrawnPolygonsPointsWg
        );

        const allDrawnPolygonsPointsUtm = cloneDeep(
          state.allDrawnPolygonsPointsUtm
        );
        const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);
        const allInputCoords = cloneDeep(state.allInputCoords);
        const allDrawnPolygonGeoProps = cloneDeep(
          state.allDrawnPolygonGeoProps
        );
        const arrayOfDrawnPolygonId = cloneDeep(state.arrayOfDrawnPolygonId);
        const currentIncomingPolygonId = cloneDeep(
          state.currentIncomingPolygonId
        );
        const currentIncomingStreetId = cloneDeep(
          state.currentIncomingStreetId
        );

        return {
          ...state,
          ...polygonEditorInitialState,
          objectType: objectType,
          objectSubtype: objectSubtype,
          objectTypeIndex: objectTypeIndex,
          objectSubtypeIndex: objectSubtypeIndex,
          allObjectSubtypes: allObjectSubtypes,
          selectedMapItemGeoProps: null,
          inSelectMode: false,
          allInputCoords: allInputCoords,
          drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
          allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
          allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
          allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
          arrayOfDrawnPolygonId: arrayOfDrawnPolygonId,
          currentIncomingPolygonId: currentIncomingPolygonId,
          canDrawPolygon: false,
          polygonDrawn: false,
          currentIncomingStreetId: currentIncomingStreetId,
        };
      } else if (!state.inSelectMode && state.polygonPointsUtm.length === 0) {
        return {
          ...state,
          inSelectMode: true,
        };
      }

      const arrayOfDrawnPolygonId = cloneDeep(state.arrayOfDrawnPolygonId);
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);
      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);
      const inputCoords = cloneDeep(state.inputCoords);
      const allInputCoords = cloneDeep(state.allInputCoords);
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const inStreetDrawingMode = state.inStreetDrawingMode;
      let currentIncomingPolygonId = state.currentIncomingPolygonId;
      let currentIncomingStreetId = state.currentIncomingStreetId;

      arrayOfDrawnPolygonId.push(currentIncomingPolygonId);

      if (inStreetDrawingMode) {
        currentIncomingStreetId = currentIncomingStreetId - 1;
      }

      const selectedMapItemGeoProps = {
        id: inStreetDrawingMode
          ? currentIncomingStreetId
          : currentIncomingPolygonId,
        t: state.objectTypeIndex + 1,
        [`t${state.objectTypeIndex + 1}`]: state.objectSubtypeIndex + 1,
      };

      if (selectedMapItemGeoProps.t === objectTypeIndexTemplate.building) {
        const defaultBuildingHeight = templatesJson.find(
          (x) =>
            x.t === selectedMapItemGeoProps.t &&
            x[`t${x.t}`] ===
              selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
        ).bh;

        selectedMapItemGeoProps.bh = Number(defaultBuildingHeight);
      } else if (
        selectedMapItemGeoProps.t === objectTypeIndexTemplate.treePatches
      ) {
        const defaultTreeHeight = templatesJson.find(
          (x) =>
            x.t === selectedMapItemGeoProps.t &&
            x[`t${x.t}`] ===
              selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
        ).th;

        selectedMapItemGeoProps.th = Number(defaultTreeHeight);
      }

      allDrawnPolygonGeoProps.push(selectedMapItemGeoProps);
      allDrawnPolygonsPointsWg.push(polygonPointsWg);
      polygonPointsWg.push(polygonPointsWg[0]);
      allInputCoords.push(inputCoords);
      allDrawnPolygonsPointsUtm.push(state.polygonPointsUtm);

      drawnPolygonGeojsonWg.features.push({
        type: "Feature",
        properties: selectedMapItemGeoProps,
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              polygonPointsWg.map((x) => {
                return [x[1], x[0]];
              }),
            ],
          ],
        },
      });
      return {
        ...state,
        inSelectMode: true,
        polygonDrawn: true,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        currentIncomingPolygonId: inStreetDrawingMode
          ? currentIncomingPolygonId
          : currentIncomingPolygonId - 1,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        selectedPolygonIndex: allDrawnPolygonsPointsWg.length - 1,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allInputCoords: allInputCoords,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        arrayOfDrawnPolygonId: arrayOfDrawnPolygonId,
        currentIncomingStreetId: currentIncomingStreetId,
        selectedPolygonPoint: null,
      };
    }

    case types.SELECT_POLYGON: {
      if (state.selectedPolygonIndex === action.payload) {
        return {
          ...state,
        };
      }
      const selectedPolygonIndex = action.payload;
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);
      const selectedMapItemGeoProps =
        allDrawnPolygonGeoProps[selectedPolygonIndex];
      const objectTypeIndex = selectedMapItemGeoProps.t;
      const objectSubtypeIndex = selectedMapItemGeoProps[`t${objectTypeIndex}`];
      const allCorrespondingSubtypes =
        getAllCorrespondingSubtypes(objectTypeIndex);
      return {
        ...state,
        polygonPointsWg: state.allDrawnPolygonsPointsWg[selectedPolygonIndex],
        polygonPointsUtm: state.allDrawnPolygonsPointsUtm[selectedPolygonIndex],
        selectedPolygonIndex: selectedPolygonIndex,
        inputCoords: state.allInputCoords[action.payload],
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        objectTypeIndex: objectTypeIndex - 1,
        objectSubtypeIndex: objectSubtypeIndex - 1,
        objectType: allObjectTypes.value[objectTypeIndex - 1],
        objectSubtype: allCorrespondingSubtypes.value[objectSubtypeIndex - 1],
        allObjectSubtypes: allCorrespondingSubtypes,
        selectedPolygonPoint: null,
      };
    }

    case types.REMOVE_DRAWN_POLYGON: {
      const indexOfPolygon = action.payload;
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const allInputCoords = cloneDeep(state.allInputCoords);
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);
      const arrayOfDrawnPolygonId = cloneDeep(state.arrayOfDrawnPolygonId);
      const currentIncomingPolygonId = cloneDeep(
        state.currentIncomingPolygonId
      );
      const currentIncomingStreetId = cloneDeep(state.currentIncomingStreetId);
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);
      drawnPolygonGeojsonWg.features.splice(indexOfPolygon, 1);
      allDrawnPolygonsPointsWg.splice(indexOfPolygon, 1);
      allDrawnPolygonsPointsUtm.splice(indexOfPolygon, 1);
      allInputCoords.splice(indexOfPolygon, 1);
      allDrawnPolygonGeoProps.splice(indexOfPolygon, 1);
      arrayOfDrawnPolygonId.splice(indexOfPolygon, 1);
      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      return {
        ...state,
        ...polygonEditorInitialState,
        inSelectMode: true,
        polygonData: polygonData,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allInputCoords: allInputCoords,
        selectedPolygonIndex: null,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        arrayOfDrawnPolygonId: arrayOfDrawnPolygonId,
        currentIncomingPolygonId: currentIncomingPolygonId,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        currentIncomingStreetId: currentIncomingStreetId,
      };
    }

    case types.SET_OBJECT_TYPE_INDEX: {
      const allCorrespondingSubtypes = getAllCorrespondingSubtypes(
        action.payload + 1
      );
      const objectTypeIndex = action.payload;
      if (state.selectedPolygonIndex !== null) {
        let selectedMapItemGeoProps = cloneDeep(state.selectedMapItemGeoProps);

        const allDrawnPolygonGeoProps = cloneDeep(
          state.allDrawnPolygonGeoProps
        );

        selectedMapItemGeoProps = {
          id: state.arrayOfDrawnPolygonId[state.selectedPolygonIndex],
          t: objectTypeIndex + 1,
          [`t${objectTypeIndex + 1}`]: 1,
        };

        if (selectedMapItemGeoProps.t === objectTypeIndexTemplate.building) {
          const defaultBuildingHeight = templatesJson.find(
            (x) =>
              x.t === selectedMapItemGeoProps.t &&
              x[`t${x.t}`] ===
                selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
          ).bh;

          selectedMapItemGeoProps.bh = Number(defaultBuildingHeight);
        } else if (
          selectedMapItemGeoProps.t !== objectTypeIndexTemplate.building &&
          !!state.selectedMapItemGeoProps.bh
        ) {
          delete selectedMapItemGeoProps.bh;
        } else if (
          selectedMapItemGeoProps.t === objectTypeIndexTemplate.treePatches
        ) {
          const defaultTreeHeight = templatesJson.find(
            (x) =>
              x.t === selectedMapItemGeoProps.t &&
              x[`t${x.t}`] ===
                selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
          ).th;

          selectedMapItemGeoProps.th = Number(defaultTreeHeight);
        } else if (
          selectedMapItemGeoProps.t !== objectTypeIndexTemplate.treePatches &&
          !!state.selectedMapItemGeoProps.th
        ) {
          delete selectedMapItemGeoProps.th;
        }

        allDrawnPolygonGeoProps[state.selectedPolygonIndex] =
          selectedMapItemGeoProps;

        const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

        drawnPolygonGeojsonWg.features[state.selectedPolygonIndex].properties =
          selectedMapItemGeoProps;

        let polygonData = cloneDeep(state.polygonData);

        if (
          !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
        ) {
          polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
        }

        return {
          ...state,
          polygonData: polygonData,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
          allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
          objectType: allObjectTypes.value[objectTypeIndex],
          objectSubtype: allCorrespondingSubtypes.value[0],
          objectTypeIndex: objectTypeIndex,
          objectSubtypeIndex: 0,
          allObjectSubtypes: allCorrespondingSubtypes,
          drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        };
      }

      return {
        ...state,
        objectType: allObjectTypes.value[objectTypeIndex],
        objectSubtype: allCorrespondingSubtypes.value[0],
        objectTypeIndex: objectTypeIndex,
        objectSubtypeIndex: 0,
        allObjectSubtypes: allCorrespondingSubtypes,
      };
    }

    case types.SET_OBJECT_SUBTYPE_INDEX: {
      if (state.selectedPolygonIndex !== null) {
        const allDrawnPolygonGeoProps = cloneDeep(
          state.allDrawnPolygonGeoProps
        );

        let selectedMapItemGeoProps = {
          id: state.arrayOfDrawnPolygonId[state.selectedPolygonIndex],
          t: state.selectedMapItemGeoProps.t,
          [`t${state.objectTypeIndex + 1}`]: action.payload + 1,
        };

        if (selectedMapItemGeoProps.t === objectTypeIndexTemplate.building) {
          const defaultBuildingHeight = templatesJson.find(
            (x) =>
              x.t === selectedMapItemGeoProps.t &&
              x[`t${x.t}`] ===
                selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
          ).bh;
          selectedMapItemGeoProps.bh = Number(defaultBuildingHeight);
        } else {
          const defaultTreeHeight = templatesJson.find(
            (x) =>
              x.t === selectedMapItemGeoProps.t &&
              x[`t${x.t}`] ===
                selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
          ).th;
          selectedMapItemGeoProps.th = Number(defaultTreeHeight);
        }

        allDrawnPolygonGeoProps[state.selectedPolygonIndex] =
          selectedMapItemGeoProps;

        const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

        drawnPolygonGeojsonWg.features[state.selectedPolygonIndex].properties =
          selectedMapItemGeoProps;

        let polygonData = cloneDeep(state.polygonData);

        if (
          !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
        ) {
          polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
        }

        return {
          ...state,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
          allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
          polygonData: polygonData,
          objectSubtype: state.allObjectSubtypes.value[action.payload],
          objectSubtypeIndex: action.payload,
          drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        };
      }

      return {
        ...state,
        objectSubtype: state.allObjectSubtypes.value[action.payload],
        objectSubtypeIndex: action.payload,
      };
    }

    case types.ADD_COORDS_ROW: {
      if (state.polygonPointsUtm.length === 0) {
        return { ...state };
      }

      const coordsIndex = action.payload;
      const inputCoords = cloneDeep(state.inputCoords);
      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      const polygonPointsUtm = cloneDeep(state.polygonPointsUtm);
      const prevPointWg = polygonPointsWg[coordsIndex];
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const allInputCoords = cloneDeep(state.allInputCoords);
      let nextPointWg = null;
      if (inputCoords.length === coordsIndex + 1) {
        nextPointWg = polygonPointsWg[0];
      } else {
        nextPointWg = polygonPointsWg[coordsIndex + 1];
      }

      const addedPointWg = [
        ((Number(prevPointWg[0]) + Number(nextPointWg[0])) / 2).toFixed(6),
        ((Number(prevPointWg[1]) + Number(nextPointWg[1])) / 2).toFixed(6),
      ];

      let inputCoordsPrev = {
        lat: (inputCoords[coordsIndex].lat / 2).toFixed(2),
        lng: (inputCoords[coordsIndex].lng / 2).toFixed(2),
      };

      if (coordsIndex === 0) {
        inputCoordsPrev = {
          lat: (
            (polygonPointsUtm[1].lat - polygonPointsUtm[0].lat) /
            2
          ).toFixed(2),
          lng: (
            (polygonPointsUtm[1].lng - polygonPointsUtm[0].lng) /
            2
          ).toFixed(2),
        };
      } else if (coordsIndex === inputCoords.length) {
        inputCoordsPrev = {
          lat: (
            (polygonPointsUtm[coordsIndex].lat - polygonPointsUtm[0].lat) /
            2
          ).toFixed(2),
          lng: (
            (polygonPointsUtm[coordsIndex].lng - polygonPointsUtm[0].lng) /
            2
          ).toFixed(2),
        };
      }

      inputCoords.splice(coordsIndex + 1, 0, inputCoordsPrev);
      if (coordsIndex !== 0 && coordsIndex !== coordsIndex.length - 1) {
        inputCoords[coordsIndex] = inputCoordsPrev;
      }
      if (coordsIndex === 0) {
        inputCoords.splice(coordsIndex + 2, 0, inputCoordsPrev);
      }

      polygonPointsWg.splice(coordsIndex + 1, 0, addedPointWg);

      const addedPointUtm = reprojectWgToUtmPoint(
        {
          lat: Number(addedPointWg[0]),
          lng: Number(addedPointWg[1]),
        },
        state.crsDef
      );
      polygonPointsUtm.splice(coordsIndex + 1, 0, addedPointUtm);

      allDrawnPolygonsPointsUtm[state.selectedPolygonIndex] = polygonPointsUtm;
      allDrawnPolygonsPointsWg[state.selectedPolygonIndex] = polygonPointsWg;
      allInputCoords[state.selectedPolygonIndex] = inputCoords;

      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

      drawnPolygonGeojsonWg.features[
        state.selectedPolygonIndex
      ].geometry.coordinates = [
        [
          polygonPointsWg.map((x) => {
            return [x[1], x[0]];
          }),
        ],
      ];

      return {
        ...state,
        inputCoords: inputCoords,
        polygonPointsUtm: polygonPointsUtm,
        polygonPointsWg: polygonPointsWg,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allInputCoords: allInputCoords,
        tempKey: "",
        tempValue: "",
        drawnPolyCoordsIndex: null,
      };
    }

    case types.REMOVE_COORDS_ROW: {
      const coordsIndex = action.payload;
      const inputCoords = cloneDeep(state.inputCoords);
      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      const polygonPointsUtm = cloneDeep(state.polygonPointsUtm);
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const allInputCoords = cloneDeep(state.allInputCoords);
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

      polygonPointsWg.splice(coordsIndex, 1);
      polygonPointsUtm.splice(coordsIndex, 1);

      if (coordsIndex !== 1) {
        inputCoords[coordsIndex - 1] = {
          lat: (inputCoords[coordsIndex - 1].lat * 2).toFixed(2),
          lng: (inputCoords[coordsIndex - 1].lng * 2).toFixed(2),
        };
      } else if (coordsIndex === 1) {
        inputCoords[coordsIndex + 1] = {
          lat: (inputCoords[coordsIndex + 1].lat * 2).toFixed(2),
          lng: (inputCoords[coordsIndex + 1].lng * 2).toFixed(2),
        };
      }
      inputCoords.splice(coordsIndex, 1);
      allDrawnPolygonsPointsWg[state.selectedPolygonIndex] = polygonPointsWg;
      allDrawnPolygonsPointsUtm[state.selectedPolygonIndex] = polygonPointsUtm;
      allInputCoords[state.selectedPolygonIndex] = inputCoords;

      drawnPolygonGeojsonWg.features[
        state.selectedPolygonIndex
      ].geometry.coordinates = [
        [
          polygonPointsWg.map((x) => {
            return [x[1], x[0]];
          }),
        ],
      ];

      return {
        ...state,
        inputCoords: inputCoords,
        polygonPointsWg: polygonPointsWg,
        polygonPointsUtm: polygonPointsUtm,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allInputCoords: allInputCoords,
      };
    }

    case types.HANDLE_COORD_INPUT_CHANGE: {
      if (state.polygonPointsUtm.length === 0) {
        return { ...state };
      }
      const typeOfCoords = action.payload.typeOfCoords;
      const value = Number(action.payload.value);

      const drawnPolyCoordsIndex = action.payload.drawnPolyCoordsIndex;
      const inputCoords = JSON.parse(JSON.stringify(state.inputCoords));
      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      const polygonPointsUtm = cloneDeep(state.polygonPointsUtm);
      const allInputCoords = cloneDeep(state.allInputCoords);
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const selectedPolygonIndex = state.selectedPolygonIndex;

      inputCoords[drawnPolyCoordsIndex][typeOfCoords] = value;

      if (drawnPolyCoordsIndex === 0) {
        polygonPointsUtm[drawnPolyCoordsIndex][typeOfCoords] = value;
      } else {
        polygonPointsUtm[drawnPolyCoordsIndex][typeOfCoords] =
          Number(polygonPointsUtm[drawnPolyCoordsIndex - 1][typeOfCoords]) +
          value;
      }

      polygonPointsWg[drawnPolyCoordsIndex][0] = reprojectUtmToWgPoint(
        [
          Number(polygonPointsUtm[drawnPolyCoordsIndex].lng),
          Number(polygonPointsUtm[drawnPolyCoordsIndex].lat),
        ],
        state.crsDef
      ).lat;

      polygonPointsWg[drawnPolyCoordsIndex][1] = reprojectUtmToWgPoint(
        [
          Number(polygonPointsUtm[drawnPolyCoordsIndex].lng),
          Number(polygonPointsUtm[drawnPolyCoordsIndex].lat),
        ],
        state.crsDef
      ).lng;

      allInputCoords[selectedPolygonIndex] = inputCoords;
      allDrawnPolygonsPointsWg[selectedPolygonIndex] = polygonPointsWg;
      allDrawnPolygonsPointsUtm[selectedPolygonIndex] = polygonPointsUtm;

      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);
      drawnPolygonGeojsonWg.features[
        selectedPolygonIndex
      ].geometry.coordinates = [
        [
          polygonPointsWg.map((x) => {
            return [x[1], x[0]];
          }),
        ],
      ];

      return {
        ...state,
        tempValue: "",
        tempKey: "",
        drawnPolyCoordsIndex: drawnPolyCoordsIndex,
        inputCoords: inputCoords,
        polygonPointsUtm: polygonPointsUtm,
        polygonPointsWg: polygonPointsWg,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        allInputCoords: allInputCoords,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        selectedPolygonPoint: drawnPolyCoordsIndex,
      };
    }

    case types.ADD_POLYGON_POINT_ONCLICK_WG: {
      if (state.polygonDrawn || state.inSelectMode) {
        return { ...state };
      }
      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      const polygonPointsUtm = cloneDeep(state.polygonPointsUtm);
      const inputCoords = cloneDeep(state.inputCoords);
      polygonPointsWg.push([action.payload.lat, action.payload.lng]);

      if (polygonPointsWg.length === 1) {
        const startCoordinateUtm = reprojectWgToUtmPoint(
          {
            lat: Number(polygonPointsWg[0][0]),
            lng: Number(polygonPointsWg[0][1]),
          },
          state.crsDef
        );
        polygonPointsUtm.push(startCoordinateUtm);

        inputCoords[0] = startCoordinateUtm;
      } else {
        const currentPointUtm = reprojectWgToUtmPoint(
          {
            lat: Number(polygonPointsWg[polygonPointsWg.length - 1][0]),
            lng: Number(polygonPointsWg[polygonPointsWg.length - 1][1]),
          },
          state.crsDef
        );

        polygonPointsUtm.push(currentPointUtm);

        const previousUtmPoint = {
          lat: polygonPointsUtm[polygonPointsUtm.length - 2].lat,
          lng: polygonPointsUtm[polygonPointsUtm.length - 2].lng,
        };

        const latDiffUtm = Number(
          currentPointUtm.lat - previousUtmPoint.lat
        ).toFixed(2);

        const lngDiffUtm = Number(
          currentPointUtm.lng - previousUtmPoint.lng
        ).toFixed(2);

        inputCoords[polygonPointsWg.length - 1] = {
          lat: latDiffUtm,
          lng: lngDiffUtm,
        };
      }

      return {
        ...state,
        polygonPointsWg: polygonPointsWg,
        polygonPointsUtm: polygonPointsUtm,
        inputCoords: inputCoords,
        canDrawPolygon: true,
      };
    }

    case types.DRAG_POINTWG: {
      const selectedPolygonPoint = action.payload.indexOfPoint;
      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      polygonPointsWg[selectedPolygonPoint] = [
        action.payload.pointCoords[0],
        action.payload.pointCoords[1],
      ];

      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );

      allDrawnPolygonsPointsWg[state.selectedPolygonIndex][
        action.payload.indexOfPoint
      ] = [action.payload.pointCoords[0], action.payload.pointCoords[1]];

      return {
        ...state,
        polygonPointsWg: polygonPointsWg,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        selectedPolygonPoint: selectedPolygonPoint,
      };
    }

    case types.DRAGEND_POINTWG: {
      const inputCoords = cloneDeep(state.inputCoords);
      const idx = action.payload.idx;
      const polygonPointsUtm = cloneDeep(state.polygonPointsUtm);

      if (polygonPointsUtm[idx] === undefined) {
        return { ...state };
      }

      const polygonPointsWg = cloneDeep(state.polygonPointsWg);
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const allInputCoords = cloneDeep(state.allInputCoords);

      polygonPointsWg[idx][0] = Number(
        action.payload.pointCoords.lat.toFixed(6)
      );
      polygonPointsWg[idx][1] = Number(
        action.payload.pointCoords.lng.toFixed(6)
      );
      const pointUtm = reprojectWgToUtmPoint(
        {
          lat: action.payload.pointCoords.lat.toFixed(6),
          lng: action.payload.pointCoords.lng.toFixed(6),
        },
        state.crsDef
      );

      polygonPointsUtm[action.payload.idx] = {
        lat: pointUtm.lat,
        lng: pointUtm.lng,
      };

      if (idx === 0) {
        inputCoords[idx].lat = pointUtm.lat;
        inputCoords[idx].lng = pointUtm.lng;

        inputCoords[idx + 1].lat = (
          polygonPointsUtm[idx + 1].lat - polygonPointsUtm[idx].lat
        ).toFixed(2);
        inputCoords[idx + 1].lng = (
          polygonPointsUtm[idx + 1].lng - polygonPointsUtm[idx].lng
        ).toFixed(2);

        inputCoords[idx + 1].lat = (
          polygonPointsUtm[idx + 1].lat - polygonPointsUtm[idx].lat
        ).toFixed(2);
        inputCoords[idx + 1].lng = (
          polygonPointsUtm[idx + 1].lng - polygonPointsUtm[idx].lng
        ).toFixed(2);
      } else if (idx === polygonPointsUtm.length - 1) {
        inputCoords[idx].lat = (
          polygonPointsUtm[idx].lat - polygonPointsUtm[idx - 1].lat
        ).toFixed(2);
        inputCoords[idx].lng = (
          polygonPointsUtm[idx].lng - polygonPointsUtm[idx - 1].lng
        ).toFixed(2);
      } else {
        inputCoords[idx + 1].lat = (
          polygonPointsUtm[idx + 1].lat - polygonPointsUtm[idx].lat
        ).toFixed(2);
        inputCoords[idx + 1].lng = (
          polygonPointsUtm[idx + 1].lng - polygonPointsUtm[idx].lng
        ).toFixed(2);
        inputCoords[idx].lat = (
          polygonPointsUtm[idx].lat - polygonPointsUtm[idx - 1].lat
        ).toFixed(2);
        inputCoords[idx].lng = (
          polygonPointsUtm[idx].lng - polygonPointsUtm[idx - 1].lng
        ).toFixed(2);
      }

      allDrawnPolygonsPointsWg[state.selectedPolygonIndex] = polygonPointsWg;
      allDrawnPolygonsPointsUtm[state.selectedPolygonIndex] = polygonPointsUtm;
      allInputCoords[state.selectedPolygonIndex] = inputCoords;
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

      drawnPolygonGeojsonWg.features[
        state.selectedPolygonIndex
      ].geometry.coordinates = [
        [
          polygonPointsWg.map((x) => {
            return [x[1], x[0]];
          }),
        ],
      ];

      return {
        ...state,
        inputCoords: inputCoords,
        polygonPointsUtm: polygonPointsUtm,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        polygonPointsWg: polygonPointsWg,
        allInputCoords: allInputCoords,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
      };
    }

    case types.GET_SELECTED_MAP_DRAWN_POLYGON_GEOPROPS: {
      return {
        ...state,
        selectedMapItemGeoProps: action.payload,
      };
    }

    case types.CHANGE_SELECTED_DRAWN_POLYGON_TYPE: {
      const selectedPolygonId = action.payload.id;
      const objectTypeIndex = action.payload.objectTypeIndex;
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);
      const selectedPolygonIndex = allDrawnPolygonGeoProps.findIndex(
        (x) => x.id === selectedPolygonId
      );

      const selectedMapItemGeoProps = {
        id: selectedPolygonId,
        t: objectTypeIndex,
        [`t${objectTypeIndex}`]: 1,
      };

      if (selectedMapItemGeoProps.t === objectTypeIndexTemplate.building) {
        const defaultBuildingHeight = templatesJson.find(
          (x) =>
            x.t === selectedMapItemGeoProps.t &&
            x[`t${x.t}`] ===
              selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
        ).bh;

        selectedMapItemGeoProps.bh = Number(defaultBuildingHeight);
      } else if (
        selectedMapItemGeoProps.t !== objectTypeIndexTemplate.building &&
        !!state.selectedMapItemGeoProps.bh
      ) {
        delete selectedMapItemGeoProps.bh;
      }
      if (selectedMapItemGeoProps.t === objectTypeIndexTemplate.treePatches) {
        const defaultTreeHeight = templatesJson.find(
          (x) =>
            x.t === selectedMapItemGeoProps.t &&
            x[`t${x.t}`] ===
              selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
        ).th;

        selectedMapItemGeoProps.th = Number(defaultTreeHeight);
      } else if (
        selectedMapItemGeoProps.t !== objectTypeIndexTemplate.treePatches &&
        !!state.selectedMapItemGeoProps.th
      ) {
        delete selectedMapItemGeoProps.th;
      }

      allDrawnPolygonGeoProps[selectedPolygonIndex] = selectedMapItemGeoProps;

      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

      drawnPolygonGeojsonWg.features[selectedPolygonIndex].properties =
        selectedMapItemGeoProps;

      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData = polygonData.filter(
          (x) => x.id !== state.selectedMapItemGeoProps.id
        );
      }
      return {
        ...state,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        currentObjectType: getCurrentObjectType(selectedMapItemGeoProps),
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        allObjectSubtypes: getAllCorrespondingSubtypes(
          selectedMapItemGeoProps.t
        ),
        polygonData: polygonData,
      };
    }

    case types.CHANGE_SELECTED_DRAWN_POLYGON_SUBTYPE: {
      const selectedPolygonId = action.payload.id;
      const subtypeIndex = action.payload.subtypeIndex;
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);

      const selectedPolygonIndex = allDrawnPolygonGeoProps.findIndex(
        (x) => x.id === selectedPolygonId
      );

      allDrawnPolygonGeoProps[selectedPolygonIndex][
        `t${action.payload.objectTypeIndex}`
      ] = subtypeIndex;

      const selectedMapItemGeoProps =
        allDrawnPolygonGeoProps[selectedPolygonIndex];
      selectedMapItemGeoProps[`t${action.payload.objectTypeIndex}`] =
        action.payload.subtypeIndex;
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);

      if (selectedMapItemGeoProps.t === objectTypeIndexTemplate.building) {
        const defaultBuildingHeight = templatesJson.find(
          (x) =>
            x.t === selectedMapItemGeoProps.t &&
            x[`t${x.t}`] ===
              selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
        ).bh;
        selectedMapItemGeoProps.bh = defaultBuildingHeight;
      } else {
        const defaultTreeHeight = templatesJson.find(
          (x) =>
            x.t === selectedMapItemGeoProps.t &&
            x[`t${x.t}`] ===
              selectedMapItemGeoProps[`t${selectedMapItemGeoProps.t}`]
        ).th;
        selectedMapItemGeoProps.th = defaultTreeHeight;
      }

      drawnPolygonGeojsonWg.features[selectedPolygonIndex].properties =
        selectedMapItemGeoProps;

      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      return {
        ...state,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        allObjectSubtypes: getAllCorrespondingSubtypes(
          selectedMapItemGeoProps.t
        ),
        polygonData: polygonData,
      };
    }

    case types.INITIALIZE_POLYGON_EDITOR: {
      const allDrawnPolygonsPointsWg = cloneDeep(
        state.allDrawnPolygonsPointsWg
      );
      const allDrawnPolygonsPointsUtm = cloneDeep(
        state.allDrawnPolygonsPointsUtm
      );
      const drawnPolygonGeojsonWg = cloneDeep(state.drawnPolygonGeojsonWg);
      const allInputCoords = cloneDeep(state.allInputCoords);
      const allDrawnPolygonGeoProps = cloneDeep(state.allDrawnPolygonGeoProps);
      const arrayOfDrawnPolygonId = cloneDeep(state.arrayOfDrawnPolygonId);
      const currentIncomingPolygonId = cloneDeep(
        state.currentIncomingPolygonId
      );
      const currentIncomingStreetId = cloneDeep(state.currentIncomingStreetId);
      const crsDef = state.crsDef;

      return {
        ...state,
        selectedMapItemGeoProps: null,
        ...polygonEditorInitialState,
        allInputCoords: allInputCoords,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
        allDrawnPolygonsPointsWg: allDrawnPolygonsPointsWg,
        allDrawnPolygonsPointsUtm: allDrawnPolygonsPointsUtm,
        allDrawnPolygonGeoProps: allDrawnPolygonGeoProps,
        arrayOfDrawnPolygonId: arrayOfDrawnPolygonId,
        currentIncomingPolygonId: currentIncomingPolygonId,
        crsDef: crsDef,
        objectType: state.inStreetDrawingMode ? "street" : "building",
        objectTypeIndex: state.inStreetDrawingMode ? 6 : 0,
        objectSubtype: state.inStreetDrawingMode
          ? "No Emission (Overwrite Only)"
          : "Residential <1950",

        allObjectSubtypes: state.inStreetDrawingMode
          ? streetSubtypes
          : buildingSubtypes,
        currentIncomingStreetId: currentIncomingStreetId,
        inSelectMode: false,
      };
    }

    case types.RESET_POLYGON_EDITOR_DATA: {
      const crsDef = state.crsDef;
      return {
        ...state,
        selectedMapItemGeoProps: null,
        ...polygonEditorInitialState,
        crsDef: crsDef,
      };
    }

    case types.UNSELECT_SELECTED_DRAWN_POLYGON_GEO_PROPS: {
      return {
        ...state,
        selectedMapItemGeoProps: null,
      };
    }

    case types.ADD_TREE: {
      const addedTreeCoordinatessWg = action.payload;
      const allDrawnTreesCoordinatesWg = cloneDeep(
        state.allDrawnTreesCoordinatesWg
      );
      const allDrawnTreesCoordinatesUtm = cloneDeep(
        state.allDrawnTreesCoordinatesUtm
      );
      let selectedTreeIndex = cloneDeep(state.selectedTreeIndex);
      const allTreesGeoProps = cloneDeep(state.allTreesGeoProps);
      const arrayOfTreesId = cloneDeep(state.arrayOfTreesId);
      let currentTreeId = cloneDeep(state.currentTreeId);

      --currentTreeId;

      arrayOfTreesId.push(currentTreeId);

      allDrawnTreesCoordinatesWg.push(addedTreeCoordinatessWg);

      allDrawnTreesCoordinatesUtm.push(
        reprojectWgToUtmPoint(
          {
            lat: addedTreeCoordinatessWg[0],
            lng: addedTreeCoordinatessWg[1],
          },
          state.crsDef
        )
      );

      allTreesGeoProps.push({
        id: currentTreeId,
        t: 6,
        [objectSubtypeKey.singleTree]: 1,
        th: 12,
      });

      if (allTreesGeoProps.length === 0 && selectedTreeIndex === null) {
        selectedTreeIndex = 0;
      } else {
        selectedTreeIndex = allDrawnTreesCoordinatesWg.length - 1;
      }

      return {
        ...state,
        allDrawnTreesCoordinatesWg: allDrawnTreesCoordinatesWg,
        allDrawnTreesCoordinatesUtm: allDrawnTreesCoordinatesUtm,
        selectedTreeIndex: selectedTreeIndex,
        allTreesGeoProps: allTreesGeoProps,
        arrayOfTreesId: arrayOfTreesId,
        currentTreeId: currentTreeId,
      };
    }

    case types.REMOVE_TREE: {
      const indexOfRemovedTree = action.payload;

      const allDrawnTreesCoordinatesWg = cloneDeep(
        state.allDrawnTreesCoordinatesWg
      );
      const allDrawnTreesCoordinatesUtm = cloneDeep(
        state.allDrawnTreesCoordinatesUtm
      );
      const arrayOfTreesId = cloneDeep(state.arrayOfTreesId);
      const allTreesGeoProps = cloneDeep(state.allTreesGeoProps);

      allDrawnTreesCoordinatesWg.splice(indexOfRemovedTree, 1);
      allDrawnTreesCoordinatesUtm.splice(indexOfRemovedTree, 1);
      arrayOfTreesId.splice(indexOfRemovedTree, 1);
      allTreesGeoProps.splice(indexOfRemovedTree, 1);

      let polygonData = cloneDeep(state.polygonData);
      if (
        state.selectedMapItemGeoProps &&
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      return {
        ...state,
        polygonData: polygonData,
        allDrawnTreesCoordinatesWg: allDrawnTreesCoordinatesWg,
        allDrawnTreesCoordinatesUtm: allDrawnTreesCoordinatesUtm,
        arrayOfTreesId: arrayOfTreesId,
        allTreesGeoProps: allTreesGeoProps,
        selectedTreeIndex: null,
      };
    }

    case types.SELECT_TREE: {
      if (state.inTreeDrawingMode) {
        return {
          ...state,
          selectedTreeIndex: action.payload,
          selectedEmissionIndex: null,
          selectedMapPoiIndex: null,
          displayTreeCursor: false,
          isTreeDraggable: true,
        };
      } else if (state.inDrawingMode) {
        return {
          ...state,
        };
      } else {
        const selectedMapItemGeoProps = state.allTreesGeoProps[action.payload];
        return {
          ...state,
          selectedTreeIndex: action.payload,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
          currentObjectType: getCurrentObjectType(selectedMapItemGeoProps),
          currentObjectSubtype: getCurrentObjectSubtype(
            selectedMapItemGeoProps
          ),
          allObjectSubtypes: getAllCorrespondingSubtypes(
            selectedMapItemGeoProps.t
          ),
          selectedMapItemGeoJson: null,
          selectedEmissionIndex: null,
          selectedMapPoiIndex: null,
          selectedWindTurbineIndex: null,
        };
      }
    }

    case types.CHANGE_SELECTED_TREE_SUBTYPE: {
      const treeId = action.payload.id;
      const subtypeIndex = action.payload.subtypeIndex;
      const allTreesGeoProps = cloneDeep(state.allTreesGeoProps);

      const selectedTreeIndex = allTreesGeoProps.findIndex(
        (x) => x.id === treeId
      );

      const currentObjectTemplate = templatesJson.find(
        (x) =>
          x.t === objectTypeIndexTemplate.singleTree &&
          x[`t${objectTypeIndexTemplate.singleTree}`] === subtypeIndex
      );
      allTreesGeoProps[selectedTreeIndex]["t6"] = subtypeIndex;
      allTreesGeoProps[selectedTreeIndex].th = Number(currentObjectTemplate.th);
      let selectedMapItemGeoProps = allTreesGeoProps[selectedTreeIndex];

      let polygonData = cloneDeep(state.polygonData);
      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      // if (
      //   !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      // ) {
      //   if (polygonData.length === 1) {
      //     polygonData = [];
      //   } else {
      //     polygonData = polygonData.filter((x) => x.id !== treeId);
      //   }
      // }

      return {
        ...state,
        allTreesGeoProps: allTreesGeoProps,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        polygonData: polygonData,
      };
    }

    case types.UPDATE_TREE_POSITION_ON_INPUT_CHANGE: {
      let { name, value } = action.payload.e.target;

      const treeIndex = action.payload.treeIndex;
      const allDrawnTreesCoordinatesWg = cloneDeep(
        state.allDrawnTreesCoordinatesWg
      );
      const allDrawnTreesCoordinatesUtm = cloneDeep(
        state.allDrawnTreesCoordinatesUtm
      );

      if (name === "lat" && value < 0) {
        return { ...state };
      } else if (name === "lng" && value < 0) {
        return { ...state };
      } else if (value === "") {
        value = 0;
      }

      value = Number(value);
      if (name === "lat") {
        const valueInWg = reprojectUtmToWgPoint(
          [Number(allDrawnTreesCoordinatesUtm[treeIndex].lng), value],
          state.crsDef
        ).lat.toFixed(6);

        allDrawnTreesCoordinatesWg[treeIndex][0] = valueInWg;
        allDrawnTreesCoordinatesUtm[treeIndex].lat = value;
      } else {
        const valueInWg = reprojectUtmToWgPoint(
          [value, Number(allDrawnTreesCoordinatesUtm[treeIndex].lat)],
          state.crsDef
        ).lng.toFixed(6);
        allDrawnTreesCoordinatesWg[treeIndex][1] = valueInWg;
        allDrawnTreesCoordinatesUtm[treeIndex].lng = value;
      }

      return {
        ...state,
        allDrawnTreesCoordinatesUtm: allDrawnTreesCoordinatesUtm,
        allDrawnTreesCoordinatesWg: allDrawnTreesCoordinatesWg,
        tempKey: "",
        tempValue: "",
      };
    }

    case types.GO_TO_TREE_COORDINATES: {
      return {
        ...state,
        inDrawingMode: true,
        inTreeDrawingMode: true,
        selectedMapItemGeoProps: null,
        isTreeDraggable: true,
      };
    }

    case types.GO_TO_TREE_PROPERTIES: {
      const selectedMapItemGeoProps = state.allTreesGeoProps[action.payload];

      return {
        ...state,
        inTreeDrawingMode: false,
        inDrawingMode: false,
        selectedTreeIndex: action.payload,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectType: "single tree",
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        allObjectSubtypes: getAllCorrespondingSubtypes(
          selectedMapItemGeoProps.t
        ),
      };
    }

    case types.UPDATE_TREE_POSITION_ON_DRAGEND: {
      const updatedCoordinates = action.payload.e;

      const allDrawnTreesCoordinatesWg = cloneDeep(
        state.allDrawnTreesCoordinatesWg
      );
      const allDrawnTreesCoordinatesUtm = cloneDeep(
        state.allDrawnTreesCoordinatesUtm
      );

      const updatedTreePositionCoordinatesWg = [
        updatedCoordinates.lat.toFixed(7),
        updatedCoordinates.lng.toFixed(7),
      ];

      const updatedTreePositionCoordinatesUtm = reprojectWgToUtmPoint(
        {
          lat: updatedTreePositionCoordinatesWg[0],
          lng: updatedTreePositionCoordinatesWg[1],
        },
        state.crsDef
      );

      allDrawnTreesCoordinatesWg[action.payload.idx] =
        updatedTreePositionCoordinatesWg;
      allDrawnTreesCoordinatesUtm[action.payload.idx] =
        updatedTreePositionCoordinatesUtm;

      return {
        ...state,
        allDrawnTreesCoordinatesWg: allDrawnTreesCoordinatesWg,
        allDrawnTreesCoordinatesUtm: allDrawnTreesCoordinatesUtm,
      };
    }

    // case types.TOGGLE_SINGLE_TREE_VISIBILITY: {
    //   return {
    //     ...state,
    //     singleTreeVisibility: !state.singleTreeVisibility,
    //   };
    // }

    case types.RESET_TREE_DRAWING_DATA: {
      const crsDef = state.crsDef;
      return {
        ...state,
        selectedMapItemGeoProps: null,
        ...treeDrawingInitialState,
        crsDef: crsDef,
      };
    }

    case types.GET_CURRENT_CURSOR_COORDS: {
      return {
        ...state,
        currentCursorCoords: action.payload,
      };
    }

    case types.TOGGLE_DRAWING_MODE: {
      return {
        ...state,
        ...copyPropertiesInitialState,

        ...drawingInitialState,

        inDrawingMode: !state.inDrawingMode,
        inRasterSettingsMode: false,
        selectedMapItemGeoProps: null,
        selectedMapItemGeoJson: null,
      };
    }

    case types.TOGGLE_POLYGON_DRAWING_MODE: {
      let inPolygonDrawingMode = state.inPolygonDrawingMode;

      if (state.inStreetDrawingMode && inPolygonDrawingMode) {
        inPolygonDrawingMode = true;
      } else {
        inPolygonDrawingMode = !inPolygonDrawingMode;
      }

      return {
        ...state,

        ...drawingInitialState,
        inPolygonDrawingMode: inPolygonDrawingMode,

        allObjectSubtypes: buildingSubtypes,
        objectType: "building",
        objectTypeIndex: 0,
        objectSubtypeIndex: 0,
        polygonPointsWg: [],
        polygonPointsUtm: [],
        inputCoords: [
          { lng: "", lat: "" },
          { lng: "", lat: "" },
          { lng: "", lat: "" },
        ],
        inSelectMode: false,
        polygonDrawn: false,
      };
    }

    case types.TOGGLE_TREE_DRAWING_MODE: {
      return {
        ...state,
        ...drawingInitialState,

        inTreeDrawingMode: !state.inTreeDrawingMode,

        displayTreeCursor: true,
      };
    }

    case types.TOGGLE_RASTER_SETTINGS_MODE: {
      if (
        state.geoJsonWg.features.length === 0 &&
        state.drawnPolygonGeojsonWg.features.length === 0
      ) {
        return { ...state };
      }

      return {
        ...state,
        ...copyPropertiesInitialState,
        ...drawingInitialState,
        inRasterSettingsMode: !state.inRasterSettingsMode,
        selectedMapItemGeoProps: null,
      };
    }

    case types.GET_SELECTED_MAP_ITEM_GEOPROPS: {
      // let selectedTreeIndex = cloneDeep(state.selectedTreeIndex);
      // // if (action.payload.id > 0 && selectedTreeIndex !== null) {
      // //   selectedTreeIndex = null;
      // // }

      const mapItemGeoprops = action.payload;
      const currentObjectType = getCurrentObjectType(
        mapItemGeoprops.properties
      );
      const currentObjectSubtype = getCurrentObjectSubtype(
        mapItemGeoprops.properties
      );
      const allObjectSubtypes = getAllCorrespondingSubtypes(
        mapItemGeoprops.properties.t
      );
      const selectedMapItemGeoJson = mapItemGeoprops;
      return {
        ...state,
        selectedMapItemGeoProps: mapItemGeoprops.properties,
        currentObjectType: currentObjectType,
        currentObjectSubtype: currentObjectSubtype,
        allObjectSubtypes: allObjectSubtypes,
        selectedTreeIndex: null,
        selectedMapItemGeoJson: selectedMapItemGeoJson,
        selectedEmissionIndex: null,
        selectedMapPoiIndex: null,
        selectedWindTurbineIndex: null,
      };
    }

    case types.UNSELECT_SELECTED_MAP_ITEM_GEO_PROPS: {
      return {
        ...state,
        selectedMapItemGeoProps: null,
      };
    }

    case types.CREATE_SINGLE_TREE_GEOJSON: {
      const crs = action.payload;
      const allDrawnTreesCoordinatesUtm = cloneDeep(
        state.allDrawnTreesCoordinatesUtm
      );

      const allTreesGeoProps = cloneDeep(state.allTreesGeoProps);
      let treeGeoJsonUtmCopy = {
        type: "FeatureCollection",
        name: `urn:ogc:def:crs:EPSG::${crs}`,
        crs: {
          type: "name",
          properties: {
            name: `urn:ogc:def:crs:EPSG::${crs}`,
          },
        },
        features: [],
      };
      allTreesGeoProps.map((x, i) => {
        return treeGeoJsonUtmCopy.features.push({
          type: "Feature",
          properties: x,
          geometry: {
            type: "Point",
            coordinates: [
              Number(allDrawnTreesCoordinatesUtm[i].lng),
              Number(allDrawnTreesCoordinatesUtm[i].lat),
            ],
          },
        });
      });
      const treeGeoJsonUtm = treeGeoJsonUtmCopy;
      return {
        ...state,
        treeGeoJsonUtm: treeGeoJsonUtm,
      };
    }
    case types.UPDATE_TEMP_INPUT: {
      const selectedPolygonPoint = action.payload.drawnPolyCoordsIndex;
      return {
        ...state,
        drawnPolyCoordsIndex: selectedPolygonPoint,
        tempKey: action.payload.tempKey,
        tempValue: action.payload.tempValue,
        selectedPolygonPoint: selectedPolygonPoint,
        isPolygonEditorInputFocused: false,
      };
    }

    case types.SET_WMS_LAYER_NAMES: {
      const wmsLayersNames = action.payload;
      return {
        ...state,
        wmsLayersNames: wmsLayersNames,
      };
    }

    case types.TOGGLE_COPY_PROPS_MODE: {
      return {
        ...state,
        assignedCopyCoords: [],
        assignedObjectCopyId: [],
        inCopyPropsMode: !state.inCopyPropsMode,
      };
    }

    case types.ADD_ASSIGNED_OBJECT_COPY: {
      const assignedCopyCoords = cloneDeep(state.assignedCopyCoords);
      const drawnGeoLookUp = new GeoJsonGeometriesLookup(
        state.drawnPolygonGeojsonWg
      );
      const onMapClickPoint = { type: "Point", coordinates: action.payload };
      let clickedGeoJson = drawnGeoLookUp.getContainers(onMapClickPoint);
      let polygonId = null;

      let clickedGeoJsonFeatureParentType = clickedGeoJson.features.filter(
        (x) => {
          return x.properties.t === state.selectedMapItemGeoProps.t;
        }
      );

      if (clickedGeoJsonFeatureParentType[0] === undefined) {
        const geoLookUp = new GeoJsonGeometriesLookup(state.geoJsonWg);
        clickedGeoJson = geoLookUp.getContainers(onMapClickPoint);
        clickedGeoJsonFeatureParentType = clickedGeoJson.features.filter(
          (x) => {
            return x.properties.t === state.selectedMapItemGeoProps.t;
          }
        );

        if (clickedGeoJsonFeatureParentType[0] === undefined) {
          return { ...state };
        }
      }

      polygonId = clickedGeoJsonFeatureParentType[0].properties.id;
      let assignedObjectCopyId = cloneDeep(state.assignedObjectCopyId);

      if (
        clickedGeoJsonFeatureParentType.length === 0 ||
        polygonId === state.selectedMapItemGeoProps.id
      ) {
        return {
          ...state,
        };
      }

      clickedGeoJsonFeatureParentType = clickedGeoJson.features.filter((x) => {
        return x.properties.t === state.selectedMapItemGeoProps.t;
      });

      if (assignedObjectCopyId.includes(polygonId)) {
        let indexOfPolygon = assignedObjectCopyId.findIndex(
          (x) => x === polygonId
        );
        assignedObjectCopyId = assignedObjectCopyId.filter(
          (x) => x !== polygonId
        );
        assignedCopyCoords.splice(indexOfPolygon, 1);
        return {
          ...state,
          assignedObjectCopyId: assignedObjectCopyId,
          assignedCopyCoords: assignedCopyCoords,
        };
      }
      let polygonOrderPoints = [];

      clickedGeoJsonFeatureParentType[0].geometry.coordinates[0].map((x) => {
        polygonOrderPoints.push([x[1], x[0]]);
      });
      assignedCopyCoords.push(polygonOrderPoints);
      assignedObjectCopyId.push(polygonId);

      return {
        ...state,
        assignedObjectCopyId: assignedObjectCopyId,
        assignedCopyCoords: assignedCopyCoords,
      };
    }

    case types.COPY_PROPS: {
      const copySubtype = action.payload.copySubtype;
      const copyHeight = action.payload.copyHeight;
      const assignProperties = action.payload.assignProperties;
      const waterTemperature = action.payload.waterTemperature;
      const buildingSurfaceFractions = action.payload.buildingSurfaceFractions;

      const assignedObjectCopyId = cloneDeep(state.assignedObjectCopyId);
      const selectedMapItemGeoProps = cloneDeep(state.selectedMapItemGeoProps);
      const typeOfParentObject = selectedMapItemGeoProps.t;
      const parentPolygonId = selectedMapItemGeoProps.id;
      const polygonData = cloneDeep(state.polygonData);
      const geoJsonWg = state.geoJsonWg;

      if (copySubtype || copyHeight) {
        if (copySubtype) {
          let subtypeOfParentObject =
            selectedMapItemGeoProps[`t${typeOfParentObject}`];

          for (
            let geoJsonIndex = 0;
            geoJsonIndex < geoJsonWg.features.length;
            geoJsonIndex++
          ) {
            for (
              let assignedObjectCopyIdIndex = 0;
              assignedObjectCopyIdIndex < assignedObjectCopyId.length;
              assignedObjectCopyIdIndex++
            ) {
              if (
                geoJsonWg.features[geoJsonIndex].properties.id ===
                assignedObjectCopyId[assignedObjectCopyIdIndex]
              ) {
                geoJsonWg.features[geoJsonIndex].properties[
                  `t${typeOfParentObject}`
                ] = subtypeOfParentObject;
              }
            }
          }
        }

        if (copyHeight) {
          let heightOfParentObjectToCopy = null;
          let keyHeight = null;
          if (typeOfParentObject === 1) {
            heightOfParentObjectToCopy = selectedMapItemGeoProps.bh;
            keyHeight = "bh";
          } else if (typeOfParentObject === 5) {
            heightOfParentObjectToCopy = selectedMapItemGeoProps.th;
            keyHeight = "th";
          }
          for (
            let geoJsonIndex = 0;
            geoJsonIndex < geoJsonWg.features.length;
            geoJsonIndex++
          ) {
            for (
              let assignedObjectCopyIdIndex = 0;
              assignedObjectCopyIdIndex < assignedObjectCopyId.length;
              assignedObjectCopyIdIndex++
            ) {
              if (
                geoJsonWg.features[geoJsonIndex].properties.id ===
                assignedObjectCopyId[assignedObjectCopyIdIndex]
              ) {
                geoJsonWg.features[geoJsonIndex].properties[keyHeight] =
                  heightOfParentObjectToCopy;
              }
            }
          }
        }
      }

      if (assignProperties && typeOfParentObject !== 4) {
        if (
          polygonData.length !== 0 &&
          polygonData.some((x) => x.id === parentPolygonId)
        ) {
          const parentPolygonData = polygonData.find(
            (x) => x.id === parentPolygonId
          );
          const sharedDataCopyId = assignedObjectCopyId.filter((a) =>
            polygonData.some((b) => b.id === a)
          );
          if (sharedDataCopyId.length === 0) {
            assignedObjectCopyId.map((x) => {
              let parentPolygonDataCopy = cloneDeep(parentPolygonData);
              parentPolygonDataCopy.id = x;
              polygonData.push(parentPolygonDataCopy);
            });
          } else {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              for (
                let sharedDataCopyIdIndex = 0;
                sharedDataCopyIdIndex < sharedDataCopyId.length;
                sharedDataCopyIdIndex++
              ) {
                if (
                  polygonData[polygonDataIndex].id ===
                  sharedDataCopyId[sharedDataCopyIdIndex]
                ) {
                  polygonData.splice(polygonDataIndex, 1);
                }
              }
            }

            assignedObjectCopyId.map((x) => {
              let parentPolygonDataCopy = cloneDeep(parentPolygonData);
              parentPolygonDataCopy.id = x;
              polygonData.push(parentPolygonDataCopy);
            });
          }
        }
      }

      if (assignProperties && typeOfParentObject === 4) {
        if (
          polygonData.length !== 0 &&
          polygonData.some((x) => x.id === parentPolygonId)
        ) {
          const parentPolygonData = polygonData.find(
            (x) => x.id === parentPolygonId
          );
          const parentPolygonDataNoWaterTemp = cloneDeep(parentPolygonData);
          if (!!parentPolygonDataNoWaterTemp?.watert) {
            delete parentPolygonDataNoWaterTemp.watert;
          }
          const sharedDataCopyId = assignedObjectCopyId.filter((a) =>
            polygonData.some((b) => b.id === a)
          );

          if (sharedDataCopyId.length === 0) {
            assignedObjectCopyId.map((x) => {
              let parentPolygonDataCopy = cloneDeep(
                parentPolygonDataNoWaterTemp
              );
              parentPolygonDataCopy.id = x;
              polygonData.push(parentPolygonDataCopy);
            });
          } else {
            const copyOfAssignedObjectCopyId = cloneDeep(assignedObjectCopyId);

            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              for (
                let sharedDataCopyIdIndex = 0;
                sharedDataCopyIdIndex < sharedDataCopyId.length;
                sharedDataCopyIdIndex++
              ) {
                if (
                  polygonData[polygonDataIndex].id ===
                    sharedDataCopyId[sharedDataCopyIdIndex] &&
                  !polygonData[polygonDataIndex]?.watert
                ) {
                  polygonData.splice(polygonDataIndex, 1);
                } else if (
                  polygonData[polygonDataIndex].id ===
                    sharedDataCopyId[sharedDataCopyIdIndex] &&
                  !!polygonData[polygonDataIndex]?.watert
                ) {
                  let parentPolygonDataCopy = cloneDeep(
                    parentPolygonDataNoWaterTemp
                  );
                  const childrenWaterTemperature =
                    polygonData[polygonDataIndex].watert;

                  polygonData.splice(polygonDataIndex, 1);
                  parentPolygonDataCopy.id = polygonData[polygonDataIndex].id;
                  parentPolygonDataCopy.watert = childrenWaterTemperature;
                  polygonData.push(parentPolygonDataCopy);
                  copyOfAssignedObjectCopyId.filter(
                    (x) => x !== sharedDataCopyId[sharedDataCopyIdIndex]
                  );
                }
              }
            }

            copyOfAssignedObjectCopyId.map((x) => {
              let parentPolygonDataCopy = cloneDeep(parentPolygonData);
              parentPolygonDataCopy.id = x;
              polygonData.push(parentPolygonDataCopy);
            });
          }
        }
      }

      if (typeOfParentObject === 4 && waterTemperature) {
        const parentWaterTemperature = polygonData.find(
          (x) => x.id === parentPolygonId
        )?.watert;

        if (polygonData.length !== 0 && !!parentWaterTemperature) {
          const sharedDataCopyId = assignedObjectCopyId.filter((a) =>
            polygonData.some((b) => b.id === a)
          );

          if (sharedDataCopyId.length === 0) {
            assignedObjectCopyId.map((x) => {
              let childrenPolygonDataCopy = {};
              childrenPolygonDataCopy.id = x;
              childrenPolygonDataCopy.watert = parentWaterTemperature;
              polygonData.push(childrenPolygonDataCopy);
            });
          } else {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              for (
                let sharedDataCopyIdIndex = 0;
                sharedDataCopyIdIndex < sharedDataCopyId.length;
                sharedDataCopyIdIndex++
              ) {
                if (
                  polygonData[polygonDataIndex].id ===
                  sharedDataCopyId[sharedDataCopyIdIndex]
                ) {
                  polygonData[polygonDataIndex].watert = parentWaterTemperature;
                }
              }
            }
          }
          return {
            ...state,
            inCopyPropsMode: false,
            polygonData: polygonData,
          };
        }
      }

      if (!assignProperties && buildingSurfaceFractions) {
        if (
          polygonData.length !== 0 &&
          polygonData.some((x) => x.id === parentPolygonId)
        ) {
          const buildingSurfaceFractionsNames = [
            "wallfrac",
            "windfrac",
            "greenfrac_wa",
            "greenfrac_ro",
            "wallfrac_ro",
            "windfrac_ro",
          ];

          const parentPolygonData = polygonData.find(
            (x) => x.id === parentPolygonId
          );
          let primaryBuildingPropsInParent = {};
          for (const key in parentPolygonData) {
            if (buildingSurfaceFractionsNames.some((x) => x === key)) {
              primaryBuildingPropsInParent[key] = parentPolygonData[key];
            }
          }
          const sharedDataCopyId = assignedObjectCopyId.filter((a) =>
            polygonData.some((b) => b.id === a)
          );

          if (
            sharedDataCopyId.length === 0 &&
            Object.keys(primaryBuildingPropsInParent).length !== 0
          ) {
            for (let index = 0; index < assignedObjectCopyId.length; index++) {
              polygonData.push({
                id: assignedObjectCopyId[index],
                ...primaryBuildingPropsInParent,
              });
            }
          } else if (
            sharedDataCopyId.length !== 0 &&
            Object.keys(primaryBuildingPropsInParent).length !== 0
          ) {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              for (
                let sharedDataCopyIdIndex = 0;
                sharedDataCopyIdIndex < sharedDataCopyId.length;
                sharedDataCopyIdIndex++
              ) {
                if (
                  polygonData[polygonDataIndex].id ===
                  sharedDataCopyId[sharedDataCopyIdIndex]
                ) {
                  for (const key in primaryBuildingPropsInParent) {
                    polygonData[polygonDataIndex][key] = parentPolygonData[key];
                  }
                }
              }
            }

            const noneSharedDataCopyId = assignedObjectCopyId.filter((a) =>
              polygonData.some((b) => b.id !== a)
            );
            if (noneSharedDataCopyId.length !== 0) {
              for (
                let index = 0;
                index < noneSharedDataCopyId.length;
                index++
              ) {
                polygonData.push({
                  id: noneSharedDataCopyId[index],
                  ...primaryBuildingPropsInParent,
                });
              }
            }
          }
        }
      }
      if (
        (assignProperties || buildingSurfaceFractions) &&
        !copySubtype &&
        !copyHeight
      ) {
        return {
          ...state,
          assignedCopyCoords: [],
          assignedObjectCopyId: [],
          polygonData: polygonData,
          inCopyPropsMode: false,
        };
      } else if (
        !assignProperties &&
        !buildingSurfaceFractions &&
        (copySubtype || copyHeight)
      ) {
        return {
          ...state,
          assignedCopyCoords: [],
          assignedObjectCopyId: [],
          inCopyPropsMode: false,
          geoJsonWg: geoJsonWg,
        };
      }
      return {
        ...state,
        assignedCopyCoords: [],
        assignedObjectCopyId: [],
        polygonData: polygonData,
        inCopyPropsMode: false,
        geoJsonWg: geoJsonWg,
      };
    }

    case types.TOGGLE_SINGLE_TREE_COPY_PROPS_MODE: {
      return {
        ...state,
        assignedSingleTreeCopyCoords: [],
        assignedSingleTreeCopyId: [],
        inSingleTreeCopyPropsMode: !state.inSingleTreeCopyPropsMode,
      };
    }

    case types.ADD_ASSIGNED_SINGLE_TREE_COPY: {
      const childrenTreeId = action.payload.properties.id;

      const treeFeature = cloneDeep(state.selectedMapItemGeoProps);
      const parentTreeId = treeFeature.id;
      const assignedSingleTreeCopyCoords = cloneDeep(
        state.assignedSingleTreeCopyCoords
      );
      let assignedSingleTreeCopyId = cloneDeep(state.assignedSingleTreeCopyId);

      if (parentTreeId === childrenTreeId || treeFeature.t !== 6) {
        return {
          ...state,
        };
      }

      if (assignedSingleTreeCopyId.includes(childrenTreeId)) {
        const indexOfTree = assignedSingleTreeCopyId.findIndex(
          (x) => x === childrenTreeId
        );
        assignedSingleTreeCopyId = assignedSingleTreeCopyId.filter(
          (x) => x !== childrenTreeId
        );
        assignedSingleTreeCopyCoords.splice(indexOfTree, 1);
        return {
          ...state,
          assignedSingleTreeCopyId: assignedSingleTreeCopyId,
          assignedSingleTreeCopyCoords: assignedSingleTreeCopyCoords,
        };
      }

      const childrenTreeCoords = action.payload.geometry.coordinates;

      if (childrenTreeId < -1000) {
        assignedSingleTreeCopyCoords.push(childrenTreeCoords);
      } else {
        const pointChildrenTreeCoords = [
          childrenTreeCoords[1],
          childrenTreeCoords[0],
        ];
        assignedSingleTreeCopyCoords.push(pointChildrenTreeCoords);
      }
      assignedSingleTreeCopyId.push(childrenTreeId);
      return {
        ...state,
        assignedSingleTreeCopyId: assignedSingleTreeCopyId,
        assignedSingleTreeCopyCoords: assignedSingleTreeCopyCoords,
      };
    }

    case types.COPY_SINGLE_TREE_PROPS: {
      const copySubtype = action.payload.copySubtype;
      const copyHeight = action.payload.copyHeight;
      const assignProperties = action.payload.assignProperties;
      const crownDiameter = action.payload.crownDiameter;
      const assignedSingleTreeCopyId = cloneDeep(
        state.assignedSingleTreeCopyId
      );
      const selectedMapItemGeoProps = cloneDeep(state.selectedMapItemGeoProps);
      const typeOfParentObject = selectedMapItemGeoProps.t;
      const parentPolygonId = selectedMapItemGeoProps.id;
      let polygonData = cloneDeep(state.polygonData);
      const geoJsonWg = state.geoJsonWg;
      const assignedDrawnTreeId = assignedSingleTreeCopyId.filter(
        (x) => x < -1000
      );
      const allDrawnTreesGeoProps = cloneDeep(state.allTreesGeoProps);

      if (copySubtype || copyHeight) {
        let subtypeOfParentObject =
          selectedMapItemGeoProps[`t${typeOfParentObject}`];
        const heightOfParentSingleTreeToCopy = selectedMapItemGeoProps.th;
        const keyHeight = "th";

        if (assignedDrawnTreeId.length !== 0) {
          for (
            let assignedDrawnTreeIdIndex = 0;
            assignedDrawnTreeIdIndex < assignedDrawnTreeId.length;
            assignedDrawnTreeIdIndex++
          ) {
            for (
              let allDrawnTreesGeoPropsIndex = 0;
              allDrawnTreesGeoPropsIndex < allDrawnTreesGeoProps.length;
              allDrawnTreesGeoPropsIndex++
            ) {
              if (
                assignedDrawnTreeId[assignedDrawnTreeIdIndex] ===
                allDrawnTreesGeoProps[allDrawnTreesGeoPropsIndex].id
              ) {
                if (copySubtype) {
                  allDrawnTreesGeoProps[allDrawnTreesGeoPropsIndex][`t6`] =
                    subtypeOfParentObject;
                }
                if (copyHeight) {
                  allDrawnTreesGeoProps[allDrawnTreesGeoPropsIndex][keyHeight] =
                    heightOfParentSingleTreeToCopy;
                }
              }
            }
          }
        }

        if (copySubtype) {
          for (
            let geoJsonIndex = 0;
            geoJsonIndex < geoJsonWg.features.length;
            geoJsonIndex++
          ) {
            for (
              let assignedSingleTreeCopyIdIndex = 0;
              assignedSingleTreeCopyIdIndex < assignedSingleTreeCopyId.length;
              assignedSingleTreeCopyIdIndex++
            ) {
              if (
                geoJsonWg.features[geoJsonIndex].properties.id ===
                assignedSingleTreeCopyId[assignedSingleTreeCopyIdIndex]
              ) {
                geoJsonWg.features[geoJsonIndex].properties[
                  `t${typeOfParentObject}`
                ] = subtypeOfParentObject;
              }
            }
          }
        }

        if (copyHeight) {
          for (
            let geoJsonIndex = 0;
            geoJsonIndex < geoJsonWg.features.length;
            geoJsonIndex++
          ) {
            for (
              let assignedSingleTreeCopyIdIndex = 0;
              assignedSingleTreeCopyIdIndex < assignedSingleTreeCopyId.length;
              assignedSingleTreeCopyIdIndex++
            ) {
              if (
                geoJsonWg.features[geoJsonIndex].properties.id ===
                assignedSingleTreeCopyId[assignedSingleTreeCopyIdIndex]
              ) {
                geoJsonWg.features[geoJsonIndex].properties[keyHeight] =
                  heightOfParentSingleTreeToCopy;
              }
            }
          }
        }
      }

      if (assignProperties) {
        if (
          polygonData.length !== 0 &&
          polygonData.some((x) => x.id === parentPolygonId)
        ) {
          const parentPolygonData = polygonData.find(
            (x) => x.id === parentPolygonId
          );

          const parentPolygonDataNoTreeCrownDiameter =
            cloneDeep(parentPolygonData);

          if (!!parentPolygonDataNoTreeCrownDiameter?.crown_dia) {
            delete parentPolygonDataNoTreeCrownDiameter.crown_dia;
          }

          let newPolygonDataTreeId = [];

          assignedSingleTreeCopyId.map((x) => {
            let childrenTreeIndexInPolygonData = polygonData.findIndex((z) => {
              return z.id === x;
            });

            if (
              childrenTreeIndexInPolygonData !== -1 &&
              polygonData[childrenTreeIndexInPolygonData].id !==
                parentPolygonDataNoTreeCrownDiameter.id
            ) {
              const childrenPolygonDataCrownDia =
                polygonData[childrenTreeIndexInPolygonData]?.crown_dia;

              polygonData[childrenTreeIndexInPolygonData] = {
                ...parentPolygonDataNoTreeCrownDiameter,
                id: x,
              };

              if (childrenPolygonDataCrownDia) {
                polygonData[childrenTreeIndexInPolygonData].crown_dia =
                  childrenPolygonDataCrownDia;
              }
            } else {
              newPolygonDataTreeId.push(x);
            }
          });

          if (newPolygonDataTreeId.length !== 0) {
            newPolygonDataTreeId.map((x) => {
              let childrenPolygonData = {
                ...parentPolygonDataNoTreeCrownDiameter,
                id: x,
              };

              polygonData.push(childrenPolygonData);
            });
          }
        }
      }

      if (crownDiameter) {
        const parentCrownDiameter = polygonData.find(
          (x) => x.id === parentPolygonId
        )?.crown_dia;

        if (polygonData.length !== 0 && !!parentCrownDiameter) {
          assignedSingleTreeCopyId.map((x) => {
            let childrenTreeIndexInPolygonData = polygonData.findIndex((z) => {
              return z.id === x;
            });
            if (childrenTreeIndexInPolygonData !== -1) {
              polygonData[childrenTreeIndexInPolygonData].crown_dia =
                parentCrownDiameter;
            } else {
              let childrenPolygonDataCopy = {
                id: x,
                crown_dia: parentCrownDiameter,
              };

              polygonData.push(childrenPolygonDataCopy);
            }
          });
        }
      }

      return {
        ...state,
        assignedSingleTreeCopyCoords: [],
        assignedSingleTreeCopyId: [],
        polygonData: polygonData,
        inSingleTreeCopyPropsMode: false,
        inCopyPropsMode: false,
        geoJsonWg: geoJsonWg,
        allTreesGeoProps: allDrawnTreesGeoProps,
      };
    }

    case projectTypes.SET_CURRENT_CASE: {
      return {
        ...initialState,
        inRasterSettingsMode: false,
      };
    }

    case types.FILTER_GEOJSON: {
      let geoJsonWg = cloneDeep(state.geoJsonWg);
      const filteredGeoJson = geoJsonWg.features.filter(
        (x) => !action.payload.includes(x.properties.t)
      );

      return {
        ...state,
        filteredGeoJson: filteredGeoJson,
        geoKey: state.geoKey + 1,
      };
    }

    case types.SET_DRAWN_POLYGON_GEOJSON_CRS: {
      const drawnPolygonGeojsonWg = state.drawnPolygonGeojsonWg;
      drawnPolygonGeojsonWg.crs.properties.name = `urn:ogc:def:crs:EPSG::${action.payload}`;

      return {
        ...state,
        drawnPolygonGeojsonWg: drawnPolygonGeojsonWg,
      };
    }

    case types.RESET_ALL_MAP_DATA: {
      return {
        ...initialState,
        crsDef: state.crsDef,
      };
    }

    case types.TOGGLE_TREE_CURSOR_DISPLAY: {
      let isTreeDraggable = cloneDeep(state.isTreeDraggable);

      if (state.displayTreeCursor) {
        isTreeDraggable = true;
      }
      return {
        ...state,
        displayTreeCursor: !state.displayTreeCursor,
        isTreeDraggable: isTreeDraggable,
      };
    }

    case types.ENABLE_TREE_DRAGGABLE: {
      return {
        ...state,
        isTreeDraggable: true,
      };
    }

    case types.DISABLE_TREE_DRAGGABLE: {
      return {
        ...state,
        isTreeDraggable: false,
      };
    }

    case types.TOGGLE_EMISSION_DRAWING_MODE: {
      return {
        ...state,
        ...drawingInitialState,
        inEmissionDrawingMode: !state.inEmissionDrawingMode,
        displayEmissionCursor: true,
        objectSubtypeIndex: 0,
        polygonDrawn: false,
      };
    }

    case types.ADD_EMISSION: {
      const addedEmissionCoordinatessWg = action.payload;
      const allDrawnEmissionCoordinatesWg = cloneDeep(
        state.allDrawnEmissionCoordinatesWg
      );
      const allDrawnEmissionCoordinatesUtm = cloneDeep(
        state.allDrawnEmissionCoordinatesUtm
      );
      let selectedEmissionIndex = cloneDeep(state.selectedEmissionIndex);
      const allDrawnEmissionGeoProps = cloneDeep(
        state.allDrawnEmissionGeoProps
      );
      const arrayOfEmissionId = cloneDeep(state.arrayOfEmissionId);
      let currentIncomingEmissionId = cloneDeep(
        state.currentIncomingEmissionId
      );

      allDrawnEmissionCoordinatesWg.push(addedEmissionCoordinatessWg);

      allDrawnEmissionCoordinatesUtm.push(
        reprojectWgToUtmPoint(
          {
            lat: addedEmissionCoordinatessWg[0],
            lng: addedEmissionCoordinatessWg[1],
          },
          state.crsDef
        )
      );
      --currentIncomingEmissionId;

      allDrawnEmissionGeoProps.push({
        id: currentIncomingEmissionId,
        t: 8,
        t8: 1,
        ea: 0.022,
        eb: 0.022,
      });

      arrayOfEmissionId.push(currentIncomingEmissionId);
      if (
        allDrawnEmissionGeoProps.length === 0 &&
        selectedEmissionIndex === null
      ) {
        selectedEmissionIndex = 0;
      } else {
        selectedEmissionIndex = allDrawnEmissionCoordinatesWg.length - 1;
      }

      return {
        ...state,
        allDrawnEmissionCoordinatesWg: allDrawnEmissionCoordinatesWg,
        allDrawnEmissionCoordinatesUtm: allDrawnEmissionCoordinatesUtm,
        selectedEmissionIndex: selectedEmissionIndex,
        allDrawnEmissionGeoProps: allDrawnEmissionGeoProps,
        arrayOfEmissionId: arrayOfEmissionId,
        currentIncomingEmissionId: currentIncomingEmissionId,
      };
    }

    case types.SELECT_EMISSION: {
      if (state.inEmissionDrawingMode) {
        return {
          ...state,
          selectedEmissionIndex: action.payload,
          displayEmissionCursor: false,
          isEmissionDraggable: true,
        };
      } else if (state.inDrawingMode) {
        return {
          ...state,
        };
      } else {
        const selectedMapItemGeoProps =
          state.allDrawnEmissionGeoProps[action.payload];
        return {
          ...state,
          selectedEmissionIndex: action.payload,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
          currentObjectType: getCurrentObjectType(selectedMapItemGeoProps),
          currentObjectSubtype: getCurrentObjectSubtype(
            selectedMapItemGeoProps
          ),
          allObjectSubtypes: getAllCorrespondingSubtypes(
            selectedMapItemGeoProps.t
          ),
          selectedMapItemGeoJson: null,
          selectedTreeIndex: null,
          selectedMapPoiIndex: null,
          selectedWindTurbineIndex: null,
        };
      }
    }

    case types.REMOVE_EMISSION: {
      const indexOfRemovedEmission = action.payload;

      const allDrawnEmissionCoordinatesWg = cloneDeep(
        state.allDrawnEmissionCoordinatesWg
      );
      const allDrawnEmissionCoordinatesUtm = cloneDeep(
        state.allDrawnEmissionCoordinatesUtm
      );
      const arrayOfEmissionId = cloneDeep(state.arrayOfEmissionId);
      const allDrawnEmissionGeoProps = cloneDeep(
        state.allDrawnEmissionGeoProps
      );

      allDrawnEmissionCoordinatesWg.splice(indexOfRemovedEmission, 1);
      allDrawnEmissionCoordinatesUtm.splice(indexOfRemovedEmission, 1);
      arrayOfEmissionId.splice(indexOfRemovedEmission, 1);
      allDrawnEmissionGeoProps.splice(indexOfRemovedEmission, 1);

      let polygonData = cloneDeep(state.polygonData);
      if (
        state.selectedMapItemGeoProps &&
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      return {
        ...state,
        polygonData: polygonData,
        allDrawnEmissionCoordinatesWg: allDrawnEmissionCoordinatesWg,
        allDrawnEmissionCoordinatesUtm: allDrawnEmissionCoordinatesUtm,
        arrayOfEmissionId: arrayOfEmissionId,
        allDrawnEmissionGeoProps: allDrawnEmissionGeoProps,
        selectedTreeIndex: null,
      };
    }

    case types.UPDATE_EMISSION_POSITION_ON_DRAGEND: {
      const updatedCoordinates = action.payload.e;

      const allDrawnEmissionCoordinatesWg = cloneDeep(
        state.allDrawnEmissionCoordinatesWg
      );
      const allDrawnEmissionCoordinatesUtm = cloneDeep(
        state.allDrawnEmissionCoordinatesUtm
      );

      const updatedEmissionPositionCoordinatesWg = [
        updatedCoordinates.lat.toFixed(7),
        updatedCoordinates.lng.toFixed(7),
      ];

      const updatedEmissionPositionCoordinatesUtm = reprojectWgToUtmPoint(
        {
          lat: updatedEmissionPositionCoordinatesWg[0],
          lng: updatedEmissionPositionCoordinatesWg[1],
        },
        state.crsDef
      );

      allDrawnEmissionCoordinatesWg[action.payload.idx] =
        updatedEmissionPositionCoordinatesWg;
      allDrawnEmissionCoordinatesUtm[action.payload.idx] =
        updatedEmissionPositionCoordinatesUtm;

      return {
        ...state,
        allDrawnEmissionCoordinatesWg: allDrawnEmissionCoordinatesWg,
        allDrawnEmissionCoordinatesUtm: allDrawnEmissionCoordinatesUtm,
      };
    }

    case types.ADD_ASSIGNED_SINGLE_EMISSION_COPY: {
      const childrenEmissionId = action.payload.properties.id;

      const emissionFeature = cloneDeep(state.selectedMapItemGeoProps);
      const parentEmissionId = emissionFeature.id;
      const assignedEmissionCopyCoords = cloneDeep(
        state.assignedEmissionCopyCoords
      );
      let assignedEmissionCopyId = cloneDeep(state.assignedEmissionCopyId);

      if (parentEmissionId === childrenEmissionId || emissionFeature.t !== 8) {
        return {
          ...state,
        };
      }

      if (assignedEmissionCopyId.includes(childrenEmissionId)) {
        const indexOfEmission = assignedEmissionCopyId.findIndex(
          (x) => x === childrenEmissionId
        );
        assignedEmissionCopyId = assignedEmissionCopyId.filter(
          (x) => x !== childrenEmissionId
        );
        assignedEmissionCopyCoords.splice(indexOfEmission, 1);
        return {
          ...state,
          assignedEmissionCopyId: assignedEmissionCopyId,
          assignedEmissionCopyCoords: assignedEmissionCopyCoords,
        };
      }

      const childrenEmissionCoords = action.payload.geometry.coordinates;

      if (childrenEmissionId < -2000 && childrenEmissionId > -3000) {
        assignedEmissionCopyCoords.push(childrenEmissionCoords);
      } else {
        const pointChildrenEmissionCoords = [
          childrenEmissionCoords[1],
          childrenEmissionCoords[0],
        ];
        assignedEmissionCopyCoords.push(pointChildrenEmissionCoords);
      }
      assignedEmissionCopyId.push(childrenEmissionId);
      return {
        ...state,
        assignedEmissionCopyId: assignedEmissionCopyId,
        assignedEmissionCopyCoords: assignedEmissionCopyCoords,
      };
    }

    case types.COPY_EMISSION_PROPS: {
      const copySubtype = action.payload.copySubtype;
      const emissionEa = action.payload.emissionEa;
      const emissionEb = action.payload.emissionEb;
      const assignProperties = action.payload.assignProperties;

      const assignedEmissionCopyId = cloneDeep(state.assignedEmissionCopyId);
      const selectedMapItemGeoProps = cloneDeep(state.selectedMapItemGeoProps);
      const typeOfParentObject = selectedMapItemGeoProps.t;
      const parentPolygonId = selectedMapItemGeoProps.id;
      //let polygonData = JSON.parse(JSON.stringify(state.polygonData))
      let polygonData = cloneDeep(state.polygonData);

      const emissionGeoJsonWg = state.emissionGeoJsonWg;
      const allDrawnEmissionGeoProps = cloneDeep(
        state.allDrawnEmissionGeoProps
      );

      if (copySubtype) {
        const subtypeOfParentObject =
          selectedMapItemGeoProps[`t${typeOfParentObject}`];

        for (
          let assignedEmissionCopyIdIndex = 0;
          assignedEmissionCopyIdIndex < assignedEmissionCopyId.length;
          assignedEmissionCopyIdIndex++
        ) {
          let geoJsonFeatureChildIndex = emissionGeoJsonWg.features.findIndex(
            (x) => {
              return (
                x.properties.id ===
                assignedEmissionCopyId[assignedEmissionCopyIdIndex]
              );
            }
          );

          if (geoJsonFeatureChildIndex !== -1) {
            emissionGeoJsonWg.features[geoJsonFeatureChildIndex].properties[
              "t8"
            ] = subtypeOfParentObject;
          } else {
            for (
              let index = 0;
              index < allDrawnEmissionGeoProps.length;
              index++
            ) {
              if (
                allDrawnEmissionGeoProps[index].id ===
                assignedEmissionCopyId[assignedEmissionCopyIdIndex]
              ) {
                allDrawnEmissionGeoProps[index]["t8"] = subtypeOfParentObject;
              }
            }
          }
        }
      }

      if (emissionEa) {
        const parentPropertyValue = polygonData.filter((x) => {
          return x.id === parentPolygonId;
        })[0]?.ea;

        const eaValue = parentPropertyValue
          ? parentPropertyValue
          : selectedMapItemGeoProps.ea;

        if (polygonData.length === 0) {
          assignedEmissionCopyId.map((x) => {
            polygonData.push({
              id: x,
              ea: eaValue,
            });
          });
        } else {
          for (
            let assignedEmissionCopyIdIndex = 0;
            assignedEmissionCopyIdIndex < assignedEmissionCopyId.length;
            assignedEmissionCopyIdIndex++
          ) {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              if (
                polygonData[polygonDataIndex].id ===
                assignedEmissionCopyId[assignedEmissionCopyIdIndex]
              ) {
                polygonData[polygonDataIndex].ea = eaValue;
              }
            }
          }
        }

        let nonPolygonDataChildren = assignedEmissionCopyId.filter(
          (x) => !polygonData.some((y) => x === y.id)
        );

        if (nonPolygonDataChildren.length !== 0) {
          nonPolygonDataChildren.map((x) => {
            polygonData.push({
              id: x,
              ea: eaValue,
            });
          });
        }
      }

      if (emissionEb) {
        const parentPropertyValue = polygonData.filter((x) => {
          return x.id === parentPolygonId;
        })[0]?.eb;

        const ebValue = parentPropertyValue
          ? parentPropertyValue
          : selectedMapItemGeoProps.eb;

        if (polygonData.length === 0) {
          assignedEmissionCopyId.map((x) => {
            polygonData.push({
              id: x,
              eb: ebValue,
            });
          });
        } else {
          for (
            let assignedEmissionCopyIdIndex = 0;
            assignedEmissionCopyIdIndex < assignedEmissionCopyId.length;
            assignedEmissionCopyIdIndex++
          ) {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              if (
                polygonData[polygonDataIndex].id ===
                assignedEmissionCopyId[assignedEmissionCopyIdIndex]
              ) {
                polygonData[polygonDataIndex].eb = ebValue;
              }
            }
          }
        }

        let nonPolygonDataChildren = assignedEmissionCopyId.filter(
          (x) => !polygonData.some((y) => x === y.id)
        );

        if (nonPolygonDataChildren.length !== 0) {
          nonPolygonDataChildren.map((x) => {
            polygonData.push({
              id: x,
              eb: ebValue,
            });
          });
        }
      }

      if (assignProperties) {
        const copyPolygonData = cloneDeep(polygonData);
        if (
          copyPolygonData.length !== 0 &&
          copyPolygonData.some((x) => x.id === parentPolygonId)
        ) {
          const parentData = copyPolygonData.find(
            (x) => x.id === parentPolygonId
          );

          if (parentData["ea"]) {
            delete parentData["ea"];
          }

          if (parentData["eb"]) {
            delete parentData["eb"];
          }

          const childrenPolygonDataCopyId = assignedEmissionCopyId.filter((a) =>
            copyPolygonData.some((b) => b.id === a)
          );

          if (childrenPolygonDataCopyId.length === 0) {
            assignedEmissionCopyId.map((x) => {
              polygonData.push({ ...parentData, id: x });
            });
          } else {
            for (
              let assignedEmissionCopyIdIndex = 0;
              assignedEmissionCopyIdIndex < assignedEmissionCopyId.length;
              assignedEmissionCopyIdIndex++
            ) {
              for (
                let polygonDataIndex = 0;
                polygonDataIndex < polygonData.length;
                polygonDataIndex++
              ) {
                if (
                  polygonData[polygonDataIndex].id ===
                  assignedEmissionCopyId[assignedEmissionCopyIdIndex]
                ) {
                  let childPolygonDataEa = polygonData[polygonDataIndex].ea;
                  let childPolygonDataEb = polygonData[polygonDataIndex].eb;

                  if (childPolygonDataEa && childPolygonDataEb) {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedEmissionCopyId[assignedEmissionCopyIdIndex],
                      ea: childPolygonDataEa,
                      eb: childPolygonDataEb,
                    };
                  } else if (childPolygonDataEa) {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedEmissionCopyId[assignedEmissionCopyIdIndex],
                      ea: childPolygonDataEa,
                    };
                  } else if (childPolygonDataEb) {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedEmissionCopyId[assignedEmissionCopyIdIndex],
                      eb: childPolygonDataEb,
                    };
                  } else {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedEmissionCopyId[assignedEmissionCopyIdIndex],
                    };
                  }
                }
              }
            }
          }
          let nonPolygonDataChildren = assignedEmissionCopyId.filter(
            (x) => !polygonData.some((y) => x === y.id)
          );

          if (nonPolygonDataChildren.length !== 0) {
            nonPolygonDataChildren.map((x) => {
              polygonData.push({ ...parentData, id: x });
            });
          }
        }
      }

      return {
        ...state,
        assignedCopyCoords: [],
        assignedEmissionCopyCoords: [],
        assignedEmissionCopyId: [],
        polygonData: polygonData,
        inEmissionCopyPropsMode: false,
        emissionGeoJsonWg: emissionGeoJsonWg,
        allDrawnEmissionGeoProps: allDrawnEmissionGeoProps,
      };
    }

    case types.ENABLE_EMISSION_DRAGGABLE: {
      return {
        ...state,
        isEmissionDraggable: true,
      };
    }

    case types.DISABLE_EMISSION_DRAGGABLE: {
      return {
        ...state,
        isEmissionDraggable: false,
      };
    }

    case types.TOGGLE_EMISSION_CURSOR_DISPLAY: {
      let isEmissionDraggable = cloneDeep(state.isEmissionDraggable);

      if (state.displayEmissionCursor) {
        isEmissionDraggable = true;
      }

      return {
        ...state,
        displayEmissionCursor: !state.displayEmissionCursor,
        isEmissionDraggable: isEmissionDraggable,
      };
    }

    case types.UPDATE_EMISSION_POSITION_ON_INPUT_CHANGE: {
      let { name, value } = action.payload.e.target;

      const emissionIndex = action.payload.emissionIndex;
      const allDrawnEmissionCoordinatesWg = cloneDeep(
        state.allDrawnEmissionCoordinatesWg
      );
      const allDrawnEmissionCoordinatesUtm = cloneDeep(
        state.allDrawnEmissionCoordinatesUtm
      );

      if (name === "lat" && value < 0) {
        return { ...state };
      } else if (name === "lng" && value < 0) {
        return { ...state };
      } else if (value === "") {
        value = 0;
      }

      value = Number(value);
      if (name === "lat") {
        const valueInWg = reprojectUtmToWgPoint(
          [Number(allDrawnEmissionCoordinatesUtm[emissionIndex].lng), value],
          state.crsDef
        ).lat.toFixed(6);

        allDrawnEmissionCoordinatesWg[emissionIndex][0] = valueInWg;
        allDrawnEmissionCoordinatesUtm[emissionIndex].lat = value;
      } else {
        const valueInWg = reprojectUtmToWgPoint(
          [value, Number(allDrawnEmissionCoordinatesUtm[emissionIndex].lat)],
          state.crsDef
        ).lng.toFixed(6);
        allDrawnEmissionCoordinatesWg[emissionIndex][1] = valueInWg;
        allDrawnEmissionCoordinatesUtm[emissionIndex].lng = value;
      }
      return {
        ...state,
        allDrawnEmissionCoordinatesUtm: allDrawnEmissionCoordinatesUtm,
        allDrawnEmissionCoordinatesWg: allDrawnEmissionCoordinatesWg,
        tempKey: "",
        tempValue: "",
      };
    }

    case types.GO_TO_EMISSION_PROPERTIES: {
      const selectedMapItemGeoProps =
        state.allDrawnEmissionGeoProps[action.payload];

      return {
        ...state,
        inEmissionDrawingMode: false,
        inDrawingMode: false,
        selectedEmissionIndex: action.payload,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectType: pointEmissionObjectType.value,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        allObjectSubtypes: getAllCorrespondingSubtypes(
          selectedMapItemGeoProps.t
        ),
      };
    }

    case types.GO_TO_EMISSION_COORDINATES: {
      return {
        ...state,
        inDrawingMode: true,
        inEmissionDrawingMode: true,
        selectedMapItemGeoProps: null,
      };
    }

    case types.CHANGE_SELECTED_DRAWN_EMISSION_SUBTYPE: {
      const emissionId = action.payload.id;
      const subtypeIndex = action.payload.subtypeIndex;
      const allDrawnEmissionGeoProps = cloneDeep(
        state.allDrawnEmissionGeoProps
      );

      const selectedEmissionIndex = allDrawnEmissionGeoProps.findIndex(
        (x) => x.id === emissionId
      );

      allDrawnEmissionGeoProps[selectedEmissionIndex]["t8"] = subtypeIndex;

      let polygonData = cloneDeep(state.polygonData);
      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      const subtypeEmissTemplate = templatesJson.find((x) => {
        return x.t === 8 && x[`t8`] === subtypeIndex;
      });

      allDrawnEmissionGeoProps[selectedEmissionIndex].ea =
        subtypeEmissTemplate.ea;
      allDrawnEmissionGeoProps[selectedEmissionIndex].eb =
        subtypeEmissTemplate.eb;

      let selectedMapItemGeoProps =
        allDrawnEmissionGeoProps[selectedEmissionIndex];
      return {
        ...state,
        allDrawnEmissionGeoProps: allDrawnEmissionGeoProps,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        polygonData: polygonData,
      };
    }

    case types.CHANGE_SELECTED_GEOJSON_EMISSION_SUBTYPE: {
      const objectId = action.payload.objectId;
      const subtypeIndex = action.payload.subtypeIndex;
      let emissionGeoJsonWg = state.emissionGeoJsonWg;
      const featureIndexInGeoJson = emissionGeoJsonWg.features.findIndex(
        (x) => x.properties.id === objectId
      );

      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        if (polygonData.length === 1) {
          polygonData = [];
        } else {
          polygonData = polygonData.filter((x) => x.id !== objectId);
        }
      }
      const objectGeojsonProperties =
        emissionGeoJsonWg.features[featureIndexInGeoJson].properties;
      objectGeojsonProperties[`t${objectGeojsonProperties.t}`] = subtypeIndex;
      return {
        ...state,
        emissionGeoJsonWg: emissionGeoJsonWg,
        polygonData: polygonData,
        currentObjectSubtype: state.allObjectSubtypes.value[subtypeIndex - 1],
      };
    }

    case types.UPDATE_EMISSION_GEOJSON_PROPERTIES: {
      const keyName = action.payload.key;
      const propertiesValue = Number(action.payload.value);
      if (state.selectedMapItemGeoProps.id > 0) {
        const emissionGeoJsonWg = state.emissionGeoJsonWg;
        const featureIndexInGeoJson = emissionGeoJsonWg.features.findIndex(
          (x) => x.properties.id === state.selectedMapItemGeoProps.id
        );
        emissionGeoJsonWg.features[featureIndexInGeoJson].properties[keyName] =
          propertiesValue;

        return {
          ...state,
          emissionGeoJsonWg: emissionGeoJsonWg,
        };
      } else {
        const allDrawnEmissionGeoProps = state.allDrawnEmissionGeoProps;
        const featureIndexInAllEmissionsGeoProps =
          allDrawnEmissionGeoProps.findIndex(
            (x) => x.id === state.selectedMapItemGeoProps.id
          );
        allDrawnEmissionGeoProps[featureIndexInAllEmissionsGeoProps][keyName] =
          propertiesValue;

        return {
          ...state,
          allDrawnEmissionGeoProps: allDrawnEmissionGeoProps,
        };
      }
    }

    case types.TOGGLE_MAP_POI_DRAWING_MODE: {
      return {
        ...state,
        ...drawingInitialState,
        inMapPoiDrawingMode: !state.inMapPoiDrawingMode,
        displayMapPoiCursor: true,
      };
    }

    case types.ADD_MAP_POI: {
      const addedMapPoiCoordinatessWg = action.payload;
      const allDrawnMapPoiCoordinatesWg = cloneDeep(
        state.allDrawnMapPoiCoordinatesWg
      );
      const allDrawnMapPoiCoordinatesUtm = cloneDeep(
        state.allDrawnMapPoiCoordinatesUtm
      );
      let selectedMapPoiIndex = cloneDeep(state.selectedMapPoiIndex);
      const allDrawnMapPoiGeoProps = cloneDeep(state.allDrawnMapPoiGeoProps);
      const arrayOfMapPoiId = cloneDeep(state.arrayOfMapPoiId);
      let currentIncomingMapPoiId = cloneDeep(state.currentIncomingMapPoiId);

      arrayOfMapPoiId.push(currentIncomingMapPoiId);
      allDrawnMapPoiCoordinatesWg.push(addedMapPoiCoordinatessWg);

      --currentIncomingMapPoiId;

      const mapPoiUtmCoordinates = reprojectWgToUtmPoint(
        {
          lat: addedMapPoiCoordinatessWg[0],
          lng: addedMapPoiCoordinatessWg[1],
        },
        state.crsDef
      );

      allDrawnMapPoiCoordinatesUtm.push({
        ...mapPoiUtmCoordinates,
        id: currentIncomingMapPoiId,
        name: `Poi ${-currentIncomingMapPoiId}`,
      });

      allDrawnMapPoiGeoProps.push({
        id: currentIncomingMapPoiId,
        t: 9,
        t9: 1,
        name: `Poi ${currentIncomingMapPoiId}`,
      });

      if (allDrawnMapPoiGeoProps.length === 0 && selectedMapPoiIndex === null) {
        selectedMapPoiIndex = 0;
      } else {
        selectedMapPoiIndex = allDrawnMapPoiCoordinatesWg.length - 1;
      }

      return {
        ...state,
        allDrawnMapPoiCoordinatesWg: allDrawnMapPoiCoordinatesWg,
        allDrawnMapPoiCoordinatesUtm: allDrawnMapPoiCoordinatesUtm,
        selectedMapPoiIndex: selectedMapPoiIndex,
        allDrawnMapPoiGeoProps: allDrawnMapPoiGeoProps,
        arrayOfMapPoiId: arrayOfMapPoiId,
        currentIncomingMapPoiId: currentIncomingMapPoiId,
      };
    }

    case types.SELECT_MAP_POI: {
      const selectedMapPoiIndex = action.payload;

      if (state.inMapPoiDrawingMode) {
        const selectedMapItemGeoProps =
          state.allDrawnMapPoiGeoProps[selectedMapPoiIndex];
        return {
          ...state,
          selectedMapPoiIndex: action.payload,
          displayMapPoiCursor: false,
          isMapPoiDraggable: true,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
        };
      } else if (state.inDrawingMode) {
        return {
          ...state,
        };
      } else {
        const selectedMapItemGeoProps =
          state.allDrawnMapPoiGeoProps[selectedMapPoiIndex];
        return {
          ...state,
          selectedMapPoiIndex: selectedMapPoiIndex,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
          currentObjectType: getCurrentObjectType(selectedMapItemGeoProps),
          currentObjectSubtype: getCurrentObjectSubtype(
            selectedMapItemGeoProps
          ),
          allObjectSubtypes: getAllCorrespondingSubtypes(
            selectedMapItemGeoProps.t
          ),
          selectedMapItemGeoJson: null,
          selectedEmissionIndex: null,
          selectedTreeIndex: null,
          selectedWindTurbineIndex: null,
        };
      }
    }

    case types.REMOVE_MAP_POI: {
      const indexOfRemovedMapPoi = action.payload;

      const allDrawnMapPoiCoordinatesWg = cloneDeep(
        state.allDrawnMapPoiCoordinatesWg
      );
      const allDrawnMapPoiCoordinatesUtm = cloneDeep(
        state.allDrawnMapPoiCoordinatesUtm
      );
      const arrayOfMapPoiId = cloneDeep(state.arrayOfMapPoiId);
      const allDrawnMapPoiGeoProps = cloneDeep(state.allDrawnMapPoiGeoProps);

      allDrawnMapPoiCoordinatesWg.splice(indexOfRemovedMapPoi, 1);
      allDrawnMapPoiCoordinatesUtm.splice(indexOfRemovedMapPoi, 1);
      arrayOfMapPoiId.splice(indexOfRemovedMapPoi, 1);
      allDrawnMapPoiGeoProps.splice(indexOfRemovedMapPoi, 1);

      let polygonData = cloneDeep(state.polygonData);
      if (
        state.selectedMapItemGeoProps &&
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }

      return {
        ...state,
        polygonData: polygonData,
        allDrawnMapPoiCoordinatesWg: allDrawnMapPoiCoordinatesWg,
        allDrawnMapPoiCoordinatesUtm: allDrawnMapPoiCoordinatesUtm,
        arrayOfMapPoiId: arrayOfMapPoiId,
        allDrawnMapPoiGeoProps: allDrawnMapPoiGeoProps,
        //selectedTreeIndex: null,
      };
    }

    case types.UPDATE_MAP_POI_POSITION_ON_DRAGEND: {
      const updatedCoordinates = action.payload.e;
      const indexOfMapPoi = action.payload.idx;
      const allDrawnMapPoiCoordinatesWg = cloneDeep(
        state.allDrawnMapPoiCoordinatesWg
      );
      const allDrawnMapPoiCoordinatesUtm = cloneDeep(
        state.allDrawnMapPoiCoordinatesUtm
      );

      const updatedMapPoiPositionCoordinatesWg = [
        updatedCoordinates.lat.toFixed(7),
        updatedCoordinates.lng.toFixed(7),
      ];

      const updatedMapPoiPositionCoordinatesUtm = reprojectWgToUtmPoint(
        {
          lat: updatedMapPoiPositionCoordinatesWg[0],
          lng: updatedMapPoiPositionCoordinatesWg[1],
        },
        state.crsDef
      );

      allDrawnMapPoiCoordinatesWg[indexOfMapPoi] =
        updatedMapPoiPositionCoordinatesWg;

      allDrawnMapPoiCoordinatesUtm[indexOfMapPoi] = {
        ...updatedMapPoiPositionCoordinatesUtm,
        id: allDrawnMapPoiCoordinatesUtm[indexOfMapPoi].id,
        name: allDrawnMapPoiCoordinatesUtm[indexOfMapPoi].name,
      };

      return {
        ...state,
        allDrawnMapPoiCoordinatesWg: allDrawnMapPoiCoordinatesWg,
        allDrawnMapPoiCoordinatesUtm: allDrawnMapPoiCoordinatesUtm,
      };
    }

    case types.ENABLE_MAP_POI_DRAGGABLE: {
      return {
        ...state,
        isMapPoiDraggable: true,
      };
    }

    case types.DISABLE_MAP_POI_DRAGGABLE: {
      return {
        ...state,
        isMapPoiDraggable: false,
      };
    }

    case types.TOGGLE_MAP_POI_CURSOR_DISPLAY: {
      let isMapPoiDraggable = cloneDeep(state.isMap);

      if (state.displayMapPoiCursor) {
        isMapPoiDraggable = true;
      }
      return {
        ...state,
        displayMapPoiCursor: !state.displayMapPoiCursor,
        isMapPoiDraggable: isMapPoiDraggable,
      };
    }

    case types.UPDATE_MAP_POI_POSITION_ON_INPUT_CHANGE: {
      let { name, value } = action.payload.e.target;

      const mapPoiIndex = action.payload.mapPoiIndex;
      const allDrawnMapPoiCoordinatesWg = cloneDeep(
        state.allDrawnMapPoiCoordinatesWg
      );
      const allDrawnMapPoiCoordinatesUtm = cloneDeep(
        state.allDrawnMapPoiCoordinatesUtm
      );

      if (name === "lat" && value < 0) {
        return { ...state };
      } else if (name === "lng" && value < 0) {
        return { ...state };
      } else if (value === "") {
        value = 0;
      }

      value = Number(value);
      if (name === "lat") {
        const valueInWg = reprojectUtmToWgPoint(
          [Number(allDrawnMapPoiCoordinatesUtm[mapPoiIndex].lng), value],
          state.crsDef
        ).lat.toFixed(6);

        allDrawnMapPoiCoordinatesWg[mapPoiIndex][0] = valueInWg;
        allDrawnMapPoiCoordinatesUtm[mapPoiIndex].lat = value;
      } else {
        const valueInWg = reprojectUtmToWgPoint(
          [value, Number(allDrawnMapPoiCoordinatesUtm[mapPoiIndex].lat)],
          state.crsDef
        ).lng.toFixed(6);
        allDrawnMapPoiCoordinatesWg[mapPoiIndex][1] = valueInWg;
        allDrawnMapPoiCoordinatesUtm[mapPoiIndex].lng = value;
      }
      return {
        ...state,
        allDrawnMapPoiCoordinatesUtm: allDrawnMapPoiCoordinatesUtm,
        allDrawnMapPoiCoordinatesWg: allDrawnMapPoiCoordinatesWg,
        tempKey: "",
        tempValue: "",
      };
    }

    case types.GO_TO_MAP_POI_PROPERTIES: {
      const selectedMapItemGeoProps =
        state.allDrawnMapPoiGeoProps[action.payload];

      return {
        ...state,
        inMapPoiDrawingMode: false,
        inDrawingMode: false,
        selectedMapPoiIndex: action.payload,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectType: pointOfInterestObjectType.value,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        allObjectSubtypes: getAllCorrespondingSubtypes(
          selectedMapItemGeoProps.t
        ),
      };
    }

    case types.GO_TO_MAP_POI_COORDINATES: {
      return {
        ...state,
        inDrawingMode: true,
        inMapPoiDrawingMode: true,
        selectedMapItemGeoProps: null,
      };
    }

    case types.TOGGLE_STREET_DRAWING_MODE: {
      let inPolygonDrawingMode = true;
      let objectTypeIndex = 6;

      if (state.inStreetDrawingMode) {
        inPolygonDrawingMode = false;
        objectTypeIndex = 0;
      }

      return {
        ...state,
        ...drawingInitialState,

        inStreetDrawingMode: !state.inStreetDrawingMode,
        inPolygonDrawingMode: inPolygonDrawingMode,

        allObjectSubtypes: streetSubtypes,
        objectType: "street",
        objectTypeIndex: objectTypeIndex,
        objectSubtypeIndex: 0,
        polygonPointsWg: [],
        polygonPointsUtm: [],
        inSelectMode: false,
        polygonDrawn: false,
        inputCoords: [
          { lng: "", lat: "" },
          { lng: "", lat: "" },
          { lng: "", lat: "" },
        ],
      };
    }

    case types.REMOVE_OBJECT_FROM_UPLOADED_GEOJSON: {
      // const indexOfPolygon = action.payload;
      // const selectedMapItemGeoProps = state.selectedMapItemGeoProps;
      // let geoJsonWg = cloneDeep(state.geoJsonWg);

      // // const index = geoJsonWg.features.findIndex((x) => {
      // //   x.properties.id === selectedMapItemGeoProps.id;
      // // });

      // const newProperties = geoJsonWg.features.filter((x) => {
      //   return x.properties.id !== selectedMapItemGeoProps.id;
      // });

      // geoJsonWg.features = newProperties;

      return {
        ...state,
      };
    }

    case types.CREATE_SINGLE_EMISSION_DRAWN_GEOJSON: {
      const crs = action.payload;
      const allDrawnEmissionCoordinatesUtm = cloneDeep(
        state.allDrawnEmissionCoordinatesUtm
      );
      const allDrawnEmissionGeoProps = cloneDeep(
        state.allDrawnEmissionGeoProps
      );
      let emissionDrawnGeoJsonUtmCopy = {
        type: "FeatureCollection",
        name: `urn:ogc:def:crs:EPSG::${crs}`,
        crs: {
          type: "name",
          properties: {
            name: `urn:ogc:def:crs:EPSG::${crs}`,
          },
        },
        features: [],
      };

      allDrawnEmissionGeoProps.map((x, i) => {
        return emissionDrawnGeoJsonUtmCopy.features.push({
          type: "Feature",
          properties: x,
          geometry: {
            type: "Point",
            coordinates: [
              Number(allDrawnEmissionCoordinatesUtm[i].lng),
              Number(allDrawnEmissionCoordinatesUtm[i].lat),
            ],
          },
        });
      });
      const emissionDrawnGeoJsonUtm = emissionDrawnGeoJsonUtmCopy;
      return {
        ...state,
        emissionDrawnGeoJsonUtm: emissionDrawnGeoJsonUtm,
      };
    }

    case types.CREATE_SINGLE_MAP_POI_DRAWN_GEOJSON: {
      const crs = action.payload;
      const allDrawnMapPoiCoordinatesUtm = cloneDeep(
        state.allDrawnMapPoiCoordinatesUtm
      );

      const allDrawnMapPoiGeoProps = cloneDeep(state.allDrawnMapPoiGeoProps);
      let mapPoiDrawnGeoJsonUtmCopy = {
        type: "FeatureCollection",
        name: `urn:ogc:def:crs:EPSG::${crs}`,
        crs: {
          type: "name",
          properties: {
            name: `urn:ogc:def:crs:EPSG::${crs}`,
          },
        },
        features: [],
      };

      allDrawnMapPoiGeoProps.map((x, i) => {
        return mapPoiDrawnGeoJsonUtmCopy.features.push({
          type: "Feature",
          properties: x,
          geometry: {
            type: "Point",
            coordinates: [
              Number(allDrawnMapPoiCoordinatesUtm[i].lng),
              Number(allDrawnMapPoiCoordinatesUtm[i].lat),
            ],
          },
        });
      });
      const mapPoiDrawnGeoJsonUtm = mapPoiDrawnGeoJsonUtmCopy;

      return {
        ...state,
        mapPoiDrawnGeoJsonUtm: mapPoiDrawnGeoJsonUtm,
      };
    }

    case types.MERGE_CHEMISTRY_GEOJSON: {
      const geoJsonWg = cloneDeep(state.geoJsonWg);
      const emissionGeoJsonWg = cloneDeep(state.emissionGeoJsonWg);
      const mapPoiGeoJsonWg = cloneDeep(state.mapPoiGeoJsonWg);

      geoJsonWg.features.push(...emissionGeoJsonWg.features);
      geoJsonWg.mapPoiGeoJsonWg.push(...mapPoiGeoJsonWg.features);

      return {
        ...state,
        geoJsonWg: geoJsonWg,
      };
    }

    case types.TOGGLE_EMISSION_DRAWN_VISIBILITY: {
      return {
        ...state,
        emissionDrawnVisibility: !state.emissionDrawnVisibility,
      };
    }

    case types.TOGGLE_MAP_POI_DRAWN_VISIBILITY: {
      return {
        ...state,
        mapPoiDrawnVisibility: !state.mapPoiDrawnVisibility,
      };
    }

    case types.TOGGLE_EMISSION_COPY_PROPS_MODE: {
      return {
        ...state,
        inEmissionCopyPropsMode: !state.inEmissionCopyPropsMode,
        assignedEmissionCopyId: [],
        assignedEmissionCopyCoords: [],
      };
    }

    case types.SET_MAP_DATA_SAVING_STATUS: {
      return {
        ...state,
        mapDataSavingStatus: action.payload,
      };
    }

    case types.SELECT_POLYGON_POINT: {
      return {
        ...state,
        selectedPolygonPoint: action.payload,
      };
    }

    case types.SELECT_EDITOR_POINT: {
      return {
        ...state,
        selectedPolygonPoint: action.payload,
        isPolygonEditorInputFocused: true,
      };
    }

    case types.TOGGLE_WIND_TURBINE_DRAWING_MODE: {
      return {
        ...state,
        ...drawingInitialState,
        inWindTurbineDrawingMode: !state.inWindTurbineDrawingMode,
        objectSubtypeIndex: 0,
        displayWindTurbineCursor: true,
        polygonDrawn: false,
      };
    }

    case types.ADD_WIND_TURBINE: {
      const addedWindTurbineCoordinatessWg = action.payload;
      const allDrawnWindTurbineCoordinatesWg = cloneDeep(
        state.allDrawnWindTurbineCoordinatesWg
      );
      const allDrawnWindTurbineCoordinatesUtm = cloneDeep(
        state.allDrawnWindTurbineCoordinatesUtm
      );
      let selectedWindTurbineIndex = cloneDeep(state.selectedWindTurbineIndex);
      const allDrawnWindTurbineGeoProps = cloneDeep(
        state.allDrawnWindTurbineGeoProps
      );
      const arrayOfWindTurbineId = cloneDeep(state.arrayOfWindTurbineId);
      let currentIncomingWindTurbineId = cloneDeep(
        state.currentIncomingWindTurbineId
      );

      --currentIncomingWindTurbineId;
      allDrawnWindTurbineCoordinatesWg.push({
        id: currentIncomingWindTurbineId,
        wtstate: 0,
        lat: addedWindTurbineCoordinatessWg[0],
        lng: addedWindTurbineCoordinatessWg[1],
      });

      const windTurbineUtmCoordinates = reprojectWgToUtmPoint(
        {
          lat: addedWindTurbineCoordinatessWg[0],
          lng: addedWindTurbineCoordinatessWg[1],
        },
        state.crsDef
      );

      allDrawnWindTurbineCoordinatesUtm.push({
        ...windTurbineUtmCoordinates,
        id: currentIncomingWindTurbineId,
        wtname: `Turbine ${-currentIncomingWindTurbineId - 5000}`,
      });

      allDrawnWindTurbineGeoProps.push({
        id: currentIncomingWindTurbineId,
        t: 10,
        t10: 1,
        wthh: 90,
        wtrd: 63,
        wtname: `Turbine ${-currentIncomingWindTurbineId - 5000}`,
        wtstate: 0,
      });

      arrayOfWindTurbineId.push(currentIncomingWindTurbineId);
      if (
        allDrawnWindTurbineGeoProps.length === 0 &&
        selectedWindTurbineIndex === null
      ) {
        selectedWindTurbineIndex = 0;
      } else {
        selectedWindTurbineIndex = allDrawnWindTurbineCoordinatesWg.length - 1;
      }

      return {
        ...state,
        allDrawnWindTurbineCoordinatesWg: allDrawnWindTurbineCoordinatesWg,
        allDrawnWindTurbineCoordinatesUtm: allDrawnWindTurbineCoordinatesUtm,
        selectedWindTurbineIndex: selectedWindTurbineIndex,
        allDrawnWindTurbineGeoProps: allDrawnWindTurbineGeoProps,
        arrayOfWindTurbineId: arrayOfWindTurbineId,
        currentIncomingWindTurbineId: currentIncomingWindTurbineId,
      };
    }

    case types.SELECT_WIND_TURBINE: {
      const selectedWindTurbineIndex = action.payload;
      if (state.inWindTurbineDrawingMode) {
        const selectedMapItemGeoProps =
          state.allDrawnWindTurbineGeoProps[selectedWindTurbineIndex];
        return {
          ...state,
          selectedWindTurbineIndex: selectedWindTurbineIndex,
          displayWindTurbineCursor: false,
          isWindTurbineDraggable: true,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
        };
      } else if (state.inDrawingMode) {
        return {
          ...state,
        };
      } else {
        const selectedMapItemGeoProps =
          state.allDrawnWindTurbineGeoProps[selectedWindTurbineIndex];
        return {
          ...state,
          selectedWindTurbineIndex: selectedWindTurbineIndex,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
          currentObjectType: getCurrentObjectType(selectedMapItemGeoProps),
          currentObjectSubtype: getCurrentObjectSubtype(
            selectedMapItemGeoProps
          ),
          allObjectSubtypes: getAllCorrespondingSubtypes(
            selectedMapItemGeoProps.t
          ),
          selectedMapItemGeoJson: null,
          selectedTreeIndex: null,
          selectedMapPoiIndex: null,
          selectedEmissionIndex: null,
        };
      }
    }

    case types.CHANGE_SELECTED_DRAWN_WIND_TURBINE_SUBTYPE: {
      const windTurbineId = action.payload.id;
      const subtypeIndex = action.payload.subtypeIndex;
      const allDrawnWindTurbineGeoProps = cloneDeep(
        state.allDrawnWindTurbineGeoProps
      );

      const selectedWindTurbineIndex = allDrawnWindTurbineGeoProps.findIndex(
        (x) => x.id === windTurbineId
      );

      allDrawnWindTurbineGeoProps[selectedWindTurbineIndex]["t10"] =
        subtypeIndex;

      let polygonData = cloneDeep(state.polygonData);
      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }

      let selectedMapItemGeoProps =
        allDrawnWindTurbineGeoProps[selectedWindTurbineIndex];
      return {
        ...state,
        allDrawnWindTurbineGeoProps: allDrawnWindTurbineGeoProps,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        polygonData: polygonData,
      };
    }

    case types.REMOVE_WIND_TURBINE: {
      const indexOfRemovedWindTurbine = action.payload;

      const allDrawnWindTurbineCoordinatesWg = cloneDeep(
        state.allDrawnWindTurbineCoordinatesWg
      );
      const allDrawnWindTurbineCoordinatesUtm = cloneDeep(
        state.allDrawnWindTurbineCoordinatesUtm
      );
      const arrayOfWindTurbineId = cloneDeep(state.arrayOfWindTurbineId);
      const allDrawnWindTurbineGeoProps = cloneDeep(
        state.allDrawnWindTurbineGeoProps
      );

      allDrawnWindTurbineCoordinatesWg.splice(indexOfRemovedWindTurbine, 1);
      allDrawnWindTurbineCoordinatesUtm.splice(indexOfRemovedWindTurbine, 1);
      arrayOfWindTurbineId.splice(indexOfRemovedWindTurbine, 1);
      allDrawnWindTurbineGeoProps.splice(indexOfRemovedWindTurbine, 1);

      let polygonData = cloneDeep(state.polygonData);
      if (
        state.selectedMapItemGeoProps &&
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        polygonData.filter((x) => x.id !== state.selectedMapItemGeoProps.id);
      }
      return {
        ...state,
        polygonData: polygonData,
        allDrawnWindTurbineCoordinatesWg: allDrawnWindTurbineCoordinatesWg,
        allDrawnWindTurbineCoordinatesUtm: allDrawnWindTurbineCoordinatesUtm,
        arrayOfWindTurbineId: arrayOfWindTurbineId,
        allDrawnWindTurbineGeoProps: allDrawnWindTurbineGeoProps,
        selectedTreeIndex: null,
      };
    }

    case types.UPDATE_WIND_TURBINE_POSITION_ON_DRAGEND: {
      const updatedCoordinates = action.payload.e;
      const indexOfWindTurbine = action.payload.idx;

      const allDrawnWindTurbineCoordinatesWg = cloneDeep(
        state.allDrawnWindTurbineCoordinatesWg
      );
      const allDrawnWindTurbineCoordinatesUtm = cloneDeep(
        state.allDrawnWindTurbineCoordinatesUtm
      );

      const updatedWindTurbinePositionCoordinatesWg = [
        updatedCoordinates.lat.toFixed(7),
        updatedCoordinates.lng.toFixed(7),
      ];

      const updatedWindTurbinePositionCoordinatesUtm = reprojectWgToUtmPoint(
        {
          lat: updatedWindTurbinePositionCoordinatesWg[0],
          lng: updatedWindTurbinePositionCoordinatesWg[1],
        },
        state.crsDef
      );

      // allDrawnWindTurbineCoordinatesWg[action.payload.idx] =
      //   updatedWindTurbinePositionCoordinatesWg;
      // allDrawnWindTurbineCoordinatesUtm[action.payload.idx] =
      //   updatedWindTurbinePositionCoordinatesUtm;

      allDrawnWindTurbineCoordinatesWg[indexOfWindTurbine].lat =
        updatedWindTurbinePositionCoordinatesWg[0];
      allDrawnWindTurbineCoordinatesWg[indexOfWindTurbine].lng =
        updatedWindTurbinePositionCoordinatesWg[1];

      allDrawnWindTurbineCoordinatesUtm[indexOfWindTurbine] = {
        ...updatedWindTurbinePositionCoordinatesUtm,
        id: allDrawnWindTurbineCoordinatesUtm[indexOfWindTurbine].id,
        wtname: allDrawnWindTurbineCoordinatesUtm[indexOfWindTurbine].wtname,
      };

      return {
        ...state,
        allDrawnWindTurbineCoordinatesWg: allDrawnWindTurbineCoordinatesWg,
        allDrawnWindTurbineCoordinatesUtm: allDrawnWindTurbineCoordinatesUtm,
      };
    }

    case types.TOGGLE_WIND_TURBINE_DRAWN_VISIBILITY: {
      return {
        ...state,
        windTurbineDrawnVisibility: !state.windTurbineDrawnVisibility,
      };
    }

    case types.ADD_ASSIGNED_WIND_TURBINE_COPY: {
      const childrenWindTurbineId = action.payload.properties.id;

      const windTurbineFeature = cloneDeep(state.selectedMapItemGeoProps);
      const parentWindTurbineId = windTurbineFeature.id;
      const assignedWindTurbineCopyCoords = cloneDeep(
        state.assignedWindTurbineCopyCoords
      );
      let assignedWindTurbineCopyId = cloneDeep(
        state.assignedWindTurbineCopyId
      );

      if (
        parentWindTurbineId === childrenWindTurbineId ||
        windTurbineFeature.t !== 10
      ) {
        return {
          ...state,
        };
      }

      if (assignedWindTurbineCopyId.includes(childrenWindTurbineId)) {
        const indexOfWindTurbine = assignedWindTurbineCopyId.findIndex(
          (x) => x === childrenWindTurbineId
        );
        assignedWindTurbineCopyId = assignedWindTurbineCopyId.filter(
          (x) => x !== childrenWindTurbineId
        );
        assignedWindTurbineCopyCoords.splice(indexOfWindTurbine, 1);
        return {
          ...state,
          assignedWindTurbineCopyId: assignedWindTurbineCopyId,
          assignedWindTurbineCopyCoords: assignedWindTurbineCopyCoords,
        };
      }

      const childrenWindTurbineCoords = action.payload.geometry.coordinates;

      if (childrenWindTurbineId < -5000) {
        assignedWindTurbineCopyCoords.push(childrenWindTurbineCoords);
      } else {
        const pointChildrenWindTurbineCoords = [
          childrenWindTurbineCoords[1],
          childrenWindTurbineCoords[0],
        ];
        assignedWindTurbineCopyCoords.push(pointChildrenWindTurbineCoords);
      }
      assignedWindTurbineCopyId.push(childrenWindTurbineId);
      return {
        ...state,
        assignedWindTurbineCopyId: assignedWindTurbineCopyId,
        assignedWindTurbineCopyCoords: assignedWindTurbineCopyCoords,
      };
    }

    case types.ENABLE_WIND_TURBINE_DRAGGABLE: {
      return {
        ...state,
        isWindTurbineDraggable: true,
      };
    }

    case types.DISABLE_WIND_TURBINE_DRAGGABLE: {
      return {
        ...state,
        isEmissionDraggable: false,
      };
    }

    case types.TOGGLE_WIND_TURBINE_CURSOR_DISPLAY: {
      let isWindTurbineDraggable = cloneDeep(state.isWindTurbineDraggable);

      if (state.displayWindTurbineCursor) {
        isWindTurbineDraggable = true;
      }
      return {
        ...state,
        displayWindTurbineCursor: !state.displayWindTurbineCursor,
        isWindTurbineDraggable: isWindTurbineDraggable,
      };
    }

    case types.UPDATE_WIND_TURBINE_POSITION_ON_INPUT_CHANGE: {
      let { name, value } = action.payload.e.target;

      const windTurbineIndex = action.payload.windTurbineIndex;
      const allDrawnWindTurbineCoordinatesWg = cloneDeep(
        state.allDrawnWindTurbineCoordinatesWg
      );
      const allDrawnWindTurbineCoordinatesUtm = cloneDeep(
        state.allDrawnWindTurbineCoordinatesUtm
      );

      if (name === "lat" && value < 0) {
        return { ...state };
      } else if (name === "lng" && value < 0) {
        return { ...state };
      } else if (value === "") {
        value = 0;
      }

      value = Number(value);
      if (name === "lat") {
        const valueInWg = reprojectUtmToWgPoint(
          [
            Number(allDrawnWindTurbineCoordinatesUtm[windTurbineIndex].lng),
            value,
          ],
          state.crsDef
        ).lat.toFixed(6);

        // allDrawnWindTurbineCoordinatesWg[windTurbineIndex][0] = valueInWg;
        // allDrawnWindTurbineCoordinatesUtm[windTurbineIndex].lat = value;

        allDrawnWindTurbineCoordinatesWg[windTurbineIndex].lat = valueInWg;
        allDrawnWindTurbineCoordinatesUtm[windTurbineIndex].lat = value;
      } else {
        const valueInWg = reprojectUtmToWgPoint(
          [
            value,
            Number(allDrawnWindTurbineCoordinatesUtm[windTurbineIndex].lat),
          ],
          state.crsDef
        ).lng.toFixed(6);

        // allDrawnWindTurbineCoordinatesWg[windTurbineIndex][1] = valueInWg;
        // allDrawnWindTurbineCoordinatesUtm[windTurbineIndex].lng = value;

        allDrawnWindTurbineCoordinatesWg[windTurbineIndex].lng = valueInWg;
        allDrawnWindTurbineCoordinatesUtm[windTurbineIndex].lng = value;
      }
      return {
        ...state,
        allDrawnWindTurbineCoordinatesUtm: allDrawnWindTurbineCoordinatesUtm,
        allDrawnWindTurbineCoordinatesWg: allDrawnWindTurbineCoordinatesWg,
        tempKey: "",
        tempValue: "",
      };
    }

    case types.GO_TO_WIND_TURBINE_PROPERTIES: {
      const selectedMapItemGeoProps =
        state.allDrawnWindTurbineGeoProps[action.payload];

      return {
        ...state,
        inWindTurbineDrawingMode: false,
        inDrawingMode: false,
        selectedWindTurbineIndex: action.payload,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        currentObjectType: windTurbineObjectType.value,
        currentObjectSubtype: getCurrentObjectSubtype(selectedMapItemGeoProps),
        allObjectSubtypes: getAllCorrespondingSubtypes(
          selectedMapItemGeoProps.t
        ),
      };
    }

    case types.GO_TO_WIND_TURBINE_COORDINATES: {
      return {
        ...state,
        inDrawingMode: true,
        inWindTurbineDrawingMode: true,
        selectedMapItemGeoProps: null,
      };
    }

    case types.CHANGE_SELECTED_GEOJSON_WIND_TURBINE_SUBTYPE: {
      const objectId = action.payload.objectId;
      const subtypeIndex = action.payload.subtypeIndex;
      let windTurbineGeoJsonWg = state.windTurbineGeoJsonWg;
      const featureIndexInGeoJson = windTurbineGeoJsonWg.features.findIndex(
        (x) => x.properties.id === objectId
      );

      let polygonData = cloneDeep(state.polygonData);

      if (
        !!polygonData.find((x) => x.id === state.selectedMapItemGeoProps.id)
      ) {
        if (polygonData.length === 1) {
          polygonData = [];
        } else {
          polygonData = polygonData.filter((x) => x.id !== objectId);
        }
      }
      const objectGeojsonProperties =
        windTurbineGeoJsonWg.features[featureIndexInGeoJson].properties;
      objectGeojsonProperties[`t${objectGeojsonProperties.t}`] = subtypeIndex;
      return {
        ...state,
        windTurbineGeoJsonWg: windTurbineGeoJsonWg,
        polygonData: polygonData,
        currentObjectSubtype: state.allObjectSubtypes.value[subtypeIndex - 1],
      };
    }

    case types.TOGGLE_WIND_TURBINE_COPY_PROPS_MODE: {
      return {
        ...state,
        inWindTurbineCopyPropsMode: !state.inWindTurbineCopyPropsMode,
        assignedWindTurbineCopyId: [],
        assignedWindTurbineCopyCoords: [],
      };
    }

    case types.COPY_WIND_TURBINE_PROPS: {
      const copySubtype = action.payload.copySubtype;
      const windTurbineWthh = action.payload.windTurbineWthh;
      const windTurbineWtrd = action.payload.windTurbineWtrd;
      const assignProperties = action.payload.assignProperties;

      const assignedWindTurbineCopyId = cloneDeep(
        state.assignedWindTurbineCopyId
      );
      const selectedMapItemGeoProps = cloneDeep(state.selectedMapItemGeoProps);
      const typeOfParentObject = selectedMapItemGeoProps.t;
      const parentPolygonId = selectedMapItemGeoProps.id;
      //let polygonData = JSON.parse(JSON.stringify(state.polygonData))
      let polygonData = cloneDeep(state.polygonData);

      const windTurbineGeoJsonWg = state.windTurbineGeoJsonWg;
      const allDrawnWindTurbineGeoProps = cloneDeep(
        state.allDrawnWindTurbineGeoProps
      );

      if (copySubtype) {
        const subtypeOfParentObject =
          selectedMapItemGeoProps[`t${typeOfParentObject}`];

        for (
          let assignedEmissionCopyIdIndex = 0;
          assignedEmissionCopyIdIndex < assignedWindTurbineCopyId.length;
          assignedEmissionCopyIdIndex++
        ) {
          let geoJsonFeatureChildIndex =
            windTurbineGeoJsonWg.features.findIndex((x) => {
              return (
                x.properties.id ===
                assignedWindTurbineCopyId[assignedEmissionCopyIdIndex]
              );
            });

          if (geoJsonFeatureChildIndex !== -1) {
            windTurbineGeoJsonWg.features[geoJsonFeatureChildIndex].properties[
              "t10"
            ] = subtypeOfParentObject;
          } else {
            for (
              let index = 0;
              index < allDrawnWindTurbineGeoProps.length;
              index++
            ) {
              if (
                allDrawnWindTurbineGeoProps[index].id ===
                assignedWindTurbineCopyId[assignedEmissionCopyIdIndex]
              ) {
                allDrawnWindTurbineGeoProps[index]["t10"] =
                  subtypeOfParentObject;
              }
            }
          }
        }
      }

      if (windTurbineWthh) {
        const parentPropertyValue = polygonData.filter((x) => {
          return x.id === parentPolygonId;
        })[0]?.wthh;

        const wthhValue = parentPropertyValue
          ? parentPropertyValue
          : selectedMapItemGeoProps.wthh;

        if (polygonData.length === 0) {
          assignedWindTurbineCopyId.map((x) => {
            polygonData.push({
              id: x,
              wthh: wthhValue,
            });
          });
        } else {
          for (
            let assignedEmissionCopyIdIndex = 0;
            assignedEmissionCopyIdIndex < assignedWindTurbineCopyId.length;
            assignedEmissionCopyIdIndex++
          ) {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              if (
                polygonData[polygonDataIndex].id ===
                assignedWindTurbineCopyId[assignedEmissionCopyIdIndex]
              ) {
                polygonData[polygonDataIndex].wthh = wthhValue;
              }
            }
          }
        }

        let nonPolygonDataChildren = assignedWindTurbineCopyId.filter(
          (x) => !polygonData.some((y) => x === y.id)
        );

        if (nonPolygonDataChildren.length !== 0) {
          nonPolygonDataChildren.map((x) => {
            polygonData.push({
              id: x,
              wthh: wthhValue,
            });
          });
        }
      }

      if (windTurbineWtrd) {
        const parentPropertyValue = polygonData.filter((x) => {
          return x.id === parentPolygonId;
        })[0]?.wtrd;

        const wtrdValue = parentPropertyValue
          ? parentPropertyValue
          : selectedMapItemGeoProps.wtrd;

        if (polygonData.length === 0) {
          assignedWindTurbineCopyId.map((x) => {
            polygonData.push({
              id: x,
              wtrd: wtrdValue,
            });
          });
        } else {
          for (
            let assignedEmissionCopyIdIndex = 0;
            assignedEmissionCopyIdIndex < assignedWindTurbineCopyId.length;
            assignedEmissionCopyIdIndex++
          ) {
            for (
              let polygonDataIndex = 0;
              polygonDataIndex < polygonData.length;
              polygonDataIndex++
            ) {
              if (
                polygonData[polygonDataIndex].id ===
                assignedWindTurbineCopyId[assignedEmissionCopyIdIndex]
              ) {
                polygonData[polygonDataIndex].wtrd = wtrdValue;
              }
            }
          }
        }

        let nonPolygonDataChildren = assignedWindTurbineCopyId.filter(
          (x) => !polygonData.some((y) => x === y.id)
        );

        if (nonPolygonDataChildren.length !== 0) {
          nonPolygonDataChildren.map((x) => {
            polygonData.push({
              id: x,
              wtrd: wtrdValue,
            });
          });
        }
      }

      if (assignProperties) {
        const copyPolygonData = cloneDeep(polygonData);
        if (
          copyPolygonData.length !== 0 &&
          copyPolygonData.some((x) => x.id === parentPolygonId)
        ) {
          const parentData = copyPolygonData.find(
            (x) => x.id === parentPolygonId
          );

          if (parentData["wthh"]) {
            delete parentData["wthh"];
          }

          if (parentData["wtrd"]) {
            delete parentData["wtrd"];
          }

          const childrenPolygonDataCopyId = assignedWindTurbineCopyId.filter(
            (a) => copyPolygonData.some((b) => b.id === a)
          );

          if (childrenPolygonDataCopyId.length === 0) {
            assignedWindTurbineCopyId.map((x) => {
              polygonData.push({ ...parentData, id: x });
            });
          } else {
            for (
              let assignedEmissionCopyIdIndex = 0;
              assignedEmissionCopyIdIndex < assignedWindTurbineCopyId.length;
              assignedEmissionCopyIdIndex++
            ) {
              for (
                let polygonDataIndex = 0;
                polygonDataIndex < polygonData.length;
                polygonDataIndex++
              ) {
                if (
                  polygonData[polygonDataIndex].id ===
                  assignedWindTurbineCopyId[assignedEmissionCopyIdIndex]
                ) {
                  let childPolygonDataWthh = polygonData[polygonDataIndex].wthh;
                  let childPolygonDataWtrd = polygonData[polygonDataIndex].wtrd;

                  if (childPolygonDataWthh && childPolygonDataWtrd) {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedWindTurbineCopyId[
                        assignedEmissionCopyIdIndex
                      ],
                      wthh: childPolygonDataWthh,
                      wtrd: childPolygonDataWtrd,
                    };
                  } else if (childPolygonDataWthh) {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedWindTurbineCopyId[
                        assignedEmissionCopyIdIndex
                      ],
                      wthh: childPolygonDataWthh,
                    };
                  } else if (childPolygonDataWtrd) {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedWindTurbineCopyId[
                        assignedEmissionCopyIdIndex
                      ],
                      wtrd: childPolygonDataWtrd,
                    };
                  } else {
                    polygonData[polygonDataIndex] = {
                      ...parentData,
                      id: assignedWindTurbineCopyId[
                        assignedEmissionCopyIdIndex
                      ],
                    };
                  }
                }
              }
            }
          }
          let nonPolygonDataChildren = assignedWindTurbineCopyId.filter(
            (x) => !polygonData.some((y) => x === y.id)
          );

          if (nonPolygonDataChildren.length !== 0) {
            nonPolygonDataChildren.map((x) => {
              polygonData.push({ ...parentData, id: x });
            });
          }
        }
      }

      return {
        ...state,
        assignedCopyCoords: [],
        assignedWindTurbineCopyCoords: [],
        assignedWindTurbineCopyId: [],
        polygonData: polygonData,
        inWindTurbineCopyPropsMode: false,
        windTurbineGeoJsonWg: windTurbineGeoJsonWg,
        allDrawnWindTurbineGeoProps: allDrawnWindTurbineGeoProps,
      };
    }

    case types.CREATE_SINGLE_WIND_TURBINE_DRAWN_GEOJSON: {
      const crs = action.payload;
      const allDrawnWindTurbineCoordinatesUtm = cloneDeep(
        state.allDrawnWindTurbineCoordinatesUtm
      );
      const allDrawnWindTurbineGeoProps = cloneDeep(
        state.allDrawnWindTurbineGeoProps
      );
      let windTurbineDrawnGeoJsonUtmCopy = {
        type: "FeatureCollection",
        name: `urn:ogc:def:crs:EPSG::${crs}`,
        crs: {
          type: "name",
          properties: {
            name: `urn:ogc:def:crs:EPSG::${crs}`,
          },
        },
        features: [],
      };

      allDrawnWindTurbineGeoProps.map((x, i) => {
        return windTurbineDrawnGeoJsonUtmCopy.features.push({
          type: "Feature",
          properties: x,
          geometry: {
            type: "Point",
            coordinates: [
              Number(allDrawnWindTurbineCoordinatesUtm[i].lng),
              Number(allDrawnWindTurbineCoordinatesUtm[i].lat),
            ],
          },
        });
      });
      const windTurbineDrawnGeoJsonUtm = windTurbineDrawnGeoJsonUtmCopy;
      return {
        ...state,
        windTurbineDrawnGeoJsonUtm: windTurbineDrawnGeoJsonUtm,
      };
    }

    case types.SET_MARKER_REF: {
      return {
        ...state,
        markerRef: action.payload,
      };
    }

    case types.CHANGE_SELECTED_WIND_TURBINE_STATE: {
      const windTurbineStateIndex = action.payload;
      const id = state.selectedMapItemGeoProps.id;
      const selectedMapItemGeoProps = cloneDeep(state.selectedMapItemGeoProps);

      selectedMapItemGeoProps.wtstate = windTurbineStateIndex;

      if (id > 0) {
        const windTurbineGeoJsonWg = cloneDeep(state.windTurbineGeoJsonWg);
        const selectedMapItemGeoJson = cloneDeep(state.selectedMapItemGeoJson);
        const featureIndexInGeoJson = windTurbineGeoJsonWg.features.findIndex(
          (x) => x.properties.id === id
        );

        windTurbineGeoJsonWg.features[
          featureIndexInGeoJson
        ].properties.wtstate = windTurbineStateIndex;
        selectedMapItemGeoJson.properties.wtstate = windTurbineStateIndex;

        return {
          ...state,
          windTurbineGeoJsonWg: windTurbineGeoJsonWg,
          selectedMapItemGeoJson: selectedMapItemGeoJson,
          selectedMapItemGeoProps: selectedMapItemGeoProps,
        };
      }

      const allDrawnWindTurbineGeoProps = cloneDeep(
        state.allDrawnWindTurbineGeoProps
      );
      const allDrawnWindTurbineCoordinatesWg = cloneDeep(
        state.allDrawnWindTurbineCoordinatesWg
      );
      const selectedWindTurbineGeoPropsIndex =
        allDrawnWindTurbineGeoProps.findIndex((x) => {
          return x.id === id;
        });

      const selectedWindTurbineCoordinatesWgIndex =
        allDrawnWindTurbineCoordinatesWg.findIndex((x) => {
          return x.id === id;
        });

      allDrawnWindTurbineCoordinatesWg[
        selectedWindTurbineCoordinatesWgIndex
      ].wtstate = windTurbineStateIndex;

      allDrawnWindTurbineGeoProps[selectedWindTurbineGeoPropsIndex].wtstate =
        windTurbineStateIndex;

      return {
        ...state,
        allDrawnWindTurbineGeoProps: allDrawnWindTurbineGeoProps,
        selectedMapItemGeoProps: selectedMapItemGeoProps,
        allDrawnWindTurbineCoordinatesWg: allDrawnWindTurbineCoordinatesWg,
      };
    }

    default:
      return state;
  }
};

export default mapReducer;
