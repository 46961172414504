import React from "react";
import L from "leaflet";
import { GeoJSON } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import mapPoiMarkerUrl from "./map-poi-icon.svg";
import highlightedMapPoiMarkerUrl from "./map-poi-highlighted-icon.svg";
import { setMarkerRef } from "../../../../redux/actions/mapActions";
import { connect } from "react-redux";

class MapPoiGeojson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.pointToMapPoiLayer = this.pointToMapPoiLayer.bind(this);
    this.createMapPoiClusterIcon = this.createMapPoiClusterIcon.bind(this);
    this.setMarkerName = this.setMarkerName.bind(this);
  }

  pointToMapPoiLayer(feature, latlng) {
    const mapPoiProperties = this.props.polygonData.find((x) => {
      return x.id === feature.properties.id;
    });
    let tooltipContent = feature.properties.name;
    if (mapPoiProperties && mapPoiProperties.name) {
      tooltipContent = mapPoiProperties.name;
    }

    const icon = mapPoiMarker;
    const marker = L.marker(latlng, { icon: icon, zIndexOffset: 10 });

    marker.bindTooltip(`${tooltipContent}`, {
      permanent: true,
      className: `map-poi-tooltip ${
        this.props.mapPoiTooltipVisibility ? "" : "map-poi-tooltip-hidden"
      }`,
      direction: "top",
      offset: [0, -10],
    });
    marker.on("click", this.setMarkerName);
    return marker;
  }

  setMarkerName(marker) {
    this.props.setMarkerRef(marker.target);
  }

  createMapPoiClusterIcon(cluster) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster marker-cluster-map-poi span",
      iconSize: L.point(40, 40, true),
    });
  }

  render() {
    return (
      <>
        {this.props.isGeoJsonVisible &&
          this.props.polygonData &&
          !this.props.mapPoiTooltipVisibility && (
            <MarkerClusterGroup
              iconCreateFunction={this.createMapPoiClusterIcon}
              disableClusteringAtZoom={19}
              showCoverageOnHover
              zoomToBoundsOnClick
              spiderfyOnMaxZoom={false}
              // onAnimationEnd={() => {}}
            >
              {this.props.mapPoiGeoJsonWg &&
                !this.props.hiddenObjectTypesIndexes.includes(10) && (
                  <GeoJSON
                    key={this.props.mapPoiGeoJsonWg ? Date.now() : null}
                    data={this.props.mapPoiGeoJsonWg}
                    onEachFeature={this.props.onEachFeature}
                    pointToLayer={this.pointToMapPoiLayer}
                  />
                )}
            </MarkerClusterGroup>
          )}
        {this.props.isGeoJsonVisible &&
          this.props.polygonData &&
          this.props.mapPoiGeoJsonWg &&
          !this.props.hiddenObjectTypesIndexes.includes(10) &&
          this.props.mapPoiTooltipVisibility && (
            <GeoJSON
              key={this.props.mapPoiGeoJsonWg ? Date.now() : null}
              data={this.props.mapPoiGeoJsonWg}
              onEachFeature={this.props.onEachFeature}
              pointToLayer={this.pointToMapPoiLayer}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapPoiGeoJsonWg: state.map.mapPoiGeoJsonWg,
    isGeoJsonVisible: state.rasterArea.isGeoJsonVisible,
    polygonData: state.map.polygonData,
    hiddenObjectTypesIndexes: state.legend.hiddenObjectTypesIndexes,
    mapPoiTooltipVisibility: state.legend.mapPoiTooltipVisibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMarkerRef: (payload) => dispatch(setMarkerRef(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPoiGeojson);

const mapPoiMarker = new L.Icon({
  iconUrl: mapPoiMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

const highlightedMapPoiMarker = new L.Icon({
  iconUrl: highlightedMapPoiMarkerUrl,
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "tree-style",
});

export { mapPoiMarker, highlightedMapPoiMarker };
